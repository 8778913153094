import React from 'react';
import { Linkedin, Twitter, Home, Facebook, ExternalLink } from '-/style/Icon';
const qualifiedUrlRe = /^https?:\/\//i;
const WebsiteIcons = {
    homepage: Home,
    linkedin: Linkedin,
    twitter: Twitter,
    facebook: Facebook
};
const WebsiteLink = props => {
    const { style, className, type, url: urlRaw, size = 16, iconStyle } = props;
    const Icon = WebsiteIcons[type] || ExternalLink;
    let url = urlRaw;
    if (urlRaw && !qualifiedUrlRe.test(urlRaw.toLowerCase())) {
        url = 'http://' + urlRaw;
    }
    if (!url) {
        return null;
    }
    return (
        <a
            className={className}
            style={style}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Icon size={size} style={iconStyle} />
        </a>
    );
};

export default WebsiteLink;
