import React, { Fragment } from 'react';
import MattermarkChart from './view/charts/Mattermark';
import MetricsConfigForm from '-/company/form/Metrics';
import { get } from 'lodash';
import { Container as ContainerRaw, Row, Col } from '-/style/base/Layout';
import { GET_COMPANY_METRICS } from '-/gql/company';
import { potentialMetrics } from '-/shared/constants';
import { graphql } from 'react-apollo';
import Box from '-/style/base/Box';
import styled from 'styled-components';
import LoadingLogo from '-/components/LoadingLogo';
import MainHeader from '-/style/base/MainHeader';
import OverlayWithState from '-/components/OverlayWithState';
import SemrushMobileDesktopChart from './view/charts/SemrushMobileDesktop';

const Container = styled(ContainerRaw)`
    @media print {
        display: none;
    }
`;



const Metrics = props => {
    const loading = get(props, 'data.loading', true);
    if (loading) {
        return <LoadingLogo />;
    }
    // const error = get(props, 'data.error');
    const permalink = get(props, 'match.params.permalink');
    const company = get(props, 'data.Company');
    const analytics = get(company, 'analytics', {});
    const metricsConfig = get(company, 'metrics', {});
    const titleText = get(company, 'name') || permalink;

    const title = (
        <Fragment>
            <span>{titleText}</span>
            <OverlayWithState closeOnOutsideClick={false} closeOnEsc>
                <MetricsConfigForm _id={permalink} />
            </OverlayWithState>
        </Fragment>
    );

    return (
        <Container>
            <MainHeader title={title} />
            <Row>
                <Col flex>
                    <Box padding={false}>
                        <Row gutter={30}>
                            {potentialMetrics.map(metricInfo => {
                                const { title: titleRaw, key } = metricInfo;
                                const chartInfo = analytics[key];
                                const metricConfig = metricsConfig[key];
                                const title =
                                    key === 'googleTrends' && metricConfig
                                        ? `${titleRaw} "${metricConfig.keyword}"`
                                        : titleRaw;
                                const chartTypes = {
                                    users: SemrushMobileDesktopChart,
                                    visits: SemrushMobileDesktopChart
                                };
                                const Chart = chartTypes[key] || MattermarkChart;

                                return (
                                    <Col
                                        key={key}
                                        flex={6}
                                        sm={12}
                                        xs={12}
                                        style={{
                                            height: 360
                                        }}
                                    >
                                        <Chart
                                            key={key}
                                            chartType={key}
                                            title={title}
                                            {...chartInfo}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </Box>
                </Col>
            </Row>
        </Container>
    );
};

const returnPermalink = props => {
    const permalink = get(props, 'match.params.permalink');
    const search = get(props, 'location.search', '');
    const skipSmooth = search.indexOf('skipSmooth') !== -1;

    return {
        variables: {
            permalink,
            skipSmooth
        }
    };
};
export default graphql(GET_COMPANY_METRICS, {
    options: returnPermalink
})(Metrics);
