import React from 'react';
import Label from './Label';
import Feedback from './Feedback';
import { uniqueId } from 'lodash';
import { StyledTextarea as StyledInputDefault } from './styled';

const Textarea = props => {
    const {
        className,
        style,
        field = {},
        fieldId: fieldIdRaw,
        label,
        error,
        StyledInput = StyledInputDefault,
        inputStyle
    } = props;
    const fieldId = fieldIdRaw || uniqueId('textarea-');
    const { value: valueRaw, ...fieldRest } = field;
    const value = valueRaw || '';
    return (
        <div className={className} style={style}>
            {label ? (
                <Label htmlFor={fieldId} error={error}>
                    {label}
                </Label>
            ) : null}
            <StyledInput
                id={fieldId}
                value={value}
                style={inputStyle}
                {...fieldRest}
            />
            <Feedback error={error} />
        </div>
    );
};

export default Textarea;
