import React, { Fragment, Component } from 'react';
import RawSelect, { components } from 'react-select';
import RawAsyncSelect from 'react-select/lib/Async';
import { withApollo } from 'react-apollo';
import styled from 'styled-components';
import { uniqueId, get, find } from 'lodash';
import Highlighter from 'react-highlight-words';

import Label from './Label';
import Feedback from './Feedback';
import { transformOptions } from './utils';
import { Search } from '-/style/Icon';
const StyledRawSelect = styled(RawSelect)`
    margin-bottom: 10px;
`;
const StyledRawAsyncSelect = styled(RawAsyncSelect)`
    margin-bottom: 10px;
`;

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <Search size={20} />
        </components.DropdownIndicator>
    );
};

const wrapOption = (option, availableOptions) => {
    const type = typeof option;
    if (type === 'string' || type === 'number') {
        if (availableOptions) {
            const optFound = find(availableOptions, o => {
                return o && o.value === option;
            });
            if (optFound) {
                return {
                    label: optFound.label,
                    value: option
                };
            }
        }
        return {
            label: option,
            value: option
        };
    } else if (option && option._id && option.name) {
        const { name, _id } = option;
        return {
            label: name,
            value: _id
        };
    }
    return option;
};

// https://codesandbox.io/s/k20x3ox31o
const findChunksAtBeginningOfWords = ({
    autoEscape,
    caseSensitive,
    sanitize,
    searchWords,
    textToHighlight
}) => {
    const chunks = [];
    const textLow = textToHighlight.toLowerCase();
    // Match at the beginning of each new word
    // New word start after whitespace or - (hyphen)
    const sep = /[-|'|(\s]+/;

    // Match at the beginning of each new word
    // New word start after whitespace or - (hyphen)
    const singleTextWords = textLow.split(sep);

    // It could be possible that there are multiple spaces between words
    // Hence we store the index (position) of each single word with textToHighlight
    let fromIndex = 0;
    const singleTextWordsWithPos = singleTextWords.map(s => {
        const indexInWord = textLow.indexOf(s, fromIndex);
        fromIndex = indexInWord;
        return {
            word: s,
            index: indexInWord
        };
    });

    // Add chunks for every searchWord
    searchWords.forEach(sw => {
        const swLow = sw.toLowerCase();
        // Do it for every single text word
        singleTextWordsWithPos.forEach(s => {
            if (s.word.startsWith(swLow)) {
                const start = s.index;
                const end = s.index + swLow.length;
                chunks.push({
                    start,
                    end
                });
            }
        });

        // The complete word including whitespace should also be handled, e.g.
        // searchWord='Angela Mer' should be highlighted in 'Angela Merkel'
        if (textLow.startsWith(swLow)) {
            const start = 0;
            const end = swLow.length;
            chunks.push({
                start,
                end
            });
        }
    });

    return chunks;
};

// https://github.com/JedWatson/react-select/issues/3067#issue-363771398
// const customFilterOption = (option, rawInput) => {
//     const words = rawInput.split(' ');
//     return words.reduce(
//         (acc, cur) =>
//             acc &&
//             ('' + option.label).toLowerCase().includes(cur.toLowerCase()),
//         true
//     );
// };

class AutoComplete extends Component {
    handleChange = value => {
        // TODO: Needs to handle multiple.
        const {
            multiple,
            field: { name } = {},
            form: { setFieldValue } = {},
            onChange
        } = this.props;
        if (multiple) {
            // THis terrible hack is because react-select wants wrapped objects like
            // {label, value} but graphql/formik is all designed to accept value
            this.wrappedValue = value || [];
            const values = this.wrappedValue.map(o => o.value);
            setFieldValue(name, values);
            if (onChange) {
                onChange(values);
            }
        } else {
            this.wrappedValue = value;
            setFieldValue(name, value && value.value);
            if (onChange) {
                onChange(value && value.value);
            }
        }
    };

    handleBlur = () => {
        const { field: { name } = {}, onBlur } = this.props;

        // this is going to call setFieldTouched and manually update touched.{name}
        if (onBlur) {
            onBlur(name, true);
        }
    };

    // This may prove to be a little brittle but the idea is to
    // simplify searching by setting query and resultKey.
    // It currently requires that the query takes only ONE variable
    // of searchTerm and you must provide the resultKey of the Query
    // must return _id and name
    loadOptionsByQuery = async (searchTerm = '') => {
        const { client, query, resultKey, creatable = false } = this.props;

        const results = await client.query({
            query,
            variables: {
                searchTerm
            }
        });
        const options = get(results, `data.${resultKey}`);
        if (!options) {
            throw new Error(`No options found for resultKey "${resultKey}"`);
        }
        const processedOptions = options.map(o => {
            const {
                _id,
                permalink,
                isWhitelisted,
                name,
                relation,
                relatedName
            } = o;
            return {
                value: permalink || _id,
                isWhitelisted,
                label: name,
                relation,
                relatedName
            };
        });
        if (creatable && processedOptions.length === 0) {
            processedOptions.push({
                value: `_new_:${searchTerm}`,
                label: searchTerm
            });
        }
        return processedOptions;
    };

    render() {
        const {
            fieldId: fieldIdRaw,
            field: { value: valueRaw, ...restOfField },
            options: optionsRaw,
            wildcard,
            showValueInLabel,
            // overrides showValueInLabel
            formatOptionLabel,
            placeholder,
            error,
            query,
            loadOptions,
            multiple = false,
            isClearable = true,
            label,
            innerRef,
            valueKey,
            labelKey,
            disabled,
            style
        } = this.props;

        let transformedOptions;
        if (optionsRaw) {
            transformedOptions = transformOptions(optionsRaw, {
                valueKey,
                labelKey
            });
        }

        let wrappedValue = null;
        // https://stackoverflow.com/questions/50412843/how-to-programmatically-clear-reset-react-select-v2
        if (valueRaw !== null) {
            if (multiple) {
                wrappedValue =
                    this.wrappedValue ||
                    (valueRaw || []).map(wrapOption, transformedOptions);
            } else {
                wrappedValue =
                    this.wrappedValue ||
                    (valueRaw ? wrapOption(valueRaw, transformedOptions) : '');
            }
        }

        let SelectLib = StyledRawSelect;
        const fieldId = fieldIdRaw || uniqueId('autocomplete-');

        const defaultFormatOptionLabel = (option, config) => {
            const { label, value, relation, relatedName } = option;
            const { inputValue } = config;
            const labelAndValueMatch = label === value;
            if (showValueInLabel && !labelAndValueMatch) {
                return `${label} (${value})`;
            }
            if (wildcard) {
                let text = label;
                if (!['lp', 'company'].includes(relation) && relatedName) {
                    text += ` (${relatedName})`;
                }
                return (
                    <Highlighter
                        highlightTag="strong"
                        highlightStyle={{
                            fontWeight: 700,
                            textDecoration: 'underline'
                        }}
                        searchWords={inputValue.split(' ')}
                        textToHighlight={text}
                        findChunks={findChunksAtBeginningOfWords}
                    />
                );
            } else {
                return label;
            }
        };

        const rawSelectConfig = {
            isMulti: multiple,
            id: fieldId,
            isClearable,
            value: wrappedValue,
            placeholder,
            menuShouldScrollIntoView: false,
            formatOptionLabel: formatOptionLabel || defaultFormatOptionLabel,
            styles: {
                menuPortal: base => {
                    return {
                        ...base,
                        zIndex: 9999
                    };
                }
            },
            menuPortalTarget: document.body,
            components: {
                ...(wildcard && { DropdownIndicator })
            },
            isDisabled: disabled,
            ...restOfField
        };
        if (transformedOptions) {
            rawSelectConfig.options = transformedOptions;
        } else if (loadOptions) {
            SelectLib = StyledRawAsyncSelect;
            rawSelectConfig.loadOptions = loadOptions;
        } else if (query) {
            SelectLib = StyledRawAsyncSelect;
            rawSelectConfig.loadOptions = this.loadOptionsByQuery;
        }

        return (
            <Fragment>
                <Label htmlFor={fieldId} error={error}>
                    {label}
                </Label>
                <SelectLib
                    {...rawSelectConfig}
                    ref={innerRef}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    style={style}
                />
                <Feedback error={error} />
            </Fragment>
        );
    }
}

AutoComplete.defaultProps = {
    showValueInLabel: false
};

export default withApollo(AutoComplete);
