import styled from 'styled-components';
import { nearlyBlack } from './../variables';

export default styled.h2`
    font-size: 21px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 10px;
    color: ${nearlyBlack};
`;
