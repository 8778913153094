import React from 'react';
import styled from 'styled-components';
import { slate, darkSand } from '-/style/variables';

const StyledExitBar = styled.div`
    background-color: ${slate};
    color: white;
    text-transform: uppercase;
    text-align: center;
    border-top: 1px solid ${darkSand};
    padding: 10px;
`;
const ExitBar = props => {
    return (
        <StyledExitBar {...props}>
            Exit Edit Mode and View Profile
        </StyledExitBar>
    );
};

export default ExitBar;
