import gql from 'graphql-tag';

export const SEARCH_PEOPLE = gql`
    query SEARCH_PEOPLE($searchTerm: String!) {
        People(searchTerm: $searchTerm) {
            _id
            name
        }
    }
`;
export const GET_PERSON_DETAILS = gql`
    query GET_PERSON_INTRO($permalink: String!) {
        Person(permalink: $permalink) {
            _id
            name
            actionItem
            primaryFirm {
                _id
                name
            }
            primaryTitle
            notes {
                _id
                type
                text
                date
            }
            bio
        }
    }
`;

export const GET_PERSON_INTRO = gql`
    query GET_PERSON_INTRO($permalink: String!) {
        Person(permalink: $permalink) {
            _id
            name
            actionItem
        }
    }
`;

export const GET_PERSON_COMPANIES = gql`
    query GET_PERSON_COMPANIES($permalink: String!) {
        Person(permalink: $permalink) {
            _id
            companies {
                _id
                name
                description
                iconCategory
                iconStatus
                nextContactDate
                lastContactDate
                boardMembers {
                    _id
                    name
                    isWhitelisted
                }
                VCs {
                    _id
                    name
                    isWhitelisted
                }
            }
        }
    }
`;

export const GET_PERSON_SUMMARY = gql`
    query GET_PERSON_SUMMARY($permalink: String!) {
        Person(permalink: $permalink) {
            _id
            primaryFirm {
                _id
                name
            }
            primaryTitle
        }
    }
`;

export const GET_PERSON_NOTES = gql`
    query GET_PERSON_NOTES($permalink: String!) {
        Person(permalink: $permalink) {
            _id
            notes {
                _id
                type
                text
                date
            }
        }
    }
`;

export const GET_PERSON_INVESTMENTS = gql`
    query GET_PERSON_INVESTMENTS($permalink: String!) {
        Person(permalink: $permalink) {
            _id
            investments {
                _id
                fundingType
                series
                date
                amount
                firmInvestors {
                    _id
                    name
                    isWhitelisted
                    isTracked
                }
                company {
                    _id
                    name
                    description
                }
            }
        }
    }
`;

export const GET_PERSON_BIO = gql`
    query GET_PERSON_BIO($permalink: String!) {
        Person(permalink: $permalink) {
            _id
            bio
        }
    }
`;

export const ADD_NOTE_TO_PERSON = gql`
    mutation addNoteToPerson($permalink: ID!, $input: NoteInput!) {
        addNoteToPerson(permalink: $permalink, input: $input) {
            _id
            notes {
                _id
                date
                text
                type
            }
        }
    }
`;

export const UPDATE_NOTE_FOR_PERSON = gql`
    mutation updateNoteForPerson(
        $_id: ID!
        $permalink: String!
        $input: NoteInput!
    ) {
        updateNoteForPerson(_id: $_id, permalink: $permalink, input: $input) {
            _id
            date
            text
            type
        }
    }
`;

export const UPDATE_PERSON_BASIC_INFO = gql`
    mutation updatePersonBasicInfo($_id: ID!, $input: PersonBasicInfoInput) {
        updatePersonBasicInfo(_id: $_id, input: $input) {
            _id
            name
        }
    }
`;

export const REMOVE_NOTE_FOR_PERSON = gql`
    mutation removeNoteForPerson($_id: ID!, $permalink: String!) {
        removeNoteForPerson(_id: $_id, permalink: $permalink) {
            _id
            notes {
                _id
                date
                text
                type
            }
        }
    }
`;
