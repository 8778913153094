import React from 'react';
import Box from '-/style/base/Box';
import Subhead from '-/style/base/Subhead';
import { GET_COMPANY_MONDAY_REPORT_ACTIVE_PORTFOLIO } from '-/gql/company';
import { graphql } from 'react-apollo';
import { get } from 'lodash';
import StandardTable from './StandardTable';
import LoadingLogo from '-/components/LoadingLogo';
import { ActivePortfolioRow } from './StandardRow';
import { ActivePortfolioHeader } from './StandardHeader';
const ActivePortfolio = props => {
    const { data = {} } = props;
    const { loading } = data;
    const companies = get(data, 'CompanyMondayReport.activePortfolio', []);
    if (loading) {
        return <LoadingLogo />;
    }
    return (
        <Box>
            <Subhead>Active Portfolio Financings</Subhead>
            <StandardTable
                data={companies}
                Row={ActivePortfolioRow}
                Header={ActivePortfolioHeader}
            />
        </Box>
    );
};

export default graphql(GET_COMPANY_MONDAY_REPORT_ACTIVE_PORTFOLIO)(
    ActivePortfolio
);
