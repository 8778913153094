import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { Checkbox, TextInput } from '-/components/inputs';
import { Row, Col } from '-/style/base/Layout';
import { GreenButton as GreenButtonRaw } from '-/style/base/Button';
import styled from 'styled-components';
const GreenButton = styled(GreenButtonRaw)`
    margin: 0 0 0 10px;
`;

// Formik Collobrator recommendation on using same form with two buttons.
// https://github.com/jaredpalmer/formik/issues/214#issuecomment-471372166
class SaveDialog extends Component {
    render() {
        const { initialValues, close, onSubmit } = this.props;
        return (
            <Formik
                initialValues={initialValues}
                onSubmit={values => {
                    const { submitAction } = this;
                    onSubmit({
                        ...values,
                        submitAction
                    });
                    close();
                    delete this.submitAction;
                }}
            >
                {renderProps => {
                    return (
                        <Form style={{ padding: 10 }}>
                            <Field
                                label="Report Name"
                                component={TextInput}
                                name="name"
                            />
                            <Field
                                label="Built-In Report"
                                component={Checkbox}
                                name="builtin"
                            />
                            <Row>
                                <Col flex style={{ textAlign: 'right' }}>
                                    <GreenButton
                                        onClick={() => {
                                            this.submitAction = 'save';
                                            renderProps.submitForm();
                                        }}
                                    >
                                        Save
                                    </GreenButton>
                                    <GreenButton
                                        onClick={() => {
                                            this.submitAction = 'saveas';
                                            renderProps.submitForm();
                                        }}
                                    >
                                        Save As
                                    </GreenButton>
                                </Col>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}
export default SaveDialog;
