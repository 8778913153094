import React, { Fragment } from 'react';
import { GET_LP_FIT } from '-/gql/lp';
import LPQuery from './LPQuery';
import { SimpleDisplayField, MillionDisplayField } from '-/components/profiles';
const FitView = props => {
    const {
        iconFit: {
            typeOfFund,
            status,
            checkRange,
            probability,
            closedToDate,
            futureClose
        } = {}
    } = props;
    return (
        <Fragment>
            <SimpleDisplayField label="Type of Fund" value={typeOfFund} />
            <SimpleDisplayField label="Status" value={status} />
            <SimpleDisplayField label="VC Check Range" value={checkRange} />
            <SimpleDisplayField label="Probability" value={probability} />
            <MillionDisplayField label="Closed To Date" value={closedToDate} />
            <MillionDisplayField label="Future Close" value={futureClose} />
        </Fragment>
    );
};

const Fit = props => {
    const { permalink } = props;
    return (
        <LPQuery query={GET_LP_FIT} permalink={permalink} component={FitView} />
    );
};

export default Fit;
