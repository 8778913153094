import styled from 'styled-components';

const shared = `
    margin-bottom: 10px;
    margin-left: -1px;
    padding: 0.5rem;
`;

export const StyledInput = styled.input.attrs({ autoComplete: 'new-password' })`
    box-sizing: border-box;
    font-size: 12px;
    width: 100%;
    display: block;
    border: 1px solid #ccc;
    height: 38px;
    border-radius: 4px;
    ${shared}
`;
export const StyledTextarea = styled.textarea`
    box-sizing: border-box;
    font-size: 12px;
    width: 100%;
    display: block;
    border: 1px solid #ccc;
    height: 112px;
    resize: none;
    border-radius: 4px;
    ${shared}
`;

export const StyledSelect = styled.select`
    box-sizing: border-box;
    appearance: none;
    border-radius: 0;
    background-color: white;
    font-size: 12px;
    width: 100%;
    display: block;
    border: 1px solid #ccc;
    // NOTE: imagery taken from formalize
    background-image: url(data:image/png;base64,R0lGODlhDQAEAIAAAAAAAP8A/yH5BAEHAAEALAAAAAANAAQAAAILhA+hG5jMDpxvhgIAOw==);
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px;
    ${shared}
`;
