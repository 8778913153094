import gql from 'graphql-tag';

export const SEARCH_FIRM = gql`
    query SEARCH_FIRM($searchTerm: String!) {
        Firms(searchTerm: $searchTerm) {
            _id
            name
        }
    }
`;

export const SEARCH_FIRM_NOT_WHITELISTED = gql`
    query SEARCH_FIRM_NOT_WHITELISTED($searchTerm: String!) {
        NotWhitelistedFirms(searchTerm: $searchTerm) {
            _id
            name
            isWhitelisted
        }
    }
`;

export const GET_WHITELISTED_FIRMS = gql`
    query GET_WHITELISTED_FIRMS {
        WhitelistedFirms {
            _id
            name
            tier
            lastCBSuccess
            isWhitelisted
            isTracked
        }
    }
`;

export const GET_FIRM_DETAILS = gql`
    query GET_FIRM_DETAILS($permalink: String!) {
        Firm(permalink: $permalink) {
            _id
            name
            actionItem
            investments {
                _id
                company {
                    _id
                    name
                    iconCategory
                    iconStatus
                    description
                }
                firmInvestors {
                    _id
                    name
                    isWhitelisted
                }
                amount
                series
                date
                fundingType
            }
            founders {
                _id
                name
                isWhitelisted
            }
            partners {
                _id
                name
                isWhitelisted
                primaryTitle
            }
            location {
                street1
                street2
                city
                region
                postalCode
            }
            notes {
                _id
                type
                text
                date
                permalink
                entity {
                    ... on Person {
                        _id
                        name
                    }
                }
            }
            companies {
                _id
                name
                iconCategory
                iconStatus
                description
                lastContactDate
                nextContactDate
            }
        }
    }
`;

export const GET_FIRM_INTRO = gql`
    query GET_FIRM_INTRO($permalink: String!) {
        Firm(permalink: $permalink) {
            _id
            name
            actionItem
        }
    }
`;

export const GET_FIRM_SUMMARY = gql`
    query GET_FIRM_SUMMARY($permalink: String!) {
        Firm(permalink: $permalink) {
            _id
            name
            founders {
                _id
                name
                isWhitelisted
            }
            partners {
                _id
                name
                isWhitelisted
                primaryTitle
            }
            location {
                street1
                street2
                city
                region
                postalCode
            }
        }
    }
`;

export const GET_FIRM_NOTES = gql`
    query GET_FIRM_NOTES($permalink: String!) {
        Firm(permalink: $permalink) {
            _id
            notes {
                _id
                type
                text
                date
                permalink
                entity {
                    ... on Person {
                        _id
                        name
                    }
                }
            }
        }
    }
`;

export const GET_FIRM_INVESTMENTS = gql`
    query GET_FIRM_INVESTMENTS($permalink: String!) {
        Firm(permalink: $permalink) {
            _id
            investments {
                _id
                company {
                    _id
                    name
                    iconCategory
                    iconStatus
                    description
                }
                firmInvestors {
                    _id
                    name
                    isWhitelisted
                    isTracked
                }
                amount
                series
                date
                fundingType
            }
        }
    }
`;

export const GET_FIRM_COMPANIES = gql`
    query GET_FIRM_COMPANIES($permalink: String!) {
        Firm(permalink: $permalink) {
            _id
            companies {
                _id
                name
                iconCategory
                iconStatus
                description
                lastContactDate
                nextContactDate
            }
        }
    }
`;

export const UPDATE_FIRM_BASIC_INFO = gql`
    mutation UpdateFirmBasicInfo($_id: ID!, $input: FirmBasicInfoInput) {
        updateFirmBasicInfo(_id: $_id, input: $input) {
            _id
            name
        }
    }
`;

export const ADD_FIRM_TO_WHITELIST = gql`
    mutation AddFirmToWhitelist($_id: ID!) {
        addFirmToWhitelist(_id: $_id) {
            _id
            isWhitelisted
        }
    }
`;

export const UPDATE_FIRM_TIER = gql`
    mutation UpdateFirmTier($_id: ID!, $tier: String) {
        updateFirmTier(_id: $_id, tier: $tier) {
            _id
            tier
        }
    }
`;

export const UPDATE_FIRM_TIERS = gql`
    mutation UpdateFirmTiers($firms: [FirmTierUpdateInput]) {
        updateFirmTiers(firms: $firms) {
            _id
            tier
            isTracked
        }
    }
`;