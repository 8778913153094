import React from 'react';
import PersonQuery from './PersonQuery';
import { PersonLink, CompanyLink } from '-/components/Link';
import { withProps } from 'recompose';
import { GET_PERSON_COMPANIES } from '-/gql/person';
import { uniqBy } from 'lodash';
import { formatDate } from '-/utils/format';
import styled from 'styled-components';
import SimpleTable from '-/components/table/Simple';
import {
    StyledTr,
    StyledTd,
    StyledTh,
    StyledEmptyRow
} from '-/style/base/Table';
import KnownIcon from '-/style/base/KnownIcon';
import nl2br from '-/components/nl2br';

const StyledIndicators = styled.div`
    display: inline-block;
    float: left;
    width: 60px;
`;
const Label = styled.div`
    width: 30px;
    display: inline-block;
`;

const CompanyHeader = props => {
    return (
        <thead>
            <tr>
                <StyledTh width={180}>Company</StyledTh>
                <StyledTh>Description</StyledTh>
                <StyledTh width={160}>VCs</StyledTh>
                <StyledTh width={120}>Key Dates</StyledTh>
            </tr>
        </thead>
    );
};

const emptyRow = (
    <StyledEmptyRow colSpan={4}>No companies found.</StyledEmptyRow>
);
const CompanyRow = props => {
    const {
        currentVC,
        index,
        className,
        style,
        _id,
        name,
        description,
        iconCategory,
        iconStatus,
        nextContactDate,
        lastContactDate,
        boardMembers = [],
        VCs = []
    } = props;
    const people = uniqBy([...boardMembers, ...VCs], '_id').filter(p => {
        return p && p._id !== currentVC;
    });
    return (
        <StyledTr index={index} className={className} style={style}>
            <StyledTd>
                <StyledIndicators>
                    <KnownIcon width={16} height={16} type={iconCategory} />
                    <KnownIcon width={16} height={16} type={iconStatus} />
                </StyledIndicators>
                <CompanyLink permalink={_id}>{name}</CompanyLink>
            </StyledTd>
            <StyledTd>{nl2br(description)}</StyledTd>
            <StyledTd>
                {people.map(p => {
                    const { _id, isWhitelisted, name } = p;
                    return (
                        <div key={_id}>
                            {isWhitelisted ? (
                                <PersonLink permalink={_id}>{name}</PersonLink>
                            ) : (
                                <span>{name}</span>
                            )}
                        </div>
                    );
                })}
            </StyledTd>

            <StyledTd>
                <div>
                    <Label>NC: </Label>
                    {formatDate(nextContactDate, 'n/a')}
                </div>
                <div>
                    <Label>LC: </Label>
                    {formatDate(lastContactDate, 'n/a')}
                </div>
            </StyledTd>
        </StyledTr>
    );
};

const Companies = props => {
    const { permalink, className, style, companies = [] } = props;
    return (
        <div className={className} style={style}>
            <SimpleTable
                Header={CompanyHeader}
                EmptyRow={emptyRow}
                data={companies}
                Row={CompanyRow}
                rowProps={{ currentVC: permalink }}
            />
        </div>
    );
};

export default withProps({
    component: Companies,
    query: GET_PERSON_COMPANIES
})(PersonQuery);
