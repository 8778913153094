import React from 'react';
import { CustomQuery } from '-/gql/utils';
import { withProps } from 'recompose';
import { GET_THEME_DETAILS } from '-/gql/theme';
import { get } from 'lodash';
import { Container } from '-/style/base/Layout';
import Headline from '-/style/base/Headline';
import SimpleTable from '-/components/table/Simple';
import { SimpleDisplayField } from '-/components/profiles';
import {
    StyledTr,
    StyledTd,
    StyledTheadTr,
    StyledTh
} from '-/style/base/Table';
import { CompanyLink } from '-/components/Link';

const CompanyRow = props => {
    const {
        _id,
        index,
        name,
        description,
        iconCategory,
        iconStatus
    } = props;
    return (
        <StyledTr index={index}>
            <StyledTd><CompanyLink permalink={_id}>{name}</CompanyLink></StyledTd>
            <StyledTd>{description} </StyledTd>
            <StyledTd>{iconCategory}</StyledTd>
            <StyledTd>{iconStatus}</StyledTd>
        </StyledTr>
    );
};

const CompanyHeader = props => {
    return (
        <thead>
            <StyledTheadTr>
                <StyledTh>Name</StyledTh>
                <StyledTh>Description</StyledTh>
                <StyledTh>Category</StyledTh>
                <StyledTh>Status</StyledTh>
            </StyledTheadTr>
        </thead>
    );
};
const Static = props => {
    const {
        className,
        style,
        category,
        name,
        ingredient,
        priority,
        companies = []
    } = props;
    return (
        <Container className={className} style={style}>
            <Headline style={{ minHeight: 45, marginTop: 15 }}>{category} - {name}</Headline>
            <SimpleDisplayField label="Priority" value={priority} />
            <SimpleDisplayField label="Ingredient" value={ingredient ? 'Yes' : 'No'} />
            <SimpleTable data={companies} Row={CompanyRow} Header={CompanyHeader} />
        </Container>
    )
};


export default withProps(initialProps => {
    const permalink = get(initialProps, 'match.params.permalink', '');
    return {
        component: Static,
        resultKey: 'Theme',
        query: GET_THEME_DETAILS,
        permalink,
        ...initialProps
    };
})(CustomQuery);
