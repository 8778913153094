import gql from 'graphql-tag';

export const ADD_LP = gql`
    mutation addLP($input: LPNewInput!) {
        addLP(input: $input) {
            _id
            permalink
            name
            actionItem
            nextContactDate
            lastContactDate
            materialsSentDate
            phone
            summary
            address {
                street1
                city
                region
                postalCode
                area
            }
            notes {
                _id
                type
                text
                date
                user {
                    initials
                }
                materialsSent
            }
            people {
                name
                primary
                title
                email
                phone
            }
            referredBy {
                name
                title
                company
                email
            }
            iconFit {
                typeOfFund
                status
                checkRange
                priority
                probability
                closedToDate
                futureClose
            }
            commitments {
                iconFund
                amount
                legalName
            }
        }
    }
`;

export const UPDATE_LP = gql`
    mutation updateLP($_id: ID!, $input: LPNewInput!) {
        updateLP(_id: $_id, input: $input) {
            _id
            permalink
            name
            actionItem
            nextContactDate
            lastContactDate
            materialsSentDate
            phone
            summary
            address {
                street1
                city
                region
                postalCode
                area
            }
            notes {
                _id
                type
                text
                date
                user {
                    initials
                }
                materialsSent
            }
            people {
                name
                primary
                title
                email
                phone
            }
            referredBy {
                name
                title
                company
                email
            }
            iconFit {
                typeOfFund
                status
                checkRange
                priority
                probability
                closedToDate
                futureClose
            }
            commitments {
                iconFund
                amount
                legalName
            }
        }
    }
`;

export const ADD_NOTE_TO_LP = gql`
    mutation addNoteToLP($permalink: ID!, $input: NoteInput!) {
        addNoteToLP(permalink: $permalink, input: $input) {
            _id
            notes {
                _id
                date
                text
                type
                materialsSent
            }
            lastContactDate
        }
    }
`;

export const UPDATE_NOTE_FOR_LP = gql`
    mutation updateNoteForLP(
        $_id: ID!
        $permalink: String!
        $input: NoteInput!
    ) {
        updateNoteForLP(_id: $_id, permalink: $permalink, input: $input) {
            _id
            date
            text
            type
            materialsSent
        }
    }
`;

export const REMOVE_NOTE_FOR_LP = gql`
    mutation removeNoteForLP($_id: ID!, $permalink: String!) {
        removeNoteForLP(_id: $_id, permalink: $permalink) {
            _id
            notes {
                _id
                date
                text
                type
                materialsSent
            }
            lastContactDate
        }
    }
`;

export const GET_ALL_LPS = gql`
    query GET_ALL_LPS {
        LPs {
            _id
            name
            permalink
            actionItem
            nextContactDate
            materialsSentDate
            iconFit {
                status
            }
            address {
                city
                region
                area
            }
        }
    }
`;

export const GET_LP = gql`
    query GET_LP($permalink: String!) {
        LP(permalink: $permalink) {
            _id
            permalink
            name
            actionItem
            nextContactDate
            lastContactDate
            materialsSentDate
            phone
            summary
            address {
                street1
                city
                region
                postalCode
                area
            }
            notes {
                _id
                type
                text
                date
                user {
                    initials
                }
                materialsSent
            }
            people {
                name
                primary
                title
                email
                phone
            }
            referredBy {
                name
                title
                company
                email
            }
            iconFit {
                typeOfFund
                status
                checkRange
                priority
                probability
                closedToDate
                futureClose
            }
            commitments {
                iconFund
                amount
                legalName
            }
        }
    }
`;

export const GET_LP_INTRO = gql`
    query GET_LP_INTRO($permalink: String!) {
        LP(permalink: $permalink) {
            _id
            name
            actionItem
            nextContactDate
            lastContactDate
            materialsSentDate
        }
    }
`;

export const GET_LP_CONTACT = gql`
    query GET_LP_CONTACT($permalink: String!) {
        LP(permalink: $permalink) {
            _id
            phone
            address {
                street1
                city
                region
                postalCode
                area
            }
            people {
                name
                primary
                title
                email
                phone
            }
            referredBy {
                name
                title
                company
                email
            }
        }
    }
`;

export const GET_LP_SUMMARY = gql`
    query GET_LP_SUMMARY($permalink: String!) {
        LP(permalink: $permalink) {
            _id
            summary
        }
    }
`;

export const GET_LP_FIT = gql`
    query GET_LP_FIT($permalink: String!) {
        LP(permalink: $permalink) {
            _id
            iconFit {
                typeOfFund
                status
                checkRange
                priority
                probability
                closedToDate
                futureClose
            }
        }
    }
`;

export const GET_LP_COMMITMENTS = gql`
    query GET_LP_COMMITMENTS($permalink: String!) {
        LP(permalink: $permalink) {
            _id
            commitments {
                iconFund
                amount
                legalName
            }
        }
    }
`;

export const GET_LP_NOTES = gql`
    query GET_LP_NOTES($permalink: String!) {
        LP(permalink: $permalink) {
            _id
            notes {
                _id
                type
                text
                date
                user {
                    initials
                }
                materialsSent
            }
        }
    }
`;

export const ADVANCED_SEARCH_LP = gql`
    query ADVANCED_SEARCH_LP($criteria: LPSearchCriteria, $sortBy: String) {
        LPAdvancedSearch(criteria: $criteria, sortBy: $sortBy) {
            _id
            permalink
            name
            actionItem
            nextContactDate
            lastContactDate
            lastContactType
            materialsSentDate
            materialsSent {
                _id
                date
                note
                type
            }
            address {
                street1
                city
                region
                postalCode
                area
            }
            iconFit {
                status
                probability
                typeOfFund
                priority
                closedToDate
                checkRange
            }
            primaryContact {
                name
                email
                phone
            }
            primaryContacts {
                name
                email
                phone
            }
            referredBy {
                name
                company
                title
                email
            }
            updatedAt
        }
    }
`;
const CriteriaParts = gql`
    fragment CriteriaParts on LPCriteria {
        name
        region
        typeOfFund
        priority
        probability
        materialsSentType
        nextContactDate
    }
`;

export const GET_ALL_LP_REPORTS = gql`
    query GET_ALL_LP_REPORTS {
        LPReports {
            _id
            permalink
            builtin
            name
            sortBy
            lastRunDate
            lastRunBy {
                _id
                initials
            }
            totalRuns
            criteria {
                ...CriteriaParts
            }
        }
    }
    ${CriteriaParts}
`;

export const GET_LP_REPORT = gql`
    query GET_LP_REPORT($permalink: String!) {
        LPReport(permalink: $permalink) {
            _id
            name
            builtin
            sortBy
            columns
            criteria {
                ...CriteriaParts
            }
        }
    }
    ${CriteriaParts}
`;

export const ADD_LP_REPORT = gql`
    mutation ADD_LP_REPORT($input: LPReportInput!) {
        addLPReport(input: $input) {
            _id
            permalink
            name
            builtin
            sortBy
        }
    }
`;

export const UPDATE_LP_REPORT = gql`
    mutation UPDATE_LP_REPORT($permalink: String!, $input: LPReportInput!) {
        updateLPReport(permalink: $permalink, input: $input) {
            _id
            permalink
            name
            builtin
        }
    }
`;

export const REMOVE_LP_REPORT = gql`
    mutation REMOVE_LP_REPORT($permalink: String!) {
        removeLPReport(permalink: $permalink)
    }
`;

export const SEARCH_LP = gql`
    query SEARCH_LP($searchTerm: String!) {
        LPs(searchTerm: $searchTerm) {
            _id
            permalink
            name
        }
    }
`;

export const WILD_SEARCH_LP = gql`
    query WILD_SEARCH_LP($searchTerm: String!) {
        LPWildSearch(searchTerm: $searchTerm) {
            _id
            permalink
            name
            relation
            relatedName
        }
    }
`;

export const UPDATE_LP_ACTION_ITEM = gql`
    mutation UpdateLPActionItem($_id: ID!, $input: LPActionItemInput) {
        updateLPActionItem(_id: $_id, input: $input) {
            _id
            actionItem
            nextContactDate
        }
    }
`;
