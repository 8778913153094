import React, { Fragment, useState } from 'react';
import { compose, graphql, Query } from 'react-apollo';
import MainHeader from '-/style/base/MainHeader';
import { AutoComplete } from '-/components/inputs';
import { Row, Col } from '-/style/base/Layout';
import { Field, Formik, Form } from 'formik';
import { Container } from '-/style/base/Layout';
import SimpleTable from '-/components/table/Simple';
import { EditAction as Edit } from '-/style/Icon';
import MessageBox from '-/components/MessageBox';
import {
    StyledTable,
    StyledTr,
    StyledTd,
    StyledTh,
    StyledEmptyRow
} from '-/style/base/Table';
import { FirmLink } from '-/components/Link';
import moment from 'moment';
import { LinkButton } from '-/style/base/Link';
import LinkList from '-/style/base/LinkList';
import { GET_WHITELISTED_FIRMS, SEARCH_FIRM_NOT_WHITELISTED, ADD_FIRM_TO_WHITELIST } from '-/gql/firm';
import { GET_ME } from '-/gql/user';
import { GreenButton as GreenButtonRaw } from '-/style/base/Button';
import { get, isObject } from 'lodash';
import Dialog from '-/components/Dialog';
import styled from 'styled-components';
import { firmTiers } from '-/shared/constants';
import LoadingLogo from '-/components/LoadingLogo';
import TierForm from './../form/Tier';

const submitForm = (path, params = {}) => {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', path);

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            const valueRaw = params[key];
            const value = isObject(valueRaw) ? JSON.stringify(valueRaw) : valueRaw;
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            hiddenField.setAttribute('value', value);
            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
};

const colSpan = 3;
const Header = props => {
    return (
        <thead>
            <tr>
                <StyledTh>Firm Name</StyledTh>
                <StyledTh>Tier</StyledTh>
                <StyledTh>Last CB Update</StyledTh>
            </tr>
        </thead>
    );
};

const ListRowStatic = props => {
    const {
        _id,
        index,
        name,
        lastCBSuccess,
        tier
    } = props;

    const ago = lastCBSuccess ? moment(lastCBSuccess).fromNow() : 'Never';

    return (
        <Fragment>
            <StyledTr index={index}>
                <StyledTd>
                    <FirmLink permalink={_id}>{name}</FirmLink>
                </StyledTd>
                <StyledTd>
                    {tier || '---'}
                </StyledTd>
                <StyledTd>{ago}</StyledTd>
            </StyledTr>
        </Fragment>
    );
};


const emptyRow = <StyledEmptyRow colSpan={colSpan}>No firms on the whitelist for this tier.</StyledEmptyRow>;

const GreenButton = styled(GreenButtonRaw)`
    margin: 0 0 0 10px;
`;

const StandardRow = props => {
    return (
        <Row
            gutter={4}
            justifyContent="space-between"
            alignItems="flex-start"
            {...props}
        />
    );
};




const FirmTierManager = props => {
    const {
        editable,
        firms,
        formTierOpen,
        onFormEdit,
        onFormCancel
    } = props;


    const initFirms = firmTiers.reduce((final, tier) => {
        final[tier] = [];
        return final;
    }, { unassigned: [], dormant: [] });
    const firmsByTier = firms.reduce((final, firm) => {
        const { _id, tier, isTracked } = firm;
        if (_id !== 'iconventures') {
            let list;
            if (isTracked) {
                if (tier && final[tier]) {
                    list = final[tier];
                } else {
                    list = final.unassigned;
                }
            } else {
                list = final.dormant;
            }
            list.push(firm);
        }

        return final;
    }, initFirms);

    return (
        <StyledTable>
            <thead>
                <tr>
                    <th></th>
                    <th width={120}></th>
                </tr>
            </thead>
            <tbody>
                {['unassigned', ...firmTiers, 'dormant'].map((firmTier, index) => {
                    const isOpen = formTierOpen === firmTier;
                    const firms = firmsByTier[firmTier];
                    const allowEdit = editable && !isOpen && firms.length;
                    return (
                        <Fragment key={firmTier}>

                            <StyledTr index={index}>
                                <StyledTd>{firmTier} Tier ({firms.length} firms)</StyledTd>
                                <StyledTd>
                                    {allowEdit ? <Edit size={16} onClick={() => {
                                        if (formTierOpen) {
                                            MessageBox.show({
                                                message: (
                                                    <div>
                                                        Would you like to proceed?<br />
                                                        Any unsaved changes will be discarded.
                                                    </div>
                                                ),
                                                onYesClick: async () => {
                                                    onFormEdit(firmTier);
                                                }
                                            });
                                        } else {
                                            onFormEdit(firmTier);
                                        }
                                    }}>
                                        Edit
                                    </Edit> : null}
                                </StyledTd>
                            </StyledTr>
                            <StyledTr key={firmTier} index={index}>
                                <StyledTd colSpan={2}>
                                    {isOpen ? <TierForm
                                        isTracked={firmTier !== 'dormant'}
                                        initialValues={{ firms }}
                                        onCancel={onFormCancel}
                                        tier={firmTier} /> :
                                        <SimpleTable
                                            Header={Header}
                                            EmptyRow={emptyRow}
                                            data={firms}
                                            Row={ListRowStatic}
                                        />}
                                </StyledTd>
                            </StyledTr>
                        </Fragment>
                    );
                })}
            </tbody>
        </StyledTable >
    );
};

const ListRaw = props => {
    const { className, style, addFirmToWhitelist } = props;
    const editable = get(props, 'data.me.role') === 'Admin';

    const [addFirmToWhitelistOpen, setAddFirmToWhitelistOpen] = useState(false);
    const [formTierOpen, setFormTierOpen] = useState(false);
    const onFormCancel = () => {
        setFormTierOpen(false);
    };

    const onAddFirmClick = () => {
        setAddFirmToWhitelistOpen(true);
    };

    const onClickCancelButton = () => {
        setAddFirmToWhitelistOpen(false);
    };

    const onSubmit = async values => {
        const { id } = values;
        await addFirmToWhitelist({
            variables: {
                _id: id
            },
            refetchQueries: [
                {
                    query: GET_WHITELISTED_FIRMS
                }
            ]
        });
        setAddFirmToWhitelistOpen(false);
    };

    return (
        <div className={className} style={style}>
            <Container>
                {addFirmToWhitelistOpen && (
                    <Dialog>
                        <Formik
                            onSubmit={onSubmit}
                            enableReinitialize={true}

                            render={props => {
                                return (
                                    <Form>
                                        <Field
                                            component={AutoComplete}
                                            query={SEARCH_FIRM_NOT_WHITELISTED}
                                            resultKey="NotWhitelistedFirms"
                                            name="id"
                                            placeholder="Search for a Firm..."
                                        />
                                        <StandardRow center>
                                            <Col flex style={{ textAlign: 'right' }}>
                                                <GreenButton type="submit">
                                                    Add to Whitelist
                                                </GreenButton>
                                                <GreenButton onClick={onClickCancelButton}>
                                                    Cancel
                                                </GreenButton>
                                            </Col>
                                        </StandardRow>
                                    </Form>
                                );
                            }}
                        />
                    </Dialog>
                )}
                <MainHeader title="Whitelisted Firms">
                    <LinkList>
                        <li>
                            <LinkButton onClick={onAddFirmClick}>Add Firm to Whitelist</LinkButton>
                        </li>
                        <li>
                            <LinkButton onClick={() => {
                                submitForm('/api/excel/firms');
                            }}>Export</LinkButton>
                        </li>
                    </LinkList>
                </MainHeader>

                <Query query={GET_WHITELISTED_FIRMS}>
                    {queryResult => {
                        const { data, error, loading } = queryResult;
                        const whistedlistedFirms = get(data, 'WhitelistedFirms', []);
                        if (error) {
                            return <span>Error querying firms.</span>;
                        } else if (loading) {
                            return <LoadingLogo />;
                        } else {
                            return (
                                <FirmTierManager
                                    editable={editable}
                                    firms={whistedlistedFirms}
                                    formTierOpen={formTierOpen}
                                    onFormEdit={setFormTierOpen}
                                    onFormCancel={onFormCancel}
                                    refetchQueries={[{ query: GET_WHITELISTED_FIRMS }]} />
                            );
                        }
                    }}
                </Query>
            </Container>
        </div>
    );
};

const List = compose(
    graphql(ADD_FIRM_TO_WHITELIST, {
        name: 'addFirmToWhitelist'
    }),
    graphql(GET_ME)
)(ListRaw);

export default List;