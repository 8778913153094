import React, { Fragment } from 'react';
import { Formik, Field, FieldArray, Form } from 'formik';
import { graphql } from 'react-apollo';
import { castArray, isFinite } from 'lodash';
import { UPDATE_COMPANY_FINANCING } from '-/gql/company';
import { SEARCH_FIRM } from '-/gql/firm';
import { SEARCH_PEOPLE } from '-/gql/person';
import Toolbar from '-/components/profiles/forms/Toolbar';
import styled from 'styled-components';
import { TrashAction as TrashActionRaw } from '-/style/Icon';
import { Col, Subhead, StandardRow } from '-/style/base/Layout';
import { GreenButton } from '-/style/base/Button';
import MessageBox from '-/components/MessageBox';
import Divider from '-/style/base/Divider';
import CompanyQuery from '-/company/view/sections/CompanyQuery';
import { GET_COMPANY_FINANCING } from '-/gql/company';
import {
    MillionsNumber,
    TextInput,
    AutoComplete,
    SimpleDate
} from '-/components/inputs';
import { compose, withProps } from 'recompose';

const TrashAction = styled(TrashActionRaw)`
    padding-left: 0;
`;
const StyledStandardForm = styled(Form)`
    padding: 15px;
    min-width: 350px;
    max-width: 450px;
`;
const FinancialYear = props => {
    const { index = 0, children } = props;
    const getFieldName = name => `fundingRounds.${index}.${name}`;
    return (
        <Fragment>
            <StandardRow>
                <Col flex={5} xs={11}>
                    <Field
                        component={TextInput}
                        label="Round"
                        name={getFieldName('series')}
                    />
                </Col>
                <Col flex={5} xs={6} xsOrder={1}>
                    <Field
                        component={MillionsNumber}
                        label="Amount ($M)"
                        name={getFieldName('amount')}
                    />
                </Col>

                <Col
                    flex={2}
                    alignSelf="flex-start"
                    style={{ textAlign: 'center', paddingTop: 23 }}
                >
                    {children}
                </Col>
            </StandardRow>
            <StandardRow>
                <Col flex={5} xs={11}>
                    <Field
                        component={SimpleDate}
                        label="Date"
                        name={getFieldName('date')}
                    />
                </Col>
                <Col flex={5} xs={6} xsOrder={1}>
                    <Field
                        component={MillionsNumber}
                        label="Post-Money ($M)"
                        name={getFieldName('valuation')}
                    />
                </Col>
            </StandardRow>
            <StandardRow>
                <Col flex={10}>
                    <Field
                        name={getFieldName('leadFirmInvestors')}
                        label="Lead Firm Investors"
                        component={AutoComplete}
                        query={SEARCH_FIRM}
                        resultKey="Firms"
                        multiple={true}
                    />
                </Col>
            </StandardRow>
            <StandardRow>
                <Col flex={10}>
                    <Field
                        name={getFieldName('firmInvestors')}
                        label="Firm Investors"
                        component={AutoComplete}
                        query={SEARCH_FIRM}
                        resultKey="Firms"
                        multiple={true}
                    />
                </Col>
            </StandardRow>
            <StandardRow>
                <Col flex={10}>
                    <Field
                        name={getFieldName('personInvestors')}
                        label="Individual Investors"
                        component={AutoComplete}
                        query={SEARCH_PEOPLE}
                        resultKey="People"
                        multiple={true}
                    />
                </Col>
            </StandardRow>
        </Fragment>
    );
};

const renderFields = props => {
    const {
        className,
        closeOverlay,
        values: { fundingRounds = [{}] }
    } = props;
    return (
        <StyledStandardForm className={className}>
            <Subhead>Funding Rounds</Subhead>
            <FieldArray
                name="fundingRounds"
                render={arrayHelpers => {
                    const onAdd = () => {
                        arrayHelpers.push({});
                    };
                    const addButton = (
                        <GreenButton type="button" onClick={onAdd}>
                            Add Round
                        </GreenButton>
                    );

                    const { length } = fundingRounds;
                    if (length) {
                        const needsRemoveBtn = length !== 1;

                        return fundingRounds.map((currRound, index) => {
                            const onRemove = () => {
                                const isEmpty =
                                    Object.keys(currRound).length === 0;
                                if (isEmpty) {
                                    arrayHelpers.remove(index);
                                } else {
                                    MessageBox.show({
                                        onYesClick: () => {
                                            arrayHelpers.remove(index);
                                        }
                                    });
                                }
                            };
                            const needsAddBtn = length - 1 === index;

                            return (
                                <Fragment key={index}>
                                    <FinancialYear index={index}>
                                        {needsRemoveBtn ? (
                                            <TrashAction
                                                size={22}
                                                onClick={onRemove}
                                            >
                                                Delete
                                            </TrashAction>
                                        ) : null}
                                    </FinancialYear>
                                    {!needsAddBtn ? <Divider /> : addButton}
                                </Fragment>
                            );
                        });
                    } else {
                        return (
                            <GreenButton type="button" onClick={onAdd}>
                                Add Year
                            </GreenButton>
                        );
                    }
                }}
            />
            <Toolbar handleCancel={closeOverlay} />
        </StyledStandardForm>
    );
};

const normalizeEntity = input => {
    if (input) {
        const entities = castArray(input);
        return entities.map(entity => {
            if (entity && entity._id) {
                return entity._id;
            } else {
                return entity;
            }
        });
    }
    return [];
};

const normalizeMillions = input => {
    const finite = isFinite(input);
    return finite ? `$${input}M` : input;
};

const FinancingForm = props => {
    const { _id, closeOverlay, update, fundingRounds, className } = props;
    const initialValues = { fundingRounds };
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const { fundingRounds: fundingRoundsRaw } = values;
                    // purpose of this is to simplify into a normalized format
                    // regardless of whether it's been touched by
                    const fundingRounds = fundingRoundsRaw.map(round => {
                        const {
                            amount: amountRaw,
                            valuation: valuationRaw,
                            firmInvestors: firmInvestorsRaw,
                            leadFirmInvestors: leadFirmInvestorsRaw,
                            personInvestors: personInvestorsRaw,
                            ...rest
                        } = round;
                        const amount = normalizeMillions(amountRaw);
                        const valuation = normalizeMillions(valuationRaw);
                        const firmInvestors = normalizeEntity(firmInvestorsRaw);
                        const leadFirmInvestors = normalizeEntity(
                            leadFirmInvestorsRaw
                        );
                        const personInvestors = normalizeEntity(
                            personInvestorsRaw
                        );
                        return {
                            ...rest,
                            amount,
                            valuation,
                            firmInvestors,
                            leadFirmInvestors,
                            personInvestors
                        };
                    });
                    await update({
                        variables: {
                            _id,
                            input: {
                                fundingRounds
                            }
                        }
                    });
                    closeOverlay();
                } catch (ex) {
                    console.warn(ex);
                }
            }}
            render={renderProps => {
                return renderFields({
                    ...renderProps,
                    closeOverlay,
                    className
                });
            }}
        />
    );
};

export default compose(
    graphql(UPDATE_COMPANY_FINANCING, { name: 'update' }),
    withProps({
        query: GET_COMPANY_FINANCING,
        component: FinancingForm
    })
)(CompanyQuery);
