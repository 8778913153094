import React, { Fragment, Component } from 'react';
import { AutoComplete, MillionsNumber, RadioGroup, Textarea } from '-/components/inputs';
import { Container, Row, Col } from '-/style/base/Layout';
import Box from '-/style/base/Box';
import MainHeader from '-/style/base/MainHeader';
import Subhead from '-/style/base/Subhead';
import { Link } from 'react-router-dom';
import { LinkButton } from '-/style/base/Link';
import LinkList from '-/style/base/LinkList';
import { Field, Formik, Form } from 'formik';
import { withRouter } from 'react-router-dom';
import { SEARCH_COMPANY, ADD_NOTE_TO_COMPANY } from '-/gql/company';
import { SEARCH_PEOPLE } from '-/gql/person';
import { SEARCH_FIRM } from '-/gql/firm';
import { GET_ME } from '-/gql/user';
import { compose, graphql } from 'react-apollo';
import { get, pick } from 'lodash';
import { companySideNoteTypes } from '-/shared/constants';
import styled from 'styled-components';
import { baseGreen } from '-/style/variables';
import Dialog from '-/components/Dialog';
import CBCompanyForm from './CBCompanyForm';

const StandardRow = props => {
    return (
        <Row
            gutter={4}
            justifyContent="space-between"
            alignItems="flex-start"
            {...props}
        />
    );
};

const StyledButton = styled.button`
    color: ${baseGreen};
    width: 100px;
    background-color: white;
    font-weight: bold;
    appearance: none;
    border: 1px solid ${baseGreen};
    padding: 5px;
`;

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addNewCompanyOpen: false
        };
    }

    openAddCompany = e => {
        e.preventDefault();
        this.setState({ addNewCompanyOpen: true });
    };
    closeAddCompany = () => {
        this.setState({ addNewCompanyOpen: false });
    };

    onCompanyChange = value => {
        if (value) {
            const { history } = this.props;
            history.push(`/company/view/${value}`);
        }
    };

    onFirmChange = value => {
        if (value) {
            const { history } = this.props;
            history.push(`/firm/view/${value}`);
        }
    };

    onPersonChange = value => {
        if (value) {
            const { history } = this.props;
            history.push(`/person/view/${value}`);
        }
    };

    render() {
        const { addNewCompanyOpen } = this.state;
        const { addNoteToCompany } = this.props;
        const initials = get(this.props, 'data.me.initials');
        const noteInitialValues = {
            type: companySideNoteTypes[0],
            text: initials ? `${initials} - ` : ''
        };
        return (
            <Fragment>
                {addNewCompanyOpen && (
                    <Dialog>
                        <CBCompanyForm
                            onClose={this.closeAddCompany}
                            onChange={this.onCompanyChange}
                        />
                    </Dialog>
                )}
                <Formik
                    enableReinitialize={true}
                    initialValues={noteInitialValues}
                    onSubmit={async (values, actions) => {
                        try {
                            const input = pick(values, ['type', 'text', 'arr']);
                            const { permalink } = values;
                            await addNoteToCompany({
                                variables: {
                                    permalink,
                                    input
                                }
                            });
                            actions.resetForm({
                                ...noteInitialValues,
                                permalink: null
                            });
                        } catch (ex) { }
                    }}
                    render={props => {
                        return (
                            <Form>
                                <Container>
                                    <MainHeader title="Welcome to Iconbase">
                                        <LinkList>
                                            <li>
                                                <LinkButton
                                                    onClick={
                                                        this.openAddCompany
                                                    }
                                                >
                                                    Add New Company
                                                </LinkButton>
                                            </li>
                                            <li>
                                                <Link to="/company/reports/monday/active">
                                                    Go To Monday Meeting
                                                </Link>
                                            </li>
                                        </LinkList>
                                    </MainHeader>
                                    <StandardRow>
                                        <Col flex={4} xs={12}>
                                            <Box>
                                                <Subhead>
                                                    Company Database
                                                </Subhead>
                                                <Field
                                                    component={AutoComplete}
                                                    query={SEARCH_COMPANY}
                                                    resultKey="Companies"
                                                    name="gotoCompany"
                                                    placeholder="Search for a Company..."
                                                    onChange={
                                                        this.onCompanyChange
                                                    }
                                                />
                                            </Box>
                                        </Col>
                                        <Col flex={4} xs={12}>
                                            <Box>
                                                <Subhead>
                                                    VC Firm Database
                                                </Subhead>
                                                <Field
                                                    component={AutoComplete}
                                                    query={SEARCH_FIRM}
                                                    resultKey="Firms"
                                                    name="gotoFirm"
                                                    placeholder="Search for a Firm..."
                                                    onChange={this.onFirmChange}
                                                />
                                            </Box>
                                        </Col>
                                        <Col flex={4} xs={12}>
                                            <Box>
                                                <Subhead>
                                                    People Database
                                                </Subhead>
                                                <Field
                                                    component={AutoComplete}
                                                    query={SEARCH_PEOPLE}
                                                    resultKey="People"
                                                    name="gotoPerson"
                                                    placeholder="Search for a VC..."
                                                    onChange={
                                                        this.onPersonChange
                                                    }
                                                />
                                            </Box>
                                        </Col>
                                    </StandardRow>
                                    <StandardRow>
                                        <Col flex={12}>
                                            <Box>
                                                <StandardRow>
                                                    <Col
                                                        flex={6}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Subhead>
                                                            Quick Add Note
                                                        </Subhead>
                                                        <Field
                                                            component={
                                                                RadioGroup
                                                            }
                                                            style={{
                                                                marginBottom: 15
                                                            }}
                                                            name="type"
                                                            options={
                                                                companySideNoteTypes
                                                            }
                                                        />
                                                        <Field
                                                            component={
                                                                AutoComplete
                                                            }
                                                            query={
                                                                SEARCH_COMPANY
                                                            }
                                                            resultKey="Companies"
                                                            label="Company"
                                                            name="permalink"
                                                        />
                                                        <Field
                                                            component={MillionsNumber}
                                                            label="Current ARR"
                                                            name="arr" />
                                                    </Col>
                                                    <Col
                                                        flex={6}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Field
                                                            component={Textarea}
                                                            name="text"
                                                            label="Note"
                                                            inputStyle={{
                                                                height: '178px'
                                                            }}
                                                        />
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'right'
                                                            }}
                                                        >
                                                            <StyledButton type="submit">
                                                                Submit
                                                            </StyledButton>
                                                        </div>
                                                    </Col>
                                                </StandardRow>
                                            </Box>
                                        </Col>
                                    </StandardRow>
                                </Container>
                            </Form>
                        );
                    }}
                />
            </Fragment>
        );
    }
}
export default compose(
    withRouter,
    graphql(ADD_NOTE_TO_COMPANY, {
        name: 'addNoteToCompany'
    }),
    graphql(GET_ME)
)(Dashboard);
