import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Active from './Active';
import ActivePortfolio from './ActivePortfolio';
import Menu from './Menu';
import WatchList from './WatchList';
import PriorityTargetReview from './PriorityTargetReview';
import Queued from './Queued';
import DealUpdate from './DealUpdate';
import NewThisWeek from './NewThisWeek';
import Portfolio from './Portfolio';
import Turndowns from './Turndowns';
import Headline from '-/style/base/Headline';
import { Container, Row, Col } from '-/style/base/Layout';
import { Printer } from '-/style/Icon';
import dayjs from 'dayjs';

const MondayReport = props => {
    const startOfWeek = dayjs()
        .startOf('week')
        .add(1, 'day')
        .format('MMM D, YYYY');
    return (
        <Container>
            <Row
                alignItems="flex-end"
                style={{ height: '60px', marginBottom: '20px' }}
            >
                <Col flex style={{ overflow: 'hidden' }}>
                    <Headline>Week of {startOfWeek}</Headline>
                </Col>
                <Col flex style={{ textAlign: 'right' }}>
                    <a
                        style={{ marginBottom: 15 }}
                        href="/api/excel/mondayReport"
                    >
                        <Printer size={16} /> Export All
                    </a>
                </Col>


            </Row>

            <Row alignItems="flex-end" style={{ marginBottom: '10px' }}>
                <Col flex style={{ textAlign: 'right' }}>
                    <Menu />
                </Col>
            </Row>
            <Switch>
                <Route
                    exact
                    path="/company/reports/monday/active"
                    component={Active}
                />
                <Route
                    exact
                    path="/company/reports/monday/activePortfolio"
                    component={ActivePortfolio}
                />
                <Route
                    exact
                    path="/company/reports/monday/watchlist"
                    component={WatchList}
                />
                <Route
                    exact
                    path="/company/reports/monday/ptReview"
                    component={PriorityTargetReview}
                />
                <Route
                    exact
                    path="/company/reports/monday/queued"
                    component={Queued}
                />
                <Route
                    exact
                    path="/company/reports/monday/dealUpdates"
                    component={DealUpdate}
                />
                <Route
                    exact
                    path="/company/reports/monday/newThisWeek"
                    component={NewThisWeek}
                />
                <Route
                    exact
                    path="/company/reports/monday/portfolio"
                    component={Portfolio}
                />
                <Route
                    exact
                    path="/company/reports/monday/turndowns"
                    component={Turndowns}
                />
            </Switch>
        </Container>
    );
};

export default MondayReport;
