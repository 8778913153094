import React from 'react';
import { Formik, Field, Form } from 'formik';
import { graphql } from 'react-apollo';
import { pick } from 'lodash';
import { UPDATE_COMPANY_CEO } from '-/gql/company';
import { TextInput } from '-/components/inputs';
import Toolbar from '-/components/profiles/forms/Toolbar';
import styled from 'styled-components';

const StyledStandardForm = styled(Form)`
    padding: 15px;
    min-width: 350;
`;
const renderFields = props => {
    const { className, closeOverlay } = props;
    return (
        <StyledStandardForm className={className}>
            <Field name="ceo" label="CEO" component={TextInput} />
            <Toolbar handleCancel={closeOverlay} />
        </StyledStandardForm>
    );
};

const CEOForm = props => {
    const { _id, closeOverlay, update, initialValues, className } = props;
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const input = pick(values, ['ceo']);
                    await update({
                        variables: {
                            _id,
                            input
                        }
                    });
                    closeOverlay();
                } catch (ex) {
                    console.warn(ex);
                }
            }}
            render={renderProps => {
                return renderFields({
                    ...renderProps,
                    closeOverlay,
                    className
                });
            }}
        />
    );
};

export default graphql(UPDATE_COMPANY_CEO, { name: 'update' })(CEOForm);
