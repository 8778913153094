import React from 'react';
import { Formik, Field, Form } from 'formik';
import { graphql, compose } from 'react-apollo';
import { get } from 'lodash';
import { GET_ICON_POINT_USERS } from '-/gql/user';
import { UPDATE_COMPANY_ICON_POINT_PEOPLE } from '-/gql/company';
import { AutoComplete } from '-/components/inputs';
import Toolbar from '-/components/profiles/forms/Toolbar';
import styled from 'styled-components';

const StyledStandardForm = styled(Form)`
    padding: 15px;
    width: 350px;
`;
const renderFields = props => {
    const { iconPointPeople, className, closeOverlay } = props;
    return (
        <StyledStandardForm className={className}>
            <Field
                label="Primary Point Person"
                options={iconPointPeople}
                name="iconPointPeople.0"
                component={AutoComplete}
                valueKey="_id"
                labelKey="name"
            />
            <Field
                label="Secondary Point Person"
                options={iconPointPeople}
                name="iconPointPeople.1"
                component={AutoComplete}
                valueKey="_id"
                labelKey="name"
            />
            <Toolbar handleCancel={closeOverlay} />
        </StyledStandardForm>
    );
};

const IconPointPeopleForm = props => {
    const { _id, closeOverlay, update, initialValues, className } = props;
    const iconPointPeople = get(props, 'data.IconPointUser', []);
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={async values => {
                try {
                    const iconPointPeopleRaw = get(
                        values,
                        'iconPointPeople',
                        []
                    );
                    const iconPointPeopleValue = iconPointPeopleRaw
                        .filter(Boolean)
                        .map(o => {
                            return o._id ? o._id : o;
                        });
                    await update({
                        variables: {
                            _id,
                            input: {
                                iconPointPeople: iconPointPeopleValue
                            }
                        }
                    });
                    closeOverlay();
                } catch (ex) {
                    console.warn(ex);
                }
            }}
            render={renderProps => {
                return renderFields({
                    ...renderProps,
                    iconPointPeople,
                    closeOverlay,
                    className
                });
            }}
        />
    );
};

export default compose(
    graphql(GET_ICON_POINT_USERS),
    graphql(UPDATE_COMPANY_ICON_POINT_PEOPLE, { name: 'update' })
)(IconPointPeopleForm);
