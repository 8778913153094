import React from 'react';
import { Container } from '-/style/base/Layout';
import Box from '-/style/base/Box';
import MainHeader from '-/style/base/MainHeader';
import { GET_ALL_NOTION_REPORTS, SET_NOTION_REPORT_STATUS } from '-/gql/notionReport';
import { graphql } from 'react-apollo';
import moment from 'moment-timezone';
import {
    StyledTable,
    StyledEmptyRow,
    StyledTheadTr,
    StyledTr,
    StyledTd,
    StyledTh
} from '-/style/base/Table';
import { DownloadAction, RestoreAction } from '-/style/Icon';
import { isObjectLike } from 'lodash';
import { compose } from 'recompose';

const columns = [
    'name',
    'status',
    'nextRunDate',
    'lastRunDate'
];

const submitForm = (path, params) => {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', path);

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const value = params[key];
            const hiddenField = document.createElement('input');
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            hiddenField.setAttribute('value', isObjectLike(value) ? JSON.stringify(value) : value);
            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
};


const renderTrs = props => {
    const { loading, error, data, setNotionReportStatus } = props;
    const tz = moment.tz.guess();
    const columnsLn = columns.length;
    if (loading) {
        return (
            <tr>
                <td colSpan={columnsLn}>Loading...</td>
            </tr>
        );
    } else if (error) {
        return (
            <tr>
                <td colSpan={columnsLn}>Failure</td>
            </tr>
        );
    } else if (data && data.NotionReports) {
        const rows = data.NotionReports;
        if (rows.length) {
            return rows.map((report, idx) => {
                const { _id, name, nextRunDate, status, criteria } = report;
                let actionIcon;
                if (status === 'failed' || status === 'waiting') {
                    actionIcon = <RestoreAction size={16} onClick={async () => {
                        const statusToSet = {
                            'failed': 'waiting',
                            'waiting': 'running'
                        };
                        const nextStatus = statusToSet[status];
                        await setNotionReportStatus({
                            variables: {
                                _id,
                                status: nextStatus
                            }
                        });
                    }} />;
                }
                return (
                    <StyledTr key={`notion-report-${idx}`}>
                        <StyledTd>{name}</StyledTd>
                        <StyledTd>{nextRunDate ? moment(nextRunDate).tz(tz).calendar() : 'Not Scheduled'}</StyledTd>
                        <StyledTd>
                            {status} {actionIcon}
                        </StyledTd>
                        <StyledTd>
                            <DownloadAction size={16} onClick={() => {

                                submitForm('/api/excel/Company', {
                                    criteria,
                                    tz,
                                    notion: _id,
                                    format: 'csv'
                                });
                            }} />
                            Current
                            <DownloadAction size={16} onClick={() => {
                                submitForm('/api/excel/NotionReport', {
                                    _id,
                                    tz
                                });
                            }} />
                            Notion
                        </StyledTd>
                    </StyledTr>
                );
            });
        } else {
            return (
                <StyledEmptyRow colSpan={columnsLn}>No Results.</StyledEmptyRow>
            );
        }
    }
    return null;
};

const NotionTable = props => {

    return (
        <StyledTable>
            <NotionReportHeader />
            <tbody>{renderTrs({ ...props, columns })}</tbody>
        </StyledTable>
    );
};

const NotionReportHeader = props => {
    return (
        <thead>
            <StyledTheadTr>
                <StyledTh>
                    Report Name
                </StyledTh>
                <StyledTh>
                    Next Run
                </StyledTh>
                <StyledTh>
                    Status
                </StyledTh>
                <StyledTh>
                    Actions
                </StyledTh>
            </StyledTheadTr>
        </thead>
    );
};

const NotionReportList = props => {
    return (
        <Container>
            <MainHeader title="Notion Reports" />
            <Box>
                <NotionTable {...props} />
            </Box>
        </Container>
    );
};

export default compose(
    graphql(SET_NOTION_REPORT_STATUS, {
        name: 'setNotionReportStatus'
    }),
    graphql(GET_ALL_NOTION_REPORTS)
)(NotionReportList);
