import React from 'react';
import NoteFormBase from '-/components/notes/Form';
import { withRouter } from 'react-router-dom';
import { ADD_NOTE_TO_LP, UPDATE_NOTE_FOR_LP, GET_LP_NOTES } from '-/gql/lp';
import { GET_ME } from '-/gql/user';
import { graphql, compose, withApollo } from 'react-apollo';
import { get } from 'lodash';
import { lpSideNoteTypes } from '-/shared/constants';

const NoteForm = props => {
    return <NoteFormBase noteTypes={lpSideNoteTypes} {...props} />;
};

const returnPermalink = props => {
    const permalink = get(props, 'match.params.permalink');
    return {
        variables: {
            permalink
        }
    };
};

export default compose(
    withRouter,
    withApollo,
    graphql(ADD_NOTE_TO_LP, {
        name: 'addNote',
        options: props => {
            const permalink = get(props, 'match.params.permalink');
            return {
                variables: {
                    permalink
                },
                refetchQueries: [
                    {
                        query: GET_LP_NOTES,
                        variables: { permalink }
                    }
                ]
            };
        }
    }),
    graphql(UPDATE_NOTE_FOR_LP, {
        name: 'updateNote',
        options: returnPermalink
    }),
    graphql(GET_ME)
)(NoteForm);
