import React, { forwardRef } from 'react';

const knownIcons = {
    // iconCategories
    Portfolio: 'Portfolio-icon.svg',
    Passive: 'Portfolio-icon.svg',
    'Priority Target': 'PriorityTarget-icon.svg',
    'Watch List': 'WatchList-icon.svg',
    'Watch List – Reached Out': 'WatchList-icon.svg',
    'Watch List – Engaged': 'WatchList-icon.svg',
    'Watch List - Met': 'WatchList-icon.svg',
    Research: 'Research-icon.svg',
    Database: 'Database-icon.svg',
    'Coveted+': 'Coveted-icon.svg',
    Alumnus: 'Alumni-icon.svg',
    // iconStatus
    Queued: 'Queued-icon.svg',
    Active: 'Active-icon.svg',
    'Declined/Informed': 'Declined-icon.svg',
    Lost: 'Lost-icon.svg',
    'New This Week': 'NewThisWeek-icon.svg',
    'Deal Update': 'DealUpdate-icon.svg',
    // action icons
    Print: 'Print.svg',
    Edit: 'Edit.svg',
    Calendar: 'Calendar.svg',
    Magnify: 'Magnify.svg',
    Menu: 'Menu.svg',
    Logo: 'Logo.svg',
    LPLogo: 'LPLogo.svg',
    Company: 'Company.svg'
};

const iconCache = Object.keys(knownIcons).reduce((o, key) => {
    const iconFile = knownIcons[key];
    if (iconFile) {
        o[key] = require(`./../svg-icons/${iconFile}`);
    }
    return o;
}, {});

const KnownIcon = forwardRef((props, ref) => {
    const { type, width, height, className, ...rest } = props;
    const iconAsset = iconCache[type];
    if (!iconAsset) {
        return null;
    } else {
        const style = {
            marginLeft: '5px'
        };
        return (
            <img
                ref={ref}
                className={className}
                src={iconAsset}
                alt={type}
                title={type}
                style={style}
                width={width}
                height={height}
                {...rest}
            />
        );
    }
});

KnownIcon.defaultProps = {
    type: '',
    height: 16,
    width: 16
};

export default KnownIcon;
