import { orderBy, get } from 'lodash';

const textToGroupMap = {
    'Focus': 'focus',
    'Non-Focus': 'nonFocus',
    'Avoid': 'avoid'
};
export const groupByPrimaryThemePriority = companies => {
    return companies.reduce((o, company) => {
        const { primaryThemePriority } = company;
        const key = textToGroupMap[primaryThemePriority] || 'nonFocus';
        o[key].push(company);
        return o;
    }, { focus: [], nonFocus: [], avoid: [] });
};

export const sortBySeries = (rawCompanies = []) => {
    return orderBy(rawCompanies, company => {
        const seriesRaw = (get(company, 'lastRound.series') || '').toLowerCase();
        let series = seriesRaw;
        const seriesPretext = 'Series ';
        if (seriesRaw.startsWith(seriesPretext)) {
            series = seriesRaw.substr(seriesPretext.length);
        }
        if (series) {
            if (series === 'pre-seed') {
                return '_';
            } else if (series === 'seed') {
                return '__';
            } else {
                // standard a, b, c, sorting with `Series ` chopped off.
                return series;
            }
        } else {
            // go last
            return 'zzzz';
        }
    });
};