import React from 'react';
import styled from 'styled-components';
import { baseRed } from '-/style/variables';

const StyledFeedback = styled.div`
    margin-top: 0.25rem;
    color: ${baseRed}
`;

const InputFeedback = props => {
    const { error } = props;
    return error ? <StyledFeedback>{error}</StyledFeedback> : null;
};

export default InputFeedback;