import React from 'react';
import styled from 'styled-components';
import { StyledTd } from '-/style/base/Table';
import { CompanyLink } from '-/components/Link';

const StyledAddress = styled.div`
    margin-top: 5px;
    font-size: 12px;
`;

const isUSA = countryRaw => {
    if (!countryRaw) {
        return false;
    }
    const list = [
        'us',
        'usa',
        'united states',
        'united states of america'
    ];
    const country = countryRaw.replace(/\./g, '').toLowerCase();
    return list.includes(country);
};

const formatAddress = location => {
    const { city, region: state, country } = location;
    const stateSide = isUSA(country);
    const partsToShow = [];
    if (country && !stateSide) {
        partsToShow.push(city, country);
    } else {
        partsToShow.push(city, state);
    }
    return (
        <StyledAddress>
            {partsToShow.filter(Boolean).join(' ')}
        </StyledAddress>
    );
};

export const TdName = props => {
    const { permalink, location, name, sticky = false } = props;
    return (
        <StyledTd sticky={sticky}>
            <CompanyLink permalink={permalink}>{name}</CompanyLink>
            {formatAddress(location)}
        </StyledTd>
    );
};
