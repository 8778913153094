import React from 'react';
import { Formik, Field, Form } from 'formik';
import { graphql } from 'react-apollo';
import { pick } from 'lodash';
import { UPDATE_FIRM_BASIC_INFO } from '-/gql/firm';
import {
    TextInput
} from '-/components/inputs';
import Toolbar from '-/components/profiles/forms/Toolbar';
import styled from 'styled-components';

const StyledForm = styled(Form)`
    width: 350px;
    padding: 10px;
`;

const renderFields = props => {
    const { className, closeOverlay } = props;
    return (
        <StyledForm className={className}>
            <Field label="Firm Name" component={TextInput} name="name" />

            <Toolbar handleCancel={closeOverlay} />
        </StyledForm>
    );
};
const BasicForm = props => {
    const {
        _id,
        closeOverlay,
        updateBasicInfo,
        initialValues,
        className
    } = props;
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const input = pick(values, [
                        'name'
                    ]);
                    await updateBasicInfo({
                        variables: {
                            _id,
                            input
                        }
                    });
                    closeOverlay();
                } catch (ex) {
                    console.warn(ex);
                }
            }}
            render={renderProps => {
                return renderFields({
                    ...renderProps,
                    closeOverlay,
                    className
                });
            }}
        />
    );
};

export default graphql(UPDATE_FIRM_BASIC_INFO, { name: 'updateBasicInfo' })(
    BasicForm
);
