import React, { Fragment } from 'react';
import Label from './Label';
import Feedback from './Feedback';
import { uniqueId } from 'lodash';
import { iconCategories, iconStatuses, statesByName } from '-/shared/constants';
import { transformOptions } from './utils';

import { StyledSelect } from './styled';

export const Select = props => {
    const {
        fieldId: fieldIdRaw,
        options: optionsRaw,
        label,
        field,
        error
    } = props;

    const options = transformOptions(optionsRaw);
    const { value: valueRaw, ...restOfField } = field;
    // Note: This is done in order to avoid `A component is changing an uncontrolled input of type undefined to be controlled.`
    // If the initial value is undefined we will default it to ''.
    const value = valueRaw || '';
    const fieldId = fieldIdRaw || uniqueId('text-');

    return (
        <Fragment>
            <Label htmlFor={fieldId} error={error}>
                {label}
            </Label>
            <StyledSelect value={value} {...restOfField}>
                <option value="" />
                {options.map((opt, idx) => {
                    const { label: optLabel, value: optValue } = opt;
                    return (
                        <option key={idx} value={optValue}>
                            {optLabel}
                        </option>
                    );
                })}
            </StyledSelect>
            <Feedback error={error} />
        </Fragment>
    );
};

export const IconCategorySelect = props => {
    const { field, form, ...otherProps } = props;
    return (
        <select {...field} {...otherProps}>
            <option value="" />
            {iconCategories.map((value, idx) => {
                return (
                    <option key={idx} value={value}>
                        {value}
                    </option>
                );
            })}
        </select>
    );
};

export const IconStatusSelect = props => {
    const { field, form, ...otherProps } = props;
    return (
        <select {...field} {...otherProps}>
            <option value="" />
            {iconStatuses.map((value, idx) => {
                return (
                    <option key={idx} value={value}>
                        {value}
                    </option>
                );
            })}
        </select>
    );
};

const stateOptions = Object.keys(statesByName);

export const StateSelect = props => {
    const { label, field, form, ...otherProps } = props;
    return (
        <Fragment>
            <Label>{label}</Label>
            <StyledSelect {...field} {...otherProps}>
                <option value="" />
                {stateOptions.map((value, idx) => {
                    return (
                        <option key={idx} value={value}>
                            {value}
                        </option>
                    );
                })}
            </StyledSelect>
        </Fragment>
    );
};
