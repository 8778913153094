import React, { Fragment } from 'react';
import { GET_LP_COMMITMENTS } from '-/gql/lp';
import LPQuery from './LPQuery';
import {
    SimpleDisplayField,
    MillionDisplayField,
    OptionDisplayField
} from '-/components/profiles';
import Divider from '-/style/base/Divider';
import { iconFunds } from '-/shared/constants';

const CommitmentView = props => {
    const { iconFund, amount, legalName } = props;
    return (
        <Fragment>
            <OptionDisplayField
                label="Fund"
                value={iconFund}
                options={iconFunds}
            />
            <MillionDisplayField label="Commitment Amount" value={amount} />
            <SimpleDisplayField label="Legal Name" value={legalName} />
        </Fragment>
    );
};

const CommitmentsView = props => {
    const { commitments = [] } = props;
    const commitmentLn = commitments.length;
    if (commitmentLn) {
        return commitments.map((commitment, idx) => {
            const showDivider = idx !== commitmentLn - 1;
            return (
                <Fragment key={idx}>
                    <CommitmentView {...commitment} />
                    {showDivider && <Divider />}
                </Fragment>
            );
        });
    } else {
        return <div>No commitments recorded yet</div>;
    }
};

const Commitments = props => {
    const { permalink } = props;
    return (
        <LPQuery
            query={GET_LP_COMMITMENTS}
            permalink={permalink}
            component={CommitmentsView}
        />
    );
};

export default Commitments;
