import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import createMaskedInput from './Masked';

const mask = createNumberMask({
    prefix: '$',
    suffix: 'M',
    allowDecimal: true
});

export default createMaskedInput({
    mask,
    suffixLength: 1
});
