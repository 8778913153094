import React from 'react';
import MainHeader from '-/style/base/MainHeader';
import { Container } from '-/style/base/Layout';
import { Formik, Form, Field } from 'formik';
import { TextInput } from '-/components/inputs';
import { get, pick } from 'lodash';
import { compose, graphql } from 'react-apollo';
import { GET_ME, UPDATE_MY_SETTINGS } from '-/gql/user';
import Box from '-/style/base/Box';
const editableUserSettings = ['name', 'initials'];

const MySettings = props => {
    const { updateMySettings } = props;
    const me = get(props, 'data.me', {});
    const initialValues = pick(me, editableUserSettings);
    return (
        <Container>
            <MainHeader title="Your Settings" />
            <Box>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={async values => {
                        const input = pick(values, editableUserSettings);
                        await updateMySettings({
                            variables: {
                                input
                            }
                        });
                    }}
                    render={props => {
                        return (
                            <Form>
                                <Field
                                    component={TextInput}
                                    label="Name"
                                    name="name"
                                />
                                <Field
                                    component={TextInput}
                                    label="Initials"
                                    name="initials"
                                />
                                <button type="submit">Update Settings</button>
                            </Form>
                        );
                    }}
                />
            </Box>
        </Container>
    );
};

export default compose(
    graphql(GET_ME),
    graphql(UPDATE_MY_SETTINGS, {
        name: 'updateMySettings'
    })
)(MySettings);
