import React, { Fragment, useState } from 'react';
import { Query } from 'react-apollo';
import MainHeader from '-/style/base/MainHeader';
import { Container } from '-/style/base/Layout';
import SimpleTable from '-/components/table/Simple';
import Box from '-/style/base/Box';
import {
    StyledTr,
    StyledTd,
    StyledTh,
    StyledEmptyRow,
    StyledStickyWrapper
} from '-/style/base/Table';
import { CompanyLink } from '-/components/Link';
import moment from 'moment';
import { GET_HEADCOUNT_GROWTH_COMPANIES } from '-/gql/company';
import { get, last, round, isFinite, orderBy } from 'lodash';
import WebsiteLink from '-/company/view/sections/WebsiteLink';
import { AlertTriangle, ArrowUp as ArrowUpRaw, ArrowDown as ArrowDownRaw } from '-/style/Icon';
import { statusColors } from '-/style/variables';
import styled from 'styled-components';
import HeadcountFilter from './HeadcountFilter';
import { StyledVCTd, ThemesTd } from './Table';
import LinkList from '-/style/base/LinkList';
import { LinkButton } from '-/style/base/Link';
import { submitForm } from '-/utils/form';

const ArrowSortUp = ArrowUpRaw;
const ArrowSortDown = ArrowDownRaw;

const colSpan = 8;
const SortIndicator = props => {
    const { targetSortBy, sortBy, sortDir } = props;
    if (targetSortBy === sortBy) {
        return sortDir === 'asc' ? <ArrowSortUp size={8} /> : <ArrowSortDown size={8} />;
    }
    return null;
};
const Header = props => {
    const { sortBy, sortDir } = props;
    const defaultStyle = {
        width: 180,
        backgroundColor: '#EFEFEF',
        borderBottom: '1px solid #CACACB'
    };
    return (
        <thead>
            <tr>
                <StyledTh sticky data-key={'name'} style={defaultStyle}>
                    Company Name <SortIndicator {...{ sortBy, sortDir, targetSortBy: 'name' }} />
                </StyledTh>
                <StyledTh style={{
                    ...defaultStyle,
                    width: 100
                }}>
                    Icon Point
                </StyledTh>
                <StyledTh data-key={'theme'} style={defaultStyle}>
                    Theme <SortIndicator {...{ sortBy, sortDir, targetSortBy: 'theme' }} />
                </StyledTh>
                <StyledTh data-key={'latestHeadcount'} style={defaultStyle}>
                    Latest Headcount Data <SortIndicator {...{ sortBy, sortDir, targetSortBy: 'latestHeadcount' }} />
                </StyledTh>
                <StyledTh data-key={'latestHeadcountDate'} style={defaultStyle}>
                    Latest Headcount Date <SortIndicator {...{ sortBy, sortDir, targetSortBy: 'latestHeadcountDate' }} />
                </StyledTh>
                <StyledTh data-key={'growth.growth'} style={defaultStyle}>
                    3mo Growth <SortIndicator {...{ sortBy, sortDir, targetSortBy: 'growth.growth' }} />
                </StyledTh>
                <StyledTh data-key={'growth6.growth'} style={defaultStyle}>
                    6mo Growth <SortIndicator {...{ sortBy, sortDir, targetSortBy: 'growth6.growth' }} />
                </StyledTh>
                <StyledTh data-key={'growth12.growth'} style={defaultStyle}>
                    12mo Growth <SortIndicator {...{ sortBy, sortDir, targetSortBy: 'growth12.growth' }} />
                </StyledTh>
                <StyledTh style={defaultStyle}>Key VCs</StyledTh>
            </tr>
        </thead>
    );
};
const calculateMetrics = (headcountData, options) => {
    const length = get(headcountData, 'length', 0);
    const { months: targetMonths } = options;
    if (!targetMonths) {
        throw new Error('Must provide months to calculateMetrics');
    }
    let metrics = {
        score: 0,
        possibleScore: 0
    };
    if (length < targetMonths) {
        return metrics;
    } else {
        const startData = last(headcountData);
        const { recorded_at: recentDate, rawScore: recentCount } = startData;
        for (let i = length - 2; i >= 0; i--) {
            const historicalData = headcountData[i];
            const prevHistoricalData = headcountData[i + 1];
            const { recorded_at: historicalDate, rawScore: historicalCount } = historicalData;
            const monthsElapsed = moment.utc(recentDate).diff(moment.utc(historicalDate), 'months', true);
            if (monthsElapsed >= targetMonths) {
                let growth = (recentCount - historicalCount) / historicalCount;
                if (monthsElapsed !== targetMonths) {
                    metrics.interpolated = true;
                    if (prevHistoricalData) {
                        const { recorded_at: historicalDate2, rawScore: historicalCount2 } = prevHistoricalData;

                        const interpolateMonths = moment.utc(historicalDate2).diff(moment.utc(historicalDate), 'months', true);
                        const countDiff = historicalCount2 - historicalCount;
                        const interpolatedCount = round((countDiff / interpolateMonths) + historicalCount);
                        growth = (recentCount - interpolatedCount) / interpolatedCount;
                    }
                }

                metrics.recentDate = moment.utc(recentDate).format('MM/DD/YYYY');
                metrics.recentCount = recentCount;
                metrics.historicalDate = moment.utc(historicalDate).format('MM/DD/YYYY');
                metrics.historicalCount = historicalCount;
                metrics.growth = round(growth, 2);
                metrics.monthsElapsed = round(monthsElapsed);

                break;
            }
        }
        return metrics;
    }
};
const StyledCompany = styled.div`
    line-height: 30px;
    a {
        vertical-align: middle;
    }
`;

const formatPercent = growth => {
    if (!growth || !isFinite(growth.growth)) {
        return '--';
    }
    const { growth: value } = growth;
    const pct = round(value * 100, 2);
    const suffix = growth.interpolated ? ' *' : '';
    return `${pct}%${suffix}`;
};

const ListRow = props => {
    const {
        index,
        _id,
        name,
        description,
        websites,
        latestHeadcount,
        latestHeadcountDate,
        growth,
        growth6,
        growth12,
        keyVCs,
        iconPointPeople,
        themes
    } = props;

    const { linkedin } = websites;
    const liLink = linkedin ? <WebsiteLink
        type={'linkedin'}
        size={12}
        iconStyle={{ marginBottom: 3 }}
        url={linkedin}
    /> : <AlertTriangle size={12} style={{ color: statusColors.error }} />;
    return (
        <StyledTr index={index}>
            <StyledTd sticky index={index}>
                <StyledCompany>
                    <CompanyLink permalink={_id}>{name}</CompanyLink> {liLink}
                </StyledCompany>
                <div>{description}</div>
            </StyledTd>
            <StyledTd>
                {iconPointPeople.map(u => u.initials).join('/')}
            </StyledTd>
            <ThemesTd value={themes} />
            <StyledTd>{latestHeadcount ? (
                <Fragment>
                    {latestHeadcount} employees<br />
                </Fragment>
            ) : <span style={{ color: statusColors.error }}>No Data Available</span>}</StyledTd>
            <StyledTd>{latestHeadcountDate ? moment.utc(latestHeadcountDate).format('MM.DD.YY') : '---'}</StyledTd>
            <StyledTd>
                {formatPercent(growth)}
            </StyledTd>
            <StyledTd>
                {formatPercent(growth6)}
            </StyledTd>
            <StyledTd>
                {formatPercent(growth12)}
            </StyledTd>
            <StyledVCTd value={keyVCs} />
        </StyledTr>
    );
};


const emptyRow = <StyledEmptyRow colSpan={colSpan}>No companies found.</StyledEmptyRow>;

const List = props => {
    const { className, style } = props;
    const [sortState, setSortState] = useState({ sortBy: 'name', sortDir: 'asc' })
    const onHeaderClick = (e, newSortBy) => {
        if (newSortBy) {
            let sortDir = 'asc';
            const { sortBy: currSortBy, sortDir: currSortDir } = sortState;
            if (currSortBy === newSortBy) {
                sortDir = currSortDir === 'asc' ? 'desc' : 'asc';
            }
            setSortState({
                sortBy: newSortBy,
                sortDir
            });
        }
    };

    const [filterState, setFilterState] = useState({

    });
    const onSubmit = values => {
        setFilterState(values);
    };

    return (
        <div className={className} style={style}>
            <Container>
                <MainHeader title="Headcount Growth" />
                <HeadcountFilter onSubmit={onSubmit} />
                <Query query={GET_HEADCOUNT_GROWTH_COMPANIES}>
                    {queryResult => {
                        const { data, error, loading } = queryResult;
                        const listRaw = get(data, 'CompanyPTWL', []).map(dataItem => {
                            const headcountData = get(dataItem, 'analytics.employeeHeadCount.data');
                            const filteredHeadcount = headcountData.filter(a => a.score);

                            const lastHeadCount = last(filteredHeadcount);
                            const latestHeadcount = get(lastHeadCount, 'score');
                            const latestHeadcountDate = get(lastHeadCount, 'recorded_at');

                            const growth = calculateMetrics(filteredHeadcount, { months: 3 });
                            const growth6 = calculateMetrics(filteredHeadcount, { months: 6 });
                            const growth12 = calculateMetrics(filteredHeadcount, { months: 12 });
                            return {
                                ...dataItem,
                                latestHeadcount,
                                latestHeadcountDate,
                                growth,
                                growth6,
                                growth12
                            };
                        }).filter(dataItem => {
                            const {
                                name: filterNameRaw,
                                iconPointPerson: iconPointPersonRaw,
                                minHeadCount: minHeadCountRaw,
                                maxHeadCount: maxHeadCountRaw,
                                growthRequired: growthRequiredRaw,
                                category: categoryRaw,
                                theme: themeRaw
                            } = filterState;
                            const {
                                name: dataName,
                                latestHeadcount: dataLatestHeadcount,
                                growth: dataGrowth,
                                growth6: dataGrowth6,
                                growth12: dataGrowth12,
                                themes: dataThemes,
                                iconPointPeople: dataIconPointPeople,
                            } = dataItem;
                            if (filterNameRaw) {
                                const filterName = filterNameRaw.toLowerCase();
                                if (!dataName.toLowerCase().includes(filterName)) {
                                    return false;
                                }
                            }
                            if (minHeadCountRaw || maxHeadCountRaw) {
                                if (isFinite(minHeadCountRaw) && minHeadCountRaw > dataLatestHeadcount) {
                                    return false;
                                }
                                if (isFinite(maxHeadCountRaw) && maxHeadCountRaw < dataLatestHeadcount) {
                                    return false;
                                }
                            }
                            if (growthRequiredRaw && growthRequiredRaw.length) {
                                if (growthRequiredRaw.includes(3)) {
                                    if (!dataGrowth.growth) {
                                        return false;
                                    }
                                }
                                if (growthRequiredRaw.includes(6)) {
                                    if (!dataGrowth6.growth) {
                                        return false;
                                    }
                                }
                                if (growthRequiredRaw.includes(12)) {
                                    if (!dataGrowth12.growth) {
                                        return false;
                                    }
                                }
                            }

                            // if none of the themes have this category, exclude it
                            if (categoryRaw) {
                                const notSeen = dataThemes.every(theme => {
                                    const { category } = theme;
                                    return category !== categoryRaw;
                                });
                                if (notSeen) {
                                    return false;
                                }
                            }
                            if (themeRaw) {
                                const notSeen = dataThemes.every(theme => {
                                    const { _id } = theme;
                                    return _id !== themeRaw;
                                });
                                if (notSeen) {
                                    return false;
                                }
                            }
                            if (iconPointPersonRaw) {
                                const notSeen = dataIconPointPeople.every(person => {
                                    const { _id } = person;
                                    return _id !== iconPointPersonRaw;
                                });
                                if (notSeen) {
                                    return false;
                                }
                            }
                            return true;
                        });
                        const { sortBy, sortDir } = sortState;

                        let list;
                        const caseInsensitive = ['name'];
                        if (caseInsensitive.includes(sortBy)) {
                            list = orderBy(listRaw, [info => {
                                const part = get(info, sortBy) || '';
                                return part.toLowerCase();
                            }], [sortDir]);
                        } else if (['growth', 'growth6', 'growth12', 'latestHeadcountDate'].includes(sortBy)) {
                            list = orderBy(listRaw, info => {
                                const part = get(info, sortBy)
                                if (!isFinite(part)) {
                                    return sortDir === 'asc' ? Infinity : -Infinity;
                                }
                                return part;
                            }, [sortDir])
                        } else {
                            list = orderBy(listRaw, [sortBy], [sortDir]);
                        }
                        return (
                            <Fragment>
                                <MainHeader title="Search Results">
                                    <LinkList>
                                        <li>
                                            <LinkButton
                                                onClick={() => {
                                                    const permalinks = list.map(c => {
                                                        return c._id;
                                                    });
                                                    const tz = moment.tz.guess();
                                                    submitForm('/api/excel/headcountGrowth', {
                                                        permalinks,
                                                        tz
                                                    });
                                                }}
                                            >
                                                Download {list.length} records
                                            </LinkButton>
                                        </li>
                                    </LinkList>
                                </MainHeader>
                                <Box>
                                    <StyledStickyWrapper>
                                        <SimpleTable
                                            Header={Header}
                                            EmptyRow={emptyRow}
                                            data={list}
                                            Row={ListRow}
                                            error={error}
                                            loading={loading}
                                            onHeaderClick={onHeaderClick}
                                            sortState={sortState}
                                        />
                                    </StyledStickyWrapper>
                                </Box>
                            </Fragment>
                        );
                    }}
                </Query>
                <div style={{ float: 'right', color: 'grey' }}>* indicates that % growth was interpolated</div>
            </Container>
        </div>
    );
};

export default List;