import React from 'react';
import FirmQuery from './FirmQuery';
import { withProps } from 'recompose';
import { GET_FIRM_SUMMARY } from '-/gql/firm';
import { PersonDisplayField, AddressDisplayField } from '-/components/profiles';
import Divider from '-/style/base/Divider';
const Summary = props => {
    const { className, style, founders, location, partners } = props;
    return (
        <div className={className} style={style}>
            <PersonDisplayField label="Founders" value={founders} />
            <Divider />
            <AddressDisplayField label="Headquarters" value={location} />
            <Divider />
            <PersonDisplayField
                label="Partners"
                showTitle={true}
                value={partners}
            />
        </div>
    );
};

export default withProps({
    component: Summary,
    query: GET_FIRM_SUMMARY
})(FirmQuery);
