import React from 'react';
import MainHeader from '-/style/base/MainHeader';
import { Container, Row, Col } from '-/style/base/Layout';
import Box from '-/style/base/Box';
import moment from 'moment-timezone';
import { LinkA, LinkButton } from '-/style/base/Link';
import { isObject } from 'lodash';

// TODO: Put this in utils!
const submitForm = (path, params = {}) => {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', path);

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            const valueRaw = params[key];
            const value = isObject(valueRaw) ? JSON.stringify(valueRaw) : valueRaw;
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            hiddenField.setAttribute('value', value);
            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
};

const ReportsHome = props => {
    return (
        <Container>
            <MainHeader title="Other Reports" />
            <Row>
                <Col flex>
                    <Box>
                        <ul>
                            <li>
                                <LinkA href="/api/excel/creationDatesXls">Company Creation By Quarter</LinkA>
                            </li>
                            <li>
                                <LinkA href="/api/excel/meetingsAndCallsXls">Meetings/Calls for Companies</LinkA>
                            </li>
                            <li>
                                <LinkButton onClick={() => {
                                    const tz = moment.tz.guess();
                                    submitForm('/api/excel/themePTWL', {
                                        tz
                                    });
                                }}>PT / WL Theme Report</LinkButton>
                            </li>
                        </ul>
                    </Box>
                </Col>
            </Row>
        </Container>
    );
};

export default ReportsHome;
