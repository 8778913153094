import React, { useState } from 'react';
import { AutoComplete } from '-/components/inputs';
import { Row, Col } from '-/style/base/Layout';
import { Field, Formik, Form } from 'formik';
import {
    SEARCH_COMPANY,
    TRACK_LI_COMPANY
} from '-/gql/company';
import { GET_ME } from '-/gql/user';
import { compose, graphql } from 'react-apollo';
import styled from 'styled-components';
import { GreenButton as GreenButtonRaw } from '-/style/base/Button';
const GreenButton = styled(GreenButtonRaw)`
    margin: 0 0 0 10px;
`;

const StandardRow = props => {
    return (
        <Row
            gutter={4}
            justifyContent="space-between"
            alignItems="flex-start"
            {...props}
        />
    );
};

const TrackCompanyForm = props => {
    const { onChange, onClose, trackLiCompany, refetchQueries } = props;
    const [statusMsg, setStatusMsg] = useState('');
    return (
        <Formik
            onSubmit={async (values, actions) => {
                const { permalink } = values;
                setStatusMsg('Adding Company to LinkedIn tracking list.')
                await trackLiCompany({
                    variables: {
                        _id: permalink
                    },
                    refetchQueries
                });

                actions.resetForm();
                setTimeout(() => {
                    if (onClose) {
                        onClose();
                    }
                    if (onChange) {
                        onChange(permalink);
                    }
                }, 2000);
            }}
            render={props => {
                return (
                    <Form>
                        <Field
                            label="Track Company"
                            component={AutoComplete}
                            query={SEARCH_COMPANY}
                            resultKey="Companies"
                            name="permalink"
                            placeholder="Search for a Company..."
                        />
                        <StandardRow center>
                            <Col flex style={{ textAlign: 'right' }}>
                                <GreenButton type="submit">
                                    Track
                                </GreenButton>
                                <GreenButton
                                    type="button"
                                    onClick={onClose}
                                >
                                    Cancel
                                </GreenButton>
                            </Col>
                        </StandardRow>
                        <div>{statusMsg}</div>
                    </Form>
                );
            }}
        />
    );
};
export default compose(
    graphql(TRACK_LI_COMPANY, {
        name: 'trackLiCompany'
    }),
    graphql(GET_ME)
)(TrackCompanyForm);