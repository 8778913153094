import React, { Fragment } from 'react';
import { Col } from '-/style/base/Layout';
import { Field, FieldArray } from 'formik';
import { TextInput, EmailInput, PhoneInput, InlineCheckbox } from '-/components/inputs';
import MessageBox from '-/components/MessageBox';
import { GreenButton } from '-/style/base/Button';
import { TrashAction as TrashActionRaw, ArrowDownAction as ArrowDownActionRaw, ArrowUpAction as ArrowUpActionRaw } from '-/style/Icon';
import Divider from '-/style/base/Divider';
import { Subhead, StandardRow } from '-/style/base/Layout';
import styled from 'styled-components';

const TrashAction = styled(TrashActionRaw)`
    padding-left: 0;
`;
const ArrowDownAction = styled(ArrowDownActionRaw)`
    padding-left: 0;
`;
const ArrowUpAction = styled(ArrowUpActionRaw)`
    padding-left: 0;
`;

const Person = props => {
    const { index = 0, children } = props;
    const getFieldName = name => `people.${index}.${name}`;
    return (
        <Fragment>
            <StandardRow>
                <Col flex={6} xs={11}>
                    <Field
                        component={InlineCheckbox}
                        label="Primary"
                        name={getFieldName('primary')}
                    />
                    <Field
                        component={TextInput}
                        label="Point of Contact Name"
                        name={getFieldName('name')}
                    />

                </Col>
                <Col flex={5} xs={11} xsOrder={1}>
                    <Field
                        component={TextInput}
                        label="Point of Contact Title"
                        name={getFieldName('title')}
                    />
                </Col>
                <Col
                    flex={1}
                    alignSelf="flex-start"
                    style={{ textAlign: 'center', paddingTop: 40 }}
                >
                    {children}
                </Col>
            </StandardRow>
            <StandardRow>
                <Col flex={6} xs={11}>
                    <Field
                        component={EmailInput}
                        label="Point of Contact Email"
                        name={getFieldName('email')}
                    />
                </Col>
                <Col flex={5} xs={11}>
                    <Field
                        component={PhoneInput}
                        label="Point of Contact Phone"
                        name={getFieldName('phone')}
                    />
                </Col>
                <Col flex={1} />
            </StandardRow>
        </Fragment>
    );
};

const People = props => {
    const { values: { people = [{}] } = {} } = props;
    return (
        <Fragment>
            <Subhead>People</Subhead>
            <FieldArray
                name="people"
                render={arrayHelpers => {
                    const onAdd = () => {
                        arrayHelpers.push({});
                    };
                    const addButton = (
                        <GreenButton
                            type="button"
                            onClick={onAdd}
                            style={{ marginLeft: 5 }}
                        >
                            Add a Person
                        </GreenButton>
                    );
                    const { length } = people;
                    if (length) {
                        const needsActions = length !== 1;
                        return people.map((person, index) => {
                            const onRemove = () => {
                                MessageBox.show({
                                    onYesClick: () => {
                                        arrayHelpers.remove(index);
                                    }
                                });
                            };

                            const isFirstItem = index === 0;
                            const isLastItem = length - 1 === index;
                            const onMoveUp = () => {
                                arrayHelpers.swap(index, index - 1);
                            };
                            const onMoveDown = () => {
                                arrayHelpers.swap(index, index + 1);
                            };
                            return (
                                <Fragment key={index}>
                                    <Person index={index}>
                                        {needsActions ? (
                                            <Fragment>
                                                <TrashAction
                                                    onClick={onRemove}
                                                    size={22}
                                                >
                                                    Delete
                                            </TrashAction>
                                                <div style={{ marginTop: 10 }}>
                                                    <ArrowUpAction
                                                        onClick={onMoveUp}
                                                        size={22}
                                                        disabled={isFirstItem}>
                                                        Move Up
                                                    </ArrowUpAction>
                                                    <ArrowDownAction
                                                        onClick={onMoveDown}
                                                        size={22}
                                                        disabled={isLastItem}>
                                                        Move Down
                                                        </ArrowDownAction>
                                                </div>
                                            </Fragment>
                                        ) : null}
                                    </Person>
                                    {!isLastItem ? <Divider /> : addButton}
                                </Fragment>
                            );
                        });
                    } else {
                        return addButton;
                    }
                }}
            />
        </Fragment>
    );
};

export default People;
