import React, { Fragment, Component } from 'react';
import { AutoComplete, TextInput } from '-/components/inputs';
import { Row, Col } from '-/style/base/Layout';
import { Field, Formik, Form } from 'formik';
import {
    SEARCH_COMPANY_TO_IMPORT,
    ADD_UNLISTED_COMPANY,
    IMPORT_COMPANY
} from '-/gql/company';
import { GET_ME } from '-/gql/user';
import { compose, graphql } from 'react-apollo';
import { get } from 'lodash';
import styled from 'styled-components';
import { darkSand } from '-/style/variables';
import { GreenButton as GreenButtonRaw } from '-/style/base/Button';
const GreenButton = styled(GreenButtonRaw)`
    margin: 0 0 0 10px;
`;

const StandardRow = props => {
    return (
        <Row
            gutter={4}
            justifyContent="space-between"
            alignItems="flex-start"
            {...props}
        />
    );
};

const StyledOptionPermalink = styled.span`
    color: ${darkSand};
    font-style: italic;
`;
const StyledOptionSuffix = styled.span`
    color: ${darkSand};
`;

const companyFormatOptionLabel = (option, config) => {
    const { label, value: valueRaw, isWhitelisted } = option;
    const { context } = config;

    if (context === 'menu') {
        const { isUnlisted, value } = checkName(valueRaw);
        if (isUnlisted) {
            return `"${value}" was not found. Would you like to create it as an
            unlisted company?`;
        } else {
            return (
                <Fragment>
                    <div>{label}</div>
                    <div>
                        <StyledOptionPermalink>{value}</StyledOptionPermalink>
                        {isWhitelisted ? (
                            <StyledOptionSuffix> | DB</StyledOptionSuffix>
                        ) : null}
                    </div>
                </Fragment>
            );
        }
    } else {
        return label;
    }
};

const newRe = /^_new_:(.*)$/;
const checkName = term => {
    const parts = newRe.exec(term);
    if (parts) {
        return {
            value: parts[1],
            isUnlisted: true
        };
    } else {
        return {
            value: term,
            isUnlisted: false
        };
    }
};
const nameToPermalink = name => {
    return name
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
};

class CBCompanyForm extends Component {
    onCompanyChange(searchTerm, setFieldValue) {
        const check = checkName(searchTerm);
        const { isUnlisted, value: company } = check;
        const permalink = isUnlisted ? nameToPermalink(company) : '';
        setFieldValue('permalink', permalink);
    }
    render() {
        const {
            importCompany,
            addUnlistedCompany,
            onClose,
            onChange
        } = this.props;
        const isAdmin = get(this.props, 'data.me.role') === 'Admin';
        return (
            <Formik
                onSubmit={async (values, actions) => {
                    const { searchTerm, permalink } = values;
                    const check = checkName(searchTerm);
                    const { isUnlisted, value: company } = check;
                    let gotoPermalink;
                    if (isUnlisted) {
                        gotoPermalink = permalink;
                        await addUnlistedCompany({
                            variables: {
                                _id: permalink,
                                input: { name: company }
                            }
                        });
                    } else {
                        gotoPermalink = company;
                        await importCompany({
                            variables: {
                                _id: company
                            }
                        });
                    }
                    actions.resetForm();
                    setTimeout(() => {
                        onClose();
                        onChange(gotoPermalink);
                    }, 2000);
                }}
                render={props => {
                    const {
                        values: { searchTerm } = {},
                        setFieldValue
                    } = props;
                    const check = checkName(searchTerm);
                    const { isUnlisted } = check;
                    return (
                        <Form>
                            <Field
                                label="Import Company"
                                component={AutoComplete}
                                query={SEARCH_COMPANY_TO_IMPORT}
                                formatOptionLabel={companyFormatOptionLabel}
                                creatable={isAdmin}
                                onChange={searchTerm => {
                                    this.onCompanyChange(
                                        searchTerm,
                                        setFieldValue
                                    );
                                }}
                                resultKey="Companies"
                                name="searchTerm"
                                placeholder="Search for a Company..."
                            />
                            {isUnlisted && (
                                <Field
                                    label="Predicted Permalink"
                                    component={TextInput}
                                    name="permalink"
                                />
                            )}
                            <StandardRow center>
                                <Col flex style={{ textAlign: 'right' }}>
                                    <GreenButton type="submit">
                                        Import
                                    </GreenButton>
                                    <GreenButton
                                        type="button"
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </GreenButton>
                                </Col>
                            </StandardRow>
                        </Form>
                    );
                }}
            />
        );
    }
}
export default compose(
    graphql(IMPORT_COMPANY, {
        name: 'importCompany'
    }),
    graphql(ADD_UNLISTED_COMPANY, {
        name: 'addUnlistedCompany'
    }),
    graphql(GET_ME)
)(CBCompanyForm);
