import React, { Fragment } from 'react';
import { Col } from '-/style/base/Layout';
import { Field } from 'formik';
import Textarea from '-/components/inputs/Textarea';
import { Subhead, StandardRow } from '-/style/base/Layout';
import { StyledTextarea as StyledTextareaRaw } from '-/components/inputs/styled';
import styled from 'styled-components';
const StyledTextarea = styled(StyledTextareaRaw)`
    height: 200px;
`;

const FirmSummary = props => {
    const fieldId = 'firm-summary';
    return (
        <Fragment>
            <Subhead htmlFor={fieldId} as="label">
                Firm Summary
            </Subhead>
            <StandardRow>
                <Col flex={11} xs={12}>
                    <Field
                        component={Textarea}
                        StyledInput={StyledTextarea}
                        fieldId={fieldId}
                        name="summary"
                    />
                </Col>
            </StandardRow>
        </Fragment>
    );
};

export default FirmSummary;
