import gql from 'graphql-tag';

export const SEARCH_COMPANY = gql`
    query SEARCH_COMPANY($searchTerm: String!) {
        Companies(searchTerm: $searchTerm, isWhitelisted: true) {
            _id
            name
        }
    }
`;

export const SEARCH_COMPANY_TO_IMPORT = gql`
    query SEARCH_COMPANY($searchTerm: String!) {
        Companies(searchTerm: $searchTerm) {
            _id
            name
            isWhitelisted
        }
    }
`;

export const ADVANCED_SEARCH_COMPANY = gql`
    query ADVANCED_SEARCH_COMPANY($criteria: CompanySearchCriteria!) {
        CompanyAdvancedSearch(criteria: $criteria) {
            _id
            name
            actionItem
            iconCategory
            iconStatus
            description
            primarySector
            secondarySector
            keyVCs {
                firm {
                    _id
                    isTracked
                    name
                }
                VCs {
                    _id
                    name
                }
            }
            nextContactDate
            iconPointPeople {
                _id
                initials
            }
            websites {
                homepage
            }
            themes {
                _id
                category
                name
                permalink
            }
        }
    }
`;

export const IMPORT_COMPANY = gql`
    mutation ImportCompany($_id: ID!) {
        importCompany(_id: $_id) {
            _id
        }
    }
`;

export const ADD_UNLISTED_COMPANY = gql`
    mutation AddUnlistedCompany($_id: ID!, $input: UnlistedCompanyInput) {
        addUnlistedCompany(_id: $_id, input: $input) {
            _id
        }
    }
`;

export const TRACK_LI_COMPANY = gql`
    mutation TrackLiCompany($_id: ID!) {
        trackLiCompany(_id: $_id) {
            _id
        }
    }
`;

export const UNTRACK_LI_COMPANY = gql`
    mutation UntrackLiCompany($_id: ID!) {
        untrackLiCompany(_id: $_id) {
            _id
        }
    }
`;

export const UPDATE_COMPANY_ACTION_ITEM = gql`
    mutation UpdateCompanyActionItem(
        $_id: ID!
        $input: CompanyActionItemInput
    ) {
        updateCompanyActionItem(_id: $_id, input: $input) {
            _id
            actionItem
        }
    }
`;

export const UPDATE_COMPANY_METRICS = gql`
    mutation UpdateCompanyMetrics($_id: ID!, $input: CompanyMetricsInput) {
        updateCompanyMetrics(_id: $_id, input: $input) {
            _id
            metrics {
                users {
                    enabled
                }
                visits {
                    enabled
                }
                googleTrends {
                    enabled
                    keyword
                }
                facebookLikes {
                    enabled
                }
                twitterFollowers {
                    enabled
                }
                linkedInFollowers {
                    enabled
                }
                monthlyUniques {
                    enabled
                }
            }
        }
    }
`;

export const UPDATE_COMPANY_BASIC_INFO = gql`
    mutation UpdateCompanyBasicInfo($_id: ID!, $input: CompanyBasicInfoInput) {
        updateCompanyBasicInfo(_id: $_id, input: $input) {
            _id
            name
            iconCategory
            iconStatus
            turnDownRationale
            queuedDate
            decisionDate
            investmentDate
            nextContactDate
        }
    }
`;

export const UPDATE_COMPANY_BOARD_MEMBERS = gql`
    mutation UpdateCompanyBoardMembers(
        $_id: ID!
        $input: CompanyBoardMembersInput
    ) {
        updateCompanyBoardMembers(_id: $_id, input: $input) {
            _id
            boardMembers {
                _id
                name
            }
        }
    }
`;

export const UPDATE_COMPANY_VCS = gql`
    mutation UpdateCompanyVCs($_id: ID!, $input: CompanyVCsInput) {
        updateCompanyVCs(_id: $_id, input: $input) {
            _id
            VCs {
                _id
                name
            }
        }
    }
`;

export const UPDATE_COMPANY_REFERRERS = gql`
    mutation UpdateCompanyReferrers($_id: ID!, $input: CompanyReferrersInput) {
        updateCompanyReferrers(_id: $_id, input: $input) {
            _id
            referrers {
                _id
                name
            }
        }
    }
`;

export const UPDATE_COMPANY_ICON_POINT_PEOPLE = gql`
    mutation UpdateCompanyIconPointPeople(
        $_id: ID!
        $input: CompanyIconPointPeopleInput
    ) {
        updateCompanyIconPointPeople(_id: $_id, input: $input) {
            _id
            iconPointPeople {
                _id
                name
            }
        }
    }
`;

export const UPDATE_COMPANY_FINANCIALS = gql`
    mutation UpdateCompanyFinancials(
        $_id: ID!
        $input: CompanyFinancialsInput
    ) {
        updateCompanyFinancials(_id: $_id, input: $input) {
            _id
            financials {
                year
                bookings
                revenue
            }
        }
    }
`;

export const UPDATE_COMPANY_FINANCING = gql`
    mutation UpdateCompanyFinancials(
        $_id: ID!
        $input: CompanyFinancingRoundsInput
    ) {
        updateCompanyFinancingRounds(_id: $_id, input: $input) {
            _id
            fundingRounds {
                _id
                fundingType
                series
                date
                amount
                valuation
                firmInvestors {
                    _id
                    name
                }
                leadFirmInvestors {
                    _id
                    name
                }
                personInvestors {
                    _id
                    name
                }
            }
        }
    }
`;

export const UPDATE_COMPANY_CEO = gql`
    mutation UpdateCompanyCEO($_id: ID!, $input: CompanyCEOInput) {
        updateCompanyCEO(_id: $_id, input: $input) {
            _id
            ceo
        }
    }
`;

export const UPDATE_COMPANY_WEBSITES = gql`
    mutation UpdateCompanyWebsites($_id: ID!, $input: CompanyWebsitesInput) {
        updateCompanyWebsites(_id: $_id, input: $input) {
            _id
            websites {
                homepage
                twitter
                linkedin
                facebook
            }
        }
    }
`;

export const UPDATE_COMPANY_DESCRIPTION = gql`
    mutation UpdateCompanyDescription(
        $_id: ID!
        $input: CompanyDescriptionInput
    ) {
        updateCompanyDescription(_id: $_id, input: $input) {
            _id
            description
        }
    }
`;

// TODO: to be deprecated
export const UPDATE_COMPANY_SECTORS = gql`
    mutation UpdateCompanySectors($_id: ID!, $input: CompanySectorsInput) {
        updateCompanySectors(_id: $_id, input: $input) {
            _id
            primarySector
            secondarySector
        }
    }
`;

export const UPDATE_COMPANY_THEMES = gql`
    mutation UpdateCompanyThemes($_id: ID!, $input: CompanyThemesInput) {
        updateCompanyThemes(_id: $_id, input: $input) {
            _id
            themes {
                _id
                category
                name
                permalink
            }
        }
    }
`;


export const UPDATE_COMPANY_LOCATION = gql`
    mutation UpdateCompanyLocation($_id: ID!, $input: AddressInput) {
        updateCompanyLocation(_id: $_id, input: $input) {
            _id
            location {
                longitude
                latitude
                street1
                street2
                city
                region
                postalCode
                country
                area
            }
        }
    }
`;

export const ADD_NOTE_TO_COMPANY = gql`
    mutation addNoteToCompany($permalink: ID!, $input: CompanyNoteInput!) {
        addNoteToCompany(permalink: $permalink, input: $input) {
            _id
            notes {
                _id
                date
                text
                type
                arr
            }
        }
    }
`;

export const ADD_COMPANY_QUICK_NOTE = gql`
    mutation AddCompanyQuickNote($input: CompanyQuickNoteInput) {
        addCompanyQuickNote(input: $input) {
            _id
            text
            type
        }
    }
`;

export const UPDATE_NOTE_FOR_COMPANY = gql`
    mutation updateNoteForCompany(
        $_id: ID!
        $permalink: String!
        $input: CompanyNoteInput!
    ) {
        updateNoteForCompany(_id: $_id, permalink: $permalink, input: $input) {
            _id
            arr
            date
            text
            type
        }
    }
`;

export const REMOVE_NOTE_FOR_COMPANY = gql`
    mutation removeNoteForCompany($_id: ID!, $permalink: String!) {
        removeNoteForCompany(_id: $_id, permalink: $permalink) {
            _id
            notes {
                _id
                date
                text
                type
            }
        }
    }
`;

export const GET_COMPANY_DETAIL = gql`
    query GET_COMPANY_DETAIL($permalink: String!) {
        Company(permalink: $permalink) {
            _id
            name
            actionItem
            description
            iconCategory
            iconStatus
            websites {
                homepage
                twitter
                linkedin
                facebook
            }
            primarySector
            secondarySector
            notes {
                _id
                date
                text
                type
                arr
            }
            location {
                longitude
                latitude
                street1
                street2
                city
                region
                postalCode
                country
                area
            }
            financials {
                year
                bookings
                revenue
            }
            queuedDate
            decisionDate
            investmentDate
            nextContactDate
            turnDownRationale
            fundingRounds {
                _id
                fundingType
                series
                date
                amount
                valuation
                firmInvestors {
                    _id
                    isWhitelisted
                    name
                }
                leadFirmInvestors {
                    _id
                    isWhitelisted
                    name
                }
                personInvestors {
                    _id
                    isWhitelisted
                    name
                }
            }
            ceo
            boardMembers {
                _id
                name
            }
            VCs {
                _id
                name
            }
            iconPointPeople {
                _id
                name
            }
            referrers {
                _id
                name
            }
            themes {
                _id
                name
                category
                permalink
            }
        }
    }
`;

export const GET_COMPANY_INTRO = gql`
    query GET_COMPANY_INTRO($permalink: String!) {
        Company(permalink: $permalink) {
            _id
            name
            actionItem
            description
            iconCategory
            iconStatus
            queuedDate
            decisionDate
            investmentDate
            nextContactDate
            turnDownRationale
        }
    }
`;

export const GET_COMPANY_FINANCING = gql`
    query GET_COMPANY_FINANCING($permalink: String!) {
        Company(permalink: $permalink) {
            _id
            fundingRounds {
                _id
                fundingType
                series
                date
                amount
                valuation
                firmInvestors {
                    _id
                    isWhitelisted
                    isTracked
                    name
                }
                leadFirmInvestors {
                    _id
                    isWhitelisted
                    isTracked
                    name
                }
                personInvestors {
                    _id
                    isWhitelisted
                    name
                }
            }
        }
    }
`;

export const GET_COMPANY_SUMMARY = gql`
    query GET_COMPANY_SUMMARY($permalink: String!) {
        Company(permalink: $permalink) {
            _id
            description
            websites {
                homepage
                twitter
                linkedin
                facebook
            }
            primarySector
            secondarySector
            themes {
                _id
                name
                category
                permalink
            }
            location {
                longitude
                latitude
                street1
                street2
                city
                region
                postalCode
                country
                area
            }
            financials {
                year
                bookings
                revenue
            }
        }
    }
`;

export const GET_COMPANY_PEOPLE = gql`
    query GET_COMPANY_PEOPLE($permalink: String!) {
        Company(permalink: $permalink) {
            _id
            ceo
            boardMembers {
                _id
                name
            }
            VCs {
                _id
                name
            }
            iconPointPeople {
                _id
                name
            }
            referrers {
                _id
                name
            }
        }
    }
`;

export const GET_COMPANY_NOTES = gql`
    query GET_COMPANY_NOTES($permalink: String!) {
        Company(permalink: $permalink) {
            _id
            notes {
                _id
                date
                text
                type
                arr
            }
        }
    }
`;
const CompanyPartsForReport = gql`
    fragment CompanyPartsForReport on Company {
        _id
        name
        location {
            city
            region
            country
        }
        description
        actionItem
        ceo
        iconPointPeople {
            _id
            initials
        }
        referrers {
            _id
            name
            primaryFirm {
                _id
                name
            }
        }
        VCs {
            _id
            name
            primaryFirm {
                _id
                name
            }
        }
        keyVCs {
            firm {
                _id
                isTracked
                name
            }
            VCs {
                _id
                name
            }
        }
    }
`;

const LastRoundPartsForReport = gql`
    fragment LastRoundPartsForReport on FundingRound {
        _id
        series
        date
        amount
        valuation
    }
`;

export const GET_COMPANY_MONDAY_REPORT_ACTIVE = gql`
    query GET_COMPANY_MONDAY_REPORT_ACTIVE {
        CompanyMondayReport {
            active {
                ...CompanyPartsForReport
                lastRound {
                    ...LastRoundPartsForReport
                }
            }
        }
    }
    ${CompanyPartsForReport}
    ${LastRoundPartsForReport}
`;

export const GET_COMPANY_MONDAY_REPORT_PORTFOLIO = gql`
    query GET_COMPANY_MONDAY_REPORT_PORTFOLIO {
        CompanyMondayReport {
            portfolio {
                ...CompanyPartsForReport
                iconCategory
                themes {
                    _id
                    category
                    name
                    permalink
                }
            }
        }
    }
    ${CompanyPartsForReport}
`;

export const GET_COMPANY_MONDAY_REPORT_ACTIVE_PORTFOLIO = gql`
    query GET_COMPANY_MONDAY_REPORT_ACTIVE_PORTFOLIO {
        CompanyMondayReport {
            activePortfolio {
                ...CompanyPartsForReport
            }
        }
    }
    ${CompanyPartsForReport}
`;

export const GET_COMPANY_MONDAY_REPORT_WATCH_LIST = gql`
    query GET_COMPANY_MONDAY_REPORT_WATCH_LIST {
        CompanyMondayReport {
            watchList {
                ...CompanyPartsForReport
                primaryThemePriority
                themes {
                    _id
                    category
                    name
                    permalink
                }
                iconCategory
            }
        }
    }
    ${CompanyPartsForReport}
`;

export const GET_COMPANY_MONDAY_REPORT_PT_REVIEW = gql`
    query GET_COMPANY_MONDAY_REPORT_PT_REVIEW {
        CompanyMondayReport {
            ptReview {
                ...CompanyPartsForReport
                primaryThemePriority
                themes {
                    _id
                    category
                    name
                    permalink
                }
                lastRound {
                    ...LastRoundPartsForReport
                }
            }
        }
    }
    ${CompanyPartsForReport}
    ${LastRoundPartsForReport}
`;

export const GET_COMPANY_MONDAY_QUEUED = gql`
    query GET_COMPANY_MONDAY_QUEUED {
        CompanyMondayReport {
            queued {
                ...CompanyPartsForReport
                queuedDate
                queuedWeeks
                lastRound {
                    ...LastRoundPartsForReport
                }
            }
        }
    }
    ${CompanyPartsForReport}
    ${LastRoundPartsForReport}
`;

export const GET_COMPANY_MONDAY_DEAL_UPDATE = gql`
    query GET_COMPANY_MONDAY_DEAL_UPDATE {
        CompanyMondayReport {
            dealUpdate {
                ...CompanyPartsForReport
                queuedDate
                lastRound {
                    ...LastRoundPartsForReport
                }
            }
        }
    }
    ${CompanyPartsForReport}
    ${LastRoundPartsForReport}
`;

export const GET_COMPANY_MONDAY_PORTFOLIO = gql`
    query GET_COMPANY_MONDAY_PORTFOLIO {
        CompanyMondayReport {
            activePortfolio {
                ...CompanyPartsForReport
            }
        }
    }
    ${CompanyPartsForReport}
`;

export const GET_COMPANY_MONDAY_REPORT_NEW_THS_WEEK = gql`
    query GET_COMPANY_MONDAY_REPORT_NEW_THS_WEEK {
        CompanyMondayReport {
            newThisWeek {
                ...CompanyPartsForReport
                primaryThemePriority
                lastRound {
                    ...LastRoundPartsForReport
                }
                relatedCompanies {
                    _id
                    name
                    iconPointPeople {
                        _id
                        initials
                    }
                }
                themes {
                    _id
                    permalink
                    category
                    name
                    priority
                    ingredient
                }
            }
        }
    }
    ${CompanyPartsForReport}
    ${LastRoundPartsForReport}
`;

export const GET_COMPANY_MONDAY_TURNDOWNS = gql`
    query GET_COMPANY_MONDAY_TURNDOWNS {
        CompanyMondayReport {
            turndowns {
                ...CompanyPartsForReport
                queuedDate
                decisionDate
            }
        }
    }
    ${CompanyPartsForReport}
`;

const CompanyAnalyticDatumPart = gql`
    fragment CompanyAnalyticDatumPart on CompanyAnalyticDatum {
        rawScore
        score
        recorded_at
        recorded_at_short
    }
`;

const CompanyAnalyticMobileDesktopDatumPart = gql`
    fragment CompanyAnalyticMobileDesktopDatumPart on CompanyAnalyticMobileDesktopDatum {
        mobile
        desktop
        total
        recorded_at
        recorded_at_short
    }
`;

export const GET_COMPANY_METRICS = gql`
    query GET_COMPANY_METRICS($permalink: String!, $skipSmooth: Boolean) {
        Company(permalink: $permalink) {
            _id
            name
            metrics {
                users {
                    enabled
                }
                visits {
                    enabled
                }
                googleTrends {
                    enabled
                    keyword
                }
                employeeHeadCount {
                    enabled
                }
                facebookLikes {
                    enabled
                }
                twitterFollowers {
                    enabled
                }
                linkedInFollowers {
                    enabled
                }
                monthlyUniques {
                    enabled
                }
            }
            analytics(skipSmooth: $skipSmooth) {
                users {
                    data {
                        ...CompanyAnalyticMobileDesktopDatumPart
                    }
                }
                visits {
                    data {
                        ...CompanyAnalyticMobileDesktopDatumPart
                    }
                }
                googleTrends {
                    data {
                        ...CompanyAnalyticDatumPart
                    }
                }
                employeeHeadCount {
                    data {
                        ...CompanyAnalyticDatumPart
                    }
                }
                facebookLikes {
                    data {
                        ...CompanyAnalyticDatumPart
                    }
                }
                linkedInFollowers {
                    data {
                        ...CompanyAnalyticDatumPart
                    }
                }
                monthlyUniques {
                    data {
                        ...CompanyAnalyticDatumPart
                    }
                }
                twitterFollowers {
                    data {
                        ...CompanyAnalyticDatumPart
                    }
                }
            }
        }
    }
    ${CompanyAnalyticDatumPart}
    ${CompanyAnalyticMobileDesktopDatumPart}
`;

export const WILD_SEARCH_COMPANY = gql`
    query WILD_SEARCH_COMPANY($searchTerm: String!) {
        CompanyWildSearch(searchTerm: $searchTerm) {
            _id
            permalink
            name
            relation
            relatedName
        }
    }
`;

export const GET_LI_TRACKED_COMPANIES = gql`
    query GET_LI_TRACKED_COMPANIES {
        CompanyLiTracked {
            _id
            name
            websites {
                linkedin
            }
            li {
                tracked
                updatedAt
            }
            analytics(skipSmooth: true) {
                employeeHeadCount {
                    data {
                        ...CompanyAnalyticDatumPart
                    }
                }
            }
        }
    }
    ${CompanyAnalyticDatumPart}
`;

export const GET_HEADCOUNT_GROWTH_COMPANIES = gql`
    query GET_HEADCOUNT_GROWTH_COMPANIES {
        CompanyPTWL {
            _id
            name
            description
            websites {
                linkedin
            }
            li {
                tracked
                updatedAt
            }
            analytics(skipSmooth: true) {
                employeeHeadCount {
                    data {
                        ...CompanyAnalyticDatumPart
                    }
                }
            }
            keyVCs {
                firm {
                    _id
                    isTracked
                    name
                }
                VCs {
                    _id
                    name
                }
            }
            iconPointPeople {
                _id
                initials
            }
            themes {
                _id
                category
                name
                permalink
            }
        }
    }
    ${CompanyAnalyticDatumPart}
`;

export const GET_LINKEDIN_UPDATE_STATS = gql`
    query GET_LINKEDIN_UPDATE_STATS {
        LinkedInUpdateStats {
            _id
            Never
            Outdated
            Recent
            Total
        }
    }
`;

export const GET_ARR_NOTE_CORRECTION = gql`
    query GET_ARR_NOTE_CORRECTION {
        ArrNotes {
            _id
            text
            date
            entity {
                ...CompanyPartsForEntity
            }
        }
    }
    fragment CompanyPartsForEntity on Company {
        _id
        name
        description
        iconCategory
        iconStatus
        iconPointPeople {
            _id
            initials
        }
    }
`;

export const UPDATE_COMPANY_BULK_NOTES = gql`
    mutation UpdateCompanyBulkNotes($input: [CompanyNoteBulkUpdate]) {
        updateCompanyBulkNotes(input: $input)
    }
`;

