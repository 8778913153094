import React from 'react';
import { Formik, Field, Form } from 'formik';
import { graphql } from 'react-apollo';
import { pick } from 'lodash';
import { UPDATE_COMPANY_SECTORS } from '-/gql/company';
import { AutoComplete } from '-/components/inputs';
import Toolbar from '-/components/profiles/forms/Toolbar';
import { sectors } from '-/shared/constants';
import styled from 'styled-components';
const StyledStandardForm = styled(Form)`
    padding: 15px;
    min-width: 350;
`;
const renderFields = props => {
    const { className, closeOverlay } = props;
    return (
        <StyledStandardForm className={className}>
            <Field
                name="primarySector"
                component={AutoComplete}
                options={sectors}
                label="Primary Sector"
            />
            <Field
                name="secondarySector"
                component={AutoComplete}
                options={sectors}
                label="Secondary Sector"
            />
            <Toolbar handleCancel={closeOverlay} />
        </StyledStandardForm>
    );
};

const SectorForm = props => {
    const { _id, closeOverlay, update, initialValues, className } = props;
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const input = pick(values, [
                        'primarySector',
                        'secondarySector'
                    ]);
                    await update({
                        variables: {
                            _id,
                            input
                        }
                    });
                    closeOverlay();
                } catch (ex) {
                    console.warn(ex);
                }
            }}
            render={renderProps => {
                return renderFields({
                    ...renderProps,
                    closeOverlay,
                    className
                });
            }}
        />
    );
};

export default graphql(UPDATE_COMPANY_SECTORS, { name: 'update' })(SectorForm);
