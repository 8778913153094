import React from 'react';

const nl2br = str => {
    str = str || '';
    return str.split('\n').map((item, idx) => {
        return (
            <span key={idx}>
                {item}
                <br />
            </span>
        );
    });
};

export default nl2br;