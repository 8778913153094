import React from 'react';
import { formatDateTime } from '-/utils/format';
import { GET_ALL_LP_REPORTS, REMOVE_LP_REPORT } from '-/gql/lp';
import { Query, Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import { Container } from '-/style/base/Layout';
import Box from '-/style/base/Box';
import LinkList from '-/style/base/LinkList';
import { TrashAction, Home as PlainHome } from '-/style/Icon';
import MessageBox from '-/components/MessageBox';
import { get } from 'lodash';
import {
    StyledTable,
    StyledTheadTr,
    StyledTh,
    StyledTr,
    StyledTd
} from '-/style/base/Table';
import styled from 'styled-components';
import MainHeader from '-/style/base/MainHeader';

const HomeBuiltin = styled(PlainHome)`
    color: darkgoldenrod;
`;

const ReportHeader = props => {
    return (
        <thead>
            <StyledTheadTr>
                <StyledTh>Report</StyledTh>
                <StyledTh>Total Runs</StyledTh>
                <StyledTh>Last Run</StyledTh>
                <StyledTh>Actions</StyledTh>
            </StyledTheadTr>
        </thead>
    );
};
const ReportRow = props => {
    const {
        index,
        name,
        totalRuns,
        lastRunDate,
        lastRunBy,
        builtin,
        permalink
    } = props;
    const { initials } = lastRunBy || {};
    const userText =
        totalRuns !== 0 ? (
            <span>
                {formatDateTime(lastRunDate)} by {initials}
            </span>
        ) : (
            <span>---</span>
        );
    return (
        <StyledTr index={index}>
            <StyledTd>
                {builtin ? <HomeBuiltin size={16} /> : <PlainHome size={16} />}
                <Link
                    to={`/lp/report/view/${permalink}`}
                    style={{ paddingLeft: 10 }}
                >
                    {name || permalink}
                </Link>
            </StyledTd>
            <StyledTd>{totalRuns}</StyledTd>
            <StyledTd>{userText}</StyledTd>
            <StyledTd>
                <Mutation
                    mutation={REMOVE_LP_REPORT}
                    variables={{ permalink }}
                    update={(cache, mutationResult) => {
                        const result = cache.readQuery({
                            query: GET_ALL_LP_REPORTS
                        });
                        const LPReports = get(result, 'LPReports', []);
                        cache.writeQuery({
                            query: GET_ALL_LP_REPORTS,
                            data: {
                                LPReports: LPReports.filter(
                                    e => e.permalink !== permalink
                                )
                            }
                        });
                    }}
                >
                    {mutate => {
                        return (
                            <TrashAction
                                size={16}
                                onClick={() => {
                                    MessageBox.show({
                                        onYesClick: () => {
                                            mutate();
                                        }
                                    });
                                }}
                            />
                        );
                    }}
                </Mutation>
            </StyledTd>
        </StyledTr>
    );
};
const List = props => {
    return (
        <StyledTable>
            <ReportHeader />
            <tbody>
                <Query query={GET_ALL_LP_REPORTS}>
                    {queryResult => {
                        const { loading, error, data } = queryResult;
                        if (loading) {
                            return (
                                <tr>
                                    <td colSpan={1}>Loading...</td>
                                </tr>
                            );
                        } else if (error) {
                            return (
                                <tr>
                                    <td colSpan={1}>Failure</td>
                                </tr>
                            );
                        } else if (data) {
                            return data.LPReports.map((lpReport, idx) => {
                                return (
                                    <ReportRow
                                        key={lpReport._id}
                                        index={idx}
                                        {...lpReport}
                                    />
                                );
                            });
                        }
                        return null;
                    }}
                </Query>
            </tbody>
        </StyledTable>
    );
};

const ReportList = props => {
    return (
        <Container>
            <MainHeader title="All Reports">
                <LinkList>
                    <li>
                        <Link to="/lp/report/new">New Report</Link>
                    </li>
                </LinkList>
            </MainHeader>
            <Box>
                <List {...props} />
            </Box>
        </Container>
    );
};

export default ReportList;
