import React, { Fragment } from 'react';
import { Formik, Field, Form } from 'formik';
import { graphql, Query } from 'react-apollo';
import { get } from 'lodash';
import { UPDATE_COMPANY_THEMES } from '-/gql/company';
import { GET_ALL_CATEGORIES } from '-/gql/theme';
import { AutoComplete } from '-/components/inputs';
import Toolbar from '-/components/profiles/forms/Toolbar';
import styled from 'styled-components';
import { GET_THEME_DETAILS } from '-/gql/theme';
import { Row, Col } from '-/style/base/Layout';
const StyledStandardForm = styled(Form)`
    padding: 15px;
    min-width: 450px;
`;
const StandardRow = props => {
    return <Row justifyContent="space-between" {...props} />;
};
const StandardCol = props => {
    return <Col gutter={10} flex={6} xs={12} sm={6} style={{ marginBottom: 0 }} {...props} />;
};

const renderFields = props => {
    const { className, closeOverlay, values, setFieldValue, errors } = props;
    const category0 = get(values, 'category.0');
    const category1 = get(values, 'category.1');
    const theme0Error = get(errors, 'themes.0');
    const theme1Error = get(errors, 'themes.1');

    return (
        <StyledStandardForm className={className}>
            <Query query={GET_ALL_CATEGORIES}>
                {queryResult => {
                    const { data, error, loading } = queryResult;
                    const categoriesRaw = get(data, 'ThemeCategories', []);
                    if (error) {
                        return <div>Error!</div>
                    } else if (loading) {
                        return <div>Loading..</div>
                    } else if (categoriesRaw.length === 0) {
                        return <div>No categories have been added yet.</div>;
                    } else {
                        const categories = categoriesRaw.map(c => {
                            const { category } = c;
                            return {
                                label: category,
                                value: category
                            };
                        });
                        const themesMapByCategory = categoriesRaw.reduce((o, curr) => {
                            const { category, themes: themesRaw } = curr;
                            o[category] = themesRaw.map(t => {
                                const { _id, name } = t;
                                return {
                                    value: _id,
                                    label: name
                                }
                            });
                            return o;
                        }, {});

                        const themes0 = themesMapByCategory[category0] || [];
                        const themes1 = themesMapByCategory[category1] || [];
                        return (
                            <Fragment>
                                <StandardRow>
                                    <StandardCol>
                                        <Field
                                            component={AutoComplete}
                                            label="Category #1"
                                            name="category.0"
                                            options={categories}
                                            onChange={() => {
                                                setFieldValue('themes.0', null);
                                            }}
                                        />
                                    </StandardCol>
                                    <StandardCol>
                                        <Field
                                            component={AutoComplete}
                                            label="Theme #1"
                                            name="themes.0"
                                            options={themes0}
                                            error={theme0Error}
                                        />
                                    </StandardCol>
                                </StandardRow>
                                <StandardRow>
                                    <StandardCol>
                                        <Field
                                            component={AutoComplete}
                                            label="Category #2"
                                            name="category.1"
                                            options={categories}
                                            onChange={() => {
                                                setFieldValue('themes.1', null);
                                            }}
                                        />
                                    </StandardCol>
                                    <StandardCol>
                                        <Field
                                            component={AutoComplete}
                                            label="Theme #2"
                                            name="themes.1"
                                            options={themes1}
                                            error={theme1Error}
                                        />
                                    </StandardCol>
                                </StandardRow>
                            </Fragment>
                        )
                    }
                }}
            </Query>
            <Toolbar handleCancel={closeOverlay} />
        </StyledStandardForm>
    );
};

const ThemeForm = props => {
    const { _id, closeOverlay, update, initialValues: initialValuesRaw, className } = props;
    const category = get(initialValuesRaw, 'themes', []).map(t => {
        const { category } = t;
        return {
            label: category,
            value: category
        };
    });
    const initialValues = {
        ...initialValuesRaw,
        category
    };
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const themesRaw = get(values, 'themes', []);
                    const themes = themesRaw.map(t => t && t._id ? t._id : t).filter(Boolean);
                    // TODO: This has the potential to fail when a user has already loaded a theme page and
                    //  then adds a theme for the first time because we only have the _id not the permalink
                    const themePermalinks = themesRaw.map(t => t && t.permalink).filter(Boolean);
                    await update({
                        variables: {
                            _id,
                            input: { themes }
                        },
                        refetchQueries: () => {
                            return themePermalinks.map(t => {
                                return {
                                    query: GET_THEME_DETAILS,
                                    variables: {
                                        permalink: t
                                    }
                                };
                            });
                        }
                    });
                    closeOverlay();
                } catch (ex) {
                    console.warn(ex);
                }
            }}
            validate={values => {
                const errors = {};
                const [category0, category1] = values.category || [];
                const [theme0, theme1] = values.themes || [];
                if (category0 && !theme0) {
                    errors['themes.0'] = 'Please select a theme.';
                }
                if (category1 && !theme1) {
                    errors['themes.1'] = 'Please select a theme.';
                }
                return errors;
            }}
            render={renderProps => {
                return renderFields({
                    ...renderProps,
                    closeOverlay,
                    className
                });
            }}
        />
    );
};

export default graphql(UPDATE_COMPANY_THEMES, { name: 'update' })(ThemeForm);
