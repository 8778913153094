import React, { Fragment } from 'react';
import { Formik, Field, FieldArray, Form } from 'formik';
import { graphql } from 'react-apollo';
import { pick } from 'lodash';
import { UPDATE_COMPANY_FINANCIALS } from '-/gql/company';
import Toolbar from '-/components/profiles/forms/Toolbar';
import styled from 'styled-components';
import { TrashAction as TrashActionRaw } from '-/style/Icon';
import { years } from '-/shared/constants';
import { Col, Subhead, StandardRow } from '-/style/base/Layout';
import { GreenButton } from '-/style/base/Button';
import MessageBox from '-/components/MessageBox';
import Divider from '-/style/base/Divider';
import { MillionsNumber, AutoComplete } from '-/components/inputs';

const TrashAction = styled(TrashActionRaw)`
    padding-left: 0;
`;
const StyledStandardForm = styled(Form)`
    padding: 15px;
    min-width: 350;
`;
const FinancialYear = props => {
    const { index = 0, children } = props;
    const getFieldName = name => `financials.${index}.${name}`;
    return (
        <StandardRow>
            <Col flex={5} xs={11}>
                <Field
                    component={AutoComplete}
                    options={years}
                    label="Year"
                    name={getFieldName('year')}
                />
            </Col>
            <Col flex={3} xs={6} xsOrder={1}>
                <Field
                    component={MillionsNumber}
                    label="Bookings"
                    name={getFieldName('bookings')}
                />
            </Col>
            <Col flex={3} xs={5} xsOrder={1}>
                <Field
                    component={MillionsNumber}
                    label="Revenue"
                    name={getFieldName('revenue')}
                />
            </Col>

            <Col
                flex={1}
                alignSelf="flex-start"
                style={{ textAlign: 'center', paddingTop: 23 }}
            >
                {children}
            </Col>
        </StandardRow>
    );
};

const renderFields = props => {
    const {
        className,
        closeOverlay,
        values: { financials = [{}] }
    } = props;
    return (
        <StyledStandardForm className={className}>
            <Subhead>Financials</Subhead>
            <FieldArray
                name="financials"
                render={arrayHelpers => {
                    const onAdd = () => {
                        arrayHelpers.push({});
                    };
                    const addButton = (
                        <GreenButton type="button" onClick={onAdd}>
                            Add Year
                        </GreenButton>
                    );

                    const { length } = financials;
                    if (length) {
                        const needsRemoveBtn = length !== 1;

                        return financials.map((financialInfo, index) => {
                            const onRemove = () => {
                                MessageBox.show({
                                    onYesClick: () => {
                                        arrayHelpers.remove(index);
                                    }
                                });
                            };
                            const needsAddBtn = length - 1 === index;

                            return (
                                <Fragment key={index}>
                                    <FinancialYear index={index}>
                                        {needsRemoveBtn ? (
                                            <TrashAction
                                                size={22}
                                                onClick={onRemove}
                                            >
                                                Delete
                                            </TrashAction>
                                        ) : null}
                                    </FinancialYear>
                                    {!needsAddBtn ? <Divider /> : addButton}
                                </Fragment>
                            );
                        });
                    } else {
                        return (
                            <GreenButton type="button" onClick={onAdd}>
                                Add Year
                            </GreenButton>
                        );
                    }
                }}
            />
            <Toolbar handleCancel={closeOverlay} />
        </StyledStandardForm>
    );
};

const FinancialsForm = props => {
    const { _id, closeOverlay, update, initialValues, className } = props;
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const input = pick(values, ['financials']);
                    await update({
                        variables: {
                            _id,
                            input
                        }
                    });
                    closeOverlay();
                } catch (ex) {
                    console.warn(ex);
                }
            }}
            render={renderProps => {
                return renderFields({
                    ...renderProps,
                    closeOverlay,
                    className
                });
            }}
        />
    );
};

export default graphql(UPDATE_COMPANY_FINANCIALS, { name: 'update' })(
    FinancialsForm
);
