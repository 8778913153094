import React, { Fragment } from 'react';
import {
    StyledTable,
    StyledTheadTr,
    StyledTh,
    StyledTr,
    StyledTd,
    StyledStickyWrapper
} from '-/style/base/Table';
import { ADVANCED_SEARCH_COMPANY } from '-/gql/company';
import Box from '-/style/base/Box';
import { graphql } from 'react-apollo';
import { CompanyLink, FirmLink, PersonLink } from '-/components/Link';
import { formatDate, formatOption } from '-/utils/format';
import { SimpleValue } from '-/components/profiles';
import WebsiteLink from './view/sections/WebsiteLink';
import { sectors } from '-/shared/constants';
import { get } from 'lodash';
import styled from 'styled-components';
const CompanyHeader = props => {
    const defaultStyle = {
        width: 180,
        backgroundColor: '#EFEFEF',
        borderBottom: '1px solid #CACACB'
    };
    return (
        <thead>
            <StyledTheadTr>
                <StyledTh sticky style={defaultStyle}>
                    Name
                </StyledTh>
                <StyledTh style={defaultStyle}>Action Item</StyledTh>
                <StyledTh style={defaultStyle}>Icon Category</StyledTh>
                <StyledTh style={defaultStyle}>Icon Status</StyledTh>
                <StyledTh style={defaultStyle}>Sectors</StyledTh>
                <StyledTh style={defaultStyle}>VCs</StyledTh>
                <StyledTh style={defaultStyle}>Next Contact</StyledTh>
                <StyledTh style={defaultStyle}>Point</StyledTh>
            </StyledTheadTr>
        </thead>
    );
};

const StyledVCTd = props => {
    const keyVCPair = get(props, 'value', []);
    return (
        <StyledTd>
            {keyVCPair.map((pair, idx) => {
                const { firm, VCs = [] } = pair;
                const { _id: firmPermalink, name: firmName } = firm || {};
                const vcCount = VCs.length;
                let vcLinks;
                if (vcCount) {
                    vcLinks = (
                        <Fragment>
                            {'('}
                            {VCs.map((vc, idx) => {
                                const { _id: vcPermalink, name: vcName } =
                                    vc || {};
                                let separator = '';
                                if (vcCount > 1) {
                                    // if (idx === vcCount - 2) {
                                    //     separator = ' \u0026 ';
                                    // } else 
                                    if (idx !== vcCount - 1) {
                                        separator = ', ';
                                    }
                                }
                                return (
                                    <Fragment key={`vc-${vcPermalink}`}>
                                        <PersonLink permalink={vcPermalink}>
                                            {vcName}
                                        </PersonLink>
                                        {separator}
                                    </Fragment>
                                );
                            })}

                            {')'}
                        </Fragment>
                    );
                }
                return (
                    <div key={idx}>
                        <FirmLink permalink={firmPermalink}>
                            {firmName}
                        </FirmLink>{' '}
                        {vcLinks}
                    </div>
                );
            })}
        </StyledTd>
    );
};

const StyledCompany = styled.div`
    line-height: 30px;
    a {
        vertical-align: middle;
    }
    
`;
const CompanyRow = props => {
    const {
        index,
        _id,
        name,
        actionItem,
        description,
        iconCategory,
        iconStatus,
        primarySector,
        secondarySector,
        nextContactDate,
        iconPointPeople = [],
        keyVCs,
        websites
    } = props;
    const homepage = get(websites, 'homepage', '');

    const noSector = !(primarySector || secondarySector);
    return (
        <StyledTr index={index}>
            <StyledTd sticky index={index}>
                <StyledCompany>
                    <WebsiteLink type="homepage" url={homepage} />
                    <CompanyLink permalink={_id} style={{ paddingLeft: 5 }}>{name}</CompanyLink>
                </StyledCompany>


                <div>{description}</div>
            </StyledTd>
            <StyledTd>{actionItem}</StyledTd>
            <StyledTd>{iconCategory}</StyledTd>
            <StyledTd>{iconStatus}</StyledTd>
            <StyledTd>
                {noSector && <SimpleValue>No sector defined.</SimpleValue>}
                {primarySector && (
                    <SimpleValue>
                        {formatOption(primarySector, sectors)}
                    </SimpleValue>
                )}
                {secondarySector && (
                    <SimpleValue>
                        {formatOption(secondarySector, sectors)}
                    </SimpleValue>
                )}
            </StyledTd>
            <StyledVCTd value={keyVCs} />
            <StyledTd>{formatDate(nextContactDate)}</StyledTd>
            <StyledTd>
                {iconPointPeople.map(u => u.initials).join('/')}
            </StyledTd>
        </StyledTr>
    );
};

const renderList = props => {
    const { loading, error, data } = props;
    if (loading) {
        return (
            <tr>
                <td colSpan={5}>Loading...</td>
            </tr>
        );
    } else if (error) {
        return (
            <tr>
                <td colSpan={5}>Failure</td>
            </tr>
        );
    } else if (data && data.CompanyAdvancedSearch) {
        return data.CompanyAdvancedSearch.map((company, idx) => {
            return <CompanyRow key={company._id} index={idx} {...company} />;
        });
    }
    return null;
};

const List = props => {
    return (
        <StyledStickyWrapper style={{ maxHeight: 450 }}>
            <StyledTable>
                <CompanyHeader />
                <tbody>{renderList(props)}</tbody>
            </StyledTable>
        </StyledStickyWrapper>
    );
};

const Results = props => {
    return (
        <Box>
            <List {...props} />
        </Box>
    );
};

export default graphql(ADVANCED_SEARCH_COMPANY, {
    options: props => {
        const { criteria } = props;
        return {
            variables: {
                criteria
            }
        };
    }
})(Results);
