import React from 'react';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import { Col } from '-/style/base/Layout';
import { StandardRow, FixedBox, FixedBody } from '-/components/profiles';
import Subhead from '-/style/base/Subhead';
import Box from '-/style/base/Box';
import OverlayWithState from '-/components/OverlayWithState';
import { getTodayAsSimpleDate } from '-/components/inputs';
import { Container } from '-/style/base/Layout';
import { GET_LP } from '-/gql/lp';
import {
    IntroSection,
    ContactSection,
    SummarySection,
    FitSection,
    CommitmentsSection,
    NotesSection,
    NoteForm,
    FitKeyIndicator
} from './sections';

const ProfileLayout = props => {
    const { permalink, iconFit: { priority, probability } = {} } = props;
    return (
        <Container>
            <IntroSection permalink={permalink} />
            <StandardRow>
                <Col xs={12} sm={6} flex={3} component={FixedBox}>
                    <Subhead>Contact</Subhead>
                    <FixedBody>
                        <ContactSection permalink={permalink} />
                    </FixedBody>
                </Col>
                <Col xs={12} flex={6} component={FixedBox}>
                    <Subhead>LP Summary</Subhead>
                    <FixedBody>
                        <SummarySection permalink={permalink} />
                    </FixedBody>
                </Col>
                <Col sm={12} xs={12} flex={3} component={FixedBox}>
                    <Subhead>
                        Icon/LP Fit
                        <FitKeyIndicator
                            priority={priority}
                            probability={probability}
                        />
                    </Subhead>
                    <FitSection permalink={permalink} />
                </Col>
            </StandardRow>
            <StandardRow>
                <Col sm={12} xs={12} flex={9} component={Box}>
                    <Subhead>
                        Notes{' '}
                        <OverlayWithState
                            arrowSide="left"
                            vAttachment="top"
                            hAttachment="left"
                            vTargetAttachment="top"
                            hTargetAttachment="right"
                            closeOnEsc
                        >
                            <NoteForm
                                permalink={permalink}
                                initialValues={{
                                    type: 'Call',
                                    date: getTodayAsSimpleDate()
                                }}
                            />
                        </OverlayWithState>
                    </Subhead>
                    <NotesSection permalink={permalink} />
                </Col>
                <Col sm={12} xs={12} flex={3}>
                    <Box>
                        <Subhead>Commitments</Subhead>
                        <CommitmentsSection permalink={permalink} />
                    </Box>
                </Col>
            </StandardRow>
        </Container>
    );
};

const Profile = props => {
    const permalink = get(props, 'match.params.permalink');
    const variables = { permalink };

    return (
        <Query query={GET_LP} variables={variables}>
            {queryResult => {
                const { loading, error, data } = queryResult;
                if (loading) {
                    return <span>loading...</span>;
                } else if (error) {
                    return <span>error</span>;
                } else {
                    return <ProfileLayout permalink={permalink} {...data.LP} />;
                }
            }}
        </Query>
    );
};

export default Profile;
