import styled from 'styled-components';
import { nearlyBlack, baseGreen } from './../variables';

const LinkList = styled.ul`
    li {
        list-style-type: none;
        display: inline;
        a,
        button {
            color: ${nearlyBlack};
            font-weight: bold;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 12px;
        }
        a:hover,
        button:hover {
            color: ${baseGreen};
        }
    }
    li:not(:first-child):before {
        padding: 0 5px 0 5px;
        content: ' | ';
    }
`;

export default LinkList;
