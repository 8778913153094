import createMaskInput from './Masked';
import options from 'text-mask-addons/dist/emailMask';

const extractEmailAddress = (str = "") => {
    // eslint-disable-next-line
    const chars = "[a-zA-Z0-9\\!\\#\\$\\%\\&\\'\\*\\+\\-\\/\\=\\?\\^\\_\\`\\{\\|\\}\\~\\\.]+";
    // eslint-disable-next-line
    const emailRegExp = new RegExp(`${chars}?\@${chars}\\.${chars}`, "g");
    const emails = [...new Set(str.match(emailRegExp) || [])]
        .filter(email => !/\.\./.test(email))
        .map(email => email.toLowerCase());
    return emails;
};

// We trigger search whenever there is a space not followed by a .
// We look for this case because it is used in the mask ("tagg@ .")
// any other time that a space got in it must have been pasted and therefore
// we need to find the email for ourselves.
const triggerToSearch = / (?!\.)/;
const { mask: maskRaw, pipe: pipeRaw } = options;
const toCreate = {
    mask: maskRaw,
    pipe: (conformedValue, config) => {
        const { rawValue } = config;
        const hasMatch = rawValue.match(triggerToSearch);
        if (hasMatch) {
            const [email] = extractEmailAddress(rawValue);
            if (email) {
                return email;
            }
        }
        return pipeRaw(conformedValue, config);
    }
};
export default createMaskInput(toCreate);
