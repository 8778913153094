import React from 'react';
import styled from 'styled-components';
import { abbreviateNumber } from '-/utils/format';
import { statusColors, baseBlue } from '-/style/variables';

const StyledTile = styled.div`
    width: 160px;
    border-radius: 4px;
    ${props => {
        return `border: 2px solid ${props.active ? baseBlue : 'transparent'};`
    }}
    box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.15);
    margin: 5px;
    float: left;
    text-align: center;
`;
const StyledContent = styled.div`
    height: 65px;
    font-size: 48px;
    background-color: ${props => {
        const { status, count } = props;
        const standardColor = statusColors['standard']
        if (count) {
            return statusColors[status] || standardColor;
        }
        return standardColor;
    }};
    color: white;
    font-weight: 300;
`;
const StyledTitle = styled.div`
    height: 35px;
    font-size: 24px;
    color: #333333;
    background-color: white;
    padding-top: 12px;
    ${props => {
        if (props.onClick) {
            return `
                &:hover {
                    text-decoration: underline;
                }
            `
        }
    }}
`;
const Tile = props => {
    const { active, count, title, status, setExactFilter, setToggleFilter } = props;
    return (
        <StyledTile active={active} onClick={setToggleFilter}>
            <StyledContent status={status} count={count}>{abbreviateNumber(count)}</StyledContent>
            {title ? <StyledTitle onClick={setExactFilter}>{title}</StyledTitle> : null}
        </StyledTile>
    );
};

export default Tile;