import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
    white-space: nowrap;
    font-weight: bold;
    font-size: 12px;
    display: block;
    margin-bottom: 0.25rem;
`;

const Label = props => {
    const { error, children, ...rest } = props;
    return <StyledLabel {...rest}>{children}</StyledLabel>;
};

export default Label;
