import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from '-/style/base/Layout';
import { Formik, Form, Field } from 'formik';
import { compose, graphql } from 'react-apollo';
import { get, isObject } from 'lodash';
import styled from 'styled-components';
import LinkList from '-/style/base/LinkList';
import BoxRaw from '-/style/base/Box';
import { Link, withRouter } from 'react-router-dom';
import { Save } from '-/style/Icon';
import {
    GET_LP_REPORT,
    UPDATE_LP_REPORT,
    ADD_LP_REPORT,
    ADVANCED_SEARCH_LP
} from '-/gql/lp';
import { AutoComplete, Checkboxes, TextInput } from '-/components/inputs';
import moment from 'moment-timezone';
import {
    nextContact,
    lpStatuses,
    lpProbabilities,
    regions,
    lpTypeOfFunds,
    lpPriorities,
    lpReportColumns,
    lpReportSortableColumns,
    materialsSentTypeMap,
    statesByAbbrev
} from '-/shared/constants';
import Popup from 'reactjs-popup';
import SaveDialog from './SaveDialog';
import { GreenButton } from '-/style/base/Button';
import { LinkButton } from '-/style/base/Link';
import MainHeader from '-/style/base/MainHeader';
import SearchResults from './SearchResults';

const submitForm = (path, params) => {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', path);

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            const valueRaw = params[key];
            const value = isObject(valueRaw) ? JSON.stringify(valueRaw) : valueRaw;
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            hiddenField.setAttribute('value', value);
            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
};
// Ref: https://github.com/apollographql/apollo-client/issues/2160
const removeTypename = value => {
    if (value === null || value === undefined) {
        return value;
    } else if (Array.isArray(value)) {
        return value.map(v => removeTypename(v));
    } else if (typeof value === 'object') {
        const newObj = {};
        Object.entries(value).forEach(([key, v]) => {
            if (key !== '__typename') {
                newObj[key] = removeTypename(v);
            }
        });
        return newObj;
    }
    return value;
};

const StandardRow = props => {
    return <Row justifyContent="space-between" {...props} />;
};
const StandardCol = props => {
    return <Col gutter={10} flex={4} xs={12} sm={6} {...props} />;
};
const Box = styled(BoxRaw)`
    margin-bottom: 20px;
`;

const renderForm = props => {
    const regionsAndStates = [
        {
            label: 'Regions',
            options: regions
        },
        {
            label: 'States',
            options: statesByAbbrev
        }
    ];

    return (
        <Form>
            <StandardRow>
                <StandardCol>
                    <Field
                        component={TextInput}
                        label="By Name"
                        name="criteria.name"
                    />
                    <Field
                        component={AutoComplete}
                        // multiple={true}
                        options={lpPriorities}
                        label="By Priority"
                        name="criteria.priority"
                    />
                    <Field
                        component={AutoComplete}
                        options={lpStatuses}
                        label="By Status"
                        name="criteria.status"
                    />
                    <Field
                        component={AutoComplete}
                        options={regionsAndStates}
                        label="By Region or State"
                        name="criteria.region"
                        multiple={true}
                    />
                </StandardCol>
                <StandardCol>
                    <Field
                        component={AutoComplete}
                        options={nextContact}
                        label="By Next Contact"
                        name="criteria.nextContactDate"
                    />
                    <Field
                        component={AutoComplete}
                        options={materialsSentTypeMap}
                        label="By Materials Sent"
                        name="criteria.materialsSentType"
                    />
                    <Field
                        component={AutoComplete}
                        options={lpProbabilities}
                        label="By Probability"
                        name="criteria.probability"
                        multiple={true}
                    />
                    <Field
                        component={AutoComplete}
                        multiple={true}
                        options={lpTypeOfFunds}
                        label="By Type of Fund"
                        name="criteria.typeOfFund"
                    />
                </StandardCol>
                <StandardCol sm={12}>
                    <Field
                        component={Checkboxes}
                        options={cbLpReportColumns}
                        label="Include"
                        name="columns"
                        style={{
                            height: 172,
                            marginBottom: 10,
                            overflow: 'auto'
                        }}
                    />
                    <Field
                        component={AutoComplete}
                        isClearable={false}
                        options={lpReportSortableColumns}
                        label="Sort Report By"
                        name="sortBy"
                    />
                    <div style={{ textAlign: 'right' }}>
                        <GreenButton type="submit">Generate Report</GreenButton>
                    </div>
                </StandardCol>
            </StandardRow>
        </Form>
    );
};

const allColumns = lpReportColumns.reduce((acc, o) => {
    acc.push(o.value);
    return acc;
}, []);
const cbLpReportColumns = lpReportColumns.filter(o => o.value !== 'name');
const RawReport = props => {
    const { title, permalink, reportName, criteria, columns, sortBy } = props;
    const count = get(props, 'data.LPAdvancedSearch.length', 0);
    return (
        <Fragment>
            <MainHeader title={title}>
                <LinkList>
                    <li>
                        <LinkButton
                            onClick={() => {
                                const tz = moment.tz.guess();
                                submitForm('/api/excel/LPReport', {
                                    permalink,
                                    reportName,
                                    criteria,
                                    columns,
                                    sortBy,
                                    tz
                                });
                            }}
                        >
                            Download {count} records
                        </LinkButton>
                    </li>
                </LinkList>
            </MainHeader>
            <Box padding={20}>
                <SearchResults
                    sticky={true}
                    hideHeader={true}
                    criteria={criteria}
                    columns={columns}
                    sortBy={sortBy}
                />
            </Box>
        </Fragment>
    );
};
const Report = graphql(ADVANCED_SEARCH_LP, {
    options: props => {
        const { criteria, sortBy } = props;
        return {
            variables: {
                criteria,
                sortBy
            }
        };
    }
})(RawReport);

class ReportWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onSaveSubmit = async values => {
        const { addLPReport, updateLPReport, history } = this.props;
        const {
            name,
            builtin,
            criteria = {},
            columns,
            sortBy,
            submitAction = 'save'
        } = values;
        const permalink = get(this.props, 'match.params.permalink');
        let result, newpermalink, historyAction;
        const input = {
            name,
            builtin,
            criteria,
            columns,
            sortBy
        };
        if (permalink && submitAction === 'save') {
            result = await updateLPReport({
                variables: {
                    permalink,
                    input
                }
            });
            newpermalink = get(result, 'data.updateLPReport.permalink');
            historyAction = 'replace';
        } else {
            result = await addLPReport({
                variables: {
                    input
                }
            });
            newpermalink = get(result, 'data.addLPReport.permalink');
            historyAction = 'push';
        }
        if (permalink !== newpermalink) {
            history[historyAction](`/lp/report/view/${newpermalink}`);
        }
    };

    render() {
        const LPReport = get(this.props, 'data.LPReport', {});
        // We always use the loaded version to start but then use the
        // state
        const {
            criteria: criteriaFromDB = {},
            name: reportName,
            builtin,
            sortBy: sortByFromDb,
            columns: columnsFromDb
        } = removeTypename(LPReport);

        const {
            criteria: criteriaFromState,
            sortBy: sortByFromState,
            columns: columnsFromState
        } = this.state;
        const criteria = criteriaFromState || criteriaFromDB || {};
        const sortBy = sortByFromState || sortByFromDb || 'name';

        const columnsUnsorted = columnsFromState || columnsFromDb;
        let columns = allColumns;
        if (columnsUnsorted) {
            columns = allColumns.filter(colName => {
                return columnsUnsorted.indexOf(colName) !== -1;
            });
        }

        const permalink = get(this.props, 'match.params.permalink');

        const initialValues = {
            name: reportName,
            builtin,
            criteria,
            columns,
            sortBy
        };

        const resultsTitleText = `Search Results - ${reportName ||
            'Unsaved Report'}`;
        const resultsTitle = (
            <Fragment>
                {resultsTitleText}
                {` `}
                <Popup trigger={<Save size={18} />} modal>
                    {close => (
                        <SaveDialog
                            close={close}
                            initialValues={initialValues}
                            onSubmit={this.onSaveSubmit}
                        />
                    )}
                </Popup>
            </Fragment>
        );
        return (
            <Container>
                <MainHeader title="Custom Report">
                    <LinkList>
                        <li>
                            <Link to="/lp/reports">All Reports</Link>
                        </li>
                    </LinkList>
                </MainHeader>
                <Box padding={10} style={{ marginBottom: 0 }}>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        render={renderForm}
                        onSubmit={async (values, formBag) => {
                            const { criteria, sortBy, columns } = values;
                            this.setState({
                                criteria,
                                sortBy,
                                columns
                            });
                        }}
                    />
                </Box>

                <Report
                    title={resultsTitle}
                    permalink={permalink}
                    reportName={reportName}
                    criteria={criteria}
                    columns={columns}
                    sortBy={sortBy}
                />
            </Container>
        );
    }
}

const ifEdit = props => {
    const haspermalink = get(props, 'match.params.permalink');
    return haspermalink;
};
const ifNew = props => {
    return !ifEdit(props);
};

export default compose(
    withRouter,
    graphql(ADD_LP_REPORT, {
        skip: ifEdit,
        name: 'addLPReport'
    }),
    graphql(UPDATE_LP_REPORT, {
        skip: ifNew,
        name: 'updateLPReport'
    }),
    graphql(GET_LP_REPORT, {
        skip: ifNew,
        options: props => {
            const permalink = get(props, 'match.params.permalink');
            return {
                variables: {
                    permalink
                }
            };
        }
    })
)(ReportWrapper);
