import React from 'react';
import Label from './Label';
import Feedback from './Feedback';
import { uniqueId } from 'lodash';
import { StyledInput } from './styled';

const TextInput = props => {
    const {
        className,
        fieldId: fieldIdRaw,
        label,
        field,
        error,
        placeholder,
        disabled,
        autoComplete = 'off'
    } = props;

    const { value: valueRaw, ...restOfField } = field;
    // Note: This is done in order to avoid `A component is changing an uncontrolled input of type undefined to be controlled.`
    // If the initial value is undefined we will default it to ''.
    const value = valueRaw || '';
    const fieldId = fieldIdRaw || uniqueId('text-');
    return (
        <React.Fragment>
            <Label htmlFor={fieldId} error={error}>
                {label}
            </Label>
            <StyledInput
                className={className}
                id={fieldId}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                autoComplete={autoComplete}
                {...restOfField}
            />
            <Feedback error={error} />
        </React.Fragment>
    );
};

export default TextInput;
