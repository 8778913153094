import { withProps } from 'recompose';
import { compose, graphql } from 'react-apollo';
import { GET_LP_NOTES, REMOVE_NOTE_FOR_LP } from '-/gql/lp';
import LPQuery from './LPQuery';
import NoteListBase from '-/components/notes/List';
import NoteForm from './inlineForms/NoteForm';

const NoteViewGql = compose(
    graphql(REMOVE_NOTE_FOR_LP, {
        name: 'removeNote'
    }),
    withProps({
        Form: NoteForm,
        type: 'lp'
    })
)(NoteListBase);

export default withProps({
    query: GET_LP_NOTES,
    component: NoteViewGql,
    editable: true
})(LPQuery);
