import React, { useState, Fragment } from 'react';
import { Row, Col } from '-/style/base/Layout';
import styled from 'styled-components';
import Subhead from '-/style/base/Subhead';
import Box from '-/style/base/Box';
import { Container } from '-/style/base/Layout';
import { GET_FIRM_DETAILS } from '-/gql/firm';
import { get } from 'lodash';
import { withProps } from 'recompose';
import {
    IntroSection,
    SummarySection,
    CompaniesSection,
    NotesSection,
    InvestmentsSection
} from './sections';
import FirmQuery from './sections/FirmQuery';
import LinkList from '-/style/base/LinkList';
import { LinkButton } from '-/style/base/Link';
import ExitBar from '-/components/profiles/ExitBar';
import moment from 'moment-timezone';

const submitForm = (path, params) => {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', path);

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            hiddenField.setAttribute('value', params[key]);
            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
};

const StandardRow = props => {
    return <Row gutter={4} justifyContent="space-between" {...props} />;
};
StandardRow.displayName = 'Row';

const StyledFixedBody = styled.div`
    max-height: 370px;
    overflow: auto;
`;

const ProfileLayout = props => {
    const { permalink } = props;
    const [inEditMode, setEditMode] = useState(false);
    const enterEditMode = () => {
        setEditMode(true);
    };
    const exitEditMode = () => {
        setEditMode(false);
    };
    return (
        <Fragment>
            {inEditMode && <ExitBar onClick={exitEditMode} />}
            <Container>
                <IntroSection
                    inEditMode={inEditMode}
                    enterEditMode={enterEditMode}
                    permalink={permalink}
                    style={{ padding: 8 }} />
                <StandardRow>
                    <Col xs={12} sm={12} flex={3} component={Box}>
                        <Subhead>Firm</Subhead>
                        <StyledFixedBody
                            as={SummarySection}
                            permalink={permalink}
                        />
                    </Col>
                    <Col xs={12} sm={12} flex={9} component={Box}>
                        <Row
                            gutter={4}
                            justifyContent="space-between"
                            alignItems="flex-end"
                        >
                            <Col flex={6}>
                                <Subhead>Priority Target & Watch List</Subhead>
                            </Col>
                            <Col
                                flex={6}
                                style={{
                                    textAlign: 'right',
                                    marginBottom: 15
                                }}
                            >
                                <LinkList>
                                    <li>
                                        <LinkButton
                                            onClick={() => {
                                                const tz = moment.tz.guess();
                                                submitForm('/api/excel/firmPTWL', {
                                                    permalink,
                                                    tz
                                                });
                                            }}
                                        >
                                            Download Companies
                                    </LinkButton>
                                    </li>
                                </LinkList>
                            </Col>
                        </Row>

                        <StyledFixedBody
                            as={CompaniesSection}
                            permalink={permalink}
                        />
                    </Col>
                </StandardRow>
                <StandardRow>
                    <Col flex={12} component={Box}>
                        <Subhead>Notes</Subhead>
                        <NotesSection
                            permalink={permalink}
                            style={{ maxHeight: 280, overflow: 'auto' }}
                        />
                    </Col>
                    <Col flex={12}>
                        <Box>
                            <Subhead>Investments</Subhead>
                            <InvestmentsSection permalink={permalink} />
                        </Box>
                    </Col>
                </StandardRow>
            </Container>
        </Fragment>
    );
};

export default withProps(initialProps => {
    const permalink = get(initialProps, 'match.params.permalink', '');
    return {
        component: ProfileLayout,
        query: GET_FIRM_DETAILS,
        permalink,
        ...initialProps
    };
})(FirmQuery);
