import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pick } from 'lodash';
import { Formik, Form, Field } from 'formik';

import { Textarea, SimpleDate } from '-/components/inputs';

import Toolbar from './Toolbar';

const StyledForm = styled(Form)`
    width: 300px;
    padding: 10px;
`;

const renderFields = props => {
    const { className, closeOverlay, side } = props;
    return (
        <StyledForm className={className}>
            <div>
                <Field
                    component={Textarea}
                    label="Action Item"
                    name="actionItem"
                />
                {side === 'lp' && (
                    <Field
                        component={SimpleDate}
                        label="Next Contact Date"
                        name="nextContactDate"
                    />
                )}
            </div>
            <Toolbar handleCancel={closeOverlay} />
        </StyledForm>
    );
};

const ActionItemForm = props => {
    const {
        _id,
        className,
        updateActionItem,
        initialValues,
        closeOverlay,
        side
    } = props;

    const pickFields =
        side === 'lp' ? ['actionItem', 'nextContactDate'] : ['actionItem'];

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={async values => {
                try {
                    const input = pick(values, pickFields);
                    await updateActionItem({
                        variables: {
                            _id,
                            input
                        }
                    });
                    closeOverlay();
                } catch (ex) {
                    console.log('Failed', ex);
                }
            }}
            render={props => {
                return renderFields({
                    ...props,
                    closeOverlay,
                    className,
                    side
                });
            }}
        />
    );
};

ActionItemForm.propTypes = {
    updateActionItem: PropTypes.func.isRequired,
    className: PropTypes.string,
    initialValues: PropTypes.shape({
        actionItem: PropTypes.string,
        nextContactDate: PropTypes.string
    }),
    closeOverlay: PropTypes.func.isRequired
};

ActionItemForm.defaultProps = {
    side: 'std'
};

export default ActionItemForm;
