import React, { Fragment } from 'react';
import { Field, FieldArray } from 'formik';

import { MillionsNumber, TextInput, AutoComplete } from '-/components/inputs';
import { iconFunds } from '-/shared/constants';
import { Col, Subhead, StandardRow } from '-/style/base/Layout';
import { GreenButton } from '-/style/base/Button';
import MessageBox from '-/components/MessageBox';
import Divider from '-/style/base/Divider';
import { TrashAction as TrashActionRaw } from '-/style/Icon';
import styled from 'styled-components';

const TrashAction = styled(TrashActionRaw)`
    padding-left: 0;
`;
const Commitment = props => {
    const { index = 0, children } = props;
    const getFieldName = name => `commitments.${index}.${name}`;
    return (
        <StandardRow>
            <Col flex={5} xs={11}>
                <Field
                    component={TextInput}
                    label="Legal Entity"
                    name={getFieldName('legalName')}
                />
            </Col>
            <Col flex={3} xs={6} xsOrder={1}>
                <Field
                    component={AutoComplete}
                    isClearable={false}
                    label="Icon Fund"
                    name={getFieldName('iconFund')}
                    options={iconFunds}
                />
            </Col>
            <Col flex={3} xs={5} xsOrder={1}>
                <Field
                    component={MillionsNumber}
                    label="Amount Committed"
                    name={getFieldName('amount')}
                />
            </Col>

            <Col
                flex={1}
                alignSelf="flex-start"
                style={{ textAlign: 'center', paddingTop: 23 }}
            >
                {children}
            </Col>
        </StandardRow>
    );
};

const Commitments = props => {
    const { values: { commitments = [{}] } = {} } = props;
    return (
        <Fragment>
            <Subhead>Commitments</Subhead>
            <FieldArray
                name="commitments"
                render={arrayHelpers => {
                    const onAdd = () => {
                        arrayHelpers.push({});
                    };
                    const addButton = (
                        <GreenButton type="button" onClick={onAdd}>
                            Add Commitment
                        </GreenButton>
                    );

                    const { length } = commitments;
                    if (length) {
                        const needsRemoveBtn = length !== 1;

                        return commitments.map((commitment, index) => {
                            const onRemove = () => {
                                MessageBox.show({
                                    onYesClick: () => {
                                        arrayHelpers.remove(index);
                                    }
                                });
                            };
                            const needsAddBtn = length - 1 === index;

                            return (
                                <Fragment key={index}>
                                    <Commitment index={index}>
                                        {needsRemoveBtn ? (
                                            <TrashAction
                                                size={22}
                                                onClick={onRemove}
                                            >
                                                Delete
                                            </TrashAction>
                                        ) : null}
                                    </Commitment>
                                    {!needsAddBtn ? <Divider /> : addButton}
                                </Fragment>
                            );
                        });
                    } else {
                        return (
                            <GreenButton type="button" onClick={onAdd}>
                                Add a Commitment
                            </GreenButton>
                        );
                    }
                }}
            />
        </Fragment>
    );
};

export default Commitments;
