import React from 'react';
import { Query } from 'react-apollo';
import MattermarkChart from '-/company/view/charts/Mattermark';
import SemrushMobileDesktopChart from '-/company/view/charts/SemrushMobileDesktop';
import { get } from 'lodash';
import { GET_COMPANY_METRICS } from '-/gql/company';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { potentialMetrics } from '-/shared/constants';
import styled from 'styled-components';

const StyledCarousel = styled(Carousel)`
    height: 320px;
    .slide {
        background: white;
        height: 300px;
    }
`;

const Momentum = props => {
    const { permalink } = props;
    return (
        <Query query={GET_COMPANY_METRICS} variables={{ permalink }}>
            {result => {
                const { data, loading, error } = result;
                const analytics = get(data, 'Company.analytics');
                if (analytics) {
                    const availableMetrics = potentialMetrics.filter(metric => {
                        const { key } = metric;
                        return analytics[key];
                    });
                    return (
                        <StyledCarousel
                            showArrows={true}
                            showStatus={false}
                            showThumbs={false}
                            showIndicators={false}
                            height={300}
                        >
                            {availableMetrics
                                .filter(metricInfo => {
                                    const { key } = metricInfo;
                                    const chartInfo = analytics[key];
                                    const hasData =
                                        get(chartInfo, 'data.length', 0) !== 0;
                                    return hasData;
                                })
                                .map(metricInfo => {
                                    const { title, key } = metricInfo;
                                    const chartInfo = analytics[key];
                                    const chartTypes = {
                                        users: SemrushMobileDesktopChart,
                                        visits: SemrushMobileDesktopChart
                                    };
                                    const Chart = chartTypes[key] || MattermarkChart;
                                    return (
                                        <Chart
                                            key={key}
                                            chartType={key}
                                            title={title}
                                            {...chartInfo}
                                        />
                                    );
                                })}
                        </StyledCarousel>
                    );
                } else if (loading) {
                    return <div>Loading</div>;
                } else if (error) {
                    return <div>Err</div>;
                }
            }}
        </Query>
    );
};

export default Momentum;
