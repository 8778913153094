import React, { Fragment, useState } from 'react';
import { StyledTr, StyledTd as StyledTdRaw } from '-/style/base/Table';
import { TdName } from './Cells';
import { SimpleValue } from '-/components/profiles';
import Link, { LinkButton } from '-/style/base/Link';
import { FirmLink, PersonLink } from '-/components/Link';
import { get } from 'lodash';
import { formatDate, formatRound } from '-/utils/format';
import styled from 'styled-components';

const StyledTd = styled(StyledTdRaw)`
    font-size: 12px;
    a {
        font-size: 12px;
        text-transform: none;
    }
`;

const TdKeyVc = props => {
    const { onlyTracked } = props;
    let keyVCPair = get(props, 'value', []);
    if (onlyTracked) {
        keyVCPair = keyVCPair.filter(pair => {
            return pair.firm && pair.firm.isTracked;
        });
    }
    const keyVCPairLength = keyVCPair.length;
    return (
        <StyledTd>
            {keyVCPair
                .map((pair, idx) => {
                    const { firm, VCs = [] } = pair;
                    const { _id: firmPermalink, name: firmName, isTracked = false } = firm || {};
                    const vcCount = VCs.length;
                    let vcLinks;
                    if (vcCount) {
                        vcLinks = (
                            <Fragment>
                                {' ('}
                                {VCs.map((vc, idx) => {
                                    const { _id: vcPermalink, name: vcName } =
                                        vc || {};
                                    let separator = '';
                                    if (vcCount > 1) {
                                        // if (idx === vcCount - 2) {
                                        //     separator = ' \u0026 ';
                                        // } else 
                                        if (idx !== vcCount - 1) {
                                            separator = ', ';
                                        }
                                    }
                                    return (
                                        <Fragment key={`vc-${vcPermalink}`}>
                                            <PersonLink permalink={vcPermalink}>
                                                {vcName}
                                            </PersonLink>
                                            {separator}
                                        </Fragment>
                                    );
                                })}

                                {')'}
                            </Fragment>
                        );
                    }
                    let pairSeparator = '';
                    if (keyVCPairLength > 1) {
                        // if (idx === keyVCPairLength - 2) {
                        //     pairSeparator = ' \u0026 ';
                        // } else 
                        if (idx !== keyVCPairLength - 1) {
                            pairSeparator = ', ';
                        }
                    }
                    return (
                        <span key={idx}>
                            {isTracked ? <FirmLink permalink={firmPermalink}>
                                {firmName}
                            </FirmLink> : firmName}
                            {vcLinks}{pairSeparator}

                        </span>
                    );
                })}
        </StyledTd>
    );
};

const Row = props => {
    const {
        _id: permalink,
        name,
        location,
        ceo,
        iconPointPeople = [],
        keyVCs,
        description,
        actionItem,
        lastRound,
        ...rest
    } = props;
    const point = iconPointPeople.map(i => i.initials).join('/');
    return (
        <StyledTr {...rest}>
            <TdName permalink={permalink} name={name} location={location} />
            <StyledTd>{ceo}</StyledTd>
            <StyledTd>{point}</StyledTd>
            <TdKeyVc value={keyVCs} onlyTracked={true} />
            <StyledTd>{description}</StyledTd>
            <StyledTd>{formatRound(lastRound)}</StyledTd>
            <StyledTd>{actionItem}</StyledTd>
        </StyledTr>
    );
};
export default Row;

export const ActiveRow = props => {
    const {
        _id: permalink,
        name,
        location,
        ceo,
        iconPointPeople = [],
        keyVCs,
        description,
        actionItem,
        lastRound,
        ...rest
    } = props;
    const point = iconPointPeople.map(i => i.initials).join('/');
    return (
        <StyledTr {...rest}>
            <TdName permalink={permalink} name={name} location={location} />
            <StyledTd>{ceo}</StyledTd>
            <StyledTd>{point}</StyledTd>
            <TdKeyVc value={keyVCs} onlyTracked={true} />
            <StyledTd>{description}</StyledTd>
            <StyledTd>{formatRound(lastRound)}</StyledTd>
            <StyledTd>{actionItem}</StyledTd>
        </StyledTr>
    );
};

export const ActivePortfolioRow = props => {
    const {
        _id: permalink,
        name,
        location,
        ceo,
        iconPointPeople = [],
        keyVCs,
        description,
        actionItem,
        ...rest
    } = props;
    const point = iconPointPeople.map(i => i.initials).join('/');
    return (
        <StyledTr {...rest}>
            <TdName permalink={permalink} name={name} location={location} />
            <StyledTd>{ceo}</StyledTd>
            <StyledTd>{point}</StyledTd>
            <TdKeyVc value={keyVCs} onlyTracked={true} />
            <StyledTd>{description}</StyledTd>
            <StyledTd>{actionItem}</StyledTd>
        </StyledTr>
    );
};;

const ThemeTd = props => {
    const { themes = [] } = props;
    if (!themes || themes.length === 0) {
        return (
            <StyledTd>No theme defined.</StyledTd>
        );
    }
    return (
        <StyledTd>
            {themes.map(t => {
                const { _id, permalink, category, name } = t;
                const to = `/themes/view/${permalink}`;
                return (
                    <SimpleValue key={_id}>
                        <Link to={to}>{category} &gt; {name}</Link>
                    </SimpleValue>
                );
            })}
        </StyledTd >
    );
};

const MAX_COMPANIES = 7;
const ThemeCompanyTd = props => {
    const [expanded, setExpanded] = useState(false);
    let truncated = false;
    const { relatedCompanies = [] } = props;

    if (!relatedCompanies || relatedCompanies.length === 0) {
        return <StyledTd />;
    }
    const finalCompanies = relatedCompanies.map(c => {
        const { _id, name, iconPointPeople = [] } = c;
        return {
            _id,
            name,
            initialString: iconPointPeople.map(p => p.initials).join('/')
        };
    });
    if (!expanded && finalCompanies.length > MAX_COMPANIES) {
        finalCompanies.length = MAX_COMPANIES;
        truncated = true;
    }
    return (
        <StyledTd>
            {finalCompanies.map(t => {
                const { _id, name, initialString } = t;
                const to = `/company/view/${_id}`;
                return (
                    <SimpleValue key={_id}>
                        <Link to={to}>{name}{initialString ? ` (${initialString})` : ''}</Link>
                    </SimpleValue>
                );
            })}
            {truncated ? <LinkButton style={{ fontWeight: 200, textTransform: 'uppercase' }} onClick={() => {
                setExpanded(true);
            }}>Show More...</LinkButton> : null}
        </StyledTd >
    );
};

export const NewThisWeekRow = props => {
    const {
        _id: permalink,
        name,
        location,
        ceo,
        iconPointPeople = [],
        keyVCs,
        lastRound,
        description,
        themes,
        relatedCompanies,
        priority,
        ...rest
    } = props;
    const point = iconPointPeople.map(i => i.initials).join('/');
    return (
        <StyledTr {...rest}>
            <TdName sticky={true} permalink={permalink} name={name} location={location} />
            <StyledTd>{ceo}</StyledTd>
            <StyledTd>{point}</StyledTd>
            <TdKeyVc value={keyVCs} />
            <StyledTd>{formatRound(lastRound)}</StyledTd>
            <ThemeTd priority={priority} themes={themes} />
            <StyledTd>{description}</StyledTd>
            <ThemeCompanyTd relatedCompanies={relatedCompanies} />
        </StyledTr>
    );
};

export const WatchListRow = props => {
    const {
        _id: permalink,
        name,
        location,
        ceo,
        iconPointPeople = [],
        keyVCs,
        description,
        actionItem,
        themes,
        iconCategory,
        ...rest
    } = props;
    const point = iconPointPeople.map(i => i.initials).join('/');
    return (
        <StyledTr {...rest}>
            <TdName sticky permalink={permalink} name={name} location={location} />
            <StyledTd>{actionItem}</StyledTd>
            <StyledTd>{iconCategory}</StyledTd>
            <StyledTd>{point}</StyledTd>
            <StyledTd>{ceo}</StyledTd>
            <TdKeyVc value={keyVCs} />
            <ThemeTd themes={themes} />
            <StyledTd>{description}</StyledTd>
        </StyledTr>
    );
};

export const PriorityTargetReviewRow = props => {
    const {
        _id: permalink,
        name,
        location,
        ceo,
        iconPointPeople = [],
        keyVCs,
        description,
        actionItem,
        themes,
        lastRound,
        ...rest
    } = props;
    const point = iconPointPeople.map(i => i.initials).join('/');
    return (
        <StyledTr {...rest}>
            <TdName sticky permalink={permalink} name={name} location={location} />
            <StyledTd>{actionItem}</StyledTd>
            <StyledTd>{point}</StyledTd>
            <StyledTd>{ceo}</StyledTd>
            <TdKeyVc value={keyVCs} />
            <StyledTd>{formatRound(lastRound)}</StyledTd>
            <StyledTd>{description}</StyledTd>
            <ThemeTd themes={themes} />
        </StyledTr>
    );
};

export const QueuedRow = props => {
    const {
        _id: permalink,
        queuedWeeks,
        name,
        location,
        ceo,
        iconPointPeople = [],
        keyVCs,
        description,
        actionItem,
        lastRound,
        ...rest
    } = props;
    const point = iconPointPeople.map(i => i.initials).join('/');
    return (
        <StyledTr {...rest}>
            <StyledTd>
                {typeof queuedWeeks === 'number' ? queuedWeeks : 'N/A'}
            </StyledTd>
            <TdName permalink={permalink} name={name} location={location} />
            <StyledTd>{ceo}</StyledTd>
            <StyledTd>{point}</StyledTd>
            <TdKeyVc value={keyVCs} />
            <StyledTd>{formatRound(lastRound)}</StyledTd>
            <StyledTd>{description}</StyledTd>
            <StyledTd>{actionItem}</StyledTd>
        </StyledTr>
    );
};

export const DealUpdateRow = props => {
    const {
        _id: permalink,
        name,
        location,
        ceo,
        iconPointPeople = [],
        keyVCs,
        description,
        actionItem,
        lastRound,
        ...rest
    } = props;
    const point = iconPointPeople.map(i => i.initials).join('/');
    return (
        <StyledTr {...rest}>
            <TdName permalink={permalink} name={name} location={location} />
            <StyledTd>{ceo}</StyledTd>
            <StyledTd>{point}</StyledTd>
            <TdKeyVc value={keyVCs} onlyTracked={true} />
            <StyledTd>{formatRound(lastRound)}</StyledTd>
            <StyledTd>{description}</StyledTd>
            <StyledTd>{actionItem}</StyledTd>
        </StyledTr>
    );
};


export const PortfolioRow = props => {
    const {
        _id: permalink,
        name,
        location,
        ceo,
        iconPointPeople = [],
        description,
        themes,
        actionItem,
        ...rest
    } = props;
    const point = iconPointPeople.map(i => i.initials).join('/');
    return (
        <StyledTr {...rest}>
            <TdName permalink={permalink} name={name} location={location} />
            <StyledTd>{ceo}</StyledTd>
            <StyledTd>{point}</StyledTd>
            <StyledTd>{description}</StyledTd>
            <ThemeTd themes={themes} />
            <StyledTd>{actionItem}</StyledTd>
        </StyledTr>
    );
};

export const TurndownRow = props => {
    const {
        _id: permalink,
        name,
        location,
        ceo,
        queuedDate,
        decisionDate,
        iconPointPeople = [],
        keyVCs,
        description,
        ...rest
    } = props;
    const point = iconPointPeople.map(i => i.initials).join('/');
    return (
        <StyledTr {...rest}>
            <TdName permalink={permalink} name={name} location={location} />
            <StyledTd>{ceo}</StyledTd>
            <StyledTd>{formatDate(queuedDate)}</StyledTd>
            <StyledTd>{formatDate(decisionDate)}</StyledTd>
            <StyledTd>{point}</StyledTd>
            <TdKeyVc value={keyVCs} onlyTracked={true} />
            <StyledTd>{description}</StyledTd>
        </StyledTr>
    );
};
