import React from 'react';
import Box from '-/style/base/Box';
import Subhead from '-/style/base/Subhead';
import { GET_COMPANY_MONDAY_REPORT_PORTFOLIO } from '-/gql/company';
import { graphql } from 'react-apollo';
import { get } from 'lodash';
import StandardTable from './StandardTable';
import LoadingLogo from '-/components/LoadingLogo';
import { PortfolioRow } from './StandardRow';
import { PortfolioHeader } from './StandardHeader';
import styled from 'styled-components';
const Section = styled.div`
    margin-bottom: 15px;
`;
const Portfolio = props => {
    const { data = {} } = props;
    const { loading } = data;
    const companies = get(data, 'CompanyMondayReport.portfolio', []);
    const { portfolioCompanies, passiveCompanies } = companies.reduce((final, company) => {
        const { iconCategory } = company;
        if (iconCategory === 'Portfolio') {
            final.portfolioCompanies.push(company);
        } else if (iconCategory === 'Passive') {
            final.passiveCompanies.push(company);
        }
        return final;
    }, { portfolioCompanies: [], passiveCompanies: [] });
    if (loading) {
        return <LoadingLogo />;
    }
    return (
        <Box>
            <Subhead>Portfolio</Subhead>
            <Section>
                <StandardTable
                    data={portfolioCompanies}
                    Row={PortfolioRow}
                    Header={PortfolioHeader}
                />
            </Section>
            <Subhead>Passive</Subhead>
            <Section>
                <StandardTable
                    data={passiveCompanies}
                    Row={PortfolioRow}
                    Header={PortfolioHeader}
                />
            </Section>
        </Box>
    );
};

export default graphql(GET_COMPANY_MONDAY_REPORT_PORTFOLIO)(Portfolio);
