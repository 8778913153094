import React from 'react';
import { get, slice } from 'lodash';
import { getTd } from './Tds';
import { getTh } from './Ths';
import {
    StyledStickyWrapper,
    StyledTable,
    StyledEmptyRow,
    StyledTheadTr,
    StyledTr
} from '-/style/base/Table';
import { baseGreen } from '-/style/variables';
import styled from 'styled-components';
import { Col, Row } from '-/style/base/Layout';
import { graphql } from 'react-apollo';
import { ADVANCED_SEARCH_LP } from '-/gql/lp';
import { LpReportViewLink } from '-/components/Link';

const LPHeader = props => {
    const { sticky = false, columns } = props;
    return (
        <thead>
            {/* 1124 at widest */}
            <StyledTheadTr>
                {columns.map((column, colIdx) => {
                    const { name } = column;
                    return getTh({
                        ...column,
                        key: `${name}-${colIdx}`,
                        // only allow first column to be sticky
                        sticky: sticky && colIdx === 0,
                        style: {
                            backgroundColor: '#EFEFEF',
                            borderBottom: '1px solid #CACACB'
                        }
                    });
                })}
            </StyledTheadTr>
        </thead>
    );
};

const LPRow = props => {
    const { index, columns, data, sticky = false } = props;

    return (
        <StyledTr index={index}>
            {columns.map((column, colIdx) => {
                const { name } = column;
                return getTd({
                    ...column,
                    data,
                    // only allow first column to be sticky
                    sticky: sticky && colIdx === 0,
                    index,
                    key: `${name}-${colIdx}`
                });
            })}
        </StyledTr>
    );
};

const renderList = props => {
    const { loading, error, data, limit, sticky = false, columns } = props;
    const columnsLn = columns.length;
    if (loading) {
        return (
            <tr>
                <td colSpan={columnsLn}>Loading...</td>
            </tr>
        );
    } else if (error) {
        return (
            <tr>
                <td colSpan={columnsLn}>Failure</td>
            </tr>
        );
    } else if (data && data.LPAdvancedSearch) {
        const rows = slice(data.LPAdvancedSearch, 0, limit);
        if (rows.length) {
            return rows.map((lp, idx) => {
                return (
                    <LPRow
                        key={lp._id}
                        index={idx}
                        sticky={sticky}
                        columns={columns}
                        data={lp}
                    />
                );
            });
        } else {
            return (
                <StyledEmptyRow colSpan={columnsLn}>No Results.</StyledEmptyRow>
            );
        }
    }
    return null;
};

const DashboardReportHeader = props => {
    const { permalink, name } = props;
    return (
        <Row gutter={4} justifyContent="space-between">
            <Col flex={6}>
                <LpReportViewLink
                    permalink={permalink}
                    style={{ fontSize: 14 }}
                >
                    {name}
                </LpReportViewLink>
            </Col>
            <Col
                flex={6}
                style={{
                    textAlign: 'right'
                }}
            >
                <RemainingRecords {...props} />
            </Col>
        </Row>
    );
};

const RemainingRecordsRaw = props => {
    const { className, count, limit, permalink } = props;
    const isCapped = limit && count > limit;
    const limitShow = Math.min(limit, count);
    return (
        <div className={className}>
            <span>
                {isCapped ? `${limitShow} of ` : ''}
                {count} records
            </span>
            <LpReportViewLink permalink={permalink}>View All</LpReportViewLink>
        </div>
    );
};
const RemainingRecords = styled(RemainingRecordsRaw)`
    span {
        padding-right: 10px;
        padding-bottom: 5px;
    }
    a:hover {
        color: ${baseGreen};
    }
`;

const List = props => {
    const {
        className,
        limit,
        name,
        columns: columnsRaw,
        sticky,
        hideHeader,
        sortBy,
        permalink,
        maxHeight
    } = props;
    const searchResults = get(props, 'data.LPAdvancedSearch', []);
    const count = get(searchResults, 'length', 0);
    const columns = columnsRaw.map(c => {
        const name = get(c, 'name') || c;
        const retCol = {
            name
        };
        if (name === 'primaryContacts') {
            const count = searchResults.reduce((count, row) => {
                const rowCount = get(row, 'primaryContacts.length', 0);
                if (rowCount > count) {
                    return rowCount;
                } else {
                    return count;
                }
            }, 0);
            retCol.count = count;
        }
        return retCol;
    });

    const wrapperStyle = {
        maxHeight
    };
    if (count === 0) {
        wrapperStyle.overflow = 'hidden';
    }
    return (
        <div className={className}>
            {!hideHeader && (
                <DashboardReportHeader
                    count={count}
                    name={name}
                    limit={limit}
                    permalink={permalink}
                />
            )}
            <StyledStickyWrapper style={wrapperStyle}>
                <StyledTable>
                    <LPHeader
                        columns={columns}
                        sortBy={sortBy}
                        sticky={sticky}
                    />
                    <tbody>{renderList({ ...props, columns })}</tbody>
                </StyledTable>
            </StyledStickyWrapper>
        </div>
    );
};

List.defaultProps = {
    columns: [
        'name',
        'location',
        'status',
        'nextContactDate',
        'actionItem',
        'updatedAt'
    ],
    hideHeader: false,
    sticky: false
};

export default graphql(ADVANCED_SEARCH_LP, {
    options: props => {
        const { criteria, sortBy } = props;
        return {
            variables: {
                criteria,
                sortBy
            }
        };
    }
})(List);
