import { toString, isObject, isArray } from 'lodash';
import DateOnly from 'dateonly';
export const transformOptions = (options, config = {}) => {
    const { valueKey = 'value', labelKey = 'label' } = config;
    if (!isArray(options)) {
        if (isObject(options)) {
            if (options.options) {
                const label = options[labelKey];
                return {
                    label,
                    options: transformOptions(options.options, config)
                };
            } else {
                return Object.keys(options).map(key => {
                    const label = options[key];
                    return {
                        value: key,
                        label: toString(label)
                    };
                });
            }
        } else {
            return [];
        }
    }
    return options.map(opt => {
        if (isObject(opt)) {
            const label = opt[labelKey];
            if (opt.options) {
                return {
                    label,
                    options: transformOptions(opt.options, config)
                };
            } else {
                const value = opt[valueKey];
                const label = opt[labelKey];
                return {
                    value,
                    label
                };
            }
        } else {
            return {
                value: opt,
                label: opt
            };
        }
    });
};

export const getTodayAsSimpleDate = props => {
    const normalize = new DateOnly();
    const mmRaw = normalize.getMonth() + 1;
    const ddRaw = normalize.getDate();
    const yyyy = normalize.getFullYear();
    const mm = mmRaw < 10 ? `0${mmRaw}` : mmRaw;
    const dd = ddRaw < 10 ? `0${ddRaw}` : ddRaw;
    return `${mm}/${dd}/${yyyy}`;
};
