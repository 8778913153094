import React, { Component } from 'react';
import { Row, Col } from '-/style/base/Layout';
import { LinkButton } from '-/style/base/Link';
import KnownIcon from '-/style/base/KnownIcon';
import Headline from '-/style/base/Headline';
import Divider from '-/style/base/Divider';
import LinkList from '-/style/base/LinkList';
import ActionItemForm from '../../form/ActionItem';
import BasicForm from '../../form/Basic';
import OverlayWithState from '-/components/OverlayWithState';
import { SimpleLabel, SimpleValue, DateDisplay } from '-/components/profiles';
import CompanyQuery from './CompanyQuery';
import { GET_COMPANY_INTRO } from '-/gql/company';
import { withProps } from 'recompose';
import styled from 'styled-components';
const StyledIntro = styled.div`
    margin-top: 15px;
    padding: 8px;
`;

class Intro extends Component {
    enterEditMode = () => {
        const { enterEditMode } = this.props;
        if (enterEditMode) {
            enterEditMode();
        }
    };

    render() {
        const {
            className,
            style,
            inEditMode,
            _id,
            name,
            actionItem,
            iconCategory,
            iconStatus,
            turnDownRationale,
            queuedDate,
            decisionDate,
            investmentDate,
            nextContactDate
        } = this.props;

        return (
            <StyledIntro className={className} style={style}>
                <Row>
                    <Col flex={6}>
                        <Headline>
                            <span>{name}</span>
                            <KnownIcon
                                type={iconCategory}
                                width={24}
                                height={24}
                                style={{ paddingLeft: 10 }}
                            />
                            <KnownIcon
                                type={iconStatus}
                                width={24}
                                height={24}
                                style={{ paddingLeft: 10 }}
                            />
                            {/* QuickEdit Mode buttons go away when in standard profile editing... */}
                            {!inEditMode && (
                                <OverlayWithState
                                    arrowSide="left"
                                    vAttachment="top"
                                    hAttachment="left"
                                    vTargetAttachment="top"
                                    hTargetAttachment="right"
                                    closeOnEsc
                                >
                                    <BasicForm
                                        _id={_id}
                                        initialValues={{
                                            name,
                                            iconCategory,
                                            iconStatus,
                                            turnDownRationale,
                                            // needs turndownRationale
                                            queuedDate,
                                            decisionDate,
                                            investmentDate,
                                            nextContactDate
                                        }}
                                    />
                                </OverlayWithState>
                            )}
                        </Headline>
                    </Col>
                    <Col
                        flex={6}
                        hideWhenPrint={true}
                        alignSelf="flex-end"
                        style={{ textAlign: 'right' }}
                    >
                        {!inEditMode && (
                            <LinkList>
                                <li>
                                    <LinkButton onClick={this.enterEditMode}>
                                        Edit Profile
                                    </LinkButton>
                                </li>
                                <li>
                                    <LinkButton
                                        onClick={() => {
                                            window.print();
                                        }}
                                    >
                                        Print Profile
                                    </LinkButton>
                                </li>
                            </LinkList>
                        )}
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col flex={6}>
                        <SimpleLabel as="span" style={{ marginRight: 5 }}>
                            Action Item:
                        </SimpleLabel>

                        <SimpleValue as="span">{actionItem}</SimpleValue>
                        {/* QuickEdit Mode buttons go away when in standard profile editing... */}
                        {!inEditMode && (
                            <OverlayWithState
                                arrowSide="left"
                                vAttachment="top"
                                hAttachment="left"
                                vTargetAttachment="top"
                                hTargetAttachment="right"
                                closeOnEsc
                            >
                                <ActionItemForm
                                    _id={_id}
                                    initialValues={{
                                        actionItem
                                    }}
                                />
                            </OverlayWithState>
                        )}
                    </Col>
                    <Col
                        flex={6}
                        alignSelf="flex-start"
                        style={{ textAlign: 'right' }}
                    >
                        <DateDisplay type="Queued" value={queuedDate} />
                        <DateDisplay
                            type="Next Contact"
                            value={nextContactDate}
                        />
                        <DateDisplay type="Decision" value={decisionDate} />
                        <DateDisplay type="Investment" value={investmentDate} />
                    </Col>
                </Row>
            </StyledIntro>
        );
    }
}

export default withProps({
    query: GET_COMPANY_INTRO,
    component: Intro
})(CompanyQuery);
