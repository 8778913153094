import styled from 'styled-components';
import React from 'react';

import {
    Archive as ArchiveRaw,
    ArrowUp as ArrowUpRaw,
    ArrowDown as ArrowDownRaw,
    Download as DownloadRaw,
    Edit as EditRaw,
    Trash as TrashRaw,
    Plus as PlusRaw,
    RotateCcw as RotateCcwRaw,
    ChevronDown,
    ChevronRight
} from 'styled-icons/feather';

export {
    AlertTriangle,
    ChevronDown,
    ChevronUp,
    ExternalLink,
    Key,
    Menu,
    PieChart,
    Printer,
    Save,
    Search,
    Sun,
    User,
    UserPlus,
    Zap,
    ZapOff,
    ArrowRight
} from 'styled-icons/feather';

export {
    Facebook,
    Linkedin,
    Twitter
} from 'styled-icons/entypo-social';

export {
    Home
} from 'styled-icons/entypo';

const asAction = IconRaw => {
    const Icon = React.forwardRef((props, ref) => {
        const { disabled = false, onClick: onClickRaw } = props;
        const onClick = () => {
            if (!disabled && onClickRaw) {
                onClickRaw();
            }
        };
        return <IconRaw {...props} ref={ref} onClick={onClick} />;
    });
    return styled(Icon)`
        padding-left: 10px;
        ${props => {
            if (props.disabled) {
                return `
                    cursor: no-drop;
                    color: #cfcfcf;
                `;
            } else {
                return `
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                    &:hover {
                        color: black;
                        transform: scale(1.15);
                    }
                `;
            }
        }
        }
        @media print {
            display: none;
        }
    `;
};

export const Archive = ArchiveRaw;
export const ArchiveAction = asAction(ArchiveRaw);
export const Edit = EditRaw;
export const EditAction = asAction(EditRaw);
export const Download = DownloadRaw;
export const DownloadAction = asAction(DownloadRaw)
export const Trash = TrashRaw;
export const TrashAction = asAction(TrashRaw);
export const Plus = PlusRaw;
export const PlusAction = asAction(PlusRaw);
export const RestoreAction = asAction(RotateCcwRaw);
export const ArrowUp = ArrowUpRaw;
export const ArrowUpAction = asAction(ArrowUp);
export const ArrowDown = ArrowDownRaw;
export const ArrowDownAction = asAction(ArrowDown);
export const DiscloseArrowDown = asAction(ChevronDown);
export const DiscloseArrowRight = asAction(ChevronRight);