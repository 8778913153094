const fontWeights = {
    'x-lt': 100,
    lt: 300,
    rg: 400,
    bd: 700
};

const fontFamilies = {
    'x-lt': 'Avenir Next W01 Light',
    lt: 'Avenir Next W01 Light',
    rg: 'AvenirNextLTW01-Regular',
    bd: 'Avenir Next LT W01 Bold'
};

export const font = (opts = {}) => {
    const { weight = 'rg', size = '0.875rem', height = 1.25 } = opts;

    const weightToUse = fontWeights[weight] || 400;
    const fontToUse = fontFamilies[weight] || fontFamilies.rg;

    return `
        font-family: ${fontToUse};
        font-size: ${size};
        font-weight: ${weightToUse};
        line-height: ${height};
    `;
};

export const xLt = props => {
    return font({
        weight: 'x-lt',
        ...props
    });
};

export const lt = props => {
    return font({
        weight: 'lt',
        ...props
    });
};

export const rg = props => {
    return font({
        weight: 'rg',
        ...props
    });
};

export const bd = props => {
    return font({
        weight: 'bd',
        ...props
    });
};

export const center = opts => {
    return `
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    `;
};

export const vCenter = opts => {
    return `
        position: absolute;
        transform: translate(0, -50%);
        top: 50%;
    `;
};

export const hCenter = opts => {
    return `
        position: absolute;
        transform: translate(-50%, 0);
        left: 50%;
    `;
};
