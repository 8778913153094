import React from 'react';
import styled, { css } from 'styled-components';
import { row, altRow, lockedRow, lockedAltRow, darkBlue } from './../variables';
import { fluid } from '-/style/utils';

const commonCellPadding = '10px';

// https://stackoverflow.com/questions/15811653/table-with-fixed-header-and-fixed-column-on-pure-css
export const StyledStickyWrapper = styled.div`
    overflow: auto;
    position: relative;
    @media print {
        max-height: unset;
    }
    @media only screen and (min-width: 0px) and (min-height: 0px) {
        max-height: 400px;
    }
    @media only screen and (min-width: 960px) and (min-height: 900px) {
        max-height: calc(100vh - 600px);
    }
`;

export const StyledTable = styled.table`
    width: 100%;
    table-layout: fixed;
    ${props =>
        props.minWidth &&
        css`
            min-width: ${props.minWidth}px;
        `}
`;

export const StyledTh = styled.th`
    box-sizing: border-box;
    text-align: left;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    padding: ${commonCellPadding} ${commonCellPadding} ${commonCellPadding} 20px;
    position: sticky;
    top: 0;
    z-index: 1;
    background: ${row};
    @media print {
        position: unset;
    }
    ${props =>
        !props.width && !props.minWidth && fluid.greaterXS`
            min-width: 120px;
        `}    
    ${props =>
        !props.width && !props.minWidth && fluid.greaterSM`
            min-width: 160px;
        `}
        ${props =>
        !props.width && !props.minWidth && fluid.greaterMD`
                min-width: 250px;
            `}
        ${props =>
        !props.width && !props.minWidth && fluid.greaterLG`
                    min-width: 400px;
                `}
    
    ${props =>
        props.minWidth &&
        css`
            min-width: ${props.minWidth}px;
        `}
    
    ${props =>
        props.hideWhenPrint &&
        css`
            @media print {
                display: none;
            }
        `}
    ${props =>
        props.sticky &&
        css`
            left: 0;
            z-index: 2;
            background: ${lockedRow};
        `}
`;

export const StyledTheadTr = styled.tr`
    background-color: ${row};
`;
export const StyledTr = styled.tr`
    background-color: ${props => (props.index % 2 ? row : altRow)};
`;

export const StyledTd = styled.td`
    padding: ${commonCellPadding} ${commonCellPadding} ${commonCellPadding} 20px;
    vertical-align: top;
    a {
        font-weight: 600;
        color: ${darkBlue};
    }
    ${props =>
        props.hideWhenPrint &&
        css`
            @media print {
                display: none;
            }
        `}
    ${props =>
        props.sticky &&
        css`
            position: sticky;
            z-index: 1;
            left: 0;
            background-color: ${props =>
                props.index % 2 ? lockedRow : lockedAltRow};
        `}
`;

export const StyledStickyTd = styled(StyledTd)``;

const StyledTdCenter = styled.td`
    padding: ${commonCellPadding} ${commonCellPadding} ${commonCellPadding} 20px;
`;

export const StyledEmptyRow = props => {
    const { colSpan, children } = props;
    return (
        <StyledTr>
            <StyledTdCenter colSpan={colSpan}>{children}</StyledTdCenter>
        </StyledTr>
    );
};
