import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import { Container, Col } from '-/style/base/Layout';
import ExitBar from '-/components/profiles/ExitBar';
import Subhead from '-/style/base/Subhead';
import OverlayWithState from '-/components/OverlayWithState';
import { getTodayAsSimpleDate } from '-/components/inputs';
import Intro from './view/sections/Intro';
import Financing from './view/sections/Financing';
import Momentum from './view/sections/Momentum';
import Summary from './view/sections/Summary';
import People from './view/sections/People';
import NotesSection from './view/sections/Notes';
import NoteForm from './form/Note';
import { GET_COMPANY_DETAIL } from '-/gql/company';
import { CompanyMetricsLink } from '-/components/Link';
import { StandardRow, FixedBox, FixedBody } from '-/components/profiles';
import Box from '-/style/base/Box';
import LoadingLogo from '-/components/LoadingLogo';
import FinancingForm from '-/company/form/Financing';

const ProfileLayout = props => {
    const { _id, forTheChildren, inEditMode, permalink, enterEditMode } = props;

    const financingForm = (
        <FinancingForm
            _id={_id}
            permalink={permalink}
            initialValues={forTheChildren}
        />
    );

    return (
        <Container>
            <Intro enterEditMode={enterEditMode} {...forTheChildren} />
            <StandardRow>
                <Col xs={12} sm={12} md={4} flex={3} component={FixedBox}>
                    <Subhead>
                        Financing
                        {inEditMode && (
                            <OverlayWithState
                                arrowSide="left"
                                vAttachment="top"
                                hAttachment="left"
                                vTargetAttachment="top"
                                hTargetAttachment="right"
                                closeOnOutsideClick={false}
                                pinned={false}
                                closeOnEsc
                                triggerStyle={{ float: 'right' }}
                            >
                                {financingForm}
                            </OverlayWithState>
                        )}
                    </Subhead>
                    <FixedBody>
                        <Financing {...forTheChildren} />
                    </FixedBody>
                </Col>
                <Col
                    xs={12}
                    sm={12}
                    md={8}
                    flex={6}
                    component={FixedBox}
                    hideWhenPrint
                >
                    <Subhead>
                        Momentum{' '}
                        <CompanyMetricsLink
                            permalink={permalink}
                            style={{ marginLeft: 15 }}
                        >
                            See All
                        </CompanyMetricsLink>
                    </Subhead>
                    <Momentum permalink={permalink} {...forTheChildren} />
                </Col>
                <Col
                    xs={12}
                    sm={12}
                    md={6}
                    flex={3}
                    printOrder={-5}
                    component={FixedBox}
                >
                    <Subhead>Company</Subhead>
                    <FixedBody>
                        <Summary {...forTheChildren} />
                    </FixedBody>
                </Col>

                <Col
                    xs={12}
                    sm={12}
                    md={12}
                    xsOrder={1}
                    smOrder={1}
                    mdOrder={1}
                    flex={9}
                    component={Box}
                >
                    <Subhead>
                        Notes{' '}
                        <OverlayWithState
                            arrowSide="left"
                            vAttachment="top"
                            hAttachment="left"
                            vTargetAttachment="top"
                            hTargetAttachment="right"
                            closeOnEsc
                        >
                            <NoteForm
                                permalink={permalink}
                                initialValues={{
                                    type: 'Note',
                                    date: getTodayAsSimpleDate()
                                }}
                            />
                        </OverlayWithState>
                    </Subhead>
                    <NotesSection editable={inEditMode} permalink={permalink} />
                </Col>
                <Col
                    xs={12}
                    sm={12}
                    md={6}
                    flex={3}
                    printOrder={-4}
                    component={FixedBox}
                >
                    <Subhead>People</Subhead>
                    <FixedBody>
                        <People {...forTheChildren} />
                    </FixedBody>
                </Col>
            </StandardRow>
        </Container>
    );
};

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inEditMode: false
        };
    }

    enterEditMode = () => {
        this.setState({
            inEditMode: true
        });
    };

    exitEditMode() {
        this.setState({
            inEditMode: false
        });
    }

    render() {
        const permalink = get(this.props, 'match.params.permalink', '');
        const { inEditMode } = this.state;
        return (
            <div>
                <Query query={GET_COMPANY_DETAIL} variables={{ permalink }}>
                    {queryResponse => {
                        const data = get(queryResponse, 'data.Company');
                        const { loading, error } = queryResponse;
                        if (loading) {
                            return <LoadingLogo />;
                        }
                        if (error) {
                            return <p>Oh snap</p>;
                        }
                        if (data) {
                            const forTheChildren = {
                                inEditMode,
                                permalink
                            };
                            return (
                                <Fragment>
                                    {inEditMode && (
                                        <ExitBar
                                            onClick={this.exitEditMode.bind(
                                                this
                                            )}
                                        />
                                    )}
                                    <ProfileLayout
                                        permalink={permalink}
                                        enterEditMode={this.enterEditMode}
                                        forTheChildren={forTheChildren}
                                        inEditMode={inEditMode}
                                    />
                                </Fragment>
                            );
                        }
                        return null;
                    }}
                </Query>
            </div>
        );
    }
}

export default Profile;
