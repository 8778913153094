import Link from '-/style/base/Link';
import { withProps } from 'recompose';

export const NewLpLink = withProps({
    to: '/lp/new'
})(Link);

export const LpReportsLink = withProps({
    to: '/lp/reports'
})(Link);

export const LpReportNewLink = withProps({
    to: '/lp/report/new'
})(Link);

export const LpReportViewLink = withProps(initProps => {
    const { permalink } = initProps;
    const to = `/lp/report/view/${permalink}`;
    return {
        to,
        ...initProps
    };
})(Link);

export const CompanyLink = withProps(initProps => {
    const { permalink } = initProps;
    const to = `/company/view/${permalink}`;
    return {
        to,
        ...initProps
    };
})(Link);

export const LpViewLink = withProps(initProps => {
    const { permalink } = initProps;
    const to = `/lp/view/${permalink}`;
    return {
        to,
        ...initProps
    };
})(Link);

export const CompanyMetricsLink = withProps(initProps => {
    const { permalink } = initProps;
    const to = `/company/view/metrics/${permalink}`;
    return {
        to,
        ...initProps
    };
})(Link);

export const FirmLink = withProps(initProps => {
    const { permalink } = initProps;
    const to = `/firm/view/${permalink}`;
    return {
        to,
        plain: true,
        ...initProps
    };
})(Link);

export const PersonLink = withProps(initProps => {
    const { permalink } = initProps;
    const to = `/person/view/${permalink}`;
    return {
        to,
        plain: true,
        ...initProps
    };
})(Link);

export const UserLink = withProps(initProps => {
    const { _id } = initProps;
    const to = `/user/edit/${_id}`;
    return {
        to,
        ...initProps
    };
})(Link);

export const NewUserLink = withProps({
    to: '/user/new'
})(Link);

export const UserListLink = withProps({
    to: '/users'
})(Link);
