import React from 'react';
import Box from '-/style/base/Box';
import Subhead from '-/style/base/Subhead';
import { GET_COMPANY_MONDAY_DEAL_UPDATE } from '-/gql/company';
import { graphql } from 'react-apollo';
import { get } from 'lodash';
import StandardTable from './StandardTable';
import LoadingLogo from '-/components/LoadingLogo';
import { DealUpdateRow } from './StandardRow';
import { DealUpdateHeader } from './StandardHeader';
import { sortBySeries } from './utils';
import { StyledEmptyRow } from '-/style/base/Table';

const EmptyRow = <StyledEmptyRow colSpan={7}>No companies.</StyledEmptyRow>;
const DealUpdate = props => {
    const { data = {} } = props;
    const { loading } = data;
    const rawCompanies = get(data, 'CompanyMondayReport.dealUpdate', []);
    const companies = sortBySeries(rawCompanies);

    if (loading) {
        return <LoadingLogo />;
    }
    return (
        <Box>
            <Subhead>Deal Updates</Subhead>
            <StandardTable
                data={companies}
                Row={DealUpdateRow}
                Header={DealUpdateHeader}
                EmptyRow={EmptyRow}
            />
        </Box>
    );
};

export default graphql(GET_COMPANY_MONDAY_DEAL_UPDATE)(DealUpdate);
