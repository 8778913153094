import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { StyledTd } from '-/style/base/Table';
import { get, compact } from 'lodash';
import {
    formatDate,
    formatDateTime,
    formatMillion,
    formatPhone
} from '-/utils/format';
import { keyedLPColumns, materialsSentTypeMap } from '-/shared/constants';
import styled from 'styled-components';

const LocationTd = props => {
    const { value, ...rest } = props;
    const { city, region, area } = value || {};
    const location = compact([city, region]).join(', ');
    return (
        <StyledTd {...rest}>
            {location}
            <br />
            {area}
        </StyledTd>
    );
};

const DateTd = props => {
    const { value, ...rest } = props;
    return <StyledTd {...rest}>{formatDate(value)}</StyledTd>;
};

const DateTimeTd = props => {
    const { value, ...rest } = props;
    return <StyledTd {...rest}>{formatDateTime(value)}</StyledTd>;
};

const MillionsTd = props => {
    const { value, ...rest } = props;
    return <StyledTd {...rest}>{formatMillion(value)}</StyledTd>;
};

const ContactTd = props => {
    const { value, ...rest } = props;
    const { name = '', company = '', title = '', email = '', phone = '' } = value || {};
    const num = formatPhone(phone);

    return (
        <StyledTd {...rest}>
            {name && (
                <div>{name}</div>
            )}
            {company && (
                <div>{company}</div>
            )}
            {title && (
                <div>{title}</div>
            )}
            {email && (
                <div>
                    <a href={`mailto:${email}`}>{email}</a>
                </div>
            )}
            {num && (
                <div>
                    <a href={`tel:${num}`}>{num}</a>
                </div>
            )}
        </StyledTd>
    );
};

const StyledMiniSpan = styled.span`
    position: relative;
    left: -7px;
`;
const MaterialsSentTd = props => {
    const { value, ...rest } = props;
    return (
        <StyledTd {...rest}>
            {value &&
                value.map((entry, idx) => {
                    const { date, type } = entry;
                    return (
                        <div key={idx} style={{ marginBottom: 5 }}>
                            <strong>{formatDate(date)}</strong>
                            {type ? (
                                type.map((materialType, idx) => {
                                    return (
                                        <li key={idx}>
                                            <StyledMiniSpan>
                                                {materialsSentTypeMap[
                                                    materialType
                                                ] || 'Other'}
                                            </StyledMiniSpan>
                                        </li>
                                    );
                                })
                            ) : (
                                <li>
                                    <StyledMiniSpan>Other</StyledMiniSpan>
                                </li>
                            )}
                        </div>
                    );
                })}
        </StyledTd>
    );
};

export const getTd = opts => {
    const { name, key: baseKey, count, data, ...rest } = opts;
    const { path } = keyedLPColumns[name] || {};
    const permalink = get(data, 'permalink');
    const value = get(data, path || name, '');
    switch (name) {
        case 'name':
            return (
                <StyledTd key={baseKey} {...rest}>
                    <Link to={`/lp/view/${permalink}`}>{value}</Link>
                </StyledTd>
            );
        case 'primaryContact':
        case 'referredBy':
            return <ContactTd key={baseKey} value={value} {...rest} />;
        case 'primaryContacts':
            // The count is the max number of primaryContacts
            // we will see on this resultset, for arrays that are
            // shorter we just show an empty td to fill the table
            const tds = new Array(count).fill(1).map((num, idx) => {
                const subValue = value[idx];
                const key = `${baseKey}-${idx}`;
                if (subValue) {
                    return <ContactTd key={key} value={subValue} {...rest} />;
                } else {
                    return <StyledTd key={key} />
                }
            });
            return (
                <Fragment key={baseKey}>
                    {tds}
                </Fragment>
            )
        case 'location':
            return <LocationTd key={baseKey} value={value} {...rest} />;
        case 'updatedAt':
            return <DateTimeTd key={baseKey} value={value} {...rest} />;
        case 'nextContactDate':
        case 'lastContactDate':
            return <DateTd key={baseKey} value={value} {...rest} />;
        case 'materialsSent':
            return <MaterialsSentTd key={baseKey} value={value} {...rest} />;
        case 'closedToDate':
            return <MillionsTd key={baseKey} value={value} {...rest} />;
        // deprecated columns
        case 'materialsSentDate':
            return null;
        default:
            return <StyledTd key={baseKey} {...rest}>{value}</StyledTd>;
    }
};
