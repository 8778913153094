import React from 'react';
import { Formik, Field, Form } from 'formik';
import { graphql } from 'react-apollo';
import { pick } from 'lodash';
import { UPDATE_COMPANY_DESCRIPTION } from '-/gql/company';
import { Textarea } from '-/components/inputs';
import Toolbar from '-/components/profiles/forms/Toolbar';
import styled from 'styled-components';
const StyledStandardForm = styled(Form)`
    padding: 15px;
    min-width: 350;
`;
const renderFields = props => {
    const { className, closeOverlay } = props;
    return (
        <StyledStandardForm className={className}>
            <Field
                name="description"
                component={Textarea}
                label="Description"
            />
            <Toolbar handleCancel={closeOverlay} />
        </StyledStandardForm>
    );
};

const DescriptionForm = props => {
    const { _id, closeOverlay, update, initialValues, className } = props;
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const input = pick(values, ['description']);
                    await update({
                        variables: {
                            _id,
                            input
                        }
                    });
                    closeOverlay();
                } catch (ex) {
                    console.warn(ex);
                }
            }}
            render={renderProps => {
                return renderFields({
                    ...renderProps,
                    closeOverlay,
                    className
                });
            }}
        />
    );
};

export default graphql(UPDATE_COMPANY_DESCRIPTION, { name: 'update' })(
    DescriptionForm
);
