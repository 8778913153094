import React, { Fragment } from 'react';
import { GET_LP_CONTACT } from '-/gql/lp';
import LPQuery from './LPQuery';
import {
    AddressDisplayField,
    ContactDisplayField,
    PhoneDisplayValue
} from '-/components/profiles';

const ContactView = props => {
    const { address, phone, people, referredBy } = props;
    return (
        <Fragment>
            <AddressDisplayField label="Location" value={address}>
                <PhoneDisplayValue>{phone}</PhoneDisplayValue>
            </AddressDisplayField>
            <ContactDisplayField indicatePrimary={true} label="People" value={people} />
            <ContactDisplayField label="Referred By" value={referredBy} />
        </Fragment>
    );
};

const Contact = props => {
    const { permalink } = props;
    return (
        <LPQuery
            query={GET_LP_CONTACT}
            permalink={permalink}
            component={ContactView}
        />
    );
};

export default Contact;
