import { lighten, darken, mix } from 'polished';

export const outExpoTransition = 'cubic-bezier(0.190, 1.000, 0.220, 1.000)';

// old colors
export const loginBg = '#6e93aa';
export const body = '#676d6f';
export const darkBody = darken(0.2, body);
export const blue = '#7195ab';
export const darkBlue = darken(0.15, blue);
export const orange = '#da821f';
export const green = '#818c46';
// export const lightGreen = lighten(0.1, green);
export const tableAlt = '#F9F6F1';
export const panelBorder = '#d9d9d9';
export const sand = '#dad0be';


export const refreshDarkGrey = '#555555';
export const refreshDarkBlue = '#4E6182';
export const refreshBaseBlue = '#6095BB';
export const refreshLightBlue = '#A2C1D7';
export const refreshLightGrey = '#E8E8E8';
export const refreshLightGreen = '#92C890';
export const refreshBurntOrange = '#DE775D';

// new colors
export const slate = '#5E6468';
export const darkSand = mix(0.5, refreshLightGreen, slate);
export const borderBox = '#CACACB';
export const baseBlue = refreshBaseBlue;
export const lightBlue = lighten(0.1, baseBlue);
export const baseGreen = refreshLightGreen;
export const baseRed = '#e5482e';
export const baseGrey = '#666';
export const lightGreen = lighten(0.1, baseGreen);
export const baseKhaki = lightGreen;
export const nearlyBlack = '#555555';
export const nearlyWhite = '#F5F5F7';
export const baseOrange = '#E5852E';
export const lightBg = '#F2F2F5';
export const lightGrey = refreshLightGrey;
export const row = 'white';
export const altRow = lighten(0.05, '#D3E0EE');
export const lockedRow = darken(0.05, row);
export const lockedAltRow = darken(0.05, altRow);
export const lightSand = lighten(0.1, mix(0.5, refreshLightGreen, lightBg));


export const statusColors = {
    success: '#87bc27',
    error: baseRed,
    warn: baseOrange,
    active: baseBlue,
    standard: baseGrey
};

export const priorityColors = {
    1: baseRed,
    2: baseOrange,
    3: baseGrey
};
