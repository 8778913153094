import styled from 'styled-components';
import { nearlyBlack } from './../variables';

export default styled.h1`
    font-size: 30px;
    font-weight: 600;
    line-height: 34px;
    color: ${nearlyBlack};
    @media print {
        white-space: nowrap;
    }
`;
