import React, { Fragment } from 'react';
import styled, { keyframes } from 'styled-components';
import IconLogo from '-/style/svg-icons/ICON-LogoMark.svg';
import { darkBody } from '-/style/variables';
import Subhead from '-/style/base/Subhead';
const size = 64;
const wobble = 1;

const spinning = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg);
`;

const SpinningDiv = styled.div`
    display: inline-block;
    animation: ${spinning} 3s infinite linear;
    transform-origin: 50% 50%;
    width: ${size}px;
    height: ${size}px;
`;
const StyledScreen = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: grey;
    opacity: 0.8;
    z-index: 4;
`;
const StyledLoadingCt = styled.div`
    top: 200px;
    left: 50%;
    position: fixed;
    width: 250px;
    margin-left: -125px;
    background-color: white;
    padding: 20px;
    border: 1px solid ${darkBody};
    overflow: auto;
    z-index: 5;
`;

const LoadingLogo = () => {
    const wobbleSize = size * wobble;
    return (
        <Fragment>
            <StyledScreen />
            <StyledLoadingCt>
                <SpinningDiv>
                    <img
                        src={IconLogo}
                        width={wobbleSize}
                        height={wobbleSize}
                        alt="Loading..."
                    />
                </SpinningDiv>
                <Subhead
                    style={{ float: 'right', marginTop: 17, marginRight: 60 }}
                >
                    Loading...
                </Subhead>
            </StyledLoadingCt>
        </Fragment>
    );
};

export default LoadingLogo;
