import React from 'react';
import LinkStyle from '-/style/base/Link';
import { NavLink } from 'react-router-dom';
import LinkList from '-/style/base/LinkList';

const ReportLi = props => {
    const { to, children } = props;
    return (
        <li>
            <LinkStyle as={NavLink} to={to}>
                {children}
            </LinkStyle>
        </li>
    );
};

const Menu = props => {
    return (
        <LinkList>
            <ReportLi to="/company/reports/monday/active">Active</ReportLi>
            <ReportLi to="/company/reports/monday/activePortfolio">Active Portfolio Financings</ReportLi>
            <ReportLi to="/company/reports/monday/watchlist">Watch List</ReportLi>
            <ReportLi to="/company/reports/monday/ptReview">PT Review</ReportLi>
            <ReportLi to="/company/reports/monday/queued">Queued</ReportLi>
            <ReportLi to="/company/reports/monday/dealUpdates">Deal Updates</ReportLi>
            <ReportLi to="/company/reports/monday/newThisWeek">New This Week</ReportLi>
            <ReportLi to="/company/reports/monday/portfolio">Portfolio</ReportLi>
            <ReportLi to="/company/reports/monday/turndowns">Turndowns</ReportLi>
        </LinkList>
    );
};

export default Menu;
