const previousYears = function (yearsBack) {
    const currYear = new Date().getFullYear() + 1;
    return Array.apply(0, Array(yearsBack)).map(function (x, y) {
        const year = currYear - y;
        return {
            label: year,
            value: year
        };
    });
};

const roles = ['Admin', 'Editor'];
const turnDownRationale = [
    {
        value: 'businessModel',
        label: 'Business Model'
    },
    {
        value: 'capitalStructure',
        label: 'Capital Structure'
    },
    {
        value: 'customerAcceptance',
        label: 'Customer Acceptance'
    },
    {
        value: 'marketDynamics',
        label: 'Market Dynamics'
    },
    {
        value: 'technologyProduct',
        label: 'Technology/Product'
    },
    {
        value: 'ventureManagement',
        label: 'Venture Management'
    },
    {
        value: 'valuation',
        label: 'Valuation'
    }
];
const sectors = [
    {
        value: 'consumerInternetDigitalMedia',
        label: 'Consumer Internet / Digital Media'
    },
    {
        value: 'enterpriseSoftwareCloudComputing',
        label: 'Enterprise Software / Cloud Computing'
    },
    {
        value: 'mobile',
        label: 'Mobile'
    },
    {
        value: 'newSectorsCleanTech',
        label: 'New Sectors / Clean Tech'
    },
    {
        value: 'securityPrivacy',
        label: 'Security / Privacy'
    },
    {
        value: 'semiconductorsComponents',
        label: 'Semiconductors / Components'
    },
    {
        value: 'healthcare',
        label: 'Healthcare'
    },
    {
        value: 'financialServices',
        label: 'Financial Services'
    },
    {
        value: 'advertising',
        label: 'Advertising'
    },
    {
        value: 'analytics',
        label: 'Analytics'
    },
    {
        value: 'education',
        label: 'Education'
    },
    {
        value: 'games',
        label: 'Games'
    },
    {
        value: 'retail',
        label: 'Retail'
    },
    {
        value: 'services',
        label: 'Services'
    },
    {
        value: 'storage',
        label: 'Storage'
    }
];
const sectorsByKey = sectors.reduce((final, sector) => {
    const { value, label } = sector;
    final[value] = label;
    return final;
}, {});
const financing = [
    {
        label: 'Pre-Seed',
        value: 'pre-seed'
    },
    {
        label: 'Seed',
        value: 'seed'
    },
    {
        label: 'Round A',
        value: 'a'
    },
    {
        label: 'Round B',
        value: 'b'
    },
    {
        label: 'Round C',
        value: 'c'
    }
];
const iconCategories = [
    'Portfolio',
    'Passive',
    'Priority Target',
    'Watch List',
    'Watch List – Reached Out',
    'Watch List – Engaged',
    'Watch List - Met',
    'Research',
    'Database',
    'Alumnus'
];
const iconStatuses = [
    'Queued',
    'Active',
    'Portfolio',
    'Declined/Informed',
    'Lost',
    'New This Week',
    'Deal Update'
];
const noteTypes = ['Meeting', 'Call', 'Note'];
const years = previousYears(40);
const nextContact = [
    {
        value: 'pastDue',
        label: 'Past Due'
    },
    {
        value: 'thisWeek',
        label: 'Until this Sunday'
    },
    {
        value: 'nextWeek',
        label: 'Until next Sunday'
    },
    {
        value: 'nextMonth',
        label: 'Until end of Month'
    },
    {
        value: 'next14Days',
        label: 'Within next 14 days'
    },
    {
        value: 'next30Days',
        label: 'Within 30 days'
    }
];

const materialsSent = [
    { value: 'last3Months', label: 'Within last 3 months' },
    { value: 'last6Months', label: 'Within last 6 months' },
    { value: 'last12Months', label: 'Within last 12 months' },
    { value: 'yes', label: 'Yes' }
];

const materialsSentTypes = [
    { value: 'introPPT', label: 'Intro Deck' },
    { value: 'overviewPPT', label: 'LP Deck' },
    { value: 'trackRecord', label: 'Track Record' },
    { value: 'annualMtgRecording', label: 'AGM Recording' },
    { value: 'annualMtgDeck', label: 'AGM Deck' },
    { value: 'annualMtgLive', label: 'AGM Live' },
    { value: 'dataroomAccess', label: 'Dataroom Access' },
    { value: 'PPM', label: 'PPM' }
];
const materialsSentTypeMap = materialsSentTypes.reduce(
    (o, curr) => {
        const { value, label } = curr;
        o[value] = label;
        return o;
    },
    {
        any: 'Any Materials'
    }
);

const lpTypeOfFunds = [
    'Asset Manager',
    'Consultant',
    'Endowment/Foundation',
    'Family Office',
    'Fund of Funds',
    'Individual',
    'Insurance Company',
    'Pension - Corporate/Private',
    'Pension - Public',
    'Service Provider',
    'HNW',
    'Fund-of-Fund',
    'Sovereign Wealth Fund',
    'Corporation'
];

const lpStatuses = ['N/A', 'Existing', 'Active', 'Prospect', 'Passed'];

const lpCheckRanges = [
    '<$1M',
    '$1-5M',
    '$5-10M',
    '$10-20M',
    '$20-40M',
    '$40M+'
];

// TODO: Comps don't have
const lpCategories = [];
const lpPriorities = [1, 2, 3];
const lpProbabilities = ['High', 'Medium', 'Low'];
// TODO: Verify we want to pull out
const lpCommitmentStatuses = [
    'Invested',
    'Passed With DD',
    'Passed Without DD',
    'Not Shown'
];

const iconFunds = {
    v: 'Icon Fund V',
    vi: 'Icon Fund VI'
};

const firmTiers = [
    '1A',
    '1B',
    '1C',
    '2',
    '3',
    'Seed'
];

const scrapedPairTypes = [
    'unknown',
    'identity',
    'premiumInsights',
    'companyDetails',
    'messaging'
];

// TODO: Verify what we want to do with regions outside of these
const regions = [
    'Northeast',
    'MidWest',
    'Pacific Northwest',
    'West',
    // 'Utah',
    // 'Texas',
    // TODO: Confirm with Icon NorCal and SoCal zipcodes
    // https://www.zipmap.net/California.htm
    'NorCal',
    'SoCal',
    'International',
    'South'
];

const statesByAbbrev = {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FM: 'Federated States Of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming'
};

const statesByName = Object.keys(statesByAbbrev)
    .map(key => {
        const value = statesByAbbrev[key];
        return { key, value };
    })
    .reduce((final, curr) => {
        const { value, key } = curr;
        final[value] = key;
        return final;
    }, {});

const formatAddress = (address = {}) => {
    const toPick = ['street1', 'street2', 'city', 'state', 'zip'];
    const fields = pickBy(address, (value, key) => {
        return value && toPick.includes(key);
    });
    const fieldsFormatted = map(fields, (value, key) => {
        const omitCommas = ['state', 'zip'];
        return omitCommas.includes(key) ? value : `${value},`;
    });

    return values(fieldsFormatted).join(' ');
};

const getStateNameByAbbreviation = abbreviation => {
    if (!abbreviation) {
        return;
    }
    return statesByAbbrev[abbreviation.toUpperCase()];
};

const getStateAbbreviationByName = state => {
    if (!state) {
        return;
    }
    const capName = `${state.charAt(0).toUpperCase}${state
        .slice(1)
        .toLowerCase()}`;
    return statesByName[capName];
};

const companySideNoteTypes = [
    'Note',
    'Meeting',
    'Call'
];
const lpSideNoteTypes = [
    'Email',
    'Call',
    'Zoom',
    'Visit',
    'Meeting',
    'Materials',
    'Note'
];

const lpReportColumns = [
    {
        value: 'name',
        width: 160,
        sortable: true,
        sortByDb: true,
        label: 'Name'
    },
    {
        value: 'location',
        width: 160,
        path: 'address',
        label: 'Location'
    },
    {
        value: 'status',
        width: 100,
        path: 'iconFit.status',
        sortable: true,
        label: 'Status'
    },
    {
        value: 'nextContactDate',
        width: 124,
        sortable: true,
        sortByDb: true,
        label: 'Next Contact'
    },
    {
        value: 'lastContactDate',
        width: 130,
        sortable: true,
        sortByDb: false,
        label: 'Last Contact'
    },
    {
        value: 'lastContactType',
        width: 160,
        sortable: true,
        sortByDb: false,
        label: 'Last Contact Type'
    },
    {
        value: 'primaryContacts',
        width: 200,
        label: 'Primary Contacts'
    },
    {
        value: 'actionItem',
        width: 420,
        label: 'Action Item'
    },
    {
        value: 'materialsSent',
        width: 200,
        sortable: true,
        sortByDb: false,
        sortField: 'materialsSentDate',
        label: 'Materials Sent'
    },
    {
        value: 'probability',
        width: 100,
        path: 'iconFit.probability',
        sortable: true,
        sortByDb: true,
        label: 'Probability'
    },
    {
        value: 'typeOfFund',
        width: 180,
        path: 'iconFit.typeOfFund',
        label: 'Type of Fund'
    },
    {
        value: 'priority',
        width: 100,
        path: 'iconFit.priority',
        sortable: true,
        sortByDb: true,
        label: 'Priority'
    },
    {
        value: 'closedToDate',
        width: 175,
        path: 'iconFit.closedToDate',
        label: 'Closed to Date'
    },
    {
        value: 'checkRange',
        width: 150,
        path: 'iconFit.checkRange',
        label: 'VC Check Range'
    },
    {
        value: 'referredBy',
        width: 150,
        label: 'Referrer'
    },
    {
        value: 'updatedAt',
        sortable: true,
        sortByDb: true,
        width: 150,
        label: 'Last Updated'
    }
];
const keyedLPColumns = lpReportColumns.reduce((keyedLPColumns, column) => {
    keyedLPColumns[column.value] = column;
    return keyedLPColumns;
}, {});

keyedLPColumns.referredByName = {
    value: 'referredByName',
    width: 160,
    path: 'referredBy.name',
    label: 'Referrer Name'
};
keyedLPColumns.referredByCompany = {
    value: 'referredByCompany',
    width: 160,
    path: 'referredBy.company',
    label: 'Referrer Company'
};
keyedLPColumns.referredByTitle = {
    value: 'referredByTitle',
    width: 160,
    path: 'referredBy.title',
    label: 'Referrer Title'
};
keyedLPColumns.referredByEmail = {
    value: 'referredByEmail',
    width: 160,
    path: 'referredBy.email',
    label: 'Referrer Email'
};
keyedLPColumns.nameWithLocation = {
    value: 'nameWithLocation',
    width: 160,
    path: 'name',
    label: 'Name'
};
keyedLPColumns.city = {
    value: 'city',
    width: 160,
    path: 'address.city',
    label: 'City'
};
keyedLPColumns.state = {
    value: 'state',
    width: 160,
    path: 'address.region',
    label: 'State'
};
keyedLPColumns.region = {
    value: 'region',
    width: 160,
    path: 'address.area',
    label: 'Region'
};
keyedLPColumns.keyVCs = {
    value: 'keyVCs',
    width: 200,
    path: 'keyVCs',
    label: 'Key VCs'
};
keyedLPColumns.allVCs = {
    value: 'allVCs',
    width: 200,
    path: 'keyVCs',
    label: 'VCs'
};
keyedLPColumns.lastRoundSeries = {
    value: 'lastRoundSeries',
    width: 60,
    path: 'lastRound.series',
    label: 'Last Series'
};
keyedLPColumns.lastRoundValuation = {
    value: 'lastRoundValuation',
    width: 60,
    path: 'lastRound.valuation',
    label: 'Valuation'
};
keyedLPColumns.lastRoundDate = {
    value: 'lastRoundDate',
    width: 60,
    path: 'lastRound.date',
    label: 'Date'
};
keyedLPColumns.lastRoundAmount = {
    value: 'lastRoundAmount',
    width: 60,
    path: 'lastRound.amount',
    label: 'Last Round'
};
keyedLPColumns.monthsSinceLastRound = {
    value: 'monthsSinceLastRound',
    width: 60,
    path: 'lastRound.date',
    label: 'Months'
};
keyedLPColumns.themes = {
    value: 'themes',
    width: 200,
    path: 'themes',
    label: 'Themes'
};
keyedLPColumns.themeCompanies = {
    value: 'themeCompanies',
    width: 200,
    path: 'relatedCompanies',
    label: 'Related Companies'
};
keyedLPColumns.primaryContactNames = {
    value: 'primaryContactNames',
    width: 180,
    path: 'primaryContacts',
    label: 'Primary Contacts'
};

const MAX_PRIMARY_CONTACT_COUNT = 3;
for (var i = 0; i < MAX_PRIMARY_CONTACT_COUNT; i++) {
    const numLabel = i + 1;
    keyedLPColumns[`primaryContactName${i}`] = {
        value: `primaryContactName${i}`,
        width: 160,
        path: `primaryContacts.${i}.name`,
        label: `Primary Contact ${numLabel}`
    };
    keyedLPColumns[`primaryContactEmail${i}`] = {
        value: `primaryContactEmail${i}`,
        width: 160,
        path: `primaryContacts.${i}.email`,
        label: `Primary Contact ${numLabel} Email`
    };
    keyedLPColumns[`primaryContactPhone${i}`] = {
        value: `primaryContactPhone${i}`,
        width: 160,
        path: `primaryContacts.${i}.phone`,
        label: `Primary Contact ${numLabel} Phone`
    };
}

keyedLPColumns.homepage = {
    value: 'homepage',
    width: 120,
    path: 'websites.homepage',
    label: 'Homepage'
};
keyedLPColumns._id = {
    value: '_id',
    width: 120,
    path: '_id',
    label: 'Permalink',
    hidden: true
};
keyedLPColumns.ceo = {
    value: 'ceo',
    width: 120,
    path: 'ceo',
    label: 'CEO'
};
keyedLPColumns.iconCategoryAbbreviated = {
    value: 'iconCategoryAbbreviated',
    width: 50,
    path: 'iconCategory',
    label: 'PT/WL'
};
keyedLPColumns.latestHeadcountWithGrowth = {
    value: 'latestHeadcountWithGrowth',
    width: 120,
    label: 'HC'
};
keyedLPColumns.latestHeadcountDate = {
    value: 'latestHeadcountDate',
    width: 120,
    label: 'HC Date'
};
keyedLPColumns.growth = {
    value: 'growth',
    width: 120,
    label: '3m Growth'
};
keyedLPColumns.growth6 = {
    value: 'growth6',
    width: 120,
    label: '6m Growth'
};
keyedLPColumns.growth12 = {
    value: 'growth12',
    width: 120,
    label: '1y Growth'
};
keyedLPColumns.growth24 = {
    value: 'growth24',
    width: 120,
    label: '2y Growth'
};
keyedLPColumns.growthNoInterpolation = {
    value: 'growthNoInterpolation',
    path: 'growth',
    width: 120,
    label: '3m Growth'
};
keyedLPColumns.growth6NoInterpolation = {
    value: 'growth6NoInterpolation',
    path: 'growth6',
    width: 120,
    label: '6m Growth'
};
keyedLPColumns.growth12NoInterpolation = {
    value: 'growth12NoInterpolation',
    path: 'growth12',
    width: 120,
    label: '1y Growth'
};
keyedLPColumns.growth24NoInterpolation = {
    value: 'growth24NoInterpolation',
    path: 'growth24',
    width: 120,
    label: '2y Growth'
};
const lpReportSortableColumns = lpReportColumns
    .filter(col => col.sortable)
    .reduce((columns, column) => {
        const { value, label } = column;
        columns.push({
            value,
            label: `${label} (ASC)`
        });
        columns.push({
            value: `-${value}`,
            label: `${label} (DESC)`
        });
        return columns;
    }, []);

const potentialMetrics = [{
    key: 'employeeHeadCount',
    title: 'Employee Headcount'
}, {
    key: 'googleTrends',
    title: 'Google Trends'
}, {
    key: 'users',
    title: 'Monthly Users'
}, {
    key: 'visits',
    title: 'Monthly Visits'
}];
const mondayReportColumns = {
    active: [
        'name',
        'location',
        'ceo',
        'iconPointPeople',
        'keyVCs',
        'description',
        'lastRound',
        'actionItem'
    ],
    activePortfolio: [
        'name',
        'location',
        'ceo',
        'iconPointPeople',
        'keyVCs',
        'description',
        'actionItem'
    ],
    watchList: [
        'primaryThemePriority',
        'name',
        'location',
        'actionItem',
        'iconCategory',
        'iconPointPeople',
        'ceo',
        'allVCs',
        'themes',
        'description'
    ],
    watchListExcel: [
        'primaryThemePriority',
        'name',
        'location',
        'iconCategory',
        'actionItem',
        'iconPointPeople',
        'ceo',
        'allVCs',
        'themes',
        'description'
    ],
    ptReview: [
        'primaryThemePriority',
        'name',
        'location',
        'actionItem',
        'iconPointPeople',
        'ceo',
        'allVCs',
        'lastRound',
        'description',
        'themes',
    ],
    queued: [
        'queuedWeeks',
        'name',
        'location',
        'ceo',
        'iconPointPeople',
        'allVCs',
        'lastRound',
        'description',
        'actionItem'
    ],
    dealUpdate: [
        'name',
        'location',
        'ceo',
        'iconPointPeople',
        'keyVCs',
        'lastRound',
        'description',
        'actionItem'
    ],
    newThisWeek: [
        'primaryThemePriority',
        'name',
        'location',
        'ceo',
        'iconPointPeople',
        'allVCs',
        'lastRound',
        'themes',
        'description',
        'themeCompanies'
    ],
    portfolio: [
        'name',
        'location',
        'ceo',
        'iconPointPeople',
        'description',
        'themes',
        'actionItem'
    ],
    portfolioExcel: [
        'name',
        'iconCategory',
        'location',
        'ceo',
        'iconPointPeople',
        'description',
        'themes',
        'actionItem'
    ],
    turndowns: [
        'name',
        'location',
        'ceo',
        'queuedDate',
        'decisionDate',
        'iconPointPeople',
        'keyVCs',
        'description'
    ]
};

const iconPointOrder = [
    'JH',
    'TM',
    'JM',
    'MM',
    'PR',
    'BHS',
    'PY',
    'MP',
    'DM',
    'HM',
    'PS',
    'DE'
];

const themePriorities = [
    'Focus',
    'Non-Focus',
    'Avoid'
];

module.exports = {
    mondayReportColumns,
    companySideNoteTypes,
    lpSideNoteTypes,
    statesByAbbrev,
    statesByName,
    getStateNameByAbbreviation,
    getStateAbbreviationByName,
    roles,
    turnDownRationale,
    sectors,
    sectorsByKey,
    financing,
    iconCategories,
    iconPointOrder,
    iconStatuses,
    noteTypes,
    years,
    nextContact,
    lpTypeOfFunds,
    lpStatuses,
    lpCheckRanges,
    lpCategories,
    lpPriorities,
    lpProbabilities,
    lpCommitmentStatuses,
    lpReportColumns,
    keyedLPColumns,
    lpReportSortableColumns,
    materialsSent,
    materialsSentTypes,
    materialsSentTypeMap,
    iconFunds,
    regions,
    potentialMetrics,
    themePriorities,
    firmTiers,
    scrapedPairTypes
};
