import React from 'react';
import CompanyQuery from './CompanyQuery';
import { GET_COMPANY_PEOPLE } from '-/gql/company';
import { SimpleValue, PersonDisplayValue } from '-/components/profiles';
import CEOForm from '-/company/form/CEO';
import BoardMembersForm from '-/company/form/BoardMembers';
import VCsForm from '-/company/form/VCs';
import IconPointPeopleForm from '-/company/form/IconPointPeople';
import ReferrersForm from '-/company/form/Referrers';
import EditableField from './EditableField';

const PeopleView = props => {
    const {
        _id,
        inEditMode,
        ceo,
        boardMembers,
        VCs,
        iconPointPeople,
        referrers
    } = props;

    const ceoForm = (
        <CEOForm
            _id={_id}
            initialValues={{
                ceo
            }}
        />
    );

    const boardMembersForm = (
        <BoardMembersForm
            _id={_id}
            initialValues={{
                boardMembers
            }}
        />
    );

    const vcsForm = (
        <VCsForm
            _id={_id}
            initialValues={{
                VCs
            }}
        />
    );

    const iconPointForm = (
        <IconPointPeopleForm
            _id={_id}
            initialValues={{
                iconPointPeople
            }}
        />
    );

    const referrersForm = (
        <ReferrersForm
            _id={_id}
            initialValues={{
                referrers
            }}
        />
    );

    return (
        <div>
            <EditableField label="CEO" inEditMode={inEditMode} form={ceoForm}>
                <SimpleValue>{ceo}</SimpleValue>
            </EditableField>

            <EditableField
                inEditMode={inEditMode}
                label="Board Members"
                form={boardMembersForm}
            >
                <PersonDisplayValue value={boardMembers} />
            </EditableField>

            <EditableField inEditMode={inEditMode} label="VCs" form={vcsForm}>
                <PersonDisplayValue value={VCs} />
            </EditableField>
            <EditableField
                inEditMode={inEditMode}
                label="Icon Point People"
                form={iconPointForm}
            >
                <PersonDisplayValue value={iconPointPeople} linkable={false} />
            </EditableField>
            <EditableField
                inEditMode={inEditMode}
                label="Referrers"
                form={referrersForm}
            >
                <PersonDisplayValue value={referrers} />
            </EditableField>
        </div>
    );
};

const People = props => {
    return (
        <CompanyQuery
            query={GET_COMPANY_PEOPLE}
            component={PeopleView}
            {...props}
        />
    );
};

export default People;