import gql from 'graphql-tag';

export const GET_ALL_NOTION_REPORTS = gql`
    query GET_ALL_NOTION_REPORTS {
        NotionReports {
            _id
            name
            nextRunDate
            status
            criteria
        }
    }
`;

export const SET_NOTION_REPORT_STATUS = gql`
    mutation SetNotionReportStatus($_id: ID!, $status: String) {
        setNotionReportStatus(_id: $_id, status: $status) {
            _id
            status
            nextRunDate
        }
    }
`;