import React, { Fragment } from 'react';
import { Formik, Field, Form } from 'formik';
import { graphql, compose } from 'react-apollo';
import { get } from 'lodash';
import { GET_COMPANY_METRICS, UPDATE_COMPANY_METRICS } from '-/gql/company';
import { InlineCheckbox, TextInput } from '-/components/inputs';
import Toolbar from '-/components/profiles/forms/Toolbar';
import styled from 'styled-components';
import { potentialMetrics } from '-/shared/constants';
import { removeTypename } from '-/gql/utils';

const StyledForm = styled(Form)`
    width: 350px;
    padding: 10px;
`;

const renderFields = props => {
    const { companyName, className, closeOverlay, values = {} } = props;
    const keywordEnabled = get(values, 'metrics.googleTrends.enabled');
    return (
        <StyledForm className={className}>
            {potentialMetrics.map(metricInfo => {
                const { key, title } = metricInfo;
                if (key === 'googleTrends') {
                    return (
                        <Fragment key={key}>
                            <Field
                                label={title}
                                component={InlineCheckbox}
                                name={`metrics.${key}.enabled`}
                            />
                            {keywordEnabled && (
                                <Field
                                    label="Keyword"
                                    component={TextInput}
                                    placeholder={`Defaults to ${companyName}`}
                                    name="metrics.googleTrends.keyword"
                                />
                            )}
                        </Fragment>
                    );
                } else {
                    return (
                        <Field
                            key={key}
                            label={title}
                            component={InlineCheckbox}
                            name={`metrics.${key}.enabled`}
                        />
                    );
                }
            })}

            <Toolbar handleCancel={closeOverlay} />
        </StyledForm>
    );
};
const MetricsForm = props => {
    const { _id, updateCompanyMetrics, closeOverlay, className } = props;
    const metrics = removeTypename(get(props, 'data.Company.metrics', {}));
    const companyName = get(props, 'data.Company.name');
    const initialValues = {
        metrics
    };
    console.log(initialValues);
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const { metrics } = values;
                    await updateCompanyMetrics({
                        variables: {
                            _id,
                            input: metrics
                        }
                    });
                    closeOverlay();
                } catch (ex) {
                    console.warn(ex);
                }
            }}
            render={renderProps => {
                return renderFields({
                    ...renderProps,
                    closeOverlay,
                    className,
                    companyName
                });
            }}
        />
    );
};

const returnPermalink = props => {
    const permalink = get(props, '_id');
    return {
        variables: {
            permalink
        },
        refetchQueries: [
            {
                query: GET_COMPANY_METRICS,
                variables: { permalink }
            }
        ]
    };
};
export default compose(
    graphql(GET_COMPANY_METRICS, {
        options: returnPermalink
    }),
    graphql(UPDATE_COMPANY_METRICS, {
        name: 'updateCompanyMetrics'
    })
)(MetricsForm);
