import gql from 'graphql-tag';

export const ADD_CATEGORY = gql`
    mutation addCategory($input: ThemeCategoryInput!) {
        addCategory(input: $input) {
            _id
            category
            themes {
                _id
                permalink
                ingredient
                name
                priority
            }
        }
    }
`;

export const GET_ALL_CATEGORIES = gql`
    query GET_ALL_CATEGORIES {
        ThemeCategories {
            _id
            category
            themes {
                _id
                permalink
                ingredient
                name
                priority
                companyCount
                importantCompanyCount
            }
        }
    }
`;


export const GET_THEME_DETAILS = gql`
    query GET_THEME_DETAILS($permalink: String!) {
        Theme(permalink: $permalink) {
            _id
            permalink
            name
            category
            ingredient
            priority
            companies {
                _id
                primaryThemePriority
                name
                description
                iconCategory
                iconStatus
            }
        }
    }
`;