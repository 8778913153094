import React, { Fragment } from 'react';
import { transformOptions } from './utils';
import { turnDownRationale } from '-/shared/constants';
import { Row, Col } from '-/style/base/Layout';
import Label from './Label';
import styled from 'styled-components';

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
    margin-bottom: 9px;
`;

export const Checkbox = props => {
    const {
        field,
        label,
        error,
        form: { setFieldValue },
        disabled
    } = props;
    const { name, value, ...restOfField } = field;
    if (!name) {
        throw new Error('Cannot have input w/o name');
    }
    return (
        <div>
            <Label error={error}>{label}</Label>
            <StyledCheckbox
                name={name}
                checked={value}
                disabled={disabled}
                {...restOfField}
                onChange={e => {
                    const { checked } = e.target;
                    setFieldValue(name, checked);
                }}
            />
        </div>
    );
};

export const InlineCheckbox = props => {
    const {
        field,
        fieldStyle,
        label,
        error,
        form: { setFieldValue }
    } = props;
    const { name, value, ...restOfField } = field;
    if (!name) {
        throw new Error('Cannot have input w/o name');
    }
    return (
        <div>
            <Label error={error}>
                <StyledCheckbox
                    name={name}
                    style={fieldStyle}
                    checked={value}
                    {...restOfField}
                    onChange={e => {
                        const { checked } = e.target;
                        setFieldValue(name, checked);
                    }}
                />
                {label}
            </Label>
        </div>
    );
};

export const Checkboxes = props => {
    const {
        field,
        label,
        error,
        form: { values, setFieldValue },
        options: optionsRaw,
        style,
        className,
        colWidth = 140
    } = props;
    const { name } = field;
    if (!name) {
        throw new Error('Cannot have input w/o name');
    }
    const arrayOfValues = values[name] || [];
    const options = transformOptions(optionsRaw);
    return (
        <Fragment>
            <Label error={error}>{label}</Label>
            <Row style={style} className={className}>
                {options.map(o => {
                    const { value, label } = o;
                    return (
                        <Col key={value} style={{ width: colWidth }}>
                            <label>
                                <StyledCheckbox
                                    value={value}
                                    checked={arrayOfValues.includes(value)}
                                    onChange={e => {
                                        const set = new Set(field.value);
                                        if (set.has(value)) {
                                            set.delete(value);
                                        } else {
                                            set.add(value);
                                        }
                                        setFieldValue(
                                            field.name,
                                            Array.from(set)
                                        );
                                    }}
                                />
                                {label}
                            </label>
                        </Col>
                    );
                })}
            </Row>
        </Fragment>
    );
};

export const TurnDownCheckboxes = props => {
    return <Checkboxes options={turnDownRationale} {...props} />;
};
