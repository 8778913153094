import gql from 'graphql-tag';

export const GET_WAITLIST = gql`
    query GET_WAITLIST($criteria: WaitListCriteria) {
        WaitList(criteria: $criteria) {
            _id
            permalink
            entityType
            returnEntityType
            relationship
            status
            errMsg
            updatedAt
            tasks
        }
        WaitListStats {
            _id
            disabled
            Waiting
            Running
            Failed
            Completed
            Archived
            Skipped
            Total
            Company
            Firm
            Person
        }
    }
`;

export const RESET_WAITLIST_ITEM = gql`
    mutation RESET_WAITLIST_ITEM($_id: ID!, $force: Boolean) {
        resetWaitListItem(_id: $_id, force: $force) {
            _id
            status
            errMsg
        }
    }
`;
export const RESET_FAILED_WAITLIST_ITEMS = gql`
    mutation RESET_WAITLIST_ITEM($entities: [String]!) {
        resetFailedWaitListItems(entities: $entities)
    }
`;

export const SET_WAITLIST_DISABLED = gql`
    mutation SET_WAITLIST_DISABLED($disabled: Boolean) {
        setWaitListDisabled(disabled: $disabled) {
            _id
            disabled
        }
    }
`;