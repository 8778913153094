import React from 'react';
import { Formik, Field, Form } from 'formik';
import { graphql } from 'react-apollo';
import { pick } from 'lodash';
import { UPDATE_COMPANY_BASIC_INFO } from '-/gql/company';
import {
    AutoComplete,
    Checkboxes,
    TextInput,
    SimpleDate
} from '-/components/inputs';
import Toolbar from '-/components/profiles/forms/Toolbar';
import styled from 'styled-components';
import {
    iconCategories,
    iconStatuses,
    turnDownRationale
} from '-/shared/constants';

const StyledForm = styled(Form)`
    width: 350px;
    padding: 10px;
`;

const renderFields = props => {
    const { className, closeOverlay, values: { iconStatus } = {} } = props;
    const showTurnDown = iconStatus && iconStatus === 'Declined/Informed';
    return (
        <StyledForm className={className}>
            <Field label="Company Name" component={TextInput} name="name" />
            <Field
                label="Icon Category"
                component={AutoComplete}
                options={iconCategories}
                name="iconCategory"
            />
            <Field
                label="Icon Status"
                component={AutoComplete}
                options={iconStatuses}
                name="iconStatus"
            />
            {showTurnDown && (
                <Field
                    name="turnDownRationale"
                    component={Checkboxes}
                    colWidth={175}
                    options={turnDownRationale}
                />
            )}
            <Field
                component={SimpleDate}
                label="Queued Date"
                name="queuedDate"
            />
            <Field
                component={SimpleDate}
                label="Next Contact Date"
                name="nextContactDate"
            />
            <Field
                component={SimpleDate}
                label="Decision Date"
                name="decisionDate"
            />
            <Field
                component={SimpleDate}
                label="Investment Date"
                name="investmentDate"
            />

            <Toolbar handleCancel={closeOverlay} />
        </StyledForm>
    );
};
const BasicForm = props => {
    const {
        _id,
        closeOverlay,
        updateBasicInfo,
        initialValues,
        className
    } = props;
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const input = pick(values, [
                        'name',
                        'iconCategory',
                        'iconStatus',
                        'turnDownRationale',
                        'queuedDate',
                        'decisionDate',
                        'investmentDate',
                        'nextContactDate'
                    ]);
                    await updateBasicInfo({
                        variables: {
                            _id,
                            input
                        }
                    });
                    closeOverlay();
                } catch (ex) {
                    console.warn(ex);
                }
            }}
            render={renderProps => {
                return renderFields({
                    ...renderProps,
                    closeOverlay,
                    className
                });
            }}
        />
    );
};

export default graphql(UPDATE_COMPANY_BASIC_INFO, { name: 'updateBasicInfo' })(
    BasicForm
);
