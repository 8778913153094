import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-flexybox';
import TetherComponent from 'react-tether';
import Link from './style/base/Link';
import Logo from './style/svg-icons/Logo.svg';
import LPLogo from './style/svg-icons/LPLogo.svg';
import styled, { css } from 'styled-components';
import { refreshLightBlue, refreshDarkGrey } from './style/variables';
import { get } from 'lodash';
import AppMenu from './AppMenu';
import AppSearch from './AppSearch';
import { withRouter } from 'react-router-dom';
import { Menu } from '-/style/Icon';
import { outExpoTransition } from '-/style/variables';

import { WILD_SEARCH_LP } from '-/gql/lp';
import { WILD_SEARCH_COMPANY } from '-/gql/company';

const appSearchConfigBySide = {
    lp: {
        placeholder: 'Search LP Database',
        resolveUrl: entityName => {
            return `/lp/view/${entityName}`;
        },
        query: WILD_SEARCH_LP,
        resultKey: 'LPWildSearch'
    },
    std: {
        placeholder: 'Search Company Database',
        resolveUrl: entityName => {
            return `/company/view/${entityName}`;
        },
        query: WILD_SEARCH_COMPANY,
        resultKey: 'CompanyWildSearch'
    }
};
const StyledMenuText = styled.span`
    color: ${props => props.side === 'lp' ? refreshDarkGrey : 'white'};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75rem;
`;
const StyledMenuIcon = styled(Menu)`
    color: ${props => props.side === 'lp' ? refreshDarkGrey : 'white'};
    transition: all 0.5s ${outExpoTransition};
    &:hover {
        transform: scaleY(1.2) translateZ(0);
    }
`;
const svgLogos = {
    std: Logo,
    lp: LPLogo
};

const stdSideCss = `
  background-color: ${refreshLightBlue};
`;

const lpSideCss = `
  background-color: white;
`;

const HeaderWrap = styled.div`
    ${props =>
        props.side === 'lp'
            ? css`
                  ${lpSideCss}
              `
            : css`
                  ${stdSideCss}
              `}
`;

const HeaderRow = styled(Row)`
    max-width: 1824px;
    margin: auto;
    height: 54px;
`;
HeaderRow.defaultProps = {
    alignItems: 'center'
};

const LeftCol = styled(Col)`
    @media print {
        visibility: hidden;
    }
`;
LeftCol.defaultProps = {
    flex: 4
};

const CenterCol = styled(Col)`
    text-align: center;
`;
CenterCol.defaultProps = {
    flex: 4
};

const RightCol = styled(Col)`
    text-align: right;
    padding-right: 8px;
    @media print {
        visibility: hidden;
    }
`;
RightCol.defaultProps = {
    flex: 4
};
const KEYCODES = {
    ESCAPE: 27
};

class AppBar extends Component {
    constructor(props) {
        super(props);
        this.tether = React.createRef();
        this.state = {
            isMenuOpen: false
        };
    }

    toggleMenuOpen = e => {
        const { isMenuOpen } = this.state;
        this.setState({
            isMenuOpen: !isMenuOpen
        });
    };

    closeMenu = () => {
        this.setState({ isMenuOpen: false });
    };

    handleOutsideMouseClick = e => {
        if (!this.state.isMenuOpen) {
            return;
        }
        const parentEl = get(this, 'tether.current._elementParentNode');
        const targetClass = get(e, 'target.className');

        if (
            !parentEl ||
            (e.button && e.button !== 0) ||
            typeof targetClass !== 'string' ||
            targetClass.indexOf('tether-target') !== -1
        ) {
            return;
        }
        this.closeMenu();
    };

    handleKeydown = e => {
        if (e.keyCode === KEYCODES.ESCAPE && this.state.isMenuOpen) {
            this.closeMenu();
        }
    };

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeydown);
        document.addEventListener('click', this.handleOutsideMouseClick);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeydown);
        document.removeEventListener('click', this.handleOutsideMouseClick);
    }
    getSide = () => {
        const isLpSide =
            get(this.props, 'location.pathname', '/').indexOf('/lp') === 0;
        return isLpSide ? 'lp' : 'std';
    };
    isLogin = () => {
        return (
            get(this.props, 'location.pathname', '/').indexOf('/login') === 0
        );
    };
    render() {
        const isLogin = this.isLogin();
        if (isLogin) {
            return null;
        }
        const { isMenuOpen } = this.state;
        const side = this.getSide();
        const logoToUse = svgLogos['std'];
        const logoLinkPath = side === 'lp' ? '/lp' : '/company';

        return (
            <HeaderWrap side={side}>
                <HeaderRow alignItems="center">
                    <LeftCol>
                        <TetherComponent
                            ref={this.tether}
                            attachment="top left"
                            targetAttachment="bottom left"
                            constraints={[
                                {
                                    to: 'window',
                                    attachment: 'together'
                                }
                            ]}
                            /* renderTarget: This is what the item will be tethered to, make sure to attach the ref */
                            renderTarget={ref => (
                                <Fragment>
                                    <StyledMenuIcon
                                        ref={ref}
                                        side={side}
                                        onClick={this.toggleMenuOpen}
                                        width={50}
                                        height={35}
                                    />
                                    {isMenuOpen && (
                                        <StyledMenuText side={side}>Menu</StyledMenuText>
                                    )}
                                </Fragment>
                            )}
                            /* renderElement: If present, this item will be tethered to the the component returned by renderTarget */
                            renderElement={ref =>
                                isMenuOpen && (
                                    <div ref={ref}>
                                        <AppMenu side={side} />
                                    </div>
                                )
                            }
                        />
                    </LeftCol>
                    <CenterCol>
                        <Link to={logoLinkPath}>
                            <img src={logoToUse} height="36" alt="Iconbase" width={206} />
                        </Link>
                    </CenterCol>
                    <RightCol>
                        <AppSearch side={side} {...appSearchConfigBySide[side]} />
                    </RightCol>
                </HeaderRow>
            </HeaderWrap>
        );
    }
}

export default withRouter(AppBar);
