import React from 'react';
import Box from '-/style/base/Box';
import Subhead from '-/style/base/Subhead';
import { GET_COMPANY_MONDAY_QUEUED } from '-/gql/company';
import { graphql } from 'react-apollo';
import { get } from 'lodash';
import StandardTable from './StandardTable';
import LoadingLogo from '-/components/LoadingLogo';
import { QueuedRow } from './StandardRow';
import { QueuedHeader } from './StandardHeader';
import { sortBySeries } from './utils';
import { StyledEmptyRow } from '-/style/base/Table';

const EmptyRow = <StyledEmptyRow colSpan={8}>No companies.</StyledEmptyRow>;
const Queued = props => {
    const { data = {} } = props;
    const { loading } = data;
    const rawCompanies = get(data, 'CompanyMondayReport.queued', []);
    const companies = sortBySeries(rawCompanies);

    if (loading) {
        return <LoadingLogo />;
    }
    return (
        <Box>
            <Subhead>Queued</Subhead>
            <StandardTable
                data={companies}
                Row={QueuedRow}
                Header={QueuedHeader}
                EmptyRow={EmptyRow}
            />
        </Box>
    );
};

export default graphql(GET_COMPANY_MONDAY_QUEUED)(Queued);
