import React from 'react';
import PersonQuery from './PersonQuery';
import { withProps } from 'recompose';
import { GET_PERSON_BIO } from '-/gql/person';
import nl2br from '-/components/nl2br';

const Bio = props => {
    const { className, style, bio } = props;
    return (
        <div className={className} style={style}>
            {nl2br(bio)}
        </div>
    );
};

export default withProps({
    component: Bio,
    query: GET_PERSON_BIO
})(PersonQuery);
