import React, { Fragment } from 'react';
import {
    Label,
    LineChart,
    Line,
    ReferenceLine,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts';
import { abbreviateNumber } from '-/utils/format';
import SubSubHeadRaw from '-/style/base/SubSubHead';
import styled from 'styled-components';
import { baseGreen } from '-/style/variables';
import moment from 'moment';

const SubSubHead = styled(SubSubHeadRaw)`
    text-align: left;
    margin-bottom: 5px;
`;

const rawDataDotColor = '#ff0000';
const lineColor = baseGreen;
const fakeData = [
    {
        recorded_at_short: 'Jan 21',
        score: 1000
    },
    {
        recorded_at_short: 'Feb 21',
        score: 850
    },
    {
        recorded_at_short: 'Mar 21',
        score: 450
    },
    {
        recorded_at_short: 'Apr 21',
        score: 550
    }
];

const MattermarkChart = props => {
    const {
        chartType,
        title = '',
        data: dataRaw = [],
        showRawData = false,
        className,
        style: styleRaw
    } = props;
    const hasData = dataRaw.length !== 0;
    const filledData = dataRaw.filter(d => d.rawScore);
    const isAnimationActive = filledData.length >= 5;
    const data = hasData ? dataRaw : fakeData;
    const style = {
        ...styleRaw,
        ...(!hasData && { opacity: 0.3 })
    };

    return (
        <Fragment>
            <SubSubHead>{title}</SubSubHead>
            <ResponsiveContainer height="95%" className={className}>
                {/* marginRight fixes an issue when integrating recharts and slider that would leave artifacts on lefthandside */}
                <LineChart
                    height={320}
                    data={data}
                    margin={{ left: 0, right: 5 }}
                    style={style}
                >
                    {chartType === 'employeeHeadCount'
                        ? <XAxis
                            dataKey='recorded_at'
                            type='number'
                            scale='time'
                            domain={['dataMin', 'dataMax']}
                            tickFormatter={value => moment.utc(value).format("MMM 'YY")}
                        />
                        : <XAxis dataKey='recorded_at_short' />
                    }
                    <YAxis
                        width={40}
                        allowDecimals={false}
                        domain={chartType === 'googleTrends' ? [0, 100] : [0, 'auto']}
                        tickFormatter={abbreviateNumber}
                    />
                    {!hasData && (
                        <ReferenceLine y={500}>
                            <Label value="MISSING DATA" position="center" />
                        </ReferenceLine>
                    )}
                    <CartesianGrid strokeDasharray="3 3" />
                    {(hasData && chartType !== 'employeeHeadCount') && <Tooltip />}
                    {(hasData && chartType === 'employeeHeadCount') && <Tooltip labelFormatter={label => moment.utc(label).format("MMM 'YY")} />}
                    <ReferenceLine y={0} stroke="black" strokeDasharray="3 3" />
                    {showRawData ? (
                        <Line
                            name={`Actual ${title}`}
                            dataKey="rawScore"
                            stroke={0}
                            isAnimationActive={isAnimationActive}
                            dot={{ fill: rawDataDotColor, r: 2 }}
                        />
                    ) : null}
                    <Line
                        name={title}
                        dataKey="score"
                        dot={(hasData && chartType === 'employeeHeadCount') ? { fill: rawDataDotColor, r: 2 } : null}
                        strokeWidth={4}
                        stroke={lineColor}
                        isAnimationActive={isAnimationActive}
                        formatter={score => {
                            return Math.round(score);
                        }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </Fragment>
    );
};

export default MattermarkChart;
