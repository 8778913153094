import React from 'react';
import { Link } from 'react-router-dom';
import LinkList from '-/style/base/LinkList';
import MainHeader from '-/style/base/MainHeader';

const FormHeader = props => {
    const { permalink, children } = props;
    return (
        <MainHeader title={children}>
            <LinkList>
                <li>
                    <Link to="/lps">All LPs</Link>
                </li>
                {permalink ? (
                    <li>
                        <Link to={`/lp/view/${permalink}`}>View</Link>
                    </li>
                ) : null}
                {!permalink ? (
                    <li>
                        <Link to="/lp/report/new">Customize Report</Link>
                    </li>
                ) : null}
            </LinkList>
        </MainHeader>
    );
};

export default FormHeader;
