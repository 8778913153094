import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { withRouter } from 'react-router-dom';
import { AutoComplete } from '-/components/inputs';
import { Search as SearchRaw } from '-/style/Icon';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { refreshDarkGrey } from './style/variables';

const Search = styled(SearchRaw)`
    color: ${props => props.side === 'lp' ? refreshDarkGrey : 'white'};
`;
class AppSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            searchTerm: null
        };
        this.gotoEntityAutoComplete = React.createRef();
    }

    onSearchClick = () => {
        const { open } = this.state;

        this.setState(
            {
                open: !open
            },
            () => {
                const { gotoEntityAutoComplete } = this;
                if (gotoEntityAutoComplete && gotoEntityAutoComplete.current) {
                    try {
                        gotoEntityAutoComplete.current.focus();
                    } catch (ex) { }
                }
            }
        );
    };
    renderForm = renderProps => {
        const { side } = this.props;
        const { open } = this.state;
        const {
            submitForm,
            resetForm,
            placeholder = '',
            query,
            resultKey
        } = renderProps;

        return (
            <Form>
                {open && (
                    <Field
                        query={query}
                        resultKey={resultKey}
                        placeholder={placeholder}
                        component={AutoComplete}
                        innerRef={this.gotoEntityAutoComplete}
                        name="gotoEntity"
                        wildcard={true}
                        // https://github.com/jaredpalmer/formik/issues/1209
                        onChange={value => {
                            if (value) {
                                setTimeout(submitForm, 10);
                            }
                        }}
                        onBlur={() => {
                            this.setState({
                                open: false,
                                searchTerm: null
                            });
                            resetForm({
                                gotoEntity: null
                            });
                        }}
                    />
                )}
                {!open && <Search side={side} size={20} onClick={this.onSearchClick} />}
            </Form>
        );
    };
    render() {
        const { searchTerm } = this.state;
        const { placeholder, query, resultKey, resolveUrl } = this.props;

        return (
            <Formik
                render={renderProps => {
                    return this.renderForm({
                        ...renderProps,
                        placeholder,
                        query,
                        resultKey
                    });
                }}
                initialValues={{ gotoEntity: searchTerm }}
                enableReinitialize={true}
                onSubmit={async (values, actions) => {
                    try {
                        const { gotoEntity } = values;
                        if (gotoEntity) {
                            const url = resolveUrl(gotoEntity);
                            if (url) {
                                const { history } = this.props;
                                history.push(url);
                            }
                        }
                        this.setState({
                            open: false,
                            searchTerm: null
                        });
                        actions.resetForm({
                            gotoEntity: null
                        });
                    } catch (ex) { }
                }}
            />
        );
    }
}

AppSearch.propTypes = {
    placeholder: PropTypes.string,
    // query: PropTypes.func.isRequired,
    resultKey: PropTypes.string.isRequired,
    resolveUrl: PropTypes.func.isRequired
};

export default withRouter(AppSearch);
