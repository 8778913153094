import React, { Fragment } from 'react';
import styled from 'styled-components';
import { darkBody } from '-/style/variables';

const StyledScreen = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: grey;
    opacity: 0.8;
    z-index: 4;
`;
const StyledMessageBox = styled.div`
    top: 200px;
    left: 50%;
    position: fixed;
    margin-left: -150px;
    background-color: white;
    padding: 20px;
    border: 1px solid ${darkBody};
    overflow: auto;
    z-index: 5;
`;
const FixedBody = styled.div`
    max-height: 185px;
    overflow: auto;
    padding: 2px;
`;

const Dialog = props => {
    const { children, width = 300, height = 300 } = props;

    return (
        <Fragment>
            <StyledScreen />
            <StyledMessageBox style={{ width }}>
                <FixedBody style={height}>{children}</FixedBody>
            </StyledMessageBox>
        </Fragment>
    );
};

export default Dialog;
