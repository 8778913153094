import React, { Fragment } from 'react';
import { keyedLPColumns } from '-/shared/constants';
import { StyledTh } from '-/style/base/Table';
import { startCase } from 'lodash';

const defaultWidth = 120;
const deprecatedColumns = ['materialsSentDate'];

export const getTh = opts => {
    const { name, key: baseKey, count, sticky, style: styleArg, ...rest } = opts;
    if (deprecatedColumns.includes(name)) {
        return null;
    }
    const { label, width: widthRaw } = keyedLPColumns[name] || {
        label: startCase(name)
    };
    const width = widthRaw || defaultWidth;
    const style = {
        ...styleArg,
        width
    };

    if (name === 'primaryContacts' && count > 1) {
        const ths = new Array(count).fill(1).map((num, idx) => {
            return (
                <StyledTh key={`${baseKey}-${idx}`} sticky={sticky} style={style} {...rest}>
                    {label} {idx + 1}
                </StyledTh>
            );
        });
        return (
            <Fragment key={baseKey}>
                {ths}
            </Fragment>
        )
    } else {
        return (
            <StyledTh key={baseKey} sticky={sticky} style={style} {...rest}>
                {label}
            </StyledTh>
        );
    }

};
