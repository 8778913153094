import { withProps } from 'recompose';
import { GET_FIRM_NOTES } from '-/gql/firm';

import FirmQuery from './FirmQuery';
import NoteListBase from '-/components/notes/List';

export default withProps({
    query: GET_FIRM_NOTES,
    component: withProps({
        showEntity: true,
        type: 'firm'
    })(NoteListBase)
})(FirmQuery);
