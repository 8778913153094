import React, { Component } from 'react';
import { pick, get } from 'lodash';
import { Formik, Form, Field } from 'formik';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { TextInput } from '-/components/inputs';

import { UPDATE_LP } from '-/gql/lp';
import Toolbar from './Toolbar';
const renderForm = props => {
    const { className, closeOverlay } = props;
    return (
        <Form className={className}>
            <div>
                <Field component={TextInput} label="Name" name="name" />
            </div>
            <Toolbar handleCancel={closeOverlay} />
        </Form>
    );
};
class BasicForm extends Component {
    render() {
        const {
            className,
            updateLP,
            _id,
            initialValues,
            history,
            closeOverlay
        } = this.props;

        return (
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={async (values, formBag) => {
                    try {
                        const input = pick(values, ['name']);

                        const result = await updateLP({
                            variables: {
                                _id,
                                input
                            }
                        });
                        const newpermalink = get(
                            result,
                            'data.updateLP.permalink'
                        );
                        closeOverlay();
                        history.push(`/lp/view/${newpermalink}`);
                    } catch (ex) {
                        console.log('Failed', ex);
                    }
                }}
                render={props => {
                    return renderForm({
                        ...props,
                        closeOverlay,
                        className
                    });
                }}
            />
        );
    }
}

export default compose(
    withRouter,
    graphql(UPDATE_LP, {
        name: 'updateLP'
    })
)(BasicForm);
