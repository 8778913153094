import React from 'react';
import styled from 'styled-components';
import { priorityColors } from '-/style/variables';

const StyledFitKeyIndicator = styled.div`
    float: right;
    font-size: 14px;
    font-weight: bold;
`;

const StyledPriorityBlock = styled.span`
    color: white;
    background-color: ${props => priorityColors[props.value]};
    padding: 3px;
    margin-right: 5px;
`;

const FitKeyIndicator = props => {
    const { priority, probability } = props;

    return (
        <StyledFitKeyIndicator>
            <StyledPriorityBlock value={priority}>
                P{priority}
            </StyledPriorityBlock>
            {probability}
        </StyledFitKeyIndicator>
    );
};

export default FitKeyIndicator;
