import React from 'react';
import { graphql } from 'react-apollo';
import { get } from 'lodash';
import styled from 'styled-components';

import Box from '-/style/base/Box';
import Subhead from '-/style/base/Subhead';
import SubSubHead from '-/style/base/SubSubHead';
import { GET_COMPANY_MONDAY_REPORT_WATCH_LIST } from '-/gql/company';
import LoadingLogo from '-/components/LoadingLogo';
import { StyledEmptyRow } from '-/style/base/Table';

import { groupByPrimaryThemePriority } from './utils';
import { WatchListRow } from './StandardRow';
import StandardTable from './StandardTable';
import { WatchListHeader } from './StandardHeader';

const Section = styled.div`
    margin-bottom: 15px;
`;
const HorizScroller = styled.div`
    overflow-x: scroll;
`;
const EmptyRow = <StyledEmptyRow colSpan={8}>No companies.</StyledEmptyRow>;

const WatchList = props => {
    const { data = {} } = props;
    const { loading } = data;
    const companies = get(data, 'CompanyMondayReport.watchList', []);
    const { focus = [], nonFocus = [], avoid = [] } = groupByPrimaryThemePriority(companies);
    if (loading) {
        return <LoadingLogo />;
    }
    return (
        <Box>
            <Subhead>Watch List</Subhead>
            <Section>
                <SubSubHead>Focus</SubSubHead>
                <HorizScroller>
                    <StandardTable
                        minWidth={1300}
                        data={focus}
                        Row={WatchListRow}
                        Header={WatchListHeader}
                        EmptyRow={EmptyRow}
                    />
                </HorizScroller>
            </Section>
            <Section>
                <SubSubHead>Non-Focus</SubSubHead>
                <HorizScroller>
                    <StandardTable
                        minWidth={1300}
                        data={nonFocus}
                        Row={WatchListRow}
                        Header={WatchListHeader}
                        EmptyRow={EmptyRow}
                    />
                </HorizScroller>
            </Section>
            <Section>
                <SubSubHead>Avoid</SubSubHead>
                <HorizScroller>
                    <StandardTable
                        minWidth={1300}
                        data={avoid}
                        Row={WatchListRow}
                        Header={WatchListHeader}
                        EmptyRow={EmptyRow}
                    />
                </HorizScroller>
            </Section>
        </Box>
    );
};

export default graphql(GET_COMPANY_MONDAY_REPORT_WATCH_LIST)(WatchList);
