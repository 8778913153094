import React, { Component, Fragment } from 'react';
import { compose, graphql } from 'react-apollo';
import { GET_ME } from '-/gql/user';
import { get } from 'lodash';
import Link from '-/style/base/Link';
import styled from 'styled-components';
import { refreshDarkBlue, refreshBaseBlue } from '-/style/variables';

const StyledMenu = styled.div`
    background-color: ${refreshDarkBlue};
    opacity: 0.9;
    width: 20rem;
    padding: 0.5rem 0;
`;
const StyledLink = styled(Link)`
    display: block;
    text-decoration: none;
    color: #fff;
    padding-left: 2rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    &:hover {
        background-color: ${refreshBaseBlue};
    }
`;
const StyledIdentedLink = styled(StyledLink)`
    margin-left: 1.5rem;
`;
const doLogout = async () => {
    await fetch('/api/auth/logout');
    window.location.href = '/login';
};
const LogoutLink = props => {
    return (
        <StyledLink as="a" onClick={doLogout}>
            Logout
        </StyledLink>
    );
};
const AdminMenuPart = props => {
    return (
        <Fragment>
            <StyledLink to="/users">Admin Area</StyledLink>
            {/* <StyledIdentedLink to="">Add Unlisted Company</StyledIdentedLink>*/}
            <StyledIdentedLink to="/arr/correct">Arr Correction</StyledIdentedLink>
            <StyledIdentedLink to="/users">User Management</StyledIdentedLink>
            <StyledIdentedLink to="/themes/manage">Theme Management</StyledIdentedLink>
            <StyledIdentedLink to="/firms">Firm Management</StyledIdentedLink>
            <StyledIdentedLink to="/waitlist">CB WaitList</StyledIdentedLink>
            <StyledIdentedLink to="/company/list">Headcount List</StyledIdentedLink>
            {/* <StyledIdentedLink to="">Import Tool</StyledIdentedLink>*/}
            {/* <StyledIdentedLink to="">Linking Tool</StyledIdentedLink>*/}
            <StyledIdentedLink to="/company/reports">
                Other Reports
            </StyledIdentedLink>
        </Fragment>
    );
};
const LPMenu = props => {
    const { isAdmin, className, style } = props;

    return (
        <StyledMenu className={className} style={style}>
            <StyledLink to="/lp">Main</StyledLink>
            <StyledLink to="/company">Go To Company</StyledLink>
            <StyledLink to="/lp/new">Add New LP Profile</StyledLink>
            <StyledLink to="/lp/report/new">All LPs</StyledLink>
            <StyledLink to="/lp/reports">All Reports</StyledLink>
            <StyledLink to="/settings">Settings</StyledLink>
            {isAdmin && <AdminMenuPart />}
            <LogoutLink />
        </StyledMenu>
    );
};
const CompanyMenu = props => {
    const { isAdmin = true, className, style } = props;
    return (
        <StyledMenu className={className} style={style}>
            <StyledLink to="/company">Main</StyledLink>
            <StyledLink to="/lp">Go To LP</StyledLink>
            <StyledLink to="/company/search">Advanced Search</StyledLink>
            <StyledLink to="/company/reports/monday/active">
                Monday Report
            </StyledLink>
            <StyledLink to="/themes">
                Themes
            </StyledLink>
            <StyledLink to="/settings">Settings</StyledLink>
            {isAdmin && <AdminMenuPart />}
            <LogoutLink />
        </StyledMenu>
    );
};

class AppMenu extends Component {
    render() {
        const { side, className, style } = this.props;
        const isAdmin = get(this.props, 'data.me.role') === 'Admin';
        if (side === 'lp') {
            return (
                <LPMenu className={className} style={style} isAdmin={isAdmin} />
            );
        } else {
            return (
                <CompanyMenu
                    className={className}
                    style={style}
                    isAdmin={isAdmin}
                />
            );
        }
    }
}

export default compose(
    graphql(GET_ME)
)(AppMenu);