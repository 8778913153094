import React, { Component } from 'react';
import {
    AutoComplete,
    RadioGroup,
    Textarea,
    Checkboxes
} from '-/components/inputs';
import RawBox from '-/style/base/Box';
import Subhead from '-/style/base/Subhead';
import LinkList from '-/style/base/LinkList';
import MainHeader from '-/style/base/MainHeader';
import { GreenButton } from '-/style/base/Button';
import { Field, Formik, Form } from 'formik';

import { Col, Container, Row } from '-/style/base/Layout';
import MainMenu from './MainMenu';
import { lpSideNoteTypes, materialsSentTypes } from '-/shared/constants';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { SEARCH_LP, ADD_NOTE_TO_LP, GET_ALL_LP_REPORTS } from '-/gql/lp';
import { GET_ME } from '-/gql/user';
import { removeTypename } from '-/gql/utils';
import { pick, get, groupBy } from 'lodash';
import styled from 'styled-components';
import SearchResults from '-/lp/view/reports/SearchResults';
import { LpReportNewLink, LpReportsLink } from '-/components/Link';
const Box = styled(RawBox)`
    margin-bottom: 12px;
`;
const StandardRow = props => {
    return (
        <Row
            gutter={20}
            justifyContent="space-between"
            alignItems="flex-start"
            {...props}
        />
    );
};

class Dashboard extends Component {
    onReportChange = value => {
        if (value) {
            const { history } = this.props;
            history.push(`/lp/report/view/${value}`);
        }
    };

    onLPChange = value => {
        if (value) {
            const { history } = this.props;
            history.push(`/lp/view/${value}`);
        }
    };
    renderForm = renderProps => {
        const { reportOpts, builtinReports } = renderProps;
        const materialsSelected =
            get(renderProps, 'values.type', '') === 'Materials';
        return (
            <Form>
                <Container>
                    <MainHeader title="LP Dashboard">
                        <MainMenu />
                    </MainHeader>
                    <Box padding={false}>
                        <StandardRow>
                            <Col flex={6} sm={12} xs={12}>
                                <Subhead>Find Institution</Subhead>
                                <Field
                                    component={AutoComplete}
                                    query={SEARCH_LP}
                                    resultKey="LPs"
                                    name="gotoLP"
                                    placeholder="Search for an LP..."
                                    onChange={this.onLPChange}
                                />
                            </Col>
                            <Col flex={6} sm={12} xs={12}>
                                <Subhead>Reports</Subhead>
                                <Field
                                    component={AutoComplete}
                                    options={reportOpts}
                                    name="gotoReport"
                                    placeholder="Select a report..."
                                    onChange={this.onReportChange}
                                />
                            </Col>
                        </StandardRow>
                    </Box>

                    <Box padding={false} style={{ fontSize: '12px' }}>
                        <StandardRow>
                            <Col flex={6} sm={12} xs={12}>
                                <Subhead>Quick Add Note</Subhead>
                                <Field
                                    component={RadioGroup}
                                    style={{ marginBottom: 15 }}
                                    name="type"
                                    options={lpSideNoteTypes}
                                />
                                {materialsSelected && (
                                    <Field
                                        component={Checkboxes}
                                        style={{ marginBottom: 15 }}
                                        options={materialsSentTypes}
                                        label="Type"
                                        name="materialsSent"
                                    />
                                )}
                                <Field
                                    component={AutoComplete}
                                    query={SEARCH_LP}
                                    resultKey="LPs"
                                    label="Find LP"
                                    name="permalink"
                                />
                            </Col>
                            <Col flex={6} sm={12} xs={12}>
                                <Field
                                    component={Textarea}
                                    inputStyle={{
                                        height: materialsSelected ? 192 : 110
                                    }}
                                    name="text"
                                    label="Note"
                                />
                                <div style={{ textAlign: 'right' }}>
                                    <GreenButton type="submit">
                                        Submit
                                    </GreenButton>
                                </div>
                            </Col>
                        </StandardRow>
                    </Box>

                    <Box>
                        <Row
                            gutter={4}
                            justifyContent="space-between"
                            alignItems="flex-end"
                        >
                            <Col flex={6}>
                                <Subhead>At a Glance</Subhead>
                            </Col>
                            <Col
                                flex={6}
                                style={{
                                    textAlign: 'right',
                                    marginBottom: 15
                                }}
                            >
                                <LinkList>
                                    <li>
                                        <LpReportNewLink>
                                            New Report
                                        </LpReportNewLink>
                                    </li>
                                    <li>
                                        <LpReportsLink>
                                            All Reports
                                        </LpReportsLink>
                                    </li>
                                </LinkList>
                            </Col>
                        </Row>

                        {builtinReports.true.map((report, i) => {
                            const { criteria = {}, sortBy } = report;
                            const notLast =
                                i !== builtinReports.true.length - 1;
                            return (
                                <div key={i}>
                                    <SearchResults
                                        criteria={criteria}
                                        sortBy={sortBy}
                                        limit={5}
                                        {...report}
                                    />
                                    {notLast && (
                                        <div
                                            style={{
                                                marginBottom: 20
                                            }}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </Box>
                </Container>
            </Form>
        );
    };

    render() {
        const { addNoteToLP, getReports } = this.props;
        const initials = get(this.props, 'data.me.initials');
        const savedReportsRaw = get(getReports, 'LPReports', []);
        const savedReports = removeTypename(savedReportsRaw);
        const builtinReports = groupBy(savedReports, 'builtin');
        builtinReports.true = builtinReports.true || [];
        builtinReports.false = builtinReports.false || [];
        const initialValues = {
            type: lpSideNoteTypes[0],
            text: initials ? `${initials} - ` : ''
        };
        const reportOpts = [];
        if (builtinReports.true.length) {
            reportOpts.push({
                label: 'Built-In',
                options: builtinReports.true.map(o => {
                    const { _id, permalink, name, builtin } = o;
                    return {
                        builtin,
                        value: permalink || _id,
                        label: name
                    };
                })
            });
        }
        if (builtinReports.false.length) {
            reportOpts.push({
                label: 'Saved',
                options: builtinReports.false.map(o => {
                    const { _id, permalink, name, builtin } = o;
                    return {
                        builtin,
                        value: permalink || _id,
                        label: name
                    };
                })
            });
        }

        return (
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={async (values, actions) => {
                    try {
                        const input = pick(values, ['type', 'text']);
                        if (values.type === 'Materials') {
                            input.materialsSent = values.materialsSent;
                        }
                        const { permalink } = values;
                        await addNoteToLP({
                            variables: {
                                permalink,
                                input
                            }
                        });
                        actions.resetForm({
                            ...initialValues,
                            permalink: null
                        });
                    } catch (ex) {}
                }}
                render={renderProps => {
                    return this.renderForm({
                        ...renderProps,
                        reportOpts,
                        builtinReports
                    });
                }}
            />
        );
    }
}

export default compose(
    withRouter,
    graphql(ADD_NOTE_TO_LP, {
        name: 'addNoteToLP'
    }),
    graphql(GET_ALL_LP_REPORTS, {
        name: 'getReports'
    }),
    graphql(GET_ME)
)(Dashboard);
