import React, { Fragment } from 'react';
import { Col } from '-/style/base/Layout';
import { Field } from 'formik';
import {
    PhoneInput,
    TextInput,
    ZipInput,
    AutoComplete
} from '-/components/inputs';
import { statesByAbbrev, regions } from '-/shared/constants';

import { Subhead, StandardRow } from '-/style/base/Layout';

const FirmInfo = props => {
    return (
        <Fragment>
            <Subhead>Firm Information</Subhead>

            <StandardRow>
                <Col flex={6} xs={12}>
                    <Field component={TextInput} label="Name" name="name" />
                </Col>
                <Col flex={5} xs={12}>
                    <Field
                        component={PhoneInput}
                        label="Main Office Phone"
                        name="phone"
                    />
                </Col>
            </StandardRow>
            <StandardRow>
                <Col flex={11} xs={12}>
                    <Field
                        component={TextInput}
                        label="Street Address"
                        name="address.street1"
                    />
                </Col>
            </StandardRow>
            <StandardRow>
                <Col flex={3} xs={6}>
                    <Field
                        component={TextInput}
                        label="City"
                        name="address.city"
                    />
                </Col>
                <Col flex={3} xs={6}>
                    <Field
                        component={AutoComplete}
                        options={statesByAbbrev}
                        label="State"
                        name="address.region"
                    />
                </Col>
                <Col flex={2} xs={6}>
                    <Field
                        component={ZipInput}
                        label="Zip"
                        name="address.postalCode"
                    />
                </Col>
                <Col flex={3} xs={6}>
                    <Field
                        component={AutoComplete}
                        options={regions}
                        label="Region"
                        name="address.area"
                    />
                </Col>
            </StandardRow>
        </Fragment>
    );
};

export default FirmInfo;
