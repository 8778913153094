import styled from 'styled-components';
import {
    outExpoTransition,
    baseGreen,
    lightGreen,
    baseBlue,
    lightBlue
} from './../variables';

export const BaseButton = styled.button`
    width: auto;
    padding: 0.5rem 1rem;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    font-weight: bold;
    @media (max-width: 60em) {
        font-size: 0.75rem;
    }
`;

export const GreenPrimaryButton = styled(BaseButton)`
    transition: background-color 1s ${outExpoTransition};
    background-color: ${baseGreen};
    color: white;
    &:hover {
        background-color: ${lightGreen};
    }
`;

// const StyledButton = styled.button`
//     color: ${baseGreen};
//     width: 100px;
//     background-color: white;

//     appearance: none;

//     padding: 5px;
// `;

export const GreenButton = styled(BaseButton)`
    transition: background-color 1s ${outExpoTransition};
    color: ${baseGreen};
    border: 1px solid ${baseGreen};
    background-color: white;
    &:hover {
        background-color: ${lightGreen};
        color: white;
    }
`;

export const BluePrimaryButton = styled(BaseButton)`
    transition: background-color 1s ${outExpoTransition};
    background-color: ${baseBlue};
    color: white;
    &:hover {
        background-color: ${lightBlue};
    }
`;

export const BlueButton = styled(BaseButton)`
    transition: background-color 1s ${outExpoTransition};
    &:disabled {
        transition: none;
        background-color: grey;
        &:hover {
            background-color: grey;
        }
    }
    background-color: ${baseBlue};
    color: white;
    &:hover {
        background-color: ${lightBlue};
    }

`;
