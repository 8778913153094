import { withProps } from 'recompose';
import { compose, graphql } from 'react-apollo';

import PersonQuery from './PersonQuery';
import { GET_PERSON_NOTES, REMOVE_NOTE_FOR_PERSON } from '-/gql/person';
import NoteListBase from '-/components/notes/List';
import NoteForm from './../../form/Note';

const NoteViewGql = compose(
    graphql(REMOVE_NOTE_FOR_PERSON, {
        name: 'removeNote'
    }),
    withProps({
        Form: NoteForm
    })
)(NoteListBase);

export default withProps({
    component: NoteViewGql,
    query: GET_PERSON_NOTES
})(PersonQuery);
