import React, { Fragment } from 'react';
import nl2br from '-/components/nl2br';
import { Link } from 'react-router-dom';
import { transformOptions } from '-/components/inputs/utils';
import { formatDate, formatMillion, formatPhone } from '-/utils/format';
import styled from 'styled-components';
import { castArray } from 'lodash';
import { darkBlue, baseGreen } from '-/style/variables';
import Box from '-/style/base/Box';
import { Row } from '-/style/base/Layout';
export const Name = styled.h3``;

export const StyledDisplayField = styled.div`
    margin-bottom: 8px;
    a {
        color: ${darkBlue};
        text-decoration: none;
    }
    a:hover {
        color: ${baseGreen};
    }
`;

const StyledSimpleLabel = styled.div`
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 3px;
    text-transform: uppercase;
`;
export const SimpleLabel = StyledSimpleLabel;
const StyledSimpleValue = styled.div`
    line-height: 18px;
    padding-bottom: 3px;
    font-weight: ${props => props.bold ? 'bold' : 'inherit'};
`;
export const SimpleValue = StyledSimpleValue;

export const SimpleDisplayField = props => {
    const { className, label, value } = props;
    return (
        <StyledDisplayField className={className}>
            {label && <StyledSimpleLabel>{label}:</StyledSimpleLabel>}
            <StyledSimpleValue>{value}</StyledSimpleValue>
        </StyledDisplayField>
    );
};

export const LongTextDisplayField = props => {
    const { className, label, value } = props;
    return (
        <StyledDisplayField className={className}>
            <StyledSimpleLabel>{label}:</StyledSimpleLabel>
            <StyledSimpleValue>{nl2br(value)}</StyledSimpleValue>
        </StyledDisplayField>
    );
};

export const PhoneDisplayValue = props => {
    const { children } = props;
    if (children && typeof children === 'string') {
        const num = formatPhone(children);
        return (
            <StyledSimpleValue>
                <a href={`tel:${num}`}>{num}</a>
            </StyledSimpleValue>
        );
    }
    return null;
};

export const EmailDisplayValue = props => {
    const { children } = props;
    if (children && typeof children === 'string') {
        return (
            <StyledSimpleValue>
                <a href={`mailto:${children}`}>{children}</a>
            </StyledSimpleValue>
        );
    }
    return null;
};

const ContactDisplayValue = props => {
    const { name, indicatePrimary, primary, title, company, email, phone } = props;
    const displayAsPrimary = indicatePrimary && primary;
    return (
        <Fragment>
            <StyledSimpleValue bold={displayAsPrimary}>{name}</StyledSimpleValue>
            <StyledSimpleValue>{title}</StyledSimpleValue>
            <StyledSimpleValue>{company}</StyledSimpleValue>
            <EmailDisplayValue>{email}</EmailDisplayValue>
            <PhoneDisplayValue>{phone}</PhoneDisplayValue>
        </Fragment>
    );
};
const StyledContactSpacer = styled.div`
    margin-bottom: 14px;
`;

export const ContactDisplayField = props => {
    const { indicatePrimary = false, className, label, value } = props;
    const contacts = castArray(value);
    const contactCount = contacts.length;
    return (
        <StyledDisplayField className={className}>
            <StyledSimpleLabel>{label}:</StyledSimpleLabel>
            {contacts.map((c, idx) => {
                const addSpacer = idx !== contactCount - 1;
                return (
                    <Fragment key={idx}>
                        <ContactDisplayValue indicatePrimary={indicatePrimary} {...c} />
                        {addSpacer ? <StyledContactSpacer /> : null}
                    </Fragment>
                );
            })}
        </StyledDisplayField>
    );
};

export const AddressDisplayField = props => {
    const { className, label, value, children } = props;
    const {
        street1 = '',
        street2 = '',
        city = '',
        region = '',
        postalCode = '',
        area = ''
    } = value || {};

    let line3 = '';
    if (city) {
        line3 += `${city},`;
    }
    if (region) {
        line3 += ` ${region}`;
    }
    if (postalCode) {
        line3 += ` ${postalCode}`;
    }
    return (
        <StyledDisplayField className={className}>
            {label && <StyledSimpleLabel>{label}:</StyledSimpleLabel>}
            {street1 ? <StyledSimpleValue>{street1}</StyledSimpleValue> : null}
            {street2 ? <StyledSimpleValue>{street2}</StyledSimpleValue> : null}
            {line3 ? <StyledSimpleValue>{line3}</StyledSimpleValue> : null}
            {area ? <StyledSimpleValue>{area}</StyledSimpleValue> : null}
            {children}
        </StyledDisplayField>
    );
};

export const MillionDisplayField = props => {
    const { value, ...restProps } = props;
    const formattedValue = formatMillion(value);
    return <SimpleDisplayField value={formattedValue} {...restProps} />;
};

export const DateDisplayField = props => {
    const { value, ...restProps } = props;
    const formattedValue = formatDate(value);
    return <SimpleDisplayField value={formattedValue} {...restProps} />;
};

// export const ActionItemDisplay = props => {
//     const { value = '' } = props;
//     return nl2br(value);
// };

export const OptionDisplayField = props => {
    const { options: optionsRaw, value: valueRaw, label } = props;
    const options = transformOptions(optionsRaw);
    const optValueToLabel = options.reduce((accumulated, o) => {
        const { value, label } = o;
        accumulated[value] = label;
        return accumulated;
    }, {});
    const value = optValueToLabel[valueRaw] || valueRaw;
    return <SimpleDisplayField value={value} label={label} />;
};

const StyledDateDisplay = styled.div`
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 5px;
`;

export const DateDisplay = props => {
    const { type, value, className, children } = props;

    if (value) {
        return (
            <StyledDateDisplay className={className}>
                <StyledSimpleLabel as="span">{type}:</StyledSimpleLabel>
                <StyledSimpleValue as="span" style={{ marginLeft: 5 }}>
                    {formatDate(value)}
                </StyledSimpleValue>
                {children}
            </StyledDateDisplay>
        );
    } else {
        return null;
    }
};

export const PersonLink = props => {
    const { _id, permalink, name } = props;
    const linkTo = _id || permalink;
    const url = `/person/view/${linkTo}`;
    return <Link to={url}>{name}</Link>;
};
export const PersonDisplay = props => {
    const { name } = props;
    return <span>{name}</span>;
};

const StyledSubduedValue = styled(StyledSimpleValue)`
    color: #707070;
`;
export const PersonDisplayValue = props => {
    const { value: valueRaw, showTitle = false, linkable = true } = props;
    const value = castArray(valueRaw);
    return (
        <Fragment>
            {value.map((person, idx) => {
                const { primaryTitle } = person || {};
                const Person = linkable ? PersonLink : PersonDisplay;
                return (
                    <StyledSimpleValue key={idx}>
                        <Person {...person} />
                        {showTitle && primaryTitle ? (
                            <StyledSubduedValue>
                                {primaryTitle}
                            </StyledSubduedValue>
                        ) : null}
                    </StyledSimpleValue>
                );
            })}
        </Fragment>
    );
};

export const PersonDisplayField = props => {
    const {
        className,
        label,
        value: valueRaw,
        showTitle = false,
        linkable = true
    } = props;
    const value = castArray(valueRaw);
    return (
        <StyledDisplayField className={className}>
            {label && <StyledSimpleLabel>{label}:</StyledSimpleLabel>}
            <PersonDisplayValue
                showTitle={showTitle}
                linkable={linkable}
                value={value}
            />
        </StyledDisplayField>
    );
};

export { default as ExitBar } from './ExitBar';

export const StandardRow = props => {
    return <Row gutter={4} justifyContent="space-between" {...props} />;
};
StandardRow.displayName = 'Row';

export const FixedBox = styled(Box)`
    height: 370px;
    overflow: hidden;
    padding-right: 10px;
    @media print {
        height: unset;
        overflow: none;
    }
`;
export const FixedBody = styled.div`
    height: 300px;
    padding-right: 10px;
    overflow: auto;
    @media print {
        height: unset;
        overflow: none;
    }
`;
