import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from '-/style/base/Layout';
import styled from 'styled-components';
import { SimpleLabel, SimpleValue, DateDisplay } from '-/components/profiles';
import OverlayWithState from '-/components/OverlayWithState';
import Headline from '-/style/base/Headline';
import LinkList from '-/style/base/LinkList';
import { LinkButton } from '-/style/base/Link';
import Divider from '-/style/base/Divider';

import BasicForm from './inlineForms/BasicForm';
import ActionItemForm from './inlineForms/ActionItemForm';
import { GET_LP_INTRO } from '-/gql/lp';
import LPQuery from './LPQuery';

const StyledIntro = styled.div`
    margin-top: 15px;
    padding: 8px;
`;

const StyledBasicForm = styled(BasicForm)`
    width: 200px;
    padding: 10px;
`;

const IntroView = props => {
    const {
        permalink,
        _id,
        name,
        actionItem,
        lastContactDate,
        nextContactDate,
        materialsSentDate
    } = props;

    return (
        <StyledIntro>
            <Row>
                <Col flex={6}>
                    <Headline>
                        <span>{name}</span>
                        <OverlayWithState
                            arrowSide="left"
                            vAttachment="top"
                            hAttachment="left"
                            vTargetAttachment="top"
                            hTargetAttachment="right"
                            closeOnEsc
                        >
                            <StyledBasicForm
                                _id={_id}
                                initialValues={{ name }}
                            />
                        </OverlayWithState>
                    </Headline>
                </Col>
                <Col
                    flex={6}
                    hideWhenPrint={true}
                    alignSelf="flex-end"
                    style={{ textAlign: 'right' }}
                >
                    <LinkList>
                        <li>
                            <Link to={`/lp/edit/${permalink}`}>
                                Edit Profile
                            </Link>
                        </li>
                        <li>
                            <LinkButton
                                onClick={() => {
                                    window.print();
                                }}
                            >
                                Print Profile
                            </LinkButton>
                        </li>
                    </LinkList>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col flex={6}>
                    <SimpleLabel as="span" style={{ marginRight: 5 }}>
                        Action Item:
                    </SimpleLabel>

                    <SimpleValue as="span">{actionItem}</SimpleValue>
                    <OverlayWithState
                        arrowSide="left"
                        vAttachment="top"
                        hAttachment="left"
                        vTargetAttachment="top"
                        hTargetAttachment="right"
                        closeOnEsc
                    >
                        <ActionItemForm
                            _id={_id}
                            initialValues={{
                                actionItem,
                                nextContactDate
                            }}
                        />
                    </OverlayWithState>
                </Col>
                <Col
                    flex={6}
                    alignSelf="flex-start"
                    style={{ textAlign: 'right' }}
                >
                    <DateDisplay type="Last Contact" value={lastContactDate} />
                    <DateDisplay type="Next Contact" value={nextContactDate} />
                    <DateDisplay
                        type="Materials Sent"
                        value={materialsSentDate}
                    />
                </Col>
            </Row>
        </StyledIntro>
    );
};

const Intro = props => {
    const { permalink } = props;
    return (
        <LPQuery
            query={GET_LP_INTRO}
            permalink={permalink}
            component={IntroView}
        />
    );
};

export default Intro;
