import React, { Fragment } from 'react';
import { Col } from '-/style/base/Layout';
import { Field } from 'formik';
import MillionsNumber from '-/components/inputs/MillionsNumber';
import {
    lpTypeOfFunds,
    lpStatuses,
    lpCheckRanges,
    lpPriorities,
    lpProbabilities
} from '-/shared/constants';
import { AutoComplete } from '-/components/inputs';
import { Subhead, StandardRow } from '-/style/base/Layout';

const IconFit = props => {
    return (
        <Fragment>
            <Subhead>Icon Fit</Subhead>
            <StandardRow>
                <Col flex={5} xs={12}>
                    <Field
                        component={AutoComplete}
                        label="Type of Fund"
                        name="iconFit.typeOfFund"
                        options={lpTypeOfFunds}
                    />
                </Col>
                <Col flex={3} xs={6}>
                    <Field
                        component={AutoComplete}
                        label="Status"
                        name="iconFit.status"
                        options={lpStatuses}
                    />
                </Col>
                <Col flex={3} xs={6}>
                    <Field
                        component={AutoComplete}
                        label="Check Range"
                        name="iconFit.checkRange"
                        options={lpCheckRanges}
                    />
                </Col>
            </StandardRow>
            <StandardRow>
                <Col flex={3} xs={6}>
                    <Field
                        component={AutoComplete}
                        label="Probability"
                        name="iconFit.probability"
                        options={lpProbabilities}
                    />
                </Col>
                <Col flex={2} xs={6}>
                    <Field
                        component={AutoComplete}
                        label="Priority"
                        name="iconFit.priority"
                        options={lpPriorities}
                    />
                </Col>
                <Col flex={3} xs={6}>
                    <Field
                        component={MillionsNumber}
                        label="Closed to Date"
                        name="iconFit.closedToDate"
                    />
                </Col>
                <Col flex={3} xs={6}>
                    <Field
                        component={MillionsNumber}
                        label="Future Close"
                        name="iconFit.futureClose"
                    />
                </Col>
            </StandardRow>
        </Fragment>
    );
};
export default IconFit;
