// TODO: Determine if we want to drop moment
import moment from 'moment';
import dayjs from 'dayjs';

import { find } from 'lodash';
export const formatDate = (date, emptyText = '') => {
    return date ? moment(date, 'MM/DD/YYYY').format('M.D.YY') : emptyText;
};

export const formatDateTime = (dateTime, emptyText = '') => {
    return dateTime ? dayjs(dateTime).format('M.D.YY h:mma') : emptyText;
};

// http://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn
export const formatNumber = number => {
    if (number === undefined || number === null) {
        return '';
    }
    const base = Math.floor(Math.log(Math.abs(number)) / Math.log(1000));
    const suffix = 'KMB'[base - 1];
    return suffix
        ? String(number / Math.pow(1000, base)).substring(0, 3) + suffix
        : '' + number;
};
export const formatMoney = number => {
    const num = formatNumber(number);
    return num ? `$${num}` : '';
};

export const abbreviateNumber = value => {
    let newValue = value;
    if (value >= 1000) {
        const suffixes = ['', 'k', 'm', 'b', 't'];
        const suffixNum = Math.floor(('' + value).length / 3);
        let shortValue = '';
        for (let precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat(
                (suffixNum !== 0
                    ? value / Math.pow(1000, suffixNum)
                    : value
                ).toPrecision(precision)
            );
            let dotLessShortValue = (shortValue + '').replace(
                /[^a-zA-Z 0-9]+/g,
                ''
            );
            if (dotLessShortValue.length <= 2) {
                break;
            }
        }
        if (shortValue % 1 !== 0) {
            shortValue = shortValue.toFixed(1);
        }
        newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
};

export const formatMillion = numberRaw => {
    if (numberRaw === undefined || numberRaw === null || isNaN(numberRaw)) {
        return '';
    }
    const number = parseFloat(numberRaw, 10);
    // intentionally checking if parsed value and raw value
    // match implicitly for csases where it's already been cast to a string.
    // eslint-disable-next-line
    if (number == numberRaw && isFinite(number)) {
        const hasFraction = number - Math.floor(number) > 0;
        return hasFraction ? `$${number.toFixed(1)}M` : `$${number}M`;
    } else {
        return number;
    }
};

const phoneRe = /^([0-9]{3})([0-9]{3})([0-9]{4})$/;
export const formatPhone = number => {
    if (typeof number === 'string') {
        const groups = phoneRe.exec(number);
        if (groups) {
            const [, part1, part2, part3] = groups;
            return `(${part1}) ${part2}-${part3}`;
        }
    }
    return number;
};

export const formatOption = (value, options) => {
    const opt = find(options, ['value', value]);
    return opt ? opt.label : value;
};


export const formatSeries = value => {
    if (typeof value === 'string' && value.length === 1) {
        return `Series ${value.toUpperCase()}`;
    }
    return value;
};

export const formatRound = round => {
    if (!round) {
        return '';
    }
    const { amount, series = '', date, valuation } = round;
    const seriesPart = formatSeries(series);
    const amountPart = amount ? formatMillion(amount) : '';
    let datePart = '';
    if (date) {
        const dateFormatted = moment.utc(date, 'MM/DD/YYYY').format('MMM-YY');
        datePart = `(${dateFormatted})`;
    }
    let postPart = '';
    if (valuation) {
        const valuationText = valuation ? formatMillion(valuation) : '';
        postPart = `at ${valuationText} Post`;
    }
    // $9.2M Series A (Feb-19) at $34M Post
    return [
        amountPart,
        seriesPart,
        datePart,
        postPart
    ].filter(Boolean).join(' ');
};