import React from 'react';
import { Row, Col } from '-/style/base/Layout';
import { Formik, Form, Field } from 'formik';
import { AutoComplete, TextInput, Checkboxes } from '-/components/inputs';
import { GreenButton } from '-/style/base/Button';
import { graphql } from 'react-apollo';
import { get } from 'lodash';
import { GET_ICON_POINT_USERS } from '-/gql/user';
import { GET_ALL_CATEGORIES } from '-/gql/theme';
import { compose } from 'recompose';
import Box from '-/style/base/Box';

const StandardRow = props => {
    return <Row justifyContent="space-between" {...props} />;
};
const StandardCol = props => {
    return <Col gutter={10} flex={6} xs={12} sm={6} style={{ marginBottom: 0 }} {...props} />;
};

const headCountRange = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' }
];
const HeadcountFilter = props => {
    const { onSubmit } = props;
    const iconPointPeople = get(props, 'userData.IconPointUser', []);
    const categoriesRaw = get(props, 'categoryData.ThemeCategories', []);
    if (iconPointPeople.length === 0 || categoriesRaw.length === 0) {
        return null;
    }
    const categories = categoriesRaw.map(c => {
        const { category } = c;
        return {
            label: category,
            value: category
        };
    });
    const themesMapByCategory = categoriesRaw.reduce((o, curr) => {
        const { category, themes: themesRaw } = curr;
        o[category] = themesRaw.map(t => {
            const { _id, name } = t;
            return {
                value: _id,
                label: name
            }
        });
        return o;
    }, {});
    const renderForm = props => {
        const {
            className,
            values,
            setFieldValue
        } = props;
        const category = get(values, 'category');
        const themes = themesMapByCategory[category] || [];

        return (
            <Form className={className}>
                <Box padding={10}>
                    <StandardRow>
                        <StandardCol>
                            <Field
                                component={TextInput}
                                label="By Company Name"
                                name="name"
                            />
                            <Field
                                label="By Point Person"
                                options={iconPointPeople}
                                name="iconPointPerson"
                                component={AutoComplete}
                                valueKey="_id"
                                labelKey="name"
                            />
                        </StandardCol>
                        <StandardCol>
                            <StandardRow>
                                <StandardCol gutter={0}>
                                    <Field
                                        label="Minimum Headcount"
                                        options={[
                                            { value: undefined, label: 'No Min' },
                                            ...headCountRange
                                        ]}
                                        name="minHeadCount"
                                        component={AutoComplete} />
                                </StandardCol>
                                <StandardCol style={{ margin: '0 0 0 5px' }}>
                                    <Field
                                        label="Maximum Headcount"
                                        options={[
                                            { value: undefined, label: 'No Max' },
                                            ...headCountRange
                                        ]}
                                        name="maxHeadCount"
                                        component={AutoComplete} />
                                </StandardCol>
                            </StandardRow>
                            <StandardRow>
                                <Field
                                    component={Checkboxes}
                                    options={[{
                                        label: '3mo',
                                        value: 3
                                    }, {
                                        label: '6mo',
                                        value: 6
                                    }, {
                                        label: '12mo',
                                        value: 12
                                    }]}
                                    label="Require Growth Data"
                                    name="growthRequired"
                                />
                            </StandardRow>
                        </StandardCol>
                    </StandardRow>
                    <StandardRow>
                        <StandardCol>
                            <Field
                                component={AutoComplete}
                                label="By Category"
                                name="category"
                                options={categories}
                                onChange={() => {
                                    setFieldValue('theme', null);
                                }}
                            />
                        </StandardCol>
                        <StandardCol>
                            <Field
                                component={AutoComplete}
                                label="By Theme"
                                name="theme"
                                options={themes}
                            />
                        </StandardCol>
                    </StandardRow>

                    <StandardRow>
                        <StandardCol />
                        <StandardCol>
                            <GreenButton type="submit" style={{ float: 'right' }}>Filter</GreenButton>
                        </StandardCol>
                    </StandardRow>
                </Box>
            </Form>
        );
    };
    return (
        <Formik
            render={renderForm}
            onSubmit={values => {
                onSubmit(values);
            }}
            initialValues={{
                name: ''
            }}
        />
    );
};
export default compose(
    graphql(GET_ICON_POINT_USERS, { name: 'userData' }),
    graphql(GET_ALL_CATEGORIES, { name: 'categoryData' })
)(HeadcountFilter);
