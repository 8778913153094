import React, { Fragment } from 'react';
import { GET_LP_SUMMARY } from '-/gql/lp';
import LPQuery from './LPQuery';
import nl2br from '-/components/nl2br';
const SummaryView = props => {
    const { summary } = props;
    return <Fragment>{nl2br(summary)}</Fragment>;
};

const Summary = props => {
    const { permalink } = props;
    return (
        <LPQuery
            query={GET_LP_SUMMARY}
            permalink={permalink}
            component={SummaryView}
        />
    );
};

export default Summary;
