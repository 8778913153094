import React from 'react';
import { Row, Col } from '-/style/base/Layout';
import { GreenButton as GreenButtonRaw } from '-/style/base/Button';
import styled from 'styled-components';
const GreenButton = styled(GreenButtonRaw)`
    margin: 0 0 0 10px;
`;
const Toolbar = props => {
    const { handleCancel, handleSubmit } = props;
    return (
        <Row center>
            <Col flex style={{ textAlign: 'right' }}>
                <GreenButton type="submit" onClick={handleSubmit}>
                    Save
                </GreenButton>
                <GreenButton type="button" onClick={handleCancel}>
                    Cancel
                </GreenButton>
            </Col>
        </Row>
    );
};

export default Toolbar;
