import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Checkbox = ({ disabled, onChange }) => (
    <Wrapper>
        <StyledLabel htmlFor='checkbox'>Enable WaitList:</StyledLabel>
        <StyledCheckbox type='checkbox' id='checkbox' checked={!disabled} onChange={onChange} />
        <StyledLabel htmlFor='checkbox'>{!disabled ? 'Yes' : 'No'}</StyledLabel>
    </Wrapper>
);

const Wrapper = styled.div`
    font-size: 20px;
    padding: 4px;
    margin: 4px;
`;

const StyledLabel = styled.label`
    margin: 4px;
    vertical-align: top;
`;

const StyledCheckbox = styled.input`
    height: 20px;
    width: 20px;
`;

Checkbox.propTypes = {
    disabled: PropTypes.bool
};

export default Checkbox;