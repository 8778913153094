import React, { Component, Fragment } from 'react';
import MainHeader from '-/style/base/MainHeader';
import { Container } from '-/style/base/Layout';
import { BlueButton, GreenPrimaryButton } from '-/style/base/Button';
import { Formik, Form, Field } from 'formik';
import { TextInput, Checkbox, AutoComplete } from '-/components/inputs';
import { withRouter } from 'react-router-dom';
import Box from '-/style/base/Box';
import { get, pick } from 'lodash';
import { compose, graphql } from 'react-apollo';
import { GET_USER, UPDATE_USER_SETTINGS, PURGE_USER_SESSIONS, ADD_USER } from '-/gql/user';
import { roles } from '-/shared/constants';
import Err from '-/style/base/Error';
import Alert from '-/style/base/Alert';
import PropTypes from 'prop-types';
import { SimpleDisplayField } from '-/components/profiles';
import { formatDateTime } from '-/utils/format';

const editableUserSettings = [
    'name',
    'email',
    'initials',
    'role',
    'isActive',
    'isPointPerson'
];
const WelcomeUser = props => {
    const { email, dismiss } = props;
    return (
        <Alert>
            <div>New User for Iconbase has been created.</div>
            <div>This user must also be created in Okta in order to use this account.</div>
            <SimpleDisplayField label="Email" value={email} />
            <button onClick={dismiss}>Dismiss</button>
        </Alert>
    );
};
WelcomeUser.propTypes = {
    _id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    dismiss: PropTypes.func.isRequired
};

class UserSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            ex: null,
            newUser: null
        };
    }
    dismissNewUser = () => {
        this.setState({
            newUser: null
        });
    };
    render() {
        const { hasError, ex, newUser } = this.state;
        let errorText;
        if (hasError && ex) {
            errorText = ex.message;
        }
        const { addUser, updateUserSettings, purgeUserSessions, history } = this.props;
        const _id = get(this.props, 'match.params._id');
        const meId = get(this.props, 'data.me._id');
        let initialValues, title, submitText, sessionCount, lastLoginDate, lastActiveDate;
        if (_id) {
            const current = get(this.props, 'data.User', {});
            initialValues = pick(current, editableUserSettings);
            ({ sessionCount = 0, lastLoginDate, lastActiveDate } = current);
            title = 'Editing User';
            submitText = 'Update Settings';
        } else {
            initialValues = {
                isActive: true
            };
            title = 'Creating User';
            submitText = 'Add User';
        }

        return (
            <Container>
                <MainHeader title={title} />
                {hasError && (
                    <Err>
                        <div>Error occurred when trying to save.</div>
                        {errorText && (
                            <div>{errorText}</div>
                        )}
                    </Err>
                )}
                <Box>
                    {newUser && (
                        <WelcomeUser
                            dismiss={this.dismissNewUser}
                            {...newUser}
                        />
                    )}
                    {!newUser && (
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}
                            onSubmit={async values => {
                                try {
                                    this.setState({
                                        hasError: false,
                                        ex: null
                                    });
                                    const input = pick(
                                        values,
                                        editableUserSettings
                                    );
                                    if (_id) {
                                        await updateUserSettings({
                                            variables: {
                                                _id,
                                                input
                                            }
                                        });
                                        history.push('/users');
                                    } else {
                                        const result = await addUser({
                                            variables: {
                                                input
                                            }
                                        });
                                        const data = get(
                                            result,
                                            'data.addUser'
                                        );
                                        const { email, password, _id } = data;
                                        this.setState({
                                            newUser: {
                                                _id,
                                                email,
                                                password
                                            }
                                        });
                                    }
                                } catch (ex) {
                                    // TODO: Get GraphQL error back.
                                    this.setState({
                                        hasError: true,
                                        ex
                                    });
                                }
                            }}
                            render={props => {
                                return (
                                    <Form>
                                        <Field
                                            component={TextInput}
                                            label="Name"
                                            name="name"
                                        />
                                        <Field
                                            component={TextInput}
                                            label="Email"
                                            name="email"
                                        />
                                        <Field
                                            component={TextInput}
                                            label="Initials"
                                            name="initials"
                                        />
                                        <Field
                                            component={AutoComplete}
                                            options={roles}
                                            label="Role"
                                            name="role"
                                        />
                                        <Field
                                            component={Checkbox}
                                            label="Account Active"
                                            name="isActive"
                                        />
                                        <Field
                                            component={Checkbox}
                                            label="Point Person"
                                            name="isPointPerson"
                                        />

                                        <GreenPrimaryButton type="submit">
                                            {submitText}
                                        </GreenPrimaryButton>
                                    </Form>
                                );
                            }}
                        />
                    )}
                </Box>
                {_id && (
                    <Fragment>
                        <MainHeader key="security" title="Security" />
                        <Box key="box">
                            <div>Has {sessionCount} established sessions.</div>
                            <div>Last logged in: {formatDateTime(lastLoginDate, 'Never')}.</div>
                            <div>Last active: {formatDateTime(lastActiveDate, 'Never')}.</div>
                            {meId !== _id ? (
                                <BlueButton disabled={sessionCount < 1} onClick={async () => {
                                    await purgeUserSessions({
                                        variables: {
                                            _id
                                        }
                                    });
                                }}>Purge All Sessions</BlueButton>
                            ) : null}
                        </Box>
                    </Fragment>
                )}
            </Container>
        );
    }
}

const ifEdit = props => {
    const hasId = get(props, 'match.params._id');
    return hasId;
};
const ifNew = props => {
    return !ifEdit(props);
};

export default compose(
    withRouter,
    graphql(GET_USER, {
        skip: ifNew,
        options: props => {
            const _id = get(props, 'match.params._id');
            return {
                variables: {
                    _id
                }
            };
        }
    }),
    graphql(UPDATE_USER_SETTINGS, {
        name: 'updateUserSettings',
        skip: ifEdit
    }),
    graphql(PURGE_USER_SESSIONS, {
        name: 'purgeUserSessions',
        skip: ifEdit
    }),
    graphql(ADD_USER, {
        name: 'addUser',
        options: {
            fetchPolicy: 'no-cache'
        },
        skip: ifEdit
    })
)(UserSettings);
