/**
 * Responsible for establishing routing, error boundaries and injecting any top level context
 * like apollo-graphql..
 */
import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import history from './history';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { withProps } from 'recompose';
import { ApolloProvider } from 'react-apollo';
import { get } from 'lodash';
import GlobalStyles from './style/Global';
import AppBar from './AppBar';
import MessageBox from './components/MessageBox';
import MondayReport from './company/view/reports/monday';
import ReportsHome from './company/view/reports';
import CompanyProfile from './company/Profile';
import CompanyMetrics from './company/Metrics';
import CompanySearchOld from './company/Search';
import CompanySearch from './company/Search2';
import Scratch from './scratch';
import Dashboard from './dashboard';
import StdDashboard from './dashboard/std';
import LpDashboard from './dashboard/lp';
import LPForm from './lp/form';
import LPList from './lp/view/List';
import LPProfile from './lp/view/Profile';
import LPReportList from './lp/view/reports/List';
import LPReportNew from './lp/view/reports/NewForm';
import FirmProfile from './firm/view/Profile';
import PersonProfile from './person/view/Profile';
import ErrorBoundary from './ErrorBoundary';
import MySettings from './user/MySettings';
import UserList from './user/List';
import UserForm from './user/Form';
import ThemeManager from './theme/Manager';
import ThemeStatic from './theme/Static';
import WaitList from './waitlist/List';
import FirmList from './firm/view/List';
import NotionList from './notion/List';
import CompanyHeadcountList from './company/view/List';
import CompanyHeadcountReport from './company/view/Headcount';
import ArrCorrection from './company/ArrCorrection';
import { GET_ME } from '-/gql/user';

const ThemeList = withProps({
    editable: false
})(ThemeManager);

const ExactRoute = withProps({
    exact: true
})(Route);

const ShowRoute = props => {
    const params = get(props, 'match');
    return <div>{JSON.stringify(params, null, 4)}</div>;
};

const MainContent = props => {
    return (
        <div>
            {/* Dashboard */}
            <ExactRoute path="/" component={Dashboard} />
            <ExactRoute path="/company" component={StdDashboard} />
            <ExactRoute path="/lp" component={LpDashboard} />

            {/* LP */}
            <ExactRoute path="/lps" component={LPList} />
            <ExactRoute path="/lp/new" component={LPForm} />
            <ExactRoute path="/lp/edit/:permalink" component={LPForm} />
            <ExactRoute path="/lp/view/:permalink" component={LPProfile} />
            <ExactRoute
                path="/lp/report/view/:permalink"
                component={LPReportNew}
            />
            <ExactRoute path="/lp/report/new" component={LPReportNew} />
            <ExactRoute path="/lp/reports" component={LPReportList} />

            {/* Company */}
            <ExactRoute
                path="/company/view/:permalink"
                component={CompanyProfile}
            />
            <ExactRoute
                path="/company/view/metrics/:permalink"
                component={CompanyMetrics}
            />

            <Route path="/company/reports/monday" component={MondayReport} />
            <ExactRoute path="/company/reports" component={ReportsHome} />
            <ExactRoute path="/company/unlisted" component={ShowRoute} />
            <ExactRoute path="/company/search" component={CompanySearch} />
            <ExactRoute path="/company/search2" component={CompanySearchOld} />
            <ExactRoute path="/company/list" component={CompanyHeadcountList} />
            <ExactRoute path="/company/headcount" component={CompanyHeadcountReport} />

            {/* Firms */}
            <ExactRoute path="/firm/view/:permalink" component={FirmProfile} />
            <ExactRoute path="/firm/new" component={ShowRoute} />

            {/* Person */}
            <ExactRoute
                path="/person/view/:permalink"
                component={PersonProfile}
            />
            <ExactRoute path="/person/new" component={ShowRoute} />

            {/* Other */}
            <ExactRoute path="/scratch" component={Scratch} />
            <ExactRoute path="/health" component={ShowRoute} />
            <ExactRoute path="/users" component={UserList} />
            <ExactRoute path="/user/edit/:_id" component={UserForm} />
            <ExactRoute path="/user/new" component={UserForm} />
            <ExactRoute path="/settings" component={MySettings} />
            <ExactRoute path="/themes" component={ThemeList} />
            <ExactRoute path="/themes/manage" component={ThemeManager} />
            <ExactRoute path="/themes/view/:permalink" component={ThemeStatic} />
            <ExactRoute path="/waitlist" component={WaitList} />
            <ExactRoute path="/firms" component={FirmList} />
            <ExactRoute path="/notionreports" component={NotionList} />
            <ExactRoute path="/arr/correct" component={ArrCorrection} />

        </div>
    );
};

const link = ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.map(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
            );
        if (networkError) {
            const { statusCode } = networkError;
            if (statusCode === 403) {
                window.location.href = '/api/login';
            }
            console.log(`[Network error]: ${networkError}`);
        }
    }),
    new HttpLink({
        uri: '/graphql',
        credentials: 'same-origin'
    })
]);
const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    connectToDevTools: true
});

class App extends Component {
    async componentDidMount() {
        try {
            await client.query({
                query: GET_ME
            });
        } catch (ex) { }
    }
    render() {
        return (
            <ApolloProvider client={client}>
                <GlobalStyles />
                <Router history={history}>
                    <ErrorBoundary>
                        <AppBar />
                        <MainContent />
                        <MessageBox />
                    </ErrorBoundary>
                </Router>
            </ApolloProvider>
        );
    }
}

export default App;
