import React from 'react';
import SimpleTable from '-/components/table/Simple';

import Row from './StandardRow';
import Header from './StandardHeader';
import { defaultProps } from 'recompose';
import { StyledEmptyRow } from '-/style/base/Table';
const EmptyRow = <StyledEmptyRow colSpan={7}>No companies.</StyledEmptyRow>;
export default defaultProps({
    Row,
    Header,
    EmptyRow
})(SimpleTable);
