import React from 'react';
import { Link } from 'react-router-dom';
import LinkList from '-/style/base/LinkList';

const MainMenu = props => {
    return (
        <LinkList>
            <li>
                <Link to="/lp/new">Add Institution</Link>
            </li>
            <li>
                <Link to="/lp/reports">Customize Reports</Link>
            </li>
        </LinkList>
    );
};

export default MainMenu;
