import React from 'react';
import FirmQuery from './FirmQuery';
import { withProps } from 'recompose';
import { GET_FIRM_COMPANIES } from '-/gql/firm';
import nl2br from '-/components/nl2br';
import { formatDate } from '-/utils/format';
import { CompanyLink } from '-/components/Link';
import {
    StyledTable,
    StyledTr,
    StyledTd,
    StyledEmptyRow
} from '-/style/base/Table';
import styled from 'styled-components';
import KnownIcon from '-/style/base/KnownIcon';
const StyledIndicators = styled.div`
    display: inline-block;
    float: left;
    width: 60px;
`;
const CompanyRow = props => {
    const {
        _id,
        index,
        iconCategory,
        iconStatus,
        name,
        description,
        lastContactDate,
        nextContactDate
    } = props;
    return (
        <StyledTr index={index}>
            <StyledTd>
                <StyledIndicators>
                    <KnownIcon width={16} height={16} type={iconCategory} />
                    <KnownIcon width={16} height={16} type={iconStatus} />
                </StyledIndicators>
            </StyledTd>
            <StyledTd>
                <CompanyLink permalink={_id}>{name}</CompanyLink>
                <div>{nl2br(description)}</div>
            </StyledTd>
            <StyledTd>
                Last Meeting: {formatDate(lastContactDate) || 'n/a'}
            </StyledTd>
            <StyledTd>
                Next Contact: {formatDate(nextContactDate) || 'n/a'}
            </StyledTd>
        </StyledTr>
    );
};
const Companies = props => {
    const { className, style, companies } = props;
    const isEmpty = companies.length === 0;
    return (
        <div className={className} style={style}>
            <StyledTable>
                <thead style={{ visibility: 'hidden' }}>
                    <tr>
                        <th style={{ width: 60 }} />
                        <th />
                        <th style={{ width: 200 }} />
                        <th style={{ width: 200 }} />
                    </tr>
                </thead>
                <tbody>
                    {isEmpty ? (
                        <StyledEmptyRow colSpan={4}>
                            No companies found.
                        </StyledEmptyRow>
                    ) : null}
                    {companies.map((o, index) => {
                        const { _id } = o;
                        return <CompanyRow key={_id} index={index} {...o} />;
                    })}
                </tbody>
            </StyledTable>
        </div>
    );
};

export default withProps({
    component: Companies,
    query: GET_FIRM_COMPANIES
})(FirmQuery);
