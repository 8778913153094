import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pick, has, get } from 'lodash';
import { StandardRow, Col } from '-/style/base/Layout';
import { Formik, Form, Field } from 'formik';
import {
    AutoComplete,
    Textarea,
    SimpleDate,
    Checkboxes,
    MillionsNumber
} from '-/components/inputs';
import Toolbar from '../profiles/Toolbar';
import { graphql } from 'react-apollo';
import { GET_ME } from '-/gql/user';
import { materialsSentTypes } from '-/shared/constants';

const renderForm = props => {
    const { className, noteTypes, closeOverlay, type } = props;
    const materialsSelected = get(props, 'values.type', '') === 'Materials';
    const isCompanyNote = type === 'company';
    return (
        <Form className={className} style={{ width: 140 * 3 }}>
            <StandardRow>
                <Col flex>
                    <Field
                        component={AutoComplete}
                        options={noteTypes}
                        isClearable={false}
                        label="Type"
                        name="type"
                    />
                </Col>
                <Col flex>
                    <Field
                        component={SimpleDate}
                        label="Date"
                        name="date" />
                </Col>
            </StandardRow>
            {materialsSelected && (
                <StandardRow>
                    <Col flex>
                        <Field
                            component={Checkboxes}
                            options={materialsSentTypes}
                            label="Type"
                            name="materialsSent"
                            style={{ width: 140 * 3 }}
                        />
                    </Col>
                </StandardRow>
            )}
            <StandardRow>
                <Col flex={12}>
                    <Field
                        component={Textarea}
                        label="Note"
                        name="text" />
                </Col>
            </StandardRow>
            {isCompanyNote && <StandardRow>
                <Col flex={12}>
                    <Field
                        component={MillionsNumber}
                        label="Current ARR"
                        name="arr" />
                </Col>
            </StandardRow>}
            <Toolbar handleCancel={closeOverlay} />
        </Form>
    );
};
class NoteForm extends Component {
    render() {
        const {
            className,
            addNote,
            updateNote,
            noteTypes,
            type,
            afterSubmit,
            _id,
            initialValues = {},
            closeOverlay
        } = this.props;

        if (!has(initialValues, 'text')) {
            const initials = get(this.props, 'data.me.initials');
            const text = initials ? `${initials} - ` : '';
            initialValues.text = text;
        }

        return (
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={async (values, formBag) => {
                    try {
                        const input = pick(values, [
                            'type',
                            'date',
                            'text',
                            'arr'
                        ]);
                        if (values.type === 'Materials') {
                            input.materialsSent = values.materialsSent;
                        }
                        const operation = _id ? 'update' : 'add';
                        let result;
                        if (_id) {
                            result = await updateNote({
                                variables: {
                                    _id,
                                    input
                                }
                            });
                        } else {
                            result = await addNote({
                                variables: {
                                    input
                                }
                            });
                        }
                        if (afterSubmit) {
                            afterSubmit({
                                operation,
                                ...result
                            });
                        }
                        closeOverlay();
                    } catch (ex) {
                        console.log('Failed', ex);
                    }
                }}
                render={props => {
                    return renderForm({
                        ...props,
                        className,
                        noteTypes,
                        closeOverlay,
                        type
                    });
                }}
            />
        );
    }
}

NoteForm.propTypes = {
    _id: PropTypes.string,
    entityId: PropTypes.string,
    noteTypes: PropTypes.array,
    initialValues: PropTypes.shape({
        date: PropTypes.any,
        type: PropTypes.string,
        text: PropTypes.string,
        materialsSent: PropTypes.array,
        arr: PropTypes.string
    }),
    addNote: PropTypes.func.isRequired,
    updateNote: PropTypes.func.isRequired,
    afterSubmit: PropTypes.func,
    type: PropTypes.oneOf(['company', 'person', 'firm', 'lp'])
};

export default graphql(GET_ME)(NoteForm);
