import { withProps } from 'recompose';
import { compose, graphql } from 'react-apollo';
import { GET_COMPANY_NOTES, REMOVE_NOTE_FOR_COMPANY } from '-/gql/company';

import CompanyQuery from './CompanyQuery';
import NoteListBase from '-/components/notes/List';
import NoteForm from './../../form/Note';

const NoteViewGql = compose(
    graphql(REMOVE_NOTE_FOR_COMPANY, {
        name: 'removeNote'
    }),
    withProps({
        Form: NoteForm
    })
)(NoteListBase);

export default withProps({
    query: GET_COMPANY_NOTES,
    component: NoteViewGql
})(CompanyQuery);
