import React, { Fragment } from 'react';
import { get } from 'lodash';

import { graphql, compose } from 'react-apollo';
import LinkList from '-/style/base/LinkList';
import { LinkButton } from '-/style/base/Link';
import MainHeader from '-/style/base/MainHeader';

import { Container } from '-/style/base/Layout';
import Box from '-/style/base/Box';
import { CompanyLink } from '-/components/Link';
import { Field, FieldArray, Formik, Form } from 'formik';
import styled from 'styled-components';
import { GET_ARR_NOTE_CORRECTION, UPDATE_COMPANY_BULK_NOTES } from '-/gql/company';
import {
    MillionsNumber,
    InlineCheckbox
} from '-/components/inputs';
import {
    StyledTable,
    StyledTheadTr,
    StyledTh,
    StyledTr,
    StyledTd
} from '-/style/base/Table';

import moment from 'moment-timezone';
import Toolbar from '-/components/profiles/Toolbar';
const submitForm = (path, params) => {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', path);

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            const value = params[key];
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            hiddenField.setAttribute('value', typeof value === 'string' ? value : JSON.stringify(value));
            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
};

// Ref: https://github.com/apollographql/apollo-client/issues/2160
const removeTypename = value => {
    if (value === null || value === undefined) {
        return value;
    } else if (Array.isArray(value)) {
        return value.map(v => removeTypename(v));
    } else if (typeof value === 'object') {
        const newObj = {};
        Object.entries(value).forEach(([key, v]) => {
            if (key !== '__typename') {
                newObj[key] = removeTypename(v);
            }
        });
        return newObj;
    }
    return value;
};

const StyledCompany = styled.div`
    // line-height: 30px;
    // a {
    //     vertical-align: middle;
    // }
    
`;
const ArrHeaderRow = props => {
    const defaultStyle = {
        width: 180,
        backgroundColor: '#EFEFEF',
        borderBottom: '1px solid #CACACB'
    };
    return (
        <thead>
            <StyledTheadTr>
                <StyledTh sticky style={defaultStyle}>
                    Company
                </StyledTh>
                <StyledTh style={defaultStyle}>Icon Category</StyledTh>
                <StyledTh style={defaultStyle}>Icon Status</StyledTh>
                <StyledTh style={defaultStyle}>Point</StyledTh>
                <StyledTh style={defaultStyle}>ARR</StyledTh>
            </StyledTheadTr>
        </thead>
    );
};

const NoteRow = props => {
    const {
        index,
        entity: {
            _id: permalink,
            name,
            description,
            iconCategory,
            iconStatus,
            iconPointPeople = []
        },
        text,
        date
    } = props;

    return (
        <Fragment>
            <StyledTr index={index}>
                <StyledTd index={index}>
                    <StyledCompany>
                        <CompanyLink permalink={permalink}>{name}</CompanyLink>
                    </StyledCompany>
                </StyledTd>
                <StyledTd>{iconCategory}</StyledTd>
                <StyledTd>{iconStatus}</StyledTd>
                <StyledTd colSpan={2}>
                    {iconPointPeople.map(u => u.initials).join('/')}
                </StyledTd>

            </StyledTr>
            <StyledTr index={index}>
                <StyledTd colSpan={4}>
                    {description}
                </StyledTd>
                <StyledTd>
                    <Field
                        component={InlineCheckbox}
                        label="Note is not about ARR"
                        name={`list.${index}.isNotArr`} />
                </StyledTd>
            </StyledTr>
            <StyledTr index={index}>
                <StyledTd colSpan={4}>{date} - {text}</StyledTd>
                <StyledTd>
                    <Field
                        component={MillionsNumber}
                        label="Current ARR"
                        name={`list.${index}.arr`} />
                </StyledTd>
            </StyledTr>
        </Fragment>
    );
};

const renderList = props => {
    const { values, loading, error } = props;
    if (loading) {
        return (
            <tr>
                <td colSpan={4}>Loading...</td>
            </tr>
        );
    } else if (error) {
        return (
            <tr>
                <td colSpan={4}>Failure</td>
            </tr>
        );
    } else if (values && values.length) {
        return values.map((note, idx) => {
            return <NoteRow key={note._id} index={idx} {...note} />;
        });
    }
    return null;
};

const renderForm = props => {
    const {
        error,
        loading,
        values: { list = [] }
    } = props;
    if (error) {
        return <span>Error in retrieving Pending Arr Notes.</span>;
    } else if (loading) {
        return <span>Loading...</span>;
    } else {
        return (
            <Form>
                <Container fluid={true}>
                    <StyledTable>
                        <ArrHeaderRow />
                        <tbody>
                            <FieldArray
                                name="list"
                                render={arrayHelpers => {

                                    if (list.length) {
                                        return list.map((note, idx) => {
                                            return <NoteRow key={note._id} index={idx} {...note} />;
                                        });
                                    } else {
                                        return null;
                                    }
                                }} />
                            {renderList(props)}</tbody>
                    </StyledTable>
                    <Toolbar />
                </Container>
            </Form>
        );
    }
};



const ArrCorrection = props => {
    const { data = {}, error, loading, updateCompanyBulkNotes } = props;
    const ArrNotes = get(data, 'ArrNotes', []);
    return (
        <Container>
            <MainHeader title="Arr Correction Tool">
                <LinkList>
                    <li>
                        <LinkButton
                            onClick={() => {
                                const tz = moment.tz.guess();
                                submitForm('/api/excel/arrNotes', {
                                    tz,
                                    format: 'xlsx'
                                });
                            }}
                        >
                            Download XLSX
                        </LinkButton>
                    </li>
                </LinkList>
            </MainHeader>
            <Box>
                <Formik
                    initialValues={{ list: removeTypename(ArrNotes) }}
                    enableReinitialize={true}
                    render={props => {
                        return renderForm({
                            data,
                            loading,
                            error,
                            ...props
                        })
                    }}
                    onSubmit={async (values, formBag) => {
                        const toSubmit = values.list.filter(item => {
                            const { isNotArr, arr = '' } = item;
                            const strippedArr = arr.replace(/[^0-9.]/g, '');
                            const notArrCondition = isNotArr === true;
                            const arrFieldCondition = strippedArr !== '';
                            return (notArrCondition || arrFieldCondition) && !(notArrCondition && arrFieldCondition);
                        }).map(item => {
                            const { _id, arr, isNotArr } = item;
                            return { _id, arr, isNotArr };
                        });
                        if (toSubmit.length) {
                            await updateCompanyBulkNotes({
                                variables: {
                                    input: toSubmit
                                },
                                refetchQueries: [{
                                    query: GET_ARR_NOTE_CORRECTION
                                }]
                            });
                            document.documentElement.scrollTop = 0
                        }
                    }}
                />
            </Box>
        </Container>
    );
};

export default compose(
    graphql(GET_ARR_NOTE_CORRECTION),
    graphql(UPDATE_COMPANY_BULK_NOTES, {
        name: 'updateCompanyBulkNotes'
    })
)(ArrCorrection);
