import React, { Component } from 'react';
import FirmQuery from './FirmQuery';
import Headline from '-/style/base/Headline';
import { withProps } from 'recompose';
import { GET_FIRM_INTRO } from '-/gql/firm';
import { Row, Col } from '-/style/base/Layout';
import styled from 'styled-components';
import { LinkButton } from '-/style/base/Link';
import LinkList from '-/style/base/LinkList';
import OverlayWithState from '-/components/OverlayWithState';
import BasicForm from './../../form/Basic';

const StyledIntro = styled.div`
    margin-top: 15px;
    padding: 8px;
`;

class Intro extends Component {
    enterEditMode = () => {
        const { enterEditMode } = this.props;
        if (enterEditMode) {
            enterEditMode();
        }
    };

    render() {
        const {
            inEditMode,
            className,
            _id,
            style,
            name
        } = this.props;
        return (
            <StyledIntro className={className} style={style}>
                <Row>
                    <Col flex={6}>
                        <Headline>
                            <span>{name}</span>
                            {/* QuickEdit Mode buttons go away when in standard profile editing... */}
                            {!inEditMode && (
                                <OverlayWithState
                                    arrowSide="left"
                                    vAttachment="top"
                                    hAttachment="left"
                                    vTargetAttachment="top"
                                    hTargetAttachment="right"
                                    closeOnEsc
                                >
                                    <BasicForm
                                        _id={_id}
                                        initialValues={{
                                            name
                                        }}
                                    />
                                </OverlayWithState>
                            )}
                        </Headline>
                    </Col>
                    <Col
                        flex={6}
                        hideWhenPrint={true}
                        alignSelf="flex-end"
                        style={{ textAlign: 'right' }}
                    >
                        {!inEditMode && (
                            <LinkList>
                                <li>
                                    <LinkButton onClick={this.enterEditMode}>
                                        Edit Profile
                                    </LinkButton>
                                </li>
                                <li>
                                    <LinkButton
                                        onClick={() => {
                                            window.print();
                                        }}
                                    >
                                        Print Profile
                                    </LinkButton>
                                </li>
                            </LinkList>
                        )}
                    </Col>
                </Row>
            </StyledIntro>
        );
    }
}

export default withProps({
    component: Intro,
    query: GET_FIRM_INTRO
})(FirmQuery);
