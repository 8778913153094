import styled from 'styled-components';
import { borderBox } from './../variables';
import { media } from '-/style/utils';
export default styled.div`
    border: 1px solid ${borderBox};
    background-color: #fff;
    ${media.xs`padding: 10px`}
    padding: ${props => {
        const { padding } = props;
        if (typeof padding === 'number') {
            return `${padding}px;`;
        } else if (padding === false) {
            return 'padding: 0;';
        } else {
            return '20px;';
        }
    }};
`;
