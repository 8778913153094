import React, { Component } from 'react';
import { Container, Row, Col } from '-/style/base/Layout';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
import { lightSand } from '-/style/variables';
import LinkList from '-/style/base/LinkList';
import { LinkButton } from '-/style/base/Link';
import MainHeader from '-/style/base/MainHeader';
import BoxRaw from '-/style/base/Box';
import { AutoComplete, TextInput, InlineCheckbox, SimpleDate } from '-/components/inputs';
import SearchResults from './SearchResults2';
import {
    iconCategories,
    turnDownRationale,
    nextContact,
    iconStatuses
} from '-/shared/constants';
import { GreenButton } from '-/style/base/Button';
import { graphql } from 'react-apollo';
import { get } from 'lodash';
import { GET_ICON_POINT_USERS } from '-/gql/user';
import { GET_ALL_CATEGORIES } from '-/gql/theme';
import { compose } from 'recompose';
import moment from 'moment-timezone';


const submitForm = (path, params) => {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', path);

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            const value = params[key];
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            hiddenField.setAttribute('value', typeof value === 'string' ? value : JSON.stringify(value));
            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
};

const StandardRow = props => {
    return <Row justifyContent="space-between" {...props} />;
};
const StandardCol = props => {
    return <Col gutter={10} flex={6} xs={12} sm={6} style={{ marginBottom: 0 }} {...props} />;
};
const Box = styled(BoxRaw)`
    margin-bottom: 20px;
`;

const renderForm = props => {
    const {
        iconPointPeople,
        themesMapByCategory,
        categories,
        className,
        values,
        setFieldValue
    } = props;
    const financingFilterEnabled = get(values, 'criteria.financingFilter.enabled', false);
    const category = get(values, 'criteria.category');
    const themes = themesMapByCategory[category] || [];

    return (
        <Form className={className}>
            <StandardRow>
                <StandardCol>
                    <Field
                        component={TextInput}
                        label="By Name"
                        name="criteria.name"
                    />
                    <Field
                        component={AutoComplete}
                        multiple={true}
                        options={iconCategories}
                        label="By Icon Category"
                        name="criteria.iconCategory"
                    />
                    <Field
                        component={AutoComplete}
                        options={turnDownRationale}
                        label="By Turndown Rationale"
                        name="criteria.turnDownRationale"
                        multiple={true}
                    />
                </StandardCol>
                <StandardCol>
                    <Field
                        component={AutoComplete}
                        options={nextContact}
                        label="By Next Contact"
                        name="criteria.nextContactDate"
                    />
                    <Field
                        component={AutoComplete}
                        multiple={true}
                        options={iconStatuses}
                        label="By Icon Status"
                        name="criteria.iconStatus"
                    />
                    <StandardRow>
                        <StandardCol style={{ margin: 0 }}>
                            <Field
                                component={AutoComplete}
                                label="By Category"
                                name="criteria.category"
                                options={categories}
                                onChange={() => {
                                    setFieldValue('criteria.theme', null);
                                }}
                            />
                        </StandardCol>
                        <Col flex={6} xs={12} sm={6}>
                            <Field
                                component={AutoComplete}
                                label="By Theme"
                                name="criteria.theme"
                                options={themes}
                            />
                        </Col>
                    </StandardRow>
                </StandardCol>
            </StandardRow>
            <StandardRow style={{ backgroundColor: lightSand }}>
                <StandardCol>
                    <Field
                        label="By Financing"
                        name="criteria.financingFilter.enabled"
                        component={InlineCheckbox} />
                </StandardCol>
            </StandardRow>

            {financingFilterEnabled ? (
                <StandardRow style={{ backgroundColor: lightSand }}>
                    <StandardCol>
                        <Field
                            label="From Date"
                            name="criteria.financingFilter.fromDate"
                            component={SimpleDate}
                        />
                        <Field
                            label="To Date"
                            name="criteria.financingFilter.toDate"
                            component={SimpleDate}
                        />
                    </StandardCol>
                    <StandardCol>
                        <Field
                            label="Round Series"
                            name="criteria.financingFilter.series"
                            multiple={true}
                            options={{
                                Seed: 'Seed',
                                A: 'A',
                                B: 'B',
                                C: 'C'
                            }}
                            component={AutoComplete} />
                        <div style={{ marginTop: 25 }}>
                            <Field
                                label="Last Round Only"
                                name="criteria.financingFilter.lastRoundOnly"

                                component={InlineCheckbox} />
                        </div>


                    </StandardCol>
                </StandardRow>
            ) : null}


            <StandardRow>
                <StandardCol>
                    <Field
                        label="By Point Person"
                        options={iconPointPeople}
                        name="criteria.iconPointPerson"
                        component={AutoComplete}
                        valueKey="_id"
                        labelKey="name"
                    />
                </StandardCol>
                <StandardCol>
                    <div style={{ textAlign: 'center', paddingTop: 19 }}>
                        <GreenButton type="submit">Generate Report</GreenButton>
                    </div>
                </StandardCol>
            </StandardRow>
        </Form>
    );
};
class ReportWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            criteria: {
                financingFilter: {
                    enabled: false,
                    lastRoundOnly: false
                }
            }
        };
    }

    render() {
        const { criteria } = this.state;
        const iconPointPeople = get(this.props, 'userData.IconPointUser', []);
        const categoriesRaw = get(this.props, 'categoryData.ThemeCategories', []);
        if (iconPointPeople.length === 0 || categoriesRaw.length === 0) {
            return null;
        }
        const categories = categoriesRaw.map(c => {
            const { category } = c;
            return {
                label: category,
                value: category
            };
        });
        const themesMapByCategory = categoriesRaw.reduce((o, curr) => {
            const { category, themes: themesRaw } = curr;
            o[category] = themesRaw.map(t => {
                const { _id, name } = t;
                return {
                    value: _id,
                    label: name
                }
            });
            return o;
        }, {});

        return (
            <Container>
                <MainHeader title="Advanced Search" />
                <Box>
                    <Formik
                        initialValues={{
                            criteria
                        }}
                        enableReinitialize={true}
                        render={renderProps => {
                            return renderForm({
                                ...renderProps,
                                iconPointPeople,
                                themesMapByCategory,
                                categories
                            });
                        }}
                        onSubmit={async (values, formBag) => {
                            const { criteria } = values;
                            this.setState({
                                criteria
                            });
                        }}
                    />
                </Box>

                <MainHeader title="Search Results">
                    <LinkList>
                        <li>
                            <LinkButton
                                onClick={() => {
                                    const tz = moment.tz.guess();
                                    submitForm('/api/excel/Company', {
                                        criteria,
                                        tz,
                                        format: 'xlsx'
                                    });
                                }}
                            >
                                Download XLSX
                            </LinkButton>
                        </li>
                        <li>
                            <LinkButton
                                onClick={() => {
                                    const tz = moment.tz.guess();
                                    submitForm('/api/excel/Company', {
                                        criteria,
                                        tz,
                                        format: 'csv'
                                    });
                                }}
                            >
                                Download CSV
                            </LinkButton>
                        </li>
                    </LinkList>
                </MainHeader>
                <SearchResults criteria={criteria} />
            </Container>
        );
    }
}
export default compose(
    graphql(GET_ICON_POINT_USERS, { name: 'userData' }),
    graphql(GET_ALL_CATEGORIES, { name: 'categoryData' })
)(ReportWrapper);
