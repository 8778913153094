import React from 'react';
import WebsiteLink from './WebsiteLink';
import { get, isObject } from 'lodash';
import Link, { LinkButton } from '-/style/base/Link';
import CompanyQuery from './CompanyQuery';
import { GET_COMPANY_SUMMARY } from '-/gql/company';
import { GET_ME } from '-/gql/user';
import { Query } from 'react-apollo';
import WebsiteForm from '-/company/form/Website';
import DescriptionForm from '-/company/form/Description';
import SectorForm from '-/company/form/Sector';
import ThemeForm from '-/company/form/Theme';
import LocationForm from '-/company/form/Location';
import FinancialsForm from '-/company/form/Financials';
import { sectors } from '-/shared/constants';
import { SimpleValue } from '-/components/profiles';
import SimpleTable from '-/components/table/Simple';
import EditableField from './EditableField';
import {
    StyledTr,
    StyledTd,
    StyledTheadTr,
    StyledTh
} from '-/style/base/Table';
import { baseBlue } from '-/style/variables';
import { formatMillion } from '-/utils/format';
import moment from 'moment-timezone';
import styled from 'styled-components';

const LinkButtonHideWhenPrint = styled(LinkButton)`
    color: ${baseBlue};
    @media print {
        display: none;
    }
`;


const submitForm = (path, params) => {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', path);

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            const valueRaw = params[key];
            const value = isObject(valueRaw) ? JSON.stringify(valueRaw) : valueRaw;
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            hiddenField.setAttribute('value', value);
            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
};

const sectorMap = sectors.reduce((map, sector) => {
    const { value, label } = sector;
    map[value] = label;
    return map;
}, {});

const FinancialHeader = props => {
    return (
        <thead>
            <StyledTheadTr>
                <StyledTh>Year</StyledTh>
                <StyledTh>Bookings</StyledTh>
                <StyledTh>Revenue</StyledTh>
            </StyledTheadTr>
        </thead>
    );
};

const FinancialRow = props => {
    const { index, year, bookings, revenue } = props;
    return (
        <StyledTr index={index}>
            <StyledTd>{year}</StyledTd>
            <StyledTd>{formatMillion(bookings)}</StyledTd>
            <StyledTd>{formatMillion(revenue)}</StyledTd>
        </StyledTr>
    );
};
const Financials = props => {
    const { value } = props;
    if (!value || value.length === 0) {
        return null;
    }
    return (
        <SimpleTable
            Header={FinancialHeader}
            Row={FinancialRow}
            style={{ width: '95%' }}
            data={value}
        />
    );
};

// <StyledDisplayField>
//     <SimpleLabel>Description:</SimpleLabel>
//     <InlineEditSection inEditMode={inEditMode} form={descForm}>
//         <DisplayValue>{description}</DisplayValue>
//     </InlineEditSection>
// </StyledDisplayField>

// <SimpleDisplayField
//     label="Description"
//     inEditMode={inEditMode}
//     form={descForm}
//     value={description}
// />
const isUSA = countryRaw => {
    if (!countryRaw) {
        return false;
    }
    const list = [
        'us',
        'usa',
        'united states',
        'united states of america'
    ];
    const country = countryRaw.replace(/\./g, '').toLowerCase();
    return list.includes(country);
};

const SummaryView = props => {
    const {
        _id,
        inEditMode,
        websites = {},
        description,
        primarySector,
        secondarySector,
        location = {},
        financials = [],
        themes
    } = props;

    const {
        street1 = '',
        street2 = '',
        city = '',
        region = '',
        postalCode = '',
        country = ''
    } = location || {};

    let line3 = '';
    if (city) {
        line3 += `${city},`;
    }

    if (region) {
        line3 += ` ${region}`;
    }
    if (postalCode) {
        line3 += ` ${postalCode}`;
    }
    if (country && !isUSA(country)) {
        line3 += ` ${country}`;
    }

    const websiteForm = <WebsiteForm _id={_id} initialValues={websites} />;
    const descForm = (
        <DescriptionForm _id={_id} initialValues={{ description }} />
    );
    const sectorForm = (
        <SectorForm
            _id={_id}
            initialValues={{ primarySector, secondarySector }}
        />
    );

    const themeForm = (
        <ThemeForm
            _id={_id}
            initialValues={{ themes }}
        />
    );

    const financialsForm = (
        <FinancialsForm
            _id={_id}
            initialValues={{
                financials
            }}
        />
    );

    const locationForm = (
        <LocationForm
            _id={_id}
            initialValues={{
                location: {
                    street1,
                    street2,
                    city,
                    region,
                    postalCode,
                    country
                }
            }}
        />
    );
    return (
        <div>
            <EditableField
                label="Websites"
                inEditMode={inEditMode}
                form={websiteForm}
            >
                {Object.keys(websites).map(type => {
                    const url = websites[type];
                    return (
                        <WebsiteLink
                            key={type}
                            type={type}
                            url={url}
                            style={{ marginRight: 10 }}
                        />
                    );
                })}
            </EditableField>

            <EditableField
                label="Description"
                inEditMode={inEditMode}
                form={descForm}
            >
                <SimpleValue>{description}</SimpleValue>
            </EditableField>

            <EditableField
                label="Sectors"
                inEditMode={inEditMode}
                form={sectorForm}
            >
                <SimpleValue>
                    {sectorMap[primarySector] || primarySector}
                </SimpleValue>
                <SimpleValue>
                    {sectorMap[secondarySector] || secondarySector}
                </SimpleValue>
            </EditableField>

            <EditableField
                label="Themes"
                inEditMode={inEditMode}
                form={themeForm}
            >
                {themes.map(t => {
                    const { _id, permalink, category, name } = t;
                    const to = `/themes/view/${permalink}`;
                    return (
                        <SimpleValue key={_id}>
                            <Link to={to}>{category} &gt; {name}</Link>
                        </SimpleValue>
                    );
                })}
            </EditableField>

            <EditableField
                label="Location"
                inEditMode={inEditMode}
                form={locationForm}
            >
                {street1 ? <SimpleValue>{street1}</SimpleValue> : null}
                {street2 ? <SimpleValue>{street2}</SimpleValue> : null}
                {line3 ? <SimpleValue>{line3}</SimpleValue> : null}
            </EditableField>

            <EditableField
                label="Financials"
                inEditMode={inEditMode}
                form={financialsForm}
            >
                <Financials value={financials} />
            </EditableField>
            <Query query={GET_ME}>
                {queryResult => {
                    const isAdmin = get(queryResult, 'data.me.role') === 'Admin';
                    if (isAdmin) {
                        return (
                            <LinkButtonHideWhenPrint
                                onClick={() => {
                                    const tz = moment.tz.guess();
                                    submitForm('/api/excel/onePager', {
                                        permalink: _id,
                                        tz
                                    });
                                }}
                            >
                                One-Pager Table
                            </LinkButtonHideWhenPrint>
                        )
                    } else {
                        return null;
                    }
                }}
            </Query>
        </div>
    );
};

const Summary = props => {
    return (
        <CompanyQuery
            query={GET_COMPANY_SUMMARY}
            component={SummaryView}
            {...props}
        />
    );
};

export default Summary;