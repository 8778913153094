import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { GET_USER_LIST } from '-/gql/user';
import { get } from 'lodash';
import SimpleTable from '-/components/table/Simple';
import Box from '-/style/base/Box';
import MainHeader from '-/style/base/MainHeader';
import { Container } from '-/style/base/Layout';
import { UserLink, NewUserLink } from '-/components/Link';
import {
    Key as KeyRaw,
    User,
    UserPlus,
    Zap as ZapRaw,
    ZapOff as ZapOffRaw
} from '-/style/Icon';
import styled from 'styled-components';
import {
    StyledTr as StyledTrRaw,
    StyledTd,
    StyledTh,
    StyledEmptyRow
} from '-/style/base/Table';
const Zap = styled(ZapRaw)`
    color: green;
`;
const ZapOff = styled(ZapOffRaw)`
    color: red;
`;
const Key = styled(KeyRaw)`
    color: darkgoldenrod;
`;
const StyledTr = styled(StyledTrRaw)`
    &.disabled {
        opacity: 0.6;
    }
    & td {
        overflow: hidden;
    }
`;
const UserHeader = props => {
    return (
        <thead>
            <tr>
                <StyledTh>Initials</StyledTh>
                <StyledTh>Full Name</StyledTh>
                <StyledTh>Email</StyledTh>
                <StyledTh>Role</StyledTh>
                <StyledTh>Active</StyledTh>
                <StyledTh>Point</StyledTh>
            </tr>
        </thead>
    );
};
const yesNoFormat = value => {
    const on = !!value;
    if (on) {
        return (
            <span>
                <Zap size={16} /> Yes
            </span>
        );
    } else {
        return (
            <span>
                <ZapOff size={16} /> No
            </span>
        );
    }
};

const UserRow = props => {
    const {
        _id,
        index,
        name,
        email,
        role,
        initials,
        isActive,
        isPointPerson
    } = props;
    const className = isActive ? '' : 'disabled';
    return (
        <StyledTr index={index} className={className}>
            <StyledTd>{initials}</StyledTd>
            <StyledTd>
                <UserLink _id={_id}>{name}</UserLink>
            </StyledTd>
            <StyledTd>{email}</StyledTd>
            <StyledTd>
                {role === 'Admin' ? <Key size={16} /> : <User size={16} />}{' '}
                {role}
            </StyledTd>
            <StyledTd>{yesNoFormat(isActive)}</StyledTd>
            <StyledTd>{yesNoFormat(isPointPerson)}</StyledTd>
        </StyledTr>
    );
};

const emptyRow = <StyledEmptyRow colSpan={6}>No users found.</StyledEmptyRow>;

const List = props => {
    const { className, style } = props;
    const [showOnlyPointUsers, setShowOnlyPointUsers] = useState(false);
    const [showOnlyActiveUsers, setShowOnlyActiveUsers] = useState(true);
    return (
        <div className={className} style={style}>
            <Container>
                <MainHeader title="User Manager">
                    <NewUserLink>
                        <UserPlus size={16} />
                        New User
                    </NewUserLink>
                </MainHeader>
                <div>
                    <label>
                        <input type="checkbox" checked={showOnlyPointUsers} onClick={() => {
                            setShowOnlyPointUsers(!showOnlyPointUsers);
                        }} /> Show Only Point People</label>
                </div>
                <div>
                    <label>
                        <input type="checkbox" checked={showOnlyActiveUsers} onClick={() => {
                            setShowOnlyActiveUsers(!showOnlyActiveUsers);
                        }} /> Show Only Active Users</label>
                </div>
                <Box padding={false}>
                    <Query query={GET_USER_LIST}>
                        {queryResult => {
                            const { data, error, loading } = queryResult;
                            const usersRaw = get(data, 'Users', []);
                            const users = usersRaw.filter(user => {
                                const { isActive, isPointPerson } = user;
                                if (showOnlyPointUsers && !isPointPerson) {
                                    return false;
                                }
                                if (showOnlyActiveUsers && !isActive) {
                                    return false;
                                }
                                return true;
                            });
                            return (
                                <SimpleTable
                                    error={error}
                                    loading={loading}
                                    Header={UserHeader}
                                    EmptyRow={emptyRow}
                                    data={users}
                                    Row={UserRow}
                                />
                            );
                        }}
                    </Query>
                </Box>
            </Container>
        </div>
    );
};

export default List;
