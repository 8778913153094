import React, { Component, Fragment } from 'react';
import { Row, Col } from '-/style/base/Layout';
import ExitBar from '-/components/profiles/ExitBar';
import styled from 'styled-components';
import Subhead from '-/style/base/Subhead';
import Box from '-/style/base/Box';
import OverlayWithState from '-/components/OverlayWithState';
import { getTodayAsSimpleDate } from '-/components/inputs';
import { Container } from '-/style/base/Layout';
import { GET_PERSON_DETAILS } from '-/gql/person';
import { get } from 'lodash';
import { withProps } from 'recompose';
import {
    IntroSection,
    SummarySection,
    CompaniesSection,
    NotesSection,
    InvestmentsSection,
    BioSection
} from './sections';
import PersonQuery from './sections/PersonQuery';
import NoteForm from './../form/Note';

const StandardRow = props => {
    return <Row gutter={4} justifyContent="space-between" {...props} />;
};
StandardRow.displayName = 'Row';

const StyledFixedBody = styled.div`
    max-height: 370px;
    overflow: auto;
`;

class ProfileLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inEditMode: false
        };
    }

    enterEditMode = () => {
        this.setState({
            inEditMode: true
        });
    };

    exitEditMode = () => {
        this.setState({
            inEditMode: false
        });
    };

    render() {
        const { permalink } = this.props;
        const { inEditMode } = this.state;

        return (
            <Fragment>
                {inEditMode && <ExitBar onClick={this.exitEditMode} />}
                <Container>
                    <IntroSection
                        enterEditMode={this.enterEditMode}
                        inEditMode={inEditMode}
                        permalink={permalink}
                        style={{ padding: 8 }}
                    />
                    <StandardRow>
                        <Col xs={12} sm={12} flex={3} component={Box}>
                            <Subhead>At A Glance</Subhead>
                            <StyledFixedBody
                                as={SummarySection}
                                permalink={permalink}
                            />
                        </Col>
                        <Col xs={12} sm={12} flex={9} component={Box}>
                            <Subhead>Priority Target & Watch List</Subhead>
                            <StyledFixedBody
                                as={CompaniesSection}
                                permalink={permalink}
                            />
                        </Col>
                    </StandardRow>
                    <StandardRow>
                        <Col flex={12} component={Box}>
                            <Subhead>
                                Notes{' '}
                                <OverlayWithState
                                    arrowSide="left"
                                    vAttachment="top"
                                    hAttachment="left"
                                    vTargetAttachment="top"
                                    hTargetAttachment="right"
                                    closeOnEsc
                                >
                                    <NoteForm
                                        permalink={permalink}
                                        initialValues={{
                                            type: 'Note',
                                            date: getTodayAsSimpleDate()
                                        }}
                                    />
                                </OverlayWithState>
                            </Subhead>
                            <NotesSection
                                editable={inEditMode}
                                permalink={permalink}
                                style={{ maxHeight: 280, overflow: 'auto' }}
                            />
                        </Col>
                        <Col flex={12}>
                            <Box>
                                <Subhead>Investments</Subhead>
                                <InvestmentsSection permalink={permalink} />
                            </Box>
                        </Col>
                        <Col flex={12}>
                            <Box>
                                <Subhead>Bio</Subhead>
                                <BioSection permalink={permalink} />
                            </Box>
                        </Col>
                    </StandardRow>
                </Container>
            </Fragment>
        );
    }
}

export default withProps(initialProps => {
    const permalink = get(initialProps, 'match.params.permalink', '');
    return {
        component: ProfileLayout,
        query: GET_PERSON_DETAILS,
        permalink,
        ...initialProps
    };
})(PersonQuery);
