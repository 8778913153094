import React from 'react';
import { transformOptions } from './utils';
import styled from 'styled-components';
import Label from './Label';

const StyledLabel = styled(Label)`
    font-weight: normal;
    display: inline-block;
    margin-right: 1rem;
`;

const StyledRadio = styled.input.attrs({
    type: 'radio'
})`
    margin-right: 0.25rem;
`;

export const RadioGroup = props => {
    const {
        className,
        style,
        field: { name },
        form: { values, setFieldValue },
        options: optionsRaw
    } = props;
    if (!name) {
        throw new Error('Cannot have input w/o name');
    }
    const currentValue = values[name];
    const options = transformOptions(optionsRaw);
    return (
        <div className={className} style={style}>
            {options.map(o => {
                const { value, label } = o;
                return (
                    <StyledLabel key={value}>
                        <StyledRadio
                            value={value}
                            checked={currentValue === value}
                            onChange={e => {
                                if (e.target.checked) {
                                    setFieldValue(name, value);
                                }
                            }}
                        />
                        {label}
                    </StyledLabel>
                );
            })}
        </div>
    );
};

export default RadioGroup;
