import React, { Fragment } from 'react';
import { Formik, Field, Form } from 'formik';
import { graphql } from 'react-apollo';
import { UPDATE_COMPANY_LOCATION } from '-/gql/company';
import { TextInput, AutoComplete, ZipInput, RadioGroup } from '-/components/inputs';
import Toolbar from '-/components/profiles/forms/Toolbar';
import styled from 'styled-components';
import { statesByAbbrev } from '-/shared/constants';
import { get } from 'lodash';

const StyledStandardForm = styled(Form)`
    padding: 15px;
    min-width: 350px;
`;

const renderFields = props => {
    const { className, closeOverlay, values: { locationChoice } } = props;
    const isInternational = locationChoice !== 'us';
    return (
        <StyledStandardForm className={className}>
            <Field
                style={{ marginBottom: 5 }}
                component={RadioGroup}
                options={{
                    us: 'USA',
                    international: 'International'
                }}
                name="locationChoice" />
            {!isInternational ? (
                <Fragment>
                    <Field
                        component={TextInput}
                        label="Street Address"
                        name="location.street1" />
                    <Field
                        component={TextInput}
                        label="Street Address 2"
                        name="location.street2" />
                </Fragment>
            ) : null}
            <Field
                component={TextInput}
                label="City"
                name="location.city" />
            {isInternational ? (
                <Field
                    component={TextInput}
                    label="Country"
                    name="location.country" />
            ) : (
                    <Fragment>
                        <Field
                            component={AutoComplete}
                            options={statesByAbbrev}
                            label="State"
                            name="location.region" />
                        <Field
                            component={ZipInput}
                            label="Zip"
                            name="location.postalCode"
                        />
                    </Fragment>

                )}

            <Toolbar handleCancel={closeOverlay} />
        </StyledStandardForm>
    );
};

const LocationForm = props => {
    const { _id, closeOverlay, update, initialValues, className } = props;
    const country = get(initialValues, 'location.country', 'US');
    initialValues.locationChoice = country === 'US' ? 'us' : 'international';
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const isInternational = values.locationChoice !== 'us';
                    const input = values.location;
                    if (isInternational) {
                        input.postalCode = '';
                        input.region = '';
                        input.street1 = '';
                        input.street2 = '';
                    } else {
                        input.country = 'US'
                    }

                    await update({
                        variables: {
                            _id,
                            input
                        }
                    });
                    closeOverlay();
                } catch (ex) {
                    console.warn(ex);
                }
            }}
            render={renderProps => {
                return renderFields({
                    ...renderProps,
                    closeOverlay,
                    className
                });
            }}
        />
    );
};

export default graphql(UPDATE_COMPANY_LOCATION, { name: 'update' })(
    LocationForm
);
