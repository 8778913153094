import React from 'react';
import { Col, Row as RowRaw } from './Layout';
import Headline from './Headline';
import styled from 'styled-components';

const Row = styled(RowRaw)`
    min-height: 65px;
`;

const MainHeader = props => {
    const { title, children, ...rest } = props;
    return (
        <Row
            gutter={4}
            justifyContent="space-between"
            alignItems="flex-end"
            {...rest}
        >
            <Col flex={children ? 6 : 12}>
                <Headline>{title}</Headline>
            </Col>
            {children && (
                <Col flex={6} hideWhenPrint={true} style={{ textAlign: 'right' }}>
                    {children}
                </Col>
            )}
        </Row>
    );
};


export default MainHeader;