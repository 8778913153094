import React from 'react';
import { Formik, Field, Form } from 'formik';
import { graphql } from 'react-apollo';
import { pick } from 'lodash';
import { SEARCH_PEOPLE } from '-/gql/person';
import { UPDATE_COMPANY_BOARD_MEMBERS } from '-/gql/company';
import { AutoComplete } from '-/components/inputs';
import Toolbar from '-/components/profiles/forms/Toolbar';
import styled from 'styled-components';

const StyledStandardForm = styled(Form)`
    padding: 15px;
    width: 350px;
`;
const renderFields = props => {
    const { className, closeOverlay } = props;
    return (
        <StyledStandardForm className={className}>
            <Field
                name="boardMembers"
                label="Board Members"
                component={AutoComplete}
                query={SEARCH_PEOPLE}
                resultKey="People"
                multiple={true}
            />
            <Toolbar handleCancel={closeOverlay} />
        </StyledStandardForm>
    );
};

const BoardMembersForm = props => {
    const { _id, closeOverlay, update, initialValues, className } = props;
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const input = pick(values, ['boardMembers']);
                    await update({
                        variables: {
                            _id,
                            input
                        }
                    });
                    closeOverlay();
                } catch (ex) {
                    console.warn(ex);
                }
            }}
            render={renderProps => {
                return renderFields({
                    ...renderProps,
                    closeOverlay,
                    className
                });
            }}
        />
    );
};

export default graphql(UPDATE_COMPANY_BOARD_MEMBERS, { name: 'update' })(
    BoardMembersForm
);
