import React, { Fragment, useState } from 'react';
import { graphql, Query } from 'react-apollo';
import MainHeader from '-/style/base/MainHeader';
import { Container } from '-/style/base/Layout';
import SimpleTable from '-/components/table/Simple';
import Box from '-/style/base/Box';
import {
    StyledTr,
    StyledTd,
    StyledTh,
    StyledEmptyRow
} from '-/style/base/Table';
import { CompanyLink } from '-/components/Link';
import moment from 'moment';
import { GET_LI_TRACKED_COMPANIES, GET_LINKEDIN_UPDATE_STATS, UNTRACK_LI_COMPANY } from '-/gql/company';
import { get, last, round } from 'lodash';
import Tile from '-/waitlist/Tile'
import WebsiteLink from '-/company/view/sections/WebsiteLink';
import { AlertTriangle, TrashAction } from '-/style/Icon';
import { statusColors } from '-/style/variables';
import Dialog from '-/components/Dialog';
import { LinkButton } from '-/style/base/Link';
import LinkList from '-/style/base/LinkList';
import TrackCompanyForm from './TrackCompanyForm';

const colSpan = 4;
const Header = props => {
    return (
        <thead>
            <tr>
                <StyledTh>Company Name</StyledTh>
                <StyledTh>Has LinkedIn URL</StyledTh>
                <StyledTh>Last Seen on Plugin</StyledTh>
                <StyledTh>Latest Headcount</StyledTh>
            </tr>
        </thead>
    );
};

const ListRowRaw = props => {
    const {
        index,
        _id,
        name,
        websites,
        li,
        analytics,
        untrackLiCompany,
        showOnlyProblems
    } = props;

    const [deleting, setDeleting] = useState(false);

    const { linkedin } = websites;
    const liLink = linkedin ? <WebsiteLink
        type={'linkedin'}
        url={linkedin}
    /> : <AlertTriangle size={16} style={{ color: statusColors.error }} />;
    const { updatedAt, tracked } = li;
    const updatedAtAgo = updatedAt ? moment(updatedAt).fromNow() : 'Never';
    const headcountData = get(analytics, 'employeeHeadCount.data');
    const filteredHeadcount = headcountData.filter(a => a.score);

    const lastHeadCount = last(filteredHeadcount);
    const latestHeadcount = get(lastHeadCount, 'score');
    const atHeadcount = get(lastHeadCount, 'recorded_at');
    const outOfDateByMonths = moment(new Date()).diff(atHeadcount, 'month')

    const isProblem = !linkedin || !lastHeadCount || (lastHeadCount && outOfDateByMonths > 2);
    return deleting ? (
        <StyledTr index={index}>
            <StyledTd colSpan={colSpan}>
                Removing {name}...
            </StyledTd>
        </StyledTr>
    ) : (
        showOnlyProblems && !isProblem ? null :
            (
                <StyledTr index={index}>
                    <StyledTd>
                        <CompanyLink permalink={_id}>{name}</CompanyLink>
                        {tracked ? <TrashAction size={10} onClick={async () => {
                            setDeleting(true);
                            await untrackLiCompany({
                                variables: {
                                    _id
                                },
                                refetchQueries: [
                                    { query: GET_LI_TRACKED_COMPANIES },
                                    { query: GET_LINKEDIN_UPDATE_STATS }
                                ]
                            });
                        }} /> : null}
                    </StyledTd>
                    <StyledTd>{liLink}</StyledTd>
                    <StyledTd>{updatedAtAgo}</StyledTd>
                    <StyledTd>{lastHeadCount ? (
                        <Fragment>
                            {latestHeadcount} employees<br />
                            {outOfDateByMonths > 2 ? <span style={{ color: statusColors.warn }}>{outOfDateByMonths} months ago</span> : <span style={{ color: statusColors.success }}>Current</span>}
                        </Fragment>
                    ) : <span style={{ color: statusColors.error }}>No Data Available</span>}</StyledTd>
                </StyledTr>
            )
    );
};
const ListRow = graphql(UNTRACK_LI_COMPANY, {
    name: 'untrackLiCompany'
})(ListRowRaw);

const emptyRow = <StyledEmptyRow colSpan={colSpan}>No companies found.</StyledEmptyRow>;

const List = props => {
    const { className, style } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [showOnlyProblems, setShowOnlyProblems] = useState(false);
    return (
        <div className={className} style={style}>
            <Container>
                {dialogOpen ? (
                    <Dialog>
                        <TrackCompanyForm onClose={() => {
                            setDialogOpen(false);
                        }} refetchQueries={[{
                            query: GET_LINKEDIN_UPDATE_STATS,
                        }, {
                            query: GET_LI_TRACKED_COMPANIES
                        }]} />
                    </Dialog>
                ) : null}
                <MainHeader title="Tracked Companies on LinkedIn">
                    <LinkList>
                        <li>
                            <LinkButton
                                onClick={() => {
                                    setDialogOpen(true)
                                }
                                }>
                                Track New Company
                            </LinkButton>
                        </li>
                    </LinkList>
                </MainHeader>
                <Box padding={false}>
                    <Query query={GET_LINKEDIN_UPDATE_STATS}>
                        {queryResult => {
                            const { data, error, loading } = queryResult;
                            const { Never, Outdated, Recent, Total } = get(data, 'LinkedInUpdateStats', {});
                            const upToDatePct = round(Recent / Total, 2) * 100;
                            if (error) {
                                return <span>Error in querying statistics.</span>;
                            } else if (loading) {
                                return <span>Loading...</span>;
                            } else {
                                return (
                                    <div>
                                        <Tile count={Never} title='New' />
                                        <Tile count={Outdated} title='Outdated' />
                                        <Tile count={Recent} title='Recent' />
                                        <Tile count={`${upToDatePct}%`} title='Up to Date' />
                                    </div>
                                );
                            }
                        }}
                    </Query>
                    <div style={{ clear: 'both' }}>
                        <label>
                            <input type="checkbox" checked={showOnlyProblems} onClick={() => {
                                setShowOnlyProblems(!showOnlyProblems);
                            }} /> Show Only Problems</label>
                    </div>
                    <Query query={GET_LI_TRACKED_COMPANIES}>
                        {queryResult => {
                            const { data, error, loading } = queryResult;
                            const list = get(data, 'CompanyLiTracked', []);
                            return (
                                <SimpleTable
                                    Header={Header}
                                    EmptyRow={emptyRow}
                                    data={list}
                                    Row={ListRow}
                                    error={error}
                                    loading={loading}
                                    rowProps={{ showOnlyProblems }}
                                />
                            );
                        }}
                    </Query>
                </Box>
            </Container>
        </div>
    );
};

export default List;