import React, { Fragment, useState } from 'react';
import { graphql, Query } from 'react-apollo';
import { GET_WAITLIST, RESET_WAITLIST_ITEM, RESET_FAILED_WAITLIST_ITEMS, SET_WAITLIST_DISABLED } from '-/gql/waitlist';
import { get, xor } from 'lodash';
import moment from 'moment';
import SimpleTable from '-/components/table/Simple';
import Box from '-/style/base/Box';
import MainHeader from '-/style/base/MainHeader';
import { Container } from '-/style/base/Layout';
import {
    StyledTr,
    StyledTd,
    StyledTh,
    StyledEmptyRow
} from '-/style/base/Table';
import { RestoreAction, DiscloseArrowDown, DiscloseArrowRight } from '-/style/Icon';
import { CompanyLink, FirmLink, PersonLink } from '-/components/Link';
import Tile from './Tile';
import Checkbox from './Checkbox';
import styled from 'styled-components';
import LoadingLogo from '-/components/LoadingLogo';

const colSpan = 6;
const Header = props => {
    return (
        <thead>
            <tr>
                <StyledTh style={{ width: 60 }} />
                <StyledTh>entity</StyledTh>
                <StyledTh>status</StyledTh>
                <StyledTh>type</StyledTh>
                <StyledTh>Last Changed</StyledTh>
                <StyledTh>actions</StyledTh>
            </tr>
        </thead>
    );
};

const Link = props => {
    const { entityType, permalink } = props;
    switch (entityType) {
        case 'Person':
            return <PersonLink permalink={permalink}>{permalink}</PersonLink>
        case 'Company':
            return <CompanyLink permalink={permalink}>{permalink}</CompanyLink>
        case 'Firm':
            return <FirmLink permalink={permalink}>{permalink}</FirmLink>
        default:
            return <div>{permalink}</div>
    }
};

const RowRaw = props => {
    const {
        _id,
        index,
        permalink,
        status,
        entityType,
        errMsg,
        updatedAt,
        resetWaitListItem,
        tasks
    } = props;

    const [actionText, setActionText] = useState('');
    const [showTasks, setShowTasks] = useState(false);
    const hasTasks = tasks.length > 0;

    const ago = moment(updatedAt).fromNow();
    const resetDisabled = status === 'Waiting';

    const clearActionText = () => {
        setTimeout(() => {
            setActionText('');
        }, 5000);
    };

    const toggleShowTasks = () => {
        setShowTasks(!showTasks);
    };

    return (
        <Fragment>
            <StyledTr index={index}>
                <StyledTd>
                    {showTasks && (
                        <DiscloseArrowDown size={16} onClick={toggleShowTasks} />
                    )}
                    {!showTasks && (
                        <DiscloseArrowRight disabled={!hasTasks} size={16} onClick={hasTasks ? toggleShowTasks : undefined} />
                    )}
                </StyledTd>
                <StyledTd><Link entityType={entityType} permalink={permalink} /></StyledTd>
                <StyledTd>{status}</StyledTd>
                <StyledTd>{entityType}</StyledTd>
                <StyledTd>{ago}</StyledTd>
                <StyledTd>
                    <RestoreAction disabled={resetDisabled} size={16} onClick={async (e) => {
                        const { altKey, metaKey, shiftKey } = e;
                        const force = !!(altKey || metaKey || shiftKey);
                        const prefix = force ? 'Force ' : '';
                        setActionText(`${prefix}Resetting...`);
                        await resetWaitListItem({
                            variables: {
                                _id,
                                force
                            }
                        });
                        setActionText(`${prefix}Reset to be re-run soon.`);
                        clearActionText();
                    }} />
                    <div>
                        {actionText}
                    </div>
                </StyledTd>
            </StyledTr>
            {status === 'Failed' && (
                <StyledTr index={index}>
                    <StyledTd colSpan={colSpan}>{errMsg}</StyledTd>
                </StyledTr>
            )}
            {showTasks && (
                <StyledTr index={index}>
                    <StyledTd colSpan={colSpan}>
                        <ul>
                            {tasks.map((t, idx) => {
                                return (
                                    <li key={idx}>
                                        {t}
                                    </li>
                                )
                            })}
                        </ul>
                    </StyledTd>
                </StyledTr>
            )}
        </Fragment>
    );
};
const Row = graphql(RESET_WAITLIST_ITEM, {
    name: 'resetWaitListItem'
})(RowRaw);

const emptyRow = <StyledEmptyRow colSpan={colSpan}>No requests returned.</StyledEmptyRow>;

const StatsRaw = props => {
    const {
        disabled,
        setWaitListDisabled,
        Waiting,
        Running,
        Failed,
        Completed,
        Skipped,
        Total,
        Person,
        Company,
        Firm,
        filter = {
            status: [],
            entities: []
        },
        setFilter
    } = props;
    const { status, entities } = filter;

    const generateStatusSetters = forStatus => {
        return {
            setExactFilter: e => {
                e.stopPropagation();
                setFilter({
                    entities,
                    status: [forStatus]
                });
            },
            setToggleFilter: e => {
                e.stopPropagation();
                setFilter({
                    entities,
                    status: xor(status, [forStatus])
                })
            }
        };
    };

    const generateEntitiySetters = forEntity => {
        return {
            setExactFilter: e => {
                e.stopPropagation();
                setFilter({
                    entities: [forEntity],
                    status
                });
            },
            setToggleFilter: e => {
                e.stopPropagation();
                setFilter({
                    entities: xor(entities, [forEntity]),
                    status
                })
            }
        };
    };

    return (
        <Fragment>
            <div>
                <Tile
                    count={Waiting}
                    title="Waiting"
                    status='active'
                    active={status.includes('Waiting')}
                    {...generateStatusSetters('Waiting')} />
                <Tile
                    count={Running}
                    title="Running"
                    status='active'
                    active={status.includes('Running')}
                    {...generateStatusSetters('Running')} />
                <Tile
                    count={Failed}
                    title="Failed"
                    status='error'
                    active={status.includes('Failed')}
                    {...generateStatusSetters('Failed')} />
                <Tile
                    count={Completed}
                    title="Completed"
                    status='active'
                    active={status.includes('Completed')}
                    {...generateStatusSetters('Completed')} />
                <Tile
                    count={Skipped}
                    title="Skipped"
                    status='warn'
                    active={status.includes('Skipped')}
                    {...generateStatusSetters('Skipped')} />
                <Tile
                    count={Total}
                    title="Total" />
                <Tile
                    count={Person}
                    title="VCs"
                    {...generateEntitiySetters('Person')}
                    active={entities.includes('Person')} />
                <Tile
                    count={Company}
                    title="Companies"
                    {...generateEntitiySetters('Company')}
                    active={entities.includes('Company')} />
                <Tile
                    count={Firm}
                    title="Firms"
                    {...generateEntitiySetters('Firm')}
                    active={entities.includes('Firm')} />
                <div style={{ clear: 'both' }} />
                <Checkbox disabled={disabled} onChange={(event) => {
                    const checked = event.target.checked;
                    setWaitListDisabled({
                        variables: {
                            disabled: !checked
                        }
                    });
                }} />
            </div>
        </Fragment>
    );
};

const Stats = graphql(SET_WAITLIST_DISABLED, {
    name: 'setWaitListDisabled'
})(StatsRaw);

const StyledFailedReset = styled.div`
    margin: 25px;
    padding: 25px;
    border: 1px solid black;
`;
const FailedResetRaw = props => {
    const { entities, resetFailedWaitListItems } = props;
    return (
        <StyledFailedReset>
            In order to reset all failed requests of types {entities.join(', ')}. Please click below action item.
            <RestoreAction size={40} onClick={async e => {
                await resetFailedWaitListItems({
                    variables: {
                        entities
                    }
                });
            }} />
        </StyledFailedReset>
    );
};
const FailedReset = graphql(RESET_FAILED_WAITLIST_ITEMS, {
    name: 'resetFailedWaitListItems'
})(FailedResetRaw);


const List = props => {
    const { className, style } = props;
    const [activeStatusFilter, setActiveStatusFilter] = useState({
        status: ['Waiting', 'Running', 'Failed', 'Completed', 'Skipped'],
        entities: ['Person', 'Company', 'Firm']
    });
    const { status: filterStatus, entities } = activeStatusFilter;
    const failedOnly = (filterStatus.length === 1 && filterStatus[0] === 'Failed');

    return (
        <div className={className} style={style}>
            <Container>
                <MainHeader title="WaitList Request Manager" />
                <Box padding={false}>
                    <Query query={GET_WAITLIST} variables={{ criteria: activeStatusFilter }}>
                        {queryResult => {
                            const { data, error, loading } = queryResult;
                            const list = get(data, 'WaitList', []);
                            const stats = get(data, 'WaitListStats', {});
                            return (
                                <Fragment>
                                    <Stats filter={activeStatusFilter} setFilter={setActiveStatusFilter} {...stats} />
                                    {failedOnly ? <FailedReset entities={entities} /> : null}
                                    {loading ? <LoadingLogo /> : null}
                                    <SimpleTable
                                        error={error}
                                        loading={loading}
                                        Header={Header}
                                        EmptyRow={emptyRow}
                                        data={list}
                                        Row={Row}
                                    />
                                </Fragment>

                            );
                        }}
                    </Query>
                </Box>
            </Container>
        </div>
    );
};

export default List;
