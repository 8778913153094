import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from '-/style/base/Layout';
import Box from '-/style/base/Box';
import MainHeader from '-/style/base/MainHeader';
import LinkList from '-/style/base/LinkList';
import SearchResults from './reports/SearchResults';

const lpList = props => {
    return (
        <Container>
            <MainHeader title="All Institutions">
                <LinkList>
                    <li>
                        <Link to="/lp/new">New Institution</Link>
                    </li>
                </LinkList>
            </MainHeader>
            <Box>
                <SearchResults hideHeader={true} maxHeight={false} />
            </Box>
        </Container>
    );
};

export default lpList;
