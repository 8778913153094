import React, { Fragment, useState } from 'react';
import { Query, compose, graphql } from 'react-apollo';
import { GET_ALL_CATEGORIES } from '-/gql/theme';
import { GET_ME } from '-/gql/user';
import { get, isObject } from 'lodash';
import Box from '-/style/base/Box';
import MainHeader from '-/style/base/MainHeader';
import { Container } from '-/style/base/Layout';
import styled from 'styled-components';
import { EditAction as Edit, PieChart as PieChartRaw } from '-/style/Icon';
import {
    StyledTable,
    StyledTr,
    StyledTd
} from '-/style/base/Table';
import Link, { LinkButton } from '-/style/base/Link';
import CategoryForm from './FullForm';
import MessageBox from '-/components/MessageBox';
import LinkList from '-/style/base/LinkList';
import SubSubHead from '-/style/base/SubSubHead';
import Alert from '-/style/base/Alert';

const MutedSpan = styled.span`
    color: grey;
`;
const PieChart = styled(PieChartRaw)`
    padding: 0 0 3px 5px;
`;
const AlertHead = styled.h3`
    font-weight: 600;
    font-size: 16px;
`;
// TODO: Put this in utils!
const submitForm = (path, params = {}) => {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', path);

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            const valueRaw = params[key];
            const value = isObject(valueRaw) ? JSON.stringify(valueRaw) : valueRaw;
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            hiddenField.setAttribute('value', value);
            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
};
const CategoryStatic = props => {
    const { _id, index, onEdit, themes = [], editable } = props;
    return (
        <Fragment key={_id}>
            <StyledTr index={index}>
                <StyledTd>
                    <SubSubHead>
                        {_id}
                    </SubSubHead>
                </StyledTd>
                <StyledTd>
                    {editable ? <Edit size={16} onClick={onEdit}>
                        Edit
                    </Edit> : null}
                </StyledTd>
            </StyledTr>
            <StyledTr index={index}>
                <StyledTd colSpan={2}>
                    <StyledTable>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {themes.map((t, j) => {
                                const {
                                    _id,
                                    permalink,
                                    name,
                                    priority,
                                    ingredient,
                                    importantCompanyCount
                                } = t;
                                const idx = index % 2 !== 0 ? j : j + 1;
                                return (
                                    <StyledTr key={_id} index={idx}>
                                        <StyledTd>
                                            <Link to={`/themes/view/${permalink}`}>{name}</Link> {ingredient ? <PieChart size={12} title="Ingredient" /> : null}<br />
                                            <MutedSpan>{importantCompanyCount} {importantCompanyCount === 1 ? 'company' : 'companies'}</MutedSpan>
                                        </StyledTd>
                                        <StyledTd>
                                            {priority}
                                        </StyledTd>
                                    </StyledTr>
                                );
                            })}
                        </tbody>
                    </StyledTable>

                </StyledTd>
            </StyledTr>
        </Fragment>
    );
};
const CategoryList = props => {
    const { categories = [], formCategoryOpen, onFormEdit, onFormCancel, editable } = props;
    const unusedThemeFilter = t => {
        return t.companyCount === 0 && t.name !== 'Other';
    };
    const unusedCategories = categories.filter(c => {
        const { themes = [] } = c;
        return themes.some(unusedThemeFilter);
    }).map(c => {
        const { themes = [], ...cRest } = c;
        return {
            ...cRest,
            themes: themes.filter(unusedThemeFilter)
        };
    });
    const unusedThemeCount = unusedCategories.reduce((total, category) => {
        return total + category.themes.length;
    }, 0);
    let unusedAlert;
    if (unusedThemeCount) {
        const text = unusedThemeCount === 1 ? `There is currrently ${unusedThemeCount} unused theme.` : `There are currently ${unusedThemeCount} unused themes.`;
        unusedAlert = (
            <Alert>
                <AlertHead>Unused Themes</AlertHead>
                {text}
                <ul>
                    {unusedCategories.map(c => {
                        const { category, themes = [] } = c;
                        return themes.map(t => {
                            const { _id, name } = t;
                            return <li key={_id}>{category} - {name}</li>;
                        });
                    })}
                </ul>
            </Alert>
        );
    }
    return (
        <Fragment>
            {unusedAlert}
            <StyledTable>
                <thead>
                    <tr>
                        <th></th>
                        <th width={120}></th>
                    </tr>
                </thead>
                <tbody>
                    {categories.map((c, index) => {
                        const { _id } = c;
                        const isOpen = formCategoryOpen === _id;
                        return isOpen ? (
                            <StyledTr key={_id} index={index}>
                                <StyledTd colSpan={2}>
                                    <CategoryForm initialValues={c} onCancel={onFormCancel} mode="edit" />
                                </StyledTd>

                            </StyledTr>
                        ) : (
                            <CategoryStatic key={_id} index={index} onEdit={() => {
                                if (formCategoryOpen) {
                                    MessageBox.show({
                                        message: (
                                            <div>
                                                Would you like to proceed?<br />
                                            Any unsaved changes will be discarded.
                                            </div>
                                        ),
                                        onYesClick: async () => {
                                            onFormEdit(_id);
                                        }
                                    });
                                } else {
                                    onFormEdit(_id);
                                }
                            }} editable={editable} {...c} />
                        );
                    })}
                </tbody>
            </StyledTable>
        </Fragment>

    );
};
const NEW_FORM_ID = '_NEW_FORM_';
const Manager = props => {
    const { className, style, editable: editableRaw } = props;
    const editable = get(props, 'data.me.role') === 'Admin' && editableRaw;
    const title = editable ? 'Category / Theme Manager' : 'Category / Theme Listing';
    const [formCategoryOpen, setIsFormOpen] = useState(false);
    const onFormCancel = () => {
        setIsFormOpen(false);
    };

    const newFormOpen = formCategoryOpen === NEW_FORM_ID;
    return (
        <div className={className} style={style}>
            <Container>
                <MainHeader title={title}>
                    <LinkList>
                        {editable ? <li>
                            <LinkButton onClick={() => {
                                if (formCategoryOpen) {
                                    MessageBox.show({
                                        message: (
                                            <div>
                                                Would you like to proceed?<br />
                                        Any unsaved changes will be discarded.
                                            </div>
                                        ),
                                        onYesClick: async () => {
                                            setIsFormOpen(NEW_FORM_ID);
                                        }
                                    });
                                } else {
                                    setIsFormOpen(NEW_FORM_ID);
                                }
                            }}>New Category</LinkButton>
                        </li> : null}
                        <li>
                            <LinkButton onClick={() => {
                                submitForm('/api/excel/themes');
                            }}>Export</LinkButton>
                        </li>
                    </LinkList>

                </MainHeader>
                {newFormOpen ? (
                    <Box style={{ marginBottom: 15 }}>
                        <CategoryForm onCancel={onFormCancel} />
                    </Box>
                ) : null}
                <Box padding={false}>
                    <Query query={GET_ALL_CATEGORIES}>
                        {queryResult => {
                            const { data, error, loading } = queryResult;
                            const categories = get(data, 'ThemeCategories', []);
                            if (error) {
                                return <div>Error!</div>
                            } else if (loading) {
                                return <div>Loading..</div>
                            } else if (categories.length === 0) {
                                return <div>No categories have been added yet.</div>;
                            } else {
                                return (
                                    <CategoryList
                                        editable={editable}
                                        formCategoryOpen={formCategoryOpen}
                                        onFormEdit={setIsFormOpen}
                                        onFormCancel={onFormCancel}
                                        categories={categories} />
                                );
                            }
                        }}
                    </Query>
                </Box>
            </Container>
        </div>
    );
};
Manager.defaultProps = {
    editable: true
};
export default compose(
    graphql(GET_ME)
)(Manager);

