import { graphql } from 'react-apollo';
import { compose, withProps } from 'recompose';
import ActionItemForm from '-/components/profiles/forms/ActionItem';
import { UPDATE_LP } from '-/gql/lp';

export default compose(
    graphql(UPDATE_LP, {
        name: 'updateActionItem'
    }),
    withProps({
        side: 'lp'
    })
)(ActionItemForm);
