import React, { Fragment } from 'react';
import {
    StyledTable,
    StyledTheadTr,
    StyledTh,
    StyledTr,
    StyledTd,
    StyledStickyWrapper
} from '-/style/base/Table';
import { ADVANCED_SEARCH_COMPANY } from '-/gql/company';
import Box from '-/style/base/Box';
import { graphql } from 'react-apollo';
import { CompanyLink } from '-/components/Link';
import { formatDate, formatOption } from '-/utils/format';
import { SimpleValue } from '-/components/profiles';
import WebsiteLink from './view/sections/WebsiteLink';
import { sectors } from '-/shared/constants';
import { get } from 'lodash';
import styled from 'styled-components';
import { StyledVCTd } from './view/Table';
const PS = styled.i`
    font-style: italic;
`;
const CompanyHeader = props => {
    const defaultStyle = {
        width: 180,
        backgroundColor: '#EFEFEF',
        borderBottom: '1px solid #CACACB'
    };
    return (
        <thead>
            <StyledTheadTr>
                <StyledTh sticky style={defaultStyle}>
                    Name
                </StyledTh>
                <StyledTh style={defaultStyle}>Action Item</StyledTh>
                <StyledTh style={defaultStyle}>Icon Category</StyledTh>
                <StyledTh style={defaultStyle}>Icon Status</StyledTh>
                <StyledTh style={defaultStyle}>Themes</StyledTh>
                <StyledTh style={defaultStyle}>Key VCs</StyledTh>
                <StyledTh style={defaultStyle}>Next Contact</StyledTh>
                <StyledTh style={defaultStyle}>Point</StyledTh>
            </StyledTheadTr>
        </thead>
    );
};



const StyledCompany = styled.div`
    line-height: 30px;
    a {
        vertical-align: middle;
    }
    
`;
const CompanyRow = props => {
    const {
        index,
        _id,
        name,
        actionItem,
        description,
        iconCategory,
        iconStatus,
        primarySector,
        secondarySector,
        themes = [],
        nextContactDate,
        iconPointPeople = [],
        keyVCs,
        websites
    } = props;
    const homepage = get(websites, 'homepage', '');

    const hasSector = (primarySector || secondarySector);
    const noThemes = !themes || themes.length === 0;
    return (
        <StyledTr index={index}>
            <StyledTd sticky index={index}>
                <StyledCompany>
                    <WebsiteLink type="homepage" url={homepage} />
                    <CompanyLink permalink={_id} style={{ paddingLeft: 5 }}>{name}</CompanyLink>
                </StyledCompany>


                <div>{description}</div>
            </StyledTd>
            <StyledTd>{actionItem}</StyledTd>
            <StyledTd>{iconCategory}</StyledTd>
            <StyledTd>{iconStatus}</StyledTd>
            <StyledTd>
                {noThemes ? (
                    <Fragment>
                        <SimpleValue>No themes defined{hasSector ? <PS> - Showing legacy sectors</PS> : '.'}</SimpleValue>
                        {primarySector && (
                            <SimpleValue>
                                {formatOption(primarySector, sectors)}
                            </SimpleValue>
                        )}
                        {secondarySector && (
                            <SimpleValue>
                                {formatOption(secondarySector, sectors)}
                            </SimpleValue>
                        )}
                    </Fragment>
                ) : (
                    themes.map(t => {
                        const { category, name } = t;
                        return <SimpleValue>{category} &gt; {name}</SimpleValue>;
                    })
                )}
            </StyledTd>
            <StyledVCTd value={keyVCs} />
            <StyledTd>{formatDate(nextContactDate)}</StyledTd>
            <StyledTd>
                {iconPointPeople.map(u => u.initials).join('/')}
            </StyledTd>
        </StyledTr>
    );
};

const renderList = props => {
    const { loading, error, data } = props;
    if (loading) {
        return (
            <tr>
                <td colSpan={5}>Loading...</td>
            </tr>
        );
    } else if (error) {
        return (
            <tr>
                <td colSpan={5}>Failure</td>
            </tr>
        );
    } else if (data && data.CompanyAdvancedSearch) {
        return data.CompanyAdvancedSearch.map((company, idx) => {
            return <CompanyRow key={company._id} index={idx} {...company} />;
        });
    }
    return null;
};

const List = props => {
    return (
        <StyledStickyWrapper style={{ maxHeight: 450 }}>
            <StyledTable>
                <CompanyHeader />
                <tbody>{renderList(props)}</tbody>
            </StyledTable>
        </StyledStickyWrapper>
    );
};

const Results = props => {
    return (
        <Box>
            <List {...props} />
        </Box>
    );
};

export default graphql(ADVANCED_SEARCH_COMPANY, {
    options: props => {
        const { criteria } = props;
        return {
            variables: {
                criteria
            }
        };
    }
})(Results);
