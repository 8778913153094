import { Link as LinkLib } from 'react-router-dom';
import styled from 'styled-components';
import { nearlyBlack, baseBlue } from './../variables';
import React from 'react';

const LinkButtonBase = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
    &:hover,
    &:focus {
        text-decoration: none;
    }
`;
const sharedStyles = `
color: ${nearlyBlack};
font-weight: bold;
text-decoration: none;
text-transform: uppercase;
font-size: 0.75rem;
&.active {
    text-decoration: none;
    border-bottom: solid 2px ${baseBlue};
}
`;

export const LinkA = styled.a`
    ${sharedStyles}
`;

export const LinkButton = styled(LinkButtonBase)`
    ${sharedStyles}
    ${props =>
        props.plain === true
            ? `text-transform: none; font-weight: normal; font-size: 14px; color: ${baseBlue};`
            : ''}
`;

// https://stackoverflow.com/questions/49834251/how-to-extend-styled-component-without-passing-props-to-underlying-dom-element
const Link = styled(propsRaw => {
    const { plain, ...props } = propsRaw;
    return <LinkLib {...props} />;
})`
    ${sharedStyles}
    ${props =>
        props.plain === true
            ? `text-transform: none; font-weight: normal; font-size: 14px; color: ${baseBlue};`
            : ''}
`;

export default Link;
