import React from 'react';
import { StyledTheadTr, StyledTh } from '-/style/base/Table';
import { withProps } from 'recompose';
import { mondayReportColumns } from '-/shared/constants';

const DefaultFixedTh = withProps({ width: 160 })(StyledTh);

const headers = {
    name: withProps({
        children: 'Name'
    })(DefaultFixedTh),
    ceo: withProps({
        children: 'CEO'
    })(DefaultFixedTh),
    iconPointPeople: withProps({
        width: 65,
        children: 'Point'
    })(StyledTh),
    keyVCs: withProps({
        children: 'Key VCs'
    })(StyledTh),
    allVCs: withProps({
        children: 'VCs'
    })(StyledTh),
    description: withProps({
        children: 'Description'
    })(StyledTh),
    lastRound: withProps({
        width: 170,
        children: 'Last Round'
    })(StyledTh),
    actionItem: withProps({
        children: 'Action'
    })(StyledTh),
    iconCategory: withProps({
        width: 87,
        children: 'Category'
    })(StyledTh),
    themes: withProps({
        width: 220,
        children: 'Theme'
    })(StyledTh),
    queuedWeeks: withProps({
        width: 65,
        children: 'Weeks'
    })(StyledTh),
    queuedDate: withProps({
        width: 85,
        children: 'Queued'
    })(StyledTh),
    decisionDate: withProps({
        width: 85,
        children: 'Decision'
    })(StyledTh),
    themeCompanies: withProps({
        width: 220,
        children: 'Related Companies'
    })(StyledTh)
};


const makeHeader = (reportKey, exceptions = {}) => {
    const { headers: headerExceptions = {} } = exceptions;
    const reportColumns = mondayReportColumns[reportKey];
    return () => {
        const skippedHeaders = [
            // location is typically embedded with name
            'location',
            // primaryThemePriority is a top level group
            'primaryThemePriority'
        ];
        return (
            <thead>
                <StyledTheadTr>
                    {reportColumns.filter(key => {
                        return !skippedHeaders.includes(key);
                    }).map(key => {
                        const Header = headerExceptions[key] || headers[key];
                        if (!Header) {
                            console.warn(`Unable to find ${key} when rendering headers.`);
                        }
                        return <Header key={key} />;
                    })}
                </StyledTheadTr>
            </thead>
        );
    };
};

const groupHeaderOverrides = {
    name: withProps({
        children: 'Name',
        sticky: true
    })(DefaultFixedTh),
    actionItem: withProps({
        minWidth: 190,
        children: 'Action'
    })(StyledTh),
    allVCs: withProps({
        minWidth: 200,
        children: 'VCs'
    })(StyledTh),
    themes: withProps({
        minWidth: 220,
        children: 'Theme'
    })(StyledTh),
    description: withProps({
        minWidth: 220,
        children: 'Description'
    })(StyledTh)
};
export default makeHeader('active');
export const ActiveHeader = makeHeader('active');
export const ActivePortfolioHeader = makeHeader('activePortfolio');
export const WatchListHeader = makeHeader('watchList', {
    headers: groupHeaderOverrides
});
export const PriorityTargetReviewHeader = makeHeader('ptReview', {
    headers: groupHeaderOverrides
});
export const QueuedHeader = makeHeader('queued');
export const DealUpdateHeader = makeHeader('dealUpdate');
export const NewThisWeekHeader = makeHeader('newThisWeek', {
    headers: groupHeaderOverrides
});
export const PortfolioHeader = makeHeader('portfolio', {
    headers: {
        themeCompanies: withProps({
            width: 240,
            children: 'Theme'
        })(StyledTh)
    }
});
export const TurndownHeader = makeHeader('turndowns');
