import React, { Fragment } from 'react';
import { Col } from '-/style/base/Layout';
import { Field } from 'formik';
import TextInput from '-/components/inputs/Text';
import EmailInput from '-/components/inputs/Email';

import { Subhead, StandardRow } from '-/style/base/Layout';

const Referrals = props => {
    return (
        <Fragment>
            <Subhead>Referred By</Subhead>
            <StandardRow>
                <Col flex={6} xs={12}>
                    <Field
                        component={TextInput}
                        label={`Referrer Name`}
                        name={`referredBy.name`}
                    />
                    <Field
                        component={TextInput}
                        label={`Referrer Company`}
                        name={`referredBy.company`}
                    />
                </Col>
                <Col flex={5} xs={12}>
                    <Field
                        component={TextInput}
                        label={`Referrer Title`}
                        name={`referredBy.title`}
                    />
                    <Field
                        component={EmailInput}
                        label={`Referrer Email`}
                        name={`referredBy.email`}
                    />
                </Col>
            </StandardRow>
        </Fragment>
    );
};

export default Referrals;
