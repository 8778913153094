import React, { Fragment } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import SubSubHeadRaw from '-/style/base/SubSubHead';
import styled from 'styled-components';
import { abbreviateNumber } from '-/utils/format';
import { startCase, round } from 'lodash';
import moment from 'moment';

const SubSubHead = styled(SubSubHeadRaw)`
    text-align: left;
    margin-bottom: 5px;
`;

const SemrushMobileDesktopChart = props => {
    const {
        className: classNameRaw = '',
        title,
        style,
        data = []
    } = props;
    const maxY = data.reduce((total, datum) => {
        if (datum.total > total) {
            return datum.total;
        }
        return total;
    }, 0)
    return (
        <Fragment>
            <SubSubHead>{title}</SubSubHead>
            <ResponsiveContainer height="95%" className={`${classNameRaw} semrush-chart`}>
                <AreaChart
                    height={320}
                    data={data}
                    margin={{ left: 0, right: 5 }}
                    style={style}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey='recorded_at'
                        type='number'
                        scale='time'
                        domain={['dataMin', 'dataMax']}
                        tickFormatter={value => moment.utc(value).format("MMM 'YY")}
                    />
                    <YAxis
                        width={40}
                        allowDecimals={false}
                        domain={[0, maxY]}
                        tickFormatter={abbreviateNumber}
                    />
                    <Tooltip
                        formatter={(value, name, props) => {
                            const pct = round((value / props.payload.total) * 100, 2);
                            const formattedName = startCase(name);
                            const nf = new Intl.NumberFormat('en-US');
                            const formattedValue = nf.format(value)
                            const sharedStyle = { display: 'inline-block', width: 100, textAlign: 'right' };
                            if (name !== 'total') {
                                const value = (
                                    <Fragment>
                                        <span style={sharedStyle}>{formattedValue}</span>
                                        <span style={{ marginLeft: 5 }}>({pct}%)</span>
                                    </Fragment>
                                )
                                return [value, formattedName];
                            } else {
                                const value = (
                                    <span style={sharedStyle}>{formattedValue}</span>
                                );
                                return [value, formattedName];
                            }

                        }}
                        labelFormatter={(value) => {
                            return moment.utc(value).format("MMM 'YY")
                        }} />
                    <Area type="monotone" dataKey="mobile" stackId="1" stroke="#8884d8" fill="#8884d8" />
                    <Area type="monotone" dataKey="desktop" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                    <Area type="monotone" dataKey="total" stackId="2" stroke="none" fill="none" />
                </AreaChart>
            </ResponsiveContainer>
        </Fragment>
    );
};

export default SemrushMobileDesktopChart;