// Button above
// arrowSide: 'top',
// // Attachment points are relative to the overlay
// vAttachment: 'top',
// hAttachment: 'left',
// vTargetAttachment: 'bottom',
// hTargetAttachment: 'left',

// Button on right
// <OverlayWithState
//     arrowSide="right"
//     vAttachment="top"
//     hAttachment="right"
//     vTargetAttachment="top"
//     hTargetAttachment="left"
//     closeOnOutsideClick
//     closeOnEsc
// >
//     <QuickEdit />
// </OverlayWithState>

// Button on left
// <OverlayWithState
// arrowSide="left"
// vAttachment="top"
// hAttachment="left"
// vTargetAttachment="top"
// hTargetAttachment="right"
// closeOnOutsideClick
// closeOnEsc
// >

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TetherComponent from 'react-tether';
import ToolTip from './ToolTip';
import { get } from 'lodash';
import { EditAction as Edit } from '-/style/Icon';

const KEYCODES = {
    ESCAPE: 27
};

class OverlayWithState extends Component {
    constructor(props) {
        super(props);
        this.tether = React.createRef();
        this.state = { isOpen: !!props.defaultOpen };
    }

    componentDidMount() {
        if (this.props.closeOnEsc) {
            document.addEventListener('keydown', this.handleKeydown);
        }
        if (this.props.closeOnOutsideClick) {
            document.addEventListener('click', this.handleOutsideMouseClick);
        }
    }

    componentWillUnmount() {
        if (this.props.closeOnEsc) {
            document.removeEventListener('keydown', this.handleKeydown);
        }
        if (this.props.closeOnOutsideClick) {
            document.removeEventListener('click', this.handleOutsideMouseClick);
        }
    }

    openOverlay = e => {
        if (this.state.isOpen) {
            return;
        }
        if (e && e.nativeEvent) {
            e.nativeEvent.stopImmediatePropagation();
        }
        this.setState({ isOpen: true }, this.props.onOpen);
    };

    closeOverlay = () => {
        if (!this.state.isOpen) {
            return;
        }
        this.setState({ isOpen: false }, this.props.onClose);
    };

    handleOutsideMouseClick = e => {
        if (!this.state.isOpen) {
            return;
        }
        const parentEl = get(this, 'tether.current._elementParentNode');
        const targetId = get(e, 'target.id');
        const isReactSelect = targetId.indexOf('react-select-') !== -1;
        if (
            !parentEl ||
            parentEl.contains(e.target) ||
            (e.button && e.button !== 0) ||
            isReactSelect
        ) {
            return;
        }
        this.closeOverlay();
    };

    handleKeydown = e => {
        if (e.keyCode === KEYCODES.ESCAPE && this.state.isOpen) {
            this.closeOverlay();
        }
    };

    render() {
        const {
            arrowSide,
            vAttachment,
            hAttachment,
            vTargetAttachment,
            hTargetAttachment,
            triggerStyle,
            pinned
        } = this.props;

        const { isOpen } = this.state;
        const offsetMap = {
            top: '0 46px',
            left: '53px 0',
            right: '53px 0'
        };
        const offset = offsetMap[arrowSide];
        const constraints = pinned
            ? [
                  {
                      to: 'window',
                      attachment: 'together',
                      pin: true
                  }
              ]
            : undefined;
        // http://tether.io/#options
        return (
            <TetherComponent
                ref={this.tether}
                // offset="53px 0"
                offset={offset}
                attachment={`${vAttachment} ${hAttachment}`}
                targetAttachment={`${vTargetAttachment} ${hTargetAttachment}`}
                constraints={constraints}
                /* renderTarget: This is what the item will be tethered to, make sure to attach the ref */
                renderTarget={ref => (
                    <Edit
                        size={16}
                        ref={ref}
                        onClick={this.openOverlay}
                        style={triggerStyle}
                    >
                        Edit
                    </Edit>
                )}
                /* renderElement: If present, this item will be tethered to the the component returned by renderTarget */
                renderElement={ref =>
                    isOpen && (
                        <ToolTip ref={ref} side={arrowSide}>
                            {React.Children.map(this.props.children, child => {
                                return React.cloneElement(child, {
                                    closeOverlay: this.closeOverlay
                                });
                            })}
                        </ToolTip>
                    )
                }
            />
        );
    }
}

OverlayWithState.propTypes = {
    defaultOpen: PropTypes.bool,
    closeOnEsc: PropTypes.bool,
    closeOnOutsideClick: PropTypes.bool,
    pinned: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    side: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    vAttachment: PropTypes.oneOf(['top', 'middle', 'bottom']),
    hAttachment: PropTypes.oneOf(['left', 'center', 'right']),
    vTargetAttachment: PropTypes.oneOf(['top', 'middle', 'bottom']),
    hTargetAttachment: PropTypes.oneOf(['left', 'center', 'right'])
};

OverlayWithState.defaultProps = {
    arrowSide: 'top',
    // Attachment points are relative to the overlay
    vAttachment: 'top',
    hAttachment: 'left',
    vTargetAttachment: 'bottom',
    hTargetAttachment: 'left',
    closeOnEsc: true,
    closeOnOutsideClick: false,
    onOpen: () => {},
    onClose: () => {},
    pinned: true
};

export default OverlayWithState;
