import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { startCase, partition } from 'lodash';
import { StyledTable } from '-/style/base/Table';
import {
    StyledTr,
    StyledTd,
    StyledTheadTr,
    StyledTh
} from '-/style/base/Table';
import styled from 'styled-components';
import { baseOrange } from '-/style/variables'
import LoadingLogo from '-/components/LoadingLogo';
const Warning = styled.div`
    margin: 5px;
    padding: 5px;
    border: 1px ${baseOrange} solid;
`;

const DumbRow = props => {
    const { _id, index, ...data } = props;
    return (
        <StyledTr index={index}>
            {Object.keys(data).map(key => {
                return <StyledTd key={key}>{data[key]}</StyledTd>;
            })}
        </StyledTr>
    );
};

const DumbHeader = props => {
    const { sampleData } = props;
    return (
        <thead>
            <StyledTheadTr>
                {Object.keys(sampleData).map(key => {
                    return <StyledTh>{startCase(key)}</StyledTh>;
                })}
            </StyledTheadTr>
        </thead>
    );
};

const Simple = props => {
    const {
        Header = DumbHeader,
        Row = DumbRow,
        EmptyRow,
        data: dataRaw = [],
        rowProps,
        className,
        style,
        showWarning = true,
        error,
        loading,
        onHeaderClick,
        sortState,
        minWidth
    } = props;
    const [
        data,
        badData
    ] = partition(dataRaw, candidate => !!candidate);
    const isEmpty = data.length === 0;
    let sampleData = {};
    if (!isEmpty) {
        sampleData = data[0];
    }
    const warningNecessary = showWarning && badData.length !== 0;
    const onClick = e => {
        if (onHeaderClick) {
            const th = e.target.closest('th');
            if (th) {
                const key = th.getAttribute('data-key');
                if (key && onHeaderClick) {
                    onHeaderClick(e, key);
                }
            }
        }
    };

    if (error) {
        return <span>Error in querying data.</span>;
    } else if (loading) {
        return <LoadingLogo />;
    } else {
        return (
            <Fragment>
                {warningNecessary ? <Warning>Some data failed to load completely. This could be due to an update currently taking place or a bad link. Please report this issue if it persists.</Warning> : null}
                <StyledTable className={className} style={style} onClick={onClick} minWidth={minWidth}>
                    {Header && <Header sampleData={sampleData} {...sortState} />}
                    <tbody>
                        {isEmpty && EmptyRow ? EmptyRow : null}
                        {data.map((o, index) => {
                            const { _id } = o;
                            return <Row key={_id} index={index} {...rowProps} {...o} />;
                        })}
                    </tbody>
                </StyledTable>
            </Fragment>
        );
    }
};

Simple.propTypes = {
    data: PropTypes.array.isRequired
};
export default Simple;
