import React from 'react';
import Label from './Label';
import Feedback from './Feedback';
import MaskedInput from 'react-text-mask';
import { uniqueId } from 'lodash';
import { StyledInput as StyledInputDefault } from './styled';
const Masked = options => {
    const {
        mask,
        suffixLength = 0,
        pipe,
        StyledInput = StyledInputDefault,
        onBlur: onBlurOption,
        ...other
    } = options;

    return props => {
        const {
            className,
            fieldId: fieldIdRaw,
            label,
            field,
            form,
            error,
            onChange,
            children,
            ...rest
        } = props;

        if (Object.keys(rest).length) {
            console.warn('Missing fields', rest);
        }

        const { value: valueRaw, onBlur: onBlurField, ...restOfField } = field;
        // Note: This is done in order to avoid `A component is changing an uncontrolled input of type undefined to be controlled.`
        // If the initial value is undefined we will default it to ''.
        const value = valueRaw || '';
        const fieldId = fieldIdRaw || uniqueId('text-');

        return (
            <React.Fragment>
                <Label htmlFor={fieldId} error={error}>
                    {label}
                </Label>
                <MaskedInput
                    mask={mask}
                    pipe={pipe}
                    id={fieldId}
                    value={value}
                    // This code will focus the first placeholder slot in cases
                    // where a user clicks on the label or at the end of the input
                    // https://github.com/text-mask/text-mask/issues/793
                    onFocus={e => {
                        const placeholder = '_';
                        const { target } = e;
                        const { value } = target;
                        let i = value.indexOf(placeholder);
                        if (i === -1) {
                            // If we can't find a placeholder, put selection at end
                            // subtract suffix length - suffixLength, this is to handle suffixes vs no suffixes
                            i = value.length - suffixLength;
                        }
                        setTimeout(function () {
                            if (target && target.setSelectionRange) {
                                target.setSelectionRange(i, i);
                            }
                        }, 0);
                    }}
                    onBlur={e => {
                        const { name } = field;
                        if (typeof onBlurOption === 'function') {
                            const setter = value => {
                                form.setFieldValue(name, value);
                            };
                            onBlurOption(e, setter);
                        }
                        onBlurField(e);
                    }}
                    render={(ref, props) => {
                        return <StyledInput ref={ref} {...props} />;
                    }}
                    showMask
                    {...restOfField}
                    {...other}
                />
                <Feedback error={error} />
            </React.Fragment>
        );
    };
};

export default Masked;