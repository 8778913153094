import createMaskedInput from './Masked';

import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

const pipe = createAutoCorrectedDatePipe('mm/dd/yyyy');

const emptyYearRe = /\d{2}\/\d{2}\/____$/;
const twoYearRe = /\d{2}\/\d{2}\/(\d{2})__$/;
const replaceTwoRe = /\d{2}__$/;
const replaceFullRe = /____$/;
export default createMaskedInput({
    pipe,
    guide: true,
    showMask: false,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    placeholder: 'mm/dd/yyyy',
    keepCharPositions: true,
    onBlur: (e, setter) => {
        const { value } = e.target;
        const twoYearMatch = twoYearRe.exec(value);
        if (twoYearMatch) {
            const [, yearRaw] = twoYearMatch;
            const yearEnd = parseInt(yearRaw, 10);
            const yearPrefix = yearEnd < 60 ? '20' : '19';
            const year = `${yearPrefix}${yearEnd}`;
            const newValue = value.replace(replaceTwoRe, year);
            setter(newValue);
        } else {
            const emptyYearMatch = emptyYearRe.exec(value);
            if (emptyYearMatch) {
                const fullYear = new Date().getFullYear();
                const newDate = value.replace(replaceFullRe, fullYear);
                setter(newDate);
            }
        }
    }
});
