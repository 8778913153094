import gql from 'graphql-tag';

export const GET_ME = gql`
    query GET_ME {
        me {
            _id
            name
            email
            initials
            role
            isActive
            isPointPerson
        }
    }
`;

export const GET_USER = gql`
    query GET_USER($_id: ID!) {
        User(_id: $_id) {
            _id
            name
            email
            initials
            role
            isActive
            isPointPerson
            sessionCount
            lastLoginDate
            lastActiveDate
        }
        me {
            _id
        }
    }
`;

export const GET_USER_LIST = gql`
    query GET_USER_LIST {
        Users {
            _id
            name
            email
            initials
            role
            isActive
            isPointPerson
        }
    }
`;

export const GET_ICON_POINT_USERS = gql`
    query GET_ICON_POINT_USERS {
        IconPointUser {
            _id
            name
        }
    }
`;

export const UPDATE_MY_SETTINGS = gql`
    mutation UPDATE_MY_SETTINGS($input: UpdateMySettingsInput!) {
        updateMySettings(input: $input) {
            _id
            name
            initials
        }
    }
`;

export const UPDATE_USER_SETTINGS = gql`
    mutation UPDATE_USER_SETTINGS($_id: ID!, $input: UpdateUserSettingsInput!) {
        updateUserSettings(_id: $_id, input: $input) {
            _id
            name
            email
            initials
            role
            isActive
            isPointPerson
        }
    }
`;

export const ADD_USER = gql`
    mutation ADD_USER($input: UpdateUserSettingsInput!) {
        addUser(input: $input) {
            _id
            email
            name
        }
    }
`;


export const PURGE_USER_SESSIONS = gql`
    mutation PURGE_USER_SESSIONS($_id: ID!) {
        purgeUserSessions(_id: $_id) {
            _id
            sessionCount
        }
    }
`;