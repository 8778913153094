import React from 'react';
import FirmQuery from './FirmQuery';
import { withProps } from 'recompose';
import { GET_FIRM_INVESTMENTS } from '-/gql/firm';
import { formatDate, formatNumber } from '-/utils/format';
import { CompanyLink, FirmLink } from '-/components/Link';
import styled from 'styled-components';
import SimpleTable from '-/components/table/Simple';
import {
    StyledTr,
    StyledTd,
    StyledTh,
    StyledEmptyRow
} from '-/style/base/Table';
import KnownIcon from '-/style/base/KnownIcon';
import nl2br from '-/components/nl2br';

const StyledIndicators = styled.div`
    display: inline-block;
    float: left;
    width: 60px;
`;

const InvestmentHeader = props => {
    return (
        <thead>
            <tr>
                <StyledTh style={{ width: 80 }}>Date</StyledTh>
                <StyledTh>Company</StyledTh>
                <StyledTh>Description</StyledTh>
                <StyledTh>VC Investors</StyledTh>
                <StyledTh>Amount Invested</StyledTh>
            </tr>
        </thead>
    );
};

const InvestmentRow = props => {
    const {
        activeFirm,
        index,
        date,
        company,
        firmInvestors: firmInvestorsRaw = [],
        amount,
        series
    } = props;
    // Note: This is broken out separately bc company could come back as null.
    const { _id, name, iconCategory, iconStatus, description } = company || {};
    const firmInvestors = firmInvestorsRaw.filter(
        investor => investor && investor._id !== activeFirm
    );
    return (
        <StyledTr index={index}>
            <StyledTd>{formatDate(date)}</StyledTd>
            <StyledTd>
                <StyledIndicators>
                    <KnownIcon width={16} height={16} type={iconCategory} />
                    <KnownIcon width={16} height={16} type={iconStatus} />
                </StyledIndicators>
                <CompanyLink permalink={_id}>{name}</CompanyLink>
            </StyledTd>
            <StyledTd>{nl2br(description)}</StyledTd>
            <StyledTd>
                {firmInvestors.map((investor, idx) => {
                    const { _id, name, isTracked } = investor;
                    return (
                        <div key={idx}>
                            {isTracked ? (
                                <FirmLink permalink={_id}>{name}</FirmLink>
                            ) : (
                                <span>{name}</span>
                            )}
                        </div>
                    );
                })}
            </StyledTd>
            <StyledTd>
                ${formatNumber(amount)} {series ? ` Series ${series}` : null}
            </StyledTd>
        </StyledTr>
    );
};

const emptyRow = (
    <StyledEmptyRow colSpan={5}>No investments found.</StyledEmptyRow>
);
const Investments = props => {
    const { className, style, investments, permalink } = props;
    return (
        <div className={className} style={style}>
            <SimpleTable
                Header={InvestmentHeader}
                EmptyRow={emptyRow}
                data={investments}
                Row={InvestmentRow}
                rowProps={{ activeFirm: permalink }}
            />
        </div>
    );
};

export default withProps({
    component: Investments,
    query: GET_FIRM_INVESTMENTS
})(FirmQuery);
