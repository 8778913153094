import React, { Fragment } from 'react';
import PersonQuery from './PersonQuery';
import { withProps } from 'recompose';
import { GET_PERSON_SUMMARY } from '-/gql/person';
import {
    SimpleLabel,
    SimpleValue,
    EmailDisplayValue,
    PhoneDisplayValue
} from '-/components/profiles';
import { FirmLink } from '-/components/Link';
import styled from 'styled-components';
const StyledFieldWrapper = styled.div`
    margin-bottom: 10px;
`;
const Summary = props => {
    const { className, style, primaryTitle, primaryFirm, email, phone } = props;
    const { _id: firmPermalink, name: firmName } = primaryFirm || {};
    const noContactInfo = !email && !phone;
    return (
        <div className={className} style={style}>
            <StyledFieldWrapper>
                <SimpleLabel>Primary Role</SimpleLabel>
                <SimpleValue>

                    {primaryTitle}
                    {firmPermalink ? (<Fragment>{' at '}<FirmLink permalink={firmPermalink}>{firmName}</FirmLink></Fragment>) : ' No primary firm assigned.'}
                </SimpleValue>
            </StyledFieldWrapper>

            <StyledFieldWrapper>
                <SimpleLabel>Contact</SimpleLabel>
                {noContactInfo && (
                    <SimpleValue>No contact info available.</SimpleValue>
                )}
                <EmailDisplayValue value={email} />
                <PhoneDisplayValue value={phone} />
            </StyledFieldWrapper>
        </div>
    );
};

export default withProps({
    component: Summary,
    query: GET_PERSON_SUMMARY
})(PersonQuery);
