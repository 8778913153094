import React from 'react';
import { Query } from 'react-apollo';
import LoadingLogo from '-/components/LoadingLogo';

// Ref: https://github.com/apollographql/apollo-client/issues/2160
export const removeTypename = value => {
    if (value === null || value === undefined) {
        return value;
    } else if (Array.isArray(value)) {
        return value.map(v => removeTypename(v));
    } else if (typeof value === 'object') {
        const newObj = {};
        Object.entries(value).forEach(([key, v]) => {
            if (key !== '__typename') {
                newObj[key] = removeTypename(v);
            }
        });
        return newObj;
    }
    return value;
};

export const CustomQuery = props => {
    const {
        query,
        permalink,
        component: Component,
        resultKey,
        ...extraProps
    } = props;
    if (!query || !permalink || !resultKey) {
        throw new Error('CustomQuery requires query, permalink or resultKey.');
    }
    const variables = { permalink };
    return (
        <Query query={query} variables={variables}>
            {queryResult => {
                const { loading, error, data = {} } = queryResult;
                const dataCleaned = removeTypename(data[resultKey]);
                if (loading) {
                    return <LoadingLogo />;
                } else if (error) {
                    return <span>error</span>;
                } else {
                    if (Component) {
                        return (
                            <Component
                                permalink={permalink}
                                {...extraProps}
                                {...dataCleaned}
                            />
                        );
                    } else {
                        return (
                            <pre>{JSON.stringify(dataCleaned, null, 4)}</pre>
                        );
                    }
                }
            }}
        </Query>
    );
};
