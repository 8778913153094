import React from 'react';
import NoteFormBase from '-/components/notes/Form';
import { withRouter } from 'react-router-dom';

import {
    ADD_NOTE_TO_PERSON,
    UPDATE_NOTE_FOR_PERSON,
    GET_PERSON_NOTES
} from '-/gql/person';

import { graphql, compose, withApollo } from 'react-apollo';
import { get } from 'lodash';
import { companySideNoteTypes } from '-/shared/constants';

const NoteForm = props => {
    return <NoteFormBase noteTypes={companySideNoteTypes} type="person" {...props} />;
};

const returnPermalink = props => {
    const permalink = get(props, 'match.params.permalink');
    return {
        variables: {
            permalink
        }
    };
};

export default compose(
    withRouter,
    withApollo,
    graphql(ADD_NOTE_TO_PERSON, {
        name: 'addNote',
        options: props => {
            const permalink = get(props, 'match.params.permalink');
            return {
                variables: {
                    permalink
                },
                refetchQueries: [
                    {
                        query: GET_PERSON_NOTES,
                        variables: { permalink }
                    }
                ]
            };
        }
    }),
    graphql(UPDATE_NOTE_FOR_PERSON, {
        name: 'updateNote',
        options: returnPermalink
    })
)(NoteForm);
