import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { GreenButton } from '-/style/base/Button';
import Subhead from '-/style/base/Subhead';
import { darkBody } from '-/style/variables';
// Singleton based off of https://hackernoon.com/managing-react-modals-with-singleton-component-design-5efdd317295b
const StyledScreen = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: grey;
    opacity: 0.8;
    z-index: 4;
`;
const StyledMessageBox = styled.div`
    top: 200px;
    left: 50%;
    position: fixed;
    width: 300px;
    margin-left: -150px;
    background-color: white;
    padding: 20px;
    border: 1px solid ${darkBody};
    overflow: auto;
    z-index: 5;
`;
const FixedBody = styled.div`
    max-height: 150px;
    overflow: auto;
    padding: 2px;
`;
const defaultTitle = 'Confirm';
const defaultMessage = 'Would you like to proceed with this action?';

class MessageBox extends Component {
    static show(config) {
        MessageBox.__singletonRef.__show(config);
    }

    static hide(config) {
        MessageBox.__singletonRef.__hide();
    }

    constructor(props) {
        super(props);
        if (MessageBox.__singletonRef) {
            return MessageBox.__singletonRef;
        }
        MessageBox.__singletonRef = this;
        this.state = {
            open: false,
            onNoClick: null,
            onYesClick: null
        };
    }

    __show(config) {
        const {
            title = defaultTitle,
            message = defaultMessage,
            onYesClick,
            onNoClick
        } = config || {};
        this.setState({
            open: true,
            onYesClick,
            onNoClick,
            title,
            message
        });
        const { style: bodyStyle } = document.body;
        MessageBox.__formerOverflow = bodyStyle.overflow;
        bodyStyle.overflow = 'hidden';
    }
    __hide(config) {
        this.setState({
            open: false,
            onNoClick: null,
            onYesClick: null,
            title: defaultTitle,
            body: ''
        });
        const { style: bodyStyle } = document.body;
        bodyStyle.overflow = MessageBox.__formerOverflow || '';
    }

    onYesClick = () => {
        const { onYesClick } = this.state;
        if (onYesClick) {
            onYesClick();
        }
        this.__hide();
    };

    onNoClick = () => {
        const { onNoClick } = this.state;
        if (onNoClick) {
            onNoClick();
        }
        this.__hide();
    };

    render() {
        const { title, message, open } = this.state;
        if (!open) {
            return null;
        }
        return (
            <Fragment>
                <StyledScreen />
                <StyledMessageBox>
                    <Subhead>{title}</Subhead>
                    <FixedBody>{message}</FixedBody>
                    <div style={{ textAlign: 'right', paddingTop: 15 }}>
                        <GreenButton
                            style={{ marginLeft: 5 }}
                            onClick={this.onYesClick}
                        >
                            Yes
                        </GreenButton>
                        <GreenButton
                            style={{ marginLeft: 5 }}
                            onClick={this.onNoClick}
                        >
                            No
                        </GreenButton>
                    </div>
                </StyledMessageBox>
            </Fragment>
        );
    }
}

export default MessageBox;
