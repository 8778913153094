import React, { useState, Fragment } from 'react';
import { StandardRow, Col } from '-/style/base/Layout';
import { GreenButton } from '-/style/base/Button';
import { TrashAction, RestoreAction } from '-/style/Icon';
import { Formik, Form, Field, FieldArray } from 'formik';
import { TextInput, AutoComplete, Checkbox } from '-/components/inputs';
import Divider from '-/style/base/Divider';
import Err from '-/style/base/Error';
import { themePriorities } from './../shared/constants';
import { get, pick } from 'lodash'
import { compose, graphql } from 'react-apollo';
import { ADD_CATEGORY, GET_ALL_CATEGORIES } from '../gql/theme';

const DEFAULT_PRIORITY = 'Non-Focus';
const OTHER_NAME = 'Other';

const ThemeRowForm = props => {
    const { index, isOtherRow, toBeDeleted, companyCount, importantCompanyCount, children } = props;
    const getFieldName = name => `themes.${index}.${name}`;
    return (
        <Fragment>
            <StandardRow>
                <Col flex={4} xs={11}>
                    <Field
                        disabled={toBeDeleted || isOtherRow}
                        component={TextInput}
                        autoFocus={true}
                        label="Name"
                        name={getFieldName('name')}
                    />
                </Col>
                <Col flex={4} xs={6} xsOrder={1}>
                    <Field
                        disabled={toBeDeleted}
                        component={AutoComplete}
                        options={themePriorities}
                        label="Priority"
                        name={getFieldName('priority')}
                    />
                </Col>
                <Col flex={2} xs={6}>
                    <Field
                        disabled={toBeDeleted || isOtherRow}
                        component={Checkbox}
                        label="Ingredient"
                        name={getFieldName("ingredient")}
                    />
                </Col>
                <Col
                    flex={2}
                    alignSelf="flex-start"
                    style={{ textAlign: 'center', paddingTop: 23 }}
                >
                    {children}
                </Col>
            </StandardRow>
            <StandardRow>
                <Col flex={12}>
                    {importantCompanyCount} companies ({companyCount} total)
                </Col>
            </StandardRow>
        </Fragment>
    );
};

const ThemeForm = props => {
    const {
        addCategory,
        initialValues: initialValuesRaw,
        onCancel,
        mode = 'new'
    } = props;

    const [hasError, setHasError] = useState(false);
    const initialValues = initialValuesRaw || {
        category: '',
        themes: [{
            name: OTHER_NAME,
            priority: DEFAULT_PRIORITY,
            ingredient: false,
            companyCount: 0,
            importantCompanyCount: 0
        }]
    };
    initialValues.themes = initialValues.themes.map(t => {
        const { name } = t;
        return {
            ...t,
            isOtherRow: name === OTHER_NAME
        }
    });
    const { _id: originalId } = initialValues;

    return (
        <Fragment>
            {hasError && <Err>Error occurred when trying to save.</Err>}
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={async (values, { resetForm }) => {
                    try {
                        setHasError(false);
                        const {
                            category,
                            themes: themesRaw
                        } = values;
                        const themes = themesRaw.map(t => {
                            return pick(t, ['_id', 'name', 'priority', 'ingredient', 'toBeDeleted']);
                        }).filter(t => t.name);
                        await addCategory({
                            variables: {
                                input: {
                                    _id: originalId,
                                    category,
                                    themes
                                }
                            },
                            refetchQueries: () => {
                                return [{
                                    query: GET_ALL_CATEGORIES
                                }];
                            }
                        });
                        resetForm();
                        onCancel();
                    } catch (ex) {
                        // TODO: Get GraphQL error back.
                        setHasError(true);
                    }
                }}
                render={props => {
                    const themes = get(props, 'values.themes', []);
                    return (
                        <Form>
                            <StandardRow>
                                <Col flex>
                                    <Field
                                        component={TextInput}
                                        label="Category Name"
                                        name="category"
                                    />
                                </Col>
                            </StandardRow>

                            <FieldArray
                                name="themes"
                                render={arrayHelpers => {
                                    const onAdd = () => {
                                        arrayHelpers.push({
                                            name: '',
                                            priority: DEFAULT_PRIORITY,
                                            ingredient: false,
                                            companyCount: 0,
                                            importantCompanyCount: 0
                                        });
                                    };
                                    const addButton = (
                                        <GreenButton type="button" onClick={onAdd}>
                                            Add Theme
                                        </GreenButton>
                                    );

                                    const { length } = themes;
                                    if (length) {
                                        return themes.map((currTheme, index) => {
                                            const { isOtherRow, toBeDeleted, companyCount, importantCompanyCount } = currTheme;
                                            const isEditWithZeroCompanies = mode === 'edit' && companyCount === 0;
                                            const isNew = mode === 'new';
                                            const canBeDeleted = (isNew || isEditWithZeroCompanies) && !isOtherRow;
                                            const onRemove = () => {
                                                const { _id } = currTheme;
                                                if (_id) {
                                                    arrayHelpers.replace(index, {
                                                        ...currTheme,
                                                        toBeDeleted: true
                                                    });
                                                } else {
                                                    arrayHelpers.remove(index);
                                                }
                                            };
                                            const onRestore = () => {
                                                arrayHelpers.replace(index, {
                                                    ...currTheme,
                                                    toBeDeleted: false
                                                });
                                            };

                                            const needsAddBtn = length - 1 === index;

                                            let deleteSection;
                                            if (toBeDeleted) {
                                                deleteSection = <RestoreAction size={22} onClick={onRestore} title="Restore Theme">Restore</RestoreAction>;
                                            } else {
                                                let title = 'Delete Theme';
                                                if (isOtherRow) {
                                                    title = 'Other themes cannot be deleted.';
                                                } else if (companyCount !== 0) {
                                                    title = `${companyCount} companies must be moved before this theme may be deleted.`;
                                                }

                                                deleteSection = (
                                                    <TrashAction
                                                        size={22}
                                                        disabled={!canBeDeleted}
                                                        onClick={onRemove}
                                                        title={title}
                                                    >
                                                        Delete
                                                    </TrashAction>
                                                );
                                            }


                                            return (
                                                <Fragment key={index}>
                                                    <ThemeRowForm importantCompanyCount={importantCompanyCount} companyCount={companyCount} isOtherRow={isOtherRow} toBeDeleted={toBeDeleted} index={index}>
                                                        {deleteSection}
                                                    </ThemeRowForm>
                                                    {toBeDeleted ? <div>This theme will be deleted upon save.</div> : null}
                                                    {!needsAddBtn ? <Divider /> : addButton}
                                                </Fragment>
                                            );
                                        });
                                    } else {
                                        return addButton;
                                    }
                                }}
                            />
                            <StandardRow>
                                <Col flex>
                                    <GreenButton type="reset" onClick={onCancel}>
                                        Cancel
                                    </GreenButton>
                                </Col>
                                <Col flex>
                                    <GreenButton type="submit">
                                        Save
                                    </GreenButton>
                                </Col>

                            </StandardRow>

                        </Form>
                    );
                }}
            />
        </Fragment>
    );
};

export default compose(
    graphql(ADD_CATEGORY, { name: 'addCategory' })
)(ThemeForm);
