import React, { Fragment } from 'react';
import {
    StyledTd
} from '-/style/base/Table';
import { FirmLink, PersonLink } from '-/components/Link';
import { get } from 'lodash';
import { SimpleValue } from '-/components/profiles';

export const StyledVCTd = props => {
    const keyVCPair = get(props, 'value', []);
    return (
        <StyledTd>
            {keyVCPair.map((pair, idx) => {
                const { firm, VCs = [] } = pair;
                const { _id: firmPermalink, name: firmName } = firm || {};
                const vcCount = VCs.length;
                let vcLinks;
                if (vcCount) {
                    vcLinks = (
                        <Fragment>
                            {'('}
                            {VCs.map((vc, idx) => {
                                const { _id: vcPermalink, name: vcName } =
                                    vc || {};
                                let separator = '';
                                if (vcCount > 1) {
                                    if (idx === vcCount - 2) {
                                        separator = ' \u0026 ';
                                    } else if (idx !== vcCount - 1) {
                                        separator = ', ';
                                    }
                                }
                                return (
                                    <Fragment key={`vc-${vcPermalink}`}>
                                        <PersonLink permalink={vcPermalink}>
                                            {vcName}
                                        </PersonLink>
                                        {separator}
                                    </Fragment>
                                );
                            })}
                            {')'}
                        </Fragment>
                    );
                }
                return (
                    <div key={idx}>
                        <FirmLink permalink={firmPermalink}>
                            {firmName}
                        </FirmLink>{' '}
                        {vcLinks}
                    </div>
                );
            })}
        </StyledTd>
    );
};

export const ThemesTd = props => {
    const { value = [] } = props;
    return (
        <StyledTd>
            {value.map(t => {
                const { _id, category, name } = t;
                return <SimpleValue key={_id}>{category} &gt; {name}</SimpleValue>;
            })}
        </StyledTd>
    );
};
