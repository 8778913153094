import React from 'react';
import styled, { css } from 'styled-components';
import SubheadRaw from '-/style/base/Subhead';
import HeadlineRaw from '-/style/base/Headline';
import { row, altRow } from '-/style/variables';
import { media, fluid as fluidMedia } from '-/style/utils';
import {
    Container as ContainerRaw,
    Row as RowRaw,
    Col as ColRaw
} from 'react-flexybox';
export const Container = styled(ContainerRaw)`
    ${props => props.fluid && 'width: 100%;'};
    ${props =>
        !props.fluid &&
        fluidMedia.xs`
    width: 95%;
  `};
    ${props =>
        !props.fluid &&
        fluidMedia.greaterXS`
    max-width: unset;
  `};
    ${props =>
        !props.fluid &&
        fluidMedia.greaterSM`
    max-width: unset;
  `};
    ${props =>
        !props.fluid &&
        fluidMedia.greaterMD`
    max-width: 68rem;
  `};
    ${props =>
        !props.fluid &&
        fluidMedia.greaterLG`
    max-width: 86rem;
  `};
  ${props =>
        !props.fluid &&
        fluidMedia.greaterXLG`
    max-width: 114rem;
    `};
  
`;
export const Row = RowRaw;
export const Col = styled(props => {
    // Take out any custom implemented props so they don't go to element
    const { xsOrder, smOrder, mdOrder, hideWhenPrint, ...rest } = props;
    return <ColRaw {...rest} />;
})`
    ${props =>
        props.hideWhenPrint &&
        css`
            @media print {
                display: none;
            }
        `}
    ${props =>
        typeof props.printOrder !== 'undefined' &&
        css`
            @media print {
                order: ${props.printOrder};
            }
        `}
    ${props =>
        typeof props.xsOrder !== 'undefined' &&
        media.xs`order: ${props.xsOrder};`}
    ${props =>
        typeof props.smOrder !== 'undefined' &&
        media.sm`order: ${props.smOrder};`}
    ${props =>
        typeof props.mdOrder !== 'undefined' &&
        media.md`order: ${props.mdOrder};`}
`;
Col.displayName = 'Col';

export const Headline = styled(HeadlineRaw)`
    padding: 15px;
`;

export const Subhead = styled(SubheadRaw)``;

export const SectionA = styled.div`
    padding: 20px;
    background-color: ${row};
`;
export const SectionB = styled.div`
    padding: 20px;
    background-color: ${altRow};
`;

export const StandardRow = props => {
    return <Row gutter={4} alignItems="flex-end" {...props} />;
};

export const HideWhen = styled.div`
    ${props => {
        const sizeFn = media[props.size || 'xs'];
        return sizeFn`
            display: none;
        `;
    }}
`;
