import React, { useState, Fragment } from 'react';
import { StandardRow, Col } from '-/style/base/Layout';
import { GreenButton } from '-/style/base/Button';
import { ArchiveAction, RestoreAction } from '-/style/Icon';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Label, AutoComplete } from '-/components/inputs';
import Divider from '-/style/base/Divider';
import Err from '-/style/base/Error';
import { firmTiers } from './../../shared/constants';
import { get, pick } from 'lodash'
import { compose, graphql } from 'react-apollo';
import { UPDATE_FIRM_TIERS } from '../../gql/firm';

const TierRowForm = props => {
    const { index, children, _id, name } = props;

    const getFieldName = name => `firms.${index}.${name}`;
    return (
        <StandardRow>
            <Col
                flex={5}
                alignSelf="flex-start"
                style={{ paddingTop: 23 }}
            >
                <Label>
                    {name} ({_id})
                </Label>
            </Col>
            <Col flex={5} xs={6} xsOrder={1}>
                <Field
                    component={AutoComplete}
                    options={firmTiers}
                    label="Tier"
                    name={getFieldName('tier')}
                />
            </Col>
            <Col
                flex={2}
                alignSelf="flex-start"
                style={{ textAlign: 'center', paddingTop: 23 }}
            >
                {children}
            </Col>
        </StandardRow>
    );
};

const TierForm = props => {
    const {
        initialValues,
        isTracked: isTrackedBase,
        onCancel,
        updateFirmTiers
    } = props;

    const [hasError, setHasError] = useState(false);
    return (
        <Fragment>
            {hasError && <Err>Error occurred when trying to save.</Err>}
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={async (values, { resetForm }) => {
                    try {
                        setHasError(false);
                        const {
                            firms: firmsRaw
                        } = values;
                        const firms = firmsRaw.map(t => {
                            return pick(t, ['_id', 'tier', 'isTracked']);
                        });

                        await updateFirmTiers({
                            variables: {
                                firms
                            }
                        });
                        resetForm();
                        onCancel();
                    } catch (ex) {
                        // TODO: Get GraphQL error back.
                        setHasError(true);
                    }
                }}
                render={props => {
                    const firms = get(props, 'values.firms', []);
                    return (
                        <Form>
                            <FieldArray
                                name="firms"
                                render={arrayHelpers => {
                                    return firms.map((currFirm, index) => {
                                        const { _id, name, isTracked } = currFirm;

                                        const onUntrack = () => {
                                            const { _id } = currFirm;
                                            if (_id) {
                                                arrayHelpers.replace(index, {
                                                    ...currFirm,
                                                    isTracked: false
                                                });
                                            } else {
                                                arrayHelpers.remove(index);
                                            }
                                        };
                                        const onRestore = () => {
                                            arrayHelpers.replace(index, {
                                                ...currFirm,
                                                isTracked: true
                                            });
                                        };

                                        let actionSection;
                                        if (isTracked) {
                                            actionSection = (
                                                <ArchiveAction
                                                    size={22}
                                                    onClick={onUntrack}
                                                    title="Mark as Dormant">
                                                    Mark as Dormant
                                                </ArchiveAction>
                                            );
                                        } else {
                                            actionSection = (
                                                <RestoreAction
                                                    size={22}
                                                    onClick={onRestore}
                                                    title="Restore">
                                                    Restore
                                                </RestoreAction>
                                            );
                                        }

                                        let warningText;
                                        if (isTracked !== isTrackedBase) {
                                            warningText = isTracked ? 'This firm will no longer be dormant upon save.' : 'This firm will be dormant upon save.';
                                        }
                                        return (
                                            <Fragment key={index}>
                                                <TierRowForm _id={_id} name={name} isTracked={isTracked} index={index}>
                                                    {actionSection}
                                                </TierRowForm>
                                                {warningText ? <div>{warningText}</div> : null}
                                                <Divider />
                                            </Fragment>
                                        );
                                    });
                                }}
                            />
                            <StandardRow>
                                <Col flex>
                                    <GreenButton type="reset" onClick={onCancel}>
                                        Cancel
                                    </GreenButton>
                                </Col>
                                <Col flex>
                                    <GreenButton type="submit">
                                        Save
                                    </GreenButton>
                                </Col>

                            </StandardRow>

                        </Form>
                    );
                }}
            />
        </Fragment>
    );
};

export default compose(
    graphql(UPDATE_FIRM_TIERS, { name: 'updateFirmTiers' })
)(TierForm);
