import React from 'react';
import { graphql } from 'react-apollo';
import { get } from 'lodash';
import styled from 'styled-components';

import Box from '-/style/base/Box';
import Subhead from '-/style/base/Subhead';
import SubSubHead from '-/style/base/SubSubHead';
import { GET_COMPANY_MONDAY_REPORT_PT_REVIEW } from '-/gql/company';
import LoadingLogo from '-/components/LoadingLogo';
import { StyledEmptyRow } from '-/style/base/Table';

import { groupByPrimaryThemePriority } from './utils';
import { PriorityTargetReviewRow } from './StandardRow';
import StandardTable from './StandardTable';
import { PriorityTargetReviewHeader } from './StandardHeader';

const Section = styled.div`
    margin-bottom: 15px;
`;
const HorizScroller = styled.div`
    overflow-x: scroll;
`;
const EmptyRow = <StyledEmptyRow colSpan={8}>No companies.</StyledEmptyRow>;

const PriorityTargetReview = props => {
    const { data = {} } = props;
    const { loading } = data;
    const companies = get(data, 'CompanyMondayReport.ptReview', []);
    const { focus = [], nonFocus = [], avoid = [] } = groupByPrimaryThemePriority(companies);
    if (loading) {
        return <LoadingLogo />;
    }
    return (
        <Box>
            <Subhead>Priority Target Review</Subhead>
            <Section>
                <SubSubHead>Focus</SubSubHead>
                <HorizScroller>
                    <StandardTable
                        minWidth={1300}
                        data={focus}
                        Row={PriorityTargetReviewRow}
                        Header={PriorityTargetReviewHeader}
                        EmptyRow={EmptyRow}
                    />
                </HorizScroller>
            </Section>
            <Section>
                <SubSubHead>Non-Focus</SubSubHead>
                <HorizScroller>
                    <StandardTable
                        minWidth={1300}
                        data={nonFocus}
                        Row={PriorityTargetReviewRow}
                        Header={PriorityTargetReviewHeader}
                        EmptyRow={EmptyRow}
                    />
                </HorizScroller>
            </Section>
            <Section>
                <SubSubHead>Avoid</SubSubHead>
                <HorizScroller>
                    <StandardTable
                        minWidth={1300}
                        data={avoid}
                        Row={PriorityTargetReviewRow}
                        Header={PriorityTargetReviewHeader}
                        EmptyRow={EmptyRow}
                    />
                </HorizScroller>
            </Section>
        </Box>
    );
};

export default graphql(GET_COMPANY_MONDAY_REPORT_PT_REVIEW)(PriorityTargetReview);
