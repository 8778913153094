import React, { Fragment } from 'react';
import { SimpleLabel, StyledDisplayField } from '-/components/profiles';
import InlineEditSection from '-/components/profiles/forms/InlineEditSection';

const EditableField = props => {
    const {
        className,
        style,
        inEditMode = false,
        form,
        label,
        children
    } = props;

    let valueWrapper;
    if (form) {
        valueWrapper = (
            <InlineEditSection inEditMode={inEditMode} form={form}>
                {children}
            </InlineEditSection>
        );
    } else {
        valueWrapper = <Fragment>{children}</Fragment>;
    }

    return (
        <StyledDisplayField className={className} style={style}>
            <SimpleLabel>{label}:</SimpleLabel>
            {valueWrapper}
        </StyledDisplayField>
    );
};

export default EditableField;
