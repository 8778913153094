import React from 'react';
import StdDashboard from './std';
import LpDashboard from './lp';

const Dashboard = props => {
    const { side = 'std', ...rest } = props;
    if (side === 'lp') {
        return <LpDashboard {...rest} />;
    } else {
        return <StdDashboard {...rest} />;
    }
};

export default Dashboard;
