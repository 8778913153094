import React from 'react';
import { pick, get, set, orderBy } from 'lodash';
import Box from '-/style/base/Box';
import { Col } from '-/style/base/Layout';
import { Formik, Form } from 'formik';
import { ADD_LP, GET_LP, UPDATE_LP } from '-/gql/lp';
import { graphql, compose } from 'react-apollo';
import {
    SectionA,
    SectionB,
    Container,
    StandardRow
} from '-/style/base/Layout';
import FormHeader from './Header';
import FirmSection from './FirmSection';
import PeopleSection from './PeopleSection';
import ReferralsSection from './ReferralsSection';
import SummarySection from './SummarySection';
import IconFitSection from './IconFitSection';
import CommitmentsSection from './CommitmentsSection';
import { withRouter } from 'react-router-dom';
import { GreenButton as GreenButtonRaw } from '-/style/base/Button';
import styled from 'styled-components';
const GreenButton = styled(GreenButtonRaw)`
    margin: 0 10px 0 10px;
`;

// Ref: https://github.com/apollographql/apollo-client/issues/2160
const removeTypename = value => {
    if (value === null || value === undefined) {
        return value;
    } else if (Array.isArray(value)) {
        return value.map(v => removeTypename(v));
    } else if (typeof value === 'object') {
        const newObj = {};
        Object.entries(value).forEach(([key, v]) => {
            if (key !== '__typename') {
                newObj[key] = removeTypename(v);
            }
        });
        return newObj;
    }
    return value;
};

const renderForm = props => {
    const {
        permalink,
        values,
        handleChange,
        handleCancel,
        mode,
        loading
    } = props;
    const title = mode === 'add' ? 'Add New LP' : 'Edit LP';
    const formSections = [
        FirmSection,
        PeopleSection,
        ReferralsSection,
        SummarySection,
        IconFitSection,
        CommitmentsSection
    ];
    return (
        <Form>
            <Container>
                <FormHeader permalink={permalink}>
                    {title} {loading ? 'Loading' : null}
                </FormHeader>

                <Box padding={false}>
                    {formSections.map((FormSection, i) => {
                        const isEven = i % 2 === 0;
                        const Wrapper = isEven ? SectionA : SectionB;
                        return (
                            <Wrapper key={i}>
                                <FormSection
                                    values={values}
                                    onChange={handleChange}
                                />
                            </Wrapper>
                        );
                    })}

                    <StandardRow>
                        <Col
                            flex
                            style={{
                                textAlign: 'right',
                                marginTop: 20,
                                marginBottom: 20
                            }}
                        >
                            <GreenButton type="submit">Save</GreenButton>
                            <GreenButton type="button" onClick={handleCancel}>
                                Cancel
                            </GreenButton>
                        </Col>
                    </StandardRow>
                </Box>
            </Container>
        </Form>
    );
};

const lpForm = props => {
    const { addLP, updateLP, data: { LP = {} } = {}, history } = props;
    const loading = get(props, 'data.loading');
    const permalink = get(props, 'match.params.permalink');
    const mode = permalink ? 'edit' : 'add';
    return (
        <Formik
            initialValues={removeTypename(LP)}
            enableReinitialize={true}
            onSubmit={async (values, formBag) => {
                try {
                    const existingId = LP._id;
                    const input = pick(values, [
                        'name',
                        'phone',
                        'address',
                        'people',
                        'referredBy',
                        'iconFit',
                        'summary',
                        'commitments'
                    ]);
                    input.people = input.people.filter(p => {
                        return p.name || p.title || p.email || p.phone;
                    });
                    input.people = orderBy(input.people, ['primary'], ['desc']);
                    const hasPrimary = input.people.some(p => p.primary);
                    if (!hasPrimary) {
                        set(input.people, '0.primary', true);
                    }

                    let result, newpermalink;
                    if (existingId) {
                        result = await updateLP({
                            variables: {
                                _id: existingId,
                                input
                            }
                        });
                        newpermalink = get(result, 'data.updateLP.permalink');
                    } else {
                        result = await addLP({
                            variables: {
                                input
                            }
                        });
                        newpermalink = get(result, 'data.addLP.permalink');
                    }
                    history.push(`/lp/view/${newpermalink}`);
                } catch (ex) {
                    console.log('Failed', ex);
                }
            }}
            render={props => {
                return renderForm({
                    mode,
                    permalink,
                    loading,
                    handleCancel: () => {
                        history.goBack();
                    },
                    ...props
                });
            }}
        />
    );
};

const skipIfEdit = props => {
    const haspermalink = get(props, 'match.params.permalink');
    return haspermalink;
};
const skipIfNew = props => {
    return !skipIfEdit(props);
};

export default compose(
    withRouter,
    graphql(ADD_LP, {
        skip: skipIfEdit,
        name: 'addLP'
    }),
    graphql(UPDATE_LP, {
        skip: skipIfNew,
        name: 'updateLP'
    }),
    graphql(GET_LP, {
        skip: skipIfNew,
        options: props => {
            const permalink = get(props, 'match.params.permalink');
            return {
                variables: {
                    permalink
                }
            };
        }
    })
)(lpForm);
