import styled from 'styled-components';
import { nearlyBlack } from '../variables';

export default styled.div`
    border: 1px solid ${nearlyBlack};
    margin: 15px;
    padding: 15px;
    background-color: #cfcfcf;
    color: black;
`;
