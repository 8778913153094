import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { font } from './mixins';
import { lightBg, nearlyBlack } from './variables';

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  body {
    ${font()}
    background-color: ${lightBg};
    color: ${nearlyBlack};
    a {
      text-decoration: none;
      color: ${nearlyBlack};
    }
    input,textarea,button,h1,h2,h3,h4,h5,a,pre {
      font-family:'AvenirNextLTW01-Regular';
    }
  }
  .tether-element {
    z-index: 3;
  }
  .semrush-chart {
    .recharts-tooltip-wrapper {
      text-align: left;
      .recharts-tooltip-item-list {
        .recharts-tooltip-item-name {
          width: 60px;
          display: inline-block;
        }
        .recharts-tooltip-item-value {
          width: 140px;
          text-align: right;
        }
      }
    }
  }
`;

export default GlobalStyle;
