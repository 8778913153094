import React, { Fragment } from 'react';
import CompanyQuery from './CompanyQuery';
import { GET_COMPANY_FINANCING } from '-/gql/company';
import { formatDate, formatMillion, formatSeries } from '-/utils/format';
import { FirmLink, PersonLink } from '-/components/Link';
import styled from 'styled-components';
import { SimpleLabel, SimpleValue } from '-/components/profiles';
import { withProps } from 'recompose';
import Divider from '-/style/base/Divider';

const StyledRound = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const StyledSeriesHead = styled(SimpleLabel)`
    text-transform: uppercase;
    margin-bottom: 5px;
`;

const InvestorWrap = styled.div`
    margin-top: 5px;
`;

const StyledCsvUl = styled.ul`
    display: inline;
    list-style: none;
    padding: 0;
`;
const StyledCsvLi = styled.li`
    display: inline;
    ::after {
        content: ', ';
    }
    :last-child::after {
        content: '';
    }
`;

const SeriesHead = props => {
    const { value = '' } = props;
    const seriesText = formatSeries(value);
    return <StyledSeriesHead>{seriesText}</StyledSeriesHead>;
};

const FirmInvestor = props => {
    const { _id, name, isTracked } = props;
    if (isTracked) {
        return <FirmLink permalink={_id}>{name}</FirmLink>;
    } else {
        return name;
    }
};

const FirmInvestors = props => {
    const { value = [] } = props;
    if (value && value.length) {
        return (
            <InvestorWrap>
                <SimpleLabel>Investors:</SimpleLabel>
                <StyledCsvUl>
                    {value.filter(Boolean).map(o => {
                        const { _id } = o;
                        return (
                            <StyledCsvLi key={_id}>
                                <FirmInvestor {...o} />
                            </StyledCsvLi>
                        );
                    })}
                </StyledCsvUl>
            </InvestorWrap>
        );
    } else {
        return null;
    }
};

const PersonInvestor = props => {
    const { _id, name, isWhitelisted } = props;
    if (isWhitelisted) {
        return <PersonLink permalink={_id}>{name}</PersonLink>;
    } else {
        return name;
    }
};

const PersonInvestors = props => {
    const { value = [] } = props;
    if (value && value.length) {
        return (
            <InvestorWrap>
                <SimpleLabel>Individual Investors:</SimpleLabel>
                <StyledCsvUl>
                    {value.filter(Boolean).map(o => {
                        const { _id } = o;
                        return (
                            <StyledCsvLi key={_id}>
                                <PersonInvestor {...o} />
                            </StyledCsvLi>
                        );
                    })}
                </StyledCsvUl>
            </InvestorWrap>
        );
    } else {
        return null;
    }
};

const Round = props => {
    const {
        series,
        amount,
        date,
        valuation,
        firmInvestors,
        personInvestors
    } = props;

    return (
        <StyledRound>
            <SeriesHead value={series} />
            <SimpleValue>
                {amount ? (
                    <span>
                        Round:&nbsp;{' '}
                        {amount ? formatMillion(amount) + '. ' : ''}
                    </span>
                ) : null}
                <span>Date:&nbsp;{date ? formatDate(date) : 'N/A'}</span>
            </SimpleValue>
            <SimpleValue>
                <span>Valuation:&nbsp; {formatMillion(valuation)}</span>
            </SimpleValue>
            <FirmInvestors value={firmInvestors} />
            <PersonInvestors value={personInvestors} />
        </StyledRound>
    );
};

const FinancingView = props => {
    const { fundingRounds = [] } = props;

    const roundCount = fundingRounds.length;
    if (roundCount) {
        const totalFunding = fundingRounds.reduce((total, round) => {
            const { amount = 0 } = round;
            if (isFinite(amount)) {
                total += amount;
            }
            return total;
        }, 0);

        return (
            <div>
                <div>
                    Raised {formatMillion(totalFunding)} in {roundCount} round
                    {roundCount === 1 ? '' : 's'}.
                </div>
                {fundingRounds.map((o, i) => {
                    return (
                        <Fragment key={o._id}>
                            <Round {...o} />
                            {i !== roundCount - 1 ? <Divider /> : null}
                        </Fragment>
                    );
                })}
            </div>
        );
    } else {
        return 'No funding rounds recorded.';
    }
};

export default withProps({
    query: GET_COMPANY_FINANCING,
    component: FinancingView
})(CompanyQuery);
