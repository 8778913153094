import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import nl2br from '-/components/nl2br';
import { formatDate } from '-/utils/format';
import {
    StyledTable,
    StyledTr,
    StyledTd,
    StyledTh,
    StyledEmptyRow
} from '-/style/base/Table';
import { TrashAction } from '-/style/Icon';
import { materialsSentTypeMap } from '-/shared/constants';
import OverlayWithState from '-/components/OverlayWithState';
import MessageBox from '-/components/MessageBox';
import styled from 'styled-components';
import { PersonLink } from '-/components/Link';
const StrippedTd = styled(StyledTd)`
    padding-left: 0;
    padding-right: 0;
`;

const NoteHeader = props => {
    const { editable, showEntity } = props;
    return (
        <thead>
            <tr>
                <StyledTh style={{ width: 85 }}>Date</StyledTh>
                {showEntity && <StyledTh style={{ width: 120 }}>VC</StyledTh>}
                <StyledTh>Note</StyledTh>
                <StyledTh style={{ width: 105 }}>Type</StyledTh>
                {editable && (
                    <StyledTh hideWhenPrint={true} style={{ width: 60 }}>
                        {' '}
                    </StyledTh>
                )}
            </tr>
        </thead>
    );
};
const NoteRow = props => {
    const {
        _id,
        arr,
        index,
        date,
        text,
        type,
        entity: { _id: entityId, name: entityName } = {},
        materialsSent,
        removeNote,
        Form: NoteForm,
        editable,
        showEntity
    } = props;

    const showMaterials = materialsSent && materialsSent.length !== 0;
    return (
        <Fragment>
            <StyledTr index={index}>
                <StyledTd>{formatDate(date)}</StyledTd>
                {showEntity && (
                    <StyledTd>
                        <PersonLink permalink={entityId}>
                            {entityName}
                        </PersonLink>
                    </StyledTd>
                )}
                <StyledTd>{nl2br(text)}</StyledTd>
                <StyledTd>{type}</StyledTd>
                {editable && (
                    <StrippedTd hideWhenPrint={true} rowSpan={showMaterials ? 2 : 1}>
                        <OverlayWithState
                            arrowSide="left"
                            vAttachment="top"
                            hAttachment="left"
                            vTargetAttachment="top"
                            hTargetAttachment="right"
                            closeOnEsc
                        >
                            <NoteForm
                                _id={_id}
                                initialValues={{
                                    arr,
                                    date,
                                    text,
                                    type,
                                    materialsSent
                                }}
                            />
                        </OverlayWithState>
                        <TrashAction
                            size={16}
                            onClick={() => {
                                MessageBox.show({
                                    message:
                                        'Are you sure you would like to remove this note?',
                                    onYesClick: async () => {
                                        await removeNote({
                                            variables: { _id }
                                        });
                                    }
                                });
                            }}
                        >
                            Remove
                        </TrashAction>
                    </StrippedTd>
                )}
            </StyledTr>
            {arr && (
                <StyledTr index={index}>
                    <StyledTd />
                    <StyledTd colSpan={editable ? 3 : 2}>
                        ARR: {' '}
                        ${arr}M
                    </StyledTd>
                </StyledTr>
            )}
            {showMaterials && (
                <StyledTr index={index}>
                    <StyledTd />
                    <StyledTd colSpan={editable ? 3 : 2}>
                        Materials Sent:{' '}
                        {materialsSent
                            .map(key => materialsSentTypeMap[key])
                            .join(', ')}
                    </StyledTd>
                </StyledTr>
            )}
        </Fragment>
    );
};
const NoteList = props => {
    const {
        className,
        style,
        showEntity,
        notes = [],
        Form,
        removeNote,
        editable = false
    } = props;

    const isEmpty = notes.length === 0;
    return (
        <div className={className} style={style}>
            <StyledTable>
                <NoteHeader editable={editable} showEntity={showEntity} />
                <tbody>
                    {isEmpty ? (
                        <StyledEmptyRow colSpan={showEntity ? 5 : 4}>
                            No notes found.
                        </StyledEmptyRow>
                    ) : null}
                    {notes.map((o, index) => {
                        const { _id } = o;
                        return (
                            <NoteRow
                                key={_id}
                                index={index}
                                Form={Form}
                                removeNote={removeNote}
                                editable={editable}
                                showEntity={showEntity}
                                {...o}
                            />
                        );
                    })}
                </tbody>
            </StyledTable>
        </div>
    );
};
NoteList.defaultProps = {
    showEntity: false
};
NoteList.propTypes = {
    removeNote: PropTypes.func.isRequired,
    notes: PropTypes.array,
    Form: PropTypes.func
};

export default NoteList;
