import React from 'react';
import styled from 'styled-components';
import OverlayWithState from '-/components/OverlayWithState';
import { lightSand } from '-/style/variables';
import { Row, Col } from '-/style/base/Layout';
import PropTypes from 'prop-types';

const StyledEditSection = styled.div`
    background-color: ${lightSand};
    padding: 5px;
    margin-right: 10px;
`;

const InlineEditSection = props => {
    const { inEditMode, children, form } = props;
    if (!inEditMode) {
        return children;
    } else {
        return (
            <Row>
                <Col flex>
                    <StyledEditSection>{children}</StyledEditSection>
                </Col>
                <Col>
                    <OverlayWithState
                        arrowSide="left"
                        vAttachment="top"
                        hAttachment="left"
                        vTargetAttachment="top"
                        hTargetAttachment="right"
                        closeOnOutsideClick={false}
                        closeOnEsc
                    >
                        {form}
                    </OverlayWithState>
                </Col>
            </Row>
        );
    }
};
InlineEditSection.propTypes = {
    inEditMode: PropTypes.bool,
    children: PropTypes.node,
    form: PropTypes.element
};

export default InlineEditSection;
